import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import RadioStations from "./RadioStations";
import BillBoardFundWallet from "../billBoardCampaign/BillBoardFundWallet";
import RadioCampaign from "./RadioCampaign";
import PreviewRadioCampaign from "./PreviewRadioCampaign";
import RadioMedia from "./RadioMedia";

const RadioStepForm = () => {
	const [selectedStation, setSelectedStation] = useState({
		id: 0,
		language: "",
	});

	const [jingleFee, setJingleFee] = useState(0);
	const [arconFee, setArconFee] = useState(0);
	const [radioAdsData, setRadioAdsData] = useState({
		step: 1,
		selectedSession: "{}",
		session: "",
		campaignDays: "",
		price: 0,
		jinglePrice: jingleFee,
		jingleLength: 0,
		createJingle: false,
		sessionCost: 0,
		selectedRate: 0,
		stationId: selectedStation.id,
		adType: "Jingle",
		attachment: "",
		arconAttachment: "",
		campaignMessage: "",
		hasJingle: true,
		hasArcon: true,
		doNotRequireArcon: false,
		spot: 0,
		selectedSpot: 0,
		numOfDays: 1,
		startDate: "",
		selectedFileName: "Upload Audio Asset *mp3, *mpeg",
		selectedArconFileName: "Upload ARCON License *pdf, *image",
		uploadPercentage: "",
		uploadArconPercentage: "",
		terms: false,
		arconTerms: false,
		name: "",
		sessionName: "",
		language: selectedStation.language,
		announcementWordCount: 0,
		arconType: "",
	});

	useEffect(() => {
		setRadioAdsData((prevRadioAdsData) => {
			return {
				...prevRadioAdsData,
				stationId: selectedStation.id,
				language: selectedStation.language,
			};
		});
	}, [selectedStation.id, selectedStation.language]);

	useEffect(() => {
		setRadioAdsData((prevRadioAdsData) => {
			return {
				...prevRadioAdsData,
				jinglePrice: jingleFee,
			};
		});
	}, [jingleFee]);

	const {
		step,
		session,
		spot,
		campaignDays,
		startDate,
		hasJingle,
		hasArcon,
		doNotRequireArcon,
		selectedFileName,
		selectedArconFileName,
		uploadPercentage,
		uploadArconPercentage,
		campaignMessage,
		terms,
		arconTerms,
		price,
		jinglePrice,
		adType,
		numOfDays,
		selectedSession,
		selectedSpot,
		selectedRate,
		attachment,
		arconAttachment,
		announcementWordCount,
		arconType,
	} = radioAdsData;

	// go back to previous step
	const prevStep = () => {
		setRadioAdsData({ ...radioAdsData, step: step - 1 });
	};

	// proceed to the next step
	const nextStep = () => {
		setRadioAdsData({ ...radioAdsData, step: step + 1 });
	};

	// const checkAudioLength = (audioUrl) => {
	// 	const audio = new Audio(audioUrl);
	// 	audio.addEventListener("loadedmetadata", () => {
	// 		const audioDuration = audio.duration; // Duration in seconds
	// 		return audioDuration;
	// 	});
	// };
	// console.log(
	// 	"🚀 ~ file: RadioStepForm.js:123 ~ checkAudioLength ~ checkAudioLength:",
	// 	checkAudioLength(
	// 		"https://res.cloudinary.com/mysogi/video/upload/v1686297069/assests/u4ziucxeskfrxvmoeieg.mp3"
	// 	)
	// );

	const uploadAsset = useCallback(async (uploadedFile) => {
		let file = uploadedFile.target.files[0];
		const maxFileSize = 31457280;
		const arconMaxFileSize = 2097152;

		const setMaxSize = () => {
			if (uploadedFile.target.name === "jingle") {
				return maxFileSize;
			} else {
				return arconMaxFileSize;
			}
		};

		const setValidationSize = () => {
			if (uploadedFile.target.name === "jingle") {
				return maxFileSize / (1024 * 1024);
			} else {
				return arconMaxFileSize / (1024 * 1024);
			}
		};

		if (file.size > setMaxSize()) {
			toast.error(
				`The selected image file is too big. Please choose one that is smaller than ${setValidationSize()}MB.`
			);
			return;
		}

		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "mysogi");

		const options = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total);

				if (percent < 100) {
					if (uploadedFile.target.name === "jingle") {
						setRadioAdsData((prevState) => ({
							...prevState,
							uploadPercentage: percent,
						}));
					} else {
						setRadioAdsData((prevState) => ({
							...prevState,
							uploadArconPercentage: percent,
						}));
					}
				}
			},
		};

		try {
			const res = await axios.post(
				uploadedFile.target.name === "jingle"
					? process.env.REACT_APP_CLOUDINARY_VIDEO_URL
					: process.env.REACT_APP_CLOUDINARY_URL,
				formData,
				options
			);
			const assetUrl = res.data.secure_url;
			if (uploadedFile.target.name === "jingle") {
				setRadioAdsData((prevState) => ({
					...prevState,
					uploadPercentage: 100,
				}));
				setTimeout(() => {
					setRadioAdsData((prevState) => ({
						...prevState,
						uploadPercentage: 0,
					}));
				}, 1000);
			} else {
				setRadioAdsData((prevState) => ({
					...prevState,
					uploadArconPercentage: 100,
				}));
				setTimeout(() => {
					setRadioAdsData((prevState) => ({
						...prevState,
						uploadArconPercentage: 0,
					}));
				}, 1000);
			}
			return assetUrl;
		} catch (err) {
			toast.error(err);
			throw err;
		}
	}, []);

	const handleFIleUpload = useCallback(
		async (e) => {
			let file = e.target.files[0];

			const assetUrl = await uploadAsset(e);
			if (e.target.name === "jingle") {
				setRadioAdsData((prevState) => ({
					...prevState,
					attachment: assetUrl,
					selectedFileName: file.name,
					// jingleLength: checkAudioLength(assetUrl),
				}));
			} else {
				setRadioAdsData((prevState) => ({
					...prevState,
					arconAttachment: assetUrl,
					selectedArconFileName: file.name,
				}));
			}
		},
		[uploadAsset]
	);

	// Handle fields change
	const handleChange = (input) => (e) => {
		const maxValue = 365;
		if (input === "hasJingle") {
			setRadioAdsData({
				...radioAdsData,
				[input]: e.target.value === "true",
			});
		} else if (input === "hasArcon") {
			setRadioAdsData({
				...radioAdsData,
				[input]: e.target.value === "true",
				doNotRequireArcon: false,
			});
		} else if (input === "doNotRequireArcon") {
			setRadioAdsData({
				...radioAdsData,
				[input]: e.target.value === "true",
				hasArcon: false,
			});
		} else if (input === "terms") {
			const isChecked = e.target.checked;
			if (isChecked) {
				setRadioAdsData({
					...radioAdsData,
					[input]: true,
					createJingle: true,
				});
			} else {
				setRadioAdsData({
					...radioAdsData,
					[input]: false,
					createJingle: false,
				});
			}
		} else if (input === "arconTerms") {
			const isChecked = e.target.checked;
			if (isChecked) {
				setRadioAdsData({
					...radioAdsData,
					[input]: true,
					createArcon: true,
				});
			} else {
				setRadioAdsData({
					...radioAdsData,
					[input]: false,
					createArcon: false,
				});
			}
		} else if (input === "selectedSession") {
			setRadioAdsData({
				...radioAdsData,
				selectedSession: JSON.parse(e.target.value),
				session: JSON.parse(e.target.value).session,
				sessionCost: JSON.parse(e.target.value).rate,
				spot: 0,
				selectedSpot: JSON.parse(e.target.value).spot,
				selectedRate: parseInt(JSON.parse(e.target.value).rate),
				announcementWordCount: parseInt(
					JSON.parse(e.target.value).announcementWordCount
						? JSON.parse(e.target.value).announcementWordCount
						: 0
				),
			});
		} else if (input === "numOfDays") {
			if (e.target.value <= maxValue || e.target.value === "") {
				setRadioAdsData({ ...radioAdsData, numOfDays: e.target.value });
			}
		} else if (input === "campaignMessage") {
			setRadioAdsData({ ...radioAdsData, [input]: e });
		} else if (input === "2days") {
			setRadioAdsData({
				...radioAdsData,
				arconType: input,
				arconTerms: true,
			});
		} else if (input === "2weeks") {
			setRadioAdsData({
				...radioAdsData,
				arconType: input,
				arconTerms: true,
			});
		} else {
			setRadioAdsData({ ...radioAdsData, [input]: e.target.value });
		}
	};

	const getArconFee = () => {
		if (arconType === "2weeks") {
			return arconFee.arcon_2weeks;
		} else if (arconType === "2days") {
			return arconFee.arcon_2days;
		} else return 0;
	};

	const setPrice = () => {
		const basePrice =
			parseInt(selectedRate) * parseInt(spot) * parseInt(numOfDays);
		let totalPrice = parseInt(basePrice);

		if (adType === "Jingle") {
			if (!hasJingle && terms && !hasArcon && arconTerms) {
				totalPrice += parseInt(jinglePrice) + getArconFee();
			} else if (!hasJingle && terms && hasArcon) {
				totalPrice += parseInt(jinglePrice);
			} else if (!hasJingle && terms && !hasArcon && !arconTerms) {
				totalPrice += parseInt(jinglePrice);
			} else if (hasJingle && !terms && !hasArcon && arconTerms) {
				totalPrice += getArconFee();
			} else if (!hasJingle && !terms && !hasArcon && arconTerms) {
				totalPrice += getArconFee();
			} else if (hasJingle && terms && !hasArcon && arconTerms) {
				totalPrice += getArconFee();
			} else {
				totalPrice += 0;
			}
		}

		setRadioAdsData({
			...radioAdsData,
			price: parseInt(totalPrice),
		});
	};

	const handleDeleteAttachment = () => {
		setRadioAdsData({
			...radioAdsData,
			attachment: "",
			selectedFileName: "Upload Audio Asset *mp3, *mpeg",
		});
	};

	const handleKeyDown = (event) => {
		const maxValue = 365;
		const keyCode = event.which || event.keyCode;
		const inputValue = parseInt(
			event.target.value + String.fromCharCode(keyCode)
		);

		if (inputValue > maxValue) {
			toast.error("Max number of days is 365");
			event.preventDefault();
		}
	};

	useEffect(() => {
		setPrice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		price,
		spot,
		numOfDays,
		terms,
		adType,
		arconType,
		hasJingle,
		hasArcon,
		arconTerms,
	]);

	const constructSpot = (spot) => {
		const arr = [
			{
				label: "Select No of spot daily",
				value: 0,
			},
		];
		for (let i = 1; i <= spot; i++) {
			arr.push({ value: i, label: i });
		}
		return arr;
	};

	switch (step) {
		case 1:
			return (
				<RadioStations
					nextStep={nextStep}
					setSelectedStation={setSelectedStation}
					setJingleFee={setJingleFee}
					setArconFee={setArconFee}
				/>
			);
		case 2:
			return (
				<RadioCampaign
					adType={adType}
					campaignDays={campaignDays}
					selectedSpot={selectedSpot}
					spot={spot}
					session={session}
					price={price}
					numOfDays={numOfDays}
					selectedStation={selectedStation}
					selectedSession={selectedSession}
					constructSpot={constructSpot}
					nextStep={nextStep}
					prevStep={prevStep}
					handleChange={handleChange}
					handleKeyDown={handleKeyDown}
				/>
			);
		case 3:
			return (
				<RadioMedia
					campaignDays={campaignDays}
					session={session}
					startDate={startDate}
					hasJingle={hasJingle}
					hasArcon={hasArcon}
					uploadPercentage={uploadPercentage}
					uploadArconPercentage={uploadArconPercentage}
					selectedFileName={selectedFileName}
					selectedArconFileName={selectedArconFileName}
					campaignMessage={campaignMessage}
					price={price}
					jinglePrice={jinglePrice}
					arconFee={arconFee}
					doNotRequireArcon={doNotRequireArcon}
					terms={terms}
					arconTerms={arconTerms}
					adType={adType}
					attachment={attachment}
					arconAttachment={arconAttachment}
					handleFIleUpload={handleFIleUpload}
					nextStep={nextStep}
					prevStep={prevStep}
					handleChange={handleChange}
					handleDeleteAttachment={handleDeleteAttachment}
					announcementWordCount={announcementWordCount}
					arconType={arconType}
				/>
			);
		case 4:
			return (
				<PreviewRadioCampaign
					radioAdsData={radioAdsData}
					nextStep={nextStep}
					prevStep={prevStep}
					handleChange={handleChange}
					selectedStation={selectedStation}
					arconFee={getArconFee()}
				/>
			);
		case 5:
			return (
				<BillBoardFundWallet
					nextStep={nextStep}
					prevStep={prevStep}
					price={price}
				/>
			);
		default:
		// do nothing
	}
};

export default RadioStepForm;
