import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../loader";
import NumberFormat from "react-number-format";
import AppDownloadActionsChart from "./AppDownload Chart/AppDownloadActionsChart";

import {
	getAppdownloadAnalyticsAction,
	clearErrors,
} from "../../../actions/analyticsActions";
import MetaData from "../../layout/MetaData";

const DigitalAnalytics = () => {
	const { campaignId } = useParams();
	const dispatch = useDispatch();

	const { analyticsLoading, error, appDownloadAnalytics } = useSelector(
		(state) => state.appDownloadAnalyticsData || {}
	);

	const formatNumber = (num) => {
		if (num >= 1000) {
			// Divide the number by 1000 and round it to one decimal place
			num = (num / 1000).toFixed(1) + "k";
		}
		return num;
	};

	useEffect(() => {
		dispatch(getAppdownloadAnalyticsAction(campaignId));
	}, [dispatch, campaignId]);

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch(clearErrors());
		}
	}, [dispatch, error]);

	return (
		<Fragment>
			{analyticsLoading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Digital Campaign Analytics"} />
					<div className="content-body">
						<div className="container pd-x-0">
							<div className="row justify-content-between">
								<div className="col-md-6 col-12">
									<Link to="../analytics">
										<p className="tx-26 tx-bold">Go back</p>
									</Link>
								</div>
							</div>
							<div className="col-md-12 col-12 mg-t-20 mg-md-t-0">
								<div className="card rounded bd-0 shadow-sm">
									<div className="card-body">
										<div className="analytics-data">
											<div className="analytics-card analytics-card-color">
												<h4 className="tx-white tx-bold tx-18">
													CTR
												</h4>
												<h2 className="tx-white tx-normal">
													{formatNumber(
														appDownloadAnalytics?.ctr
													)}
												</h2>
											</div>
											<div className="analytics-card analytics-card-color">
												<h4 className="tx-white tx-bold tx-18">
													Impressions
												</h4>
												<h2 className="tx-white tx-normal">
													{formatNumber(
														appDownloadAnalytics?.impressions
													)}
												</h2>
											</div>
											<div className="analytics-card analytics-card-color">
												<h4 className="tx-white tx-bold tx-18">
													Clicks
												</h4>
												<h2 className="tx-white tx-normal">
													{formatNumber(
														appDownloadAnalytics?.clicks
													)}
												</h2>
											</div>
											<div className="analytics-card analytics-card-color">
												<h4 className="tx-white tx-bold tx-18">
													CPI
												</h4>
												<h2 className="tx-white tx-normal">
													<NumberFormat
														value={
															appDownloadAnalytics?.cpi
														}
														displayType={"text"}
														thousandSeparator={true}
														prefix={"₦"}
													/>
												</h2>
											</div>
										</div>
										<AppDownloadActionsChart
											appDownloadAnalytics={
												appDownloadAnalytics
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default DigitalAnalytics;
