import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { MDBDataTable } from "mdbreact";
import { toast } from "react-toastify";

import Loader from "../../loader";
import MetaData from "../../layout/MetaData";
import { getSenderID, clearErrors } from "../../../actions/senderIDActions";

const SenderID = () => {
	const { loading, error, senderID } = useSelector(
		(state) => state.senderID || []
	);
	const dispatch = useDispatch();

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch(clearErrors());
		}
	}, [dispatch, error]);

	useEffect(() => {
		dispatch(getSenderID());
	}, [dispatch]);

	const setSenderID = () => {
		const data = {
			columns: [
				{
					label: "SENDER ID",
					field: "senderId",
					sort: "asc",
				},
				{
					label: "DATE REQUESTED",
					field: "dataRequested",
					sort: "asc",
				},
				{
					label: "STATUS",
					field: "status",
				},
				{
					label: "ADDITIONAL INFO",
					field: "additionalInfo",
				},
				// {
				// 	label: "ACTION",
				// 	field: "actions",
				// },
			],
			rows: [],
		};

		senderID?.forEach((senderids) => {
			data.rows.push({
				// id: senderids._id,
				senderId: senderids.senderId,
				dataRequested: DateTime.fromJSDate(
					new Date(senderids.createdAt)
				).toFormat("dd MMM yyyy"),
				status: (
					<span
						className={`badge d-flex-center ${
							senderids.telcoStatus === null ||
							senderids.telcoStatus === "pending"
								? "badge-pink"
								: ""
						} ${
							senderids.telcoStatus === "approved"
								? "badge-active"
								: ""
						} ${
							senderids.telcoStatus === "declined"
								? "badge-primary"
								: ""
						}`}
					>
						{senderids.telcoStatus === "pending" ||
						senderids.telcoStatus === null
							? "Pending"
							: null || senderids.telcoStatus === "declined"
							? "Declined"
							: null || senderids.telcoStatus === "approved"
							? "Approved"
							: null}
					</span>
				),
				additionalInfo: senderids.additionalInfo
					? senderids.additionalInfo
					: "-",
				// actions: (
				// 	<Fragment>
				// 		<div className="dropdown">
				// 			<span
				// 				className
				// 				type="button"
				// 				id="dropdownMenuButton"
				// 				data-toggle="dropdown"
				// 				aria-haspopup="true"
				// 				aria-expanded="false"
				// 			>
				// 				<span className="dot" />
				// 				<span className="dot" />
				// 				<span className="dot" />
				// 			</span>
				// 			<div
				// 				className="dropdown-menu"
				// 				aria-labelledby="dropdownMenuButton"
				// 			>
				// 				<a
				// 					className="dropdown-item"
				// 					href="./view-sender.html"
				// 				>
				// 					{" "}
				// 					<i
				// 						data-feather="eye"
				// 						className="favourite-icon mr-2 wd-15 ht-15"
				// 					/>
				// 					View
				// 				</a>
				// 				<a className="dropdown-item" href>
				// 					{" "}
				// 					<i
				// 						data-feather="edit"
				// 						className="favourite-icon mr-2 wd-15 ht-15"
				// 					/>
				// 					Edit
				// 				</a>
				// 				<a className="dropdown-item" href="#">
				// 					<i
				// 						data-feather="trash-2"
				// 						className="favourite-icon mr-2 wd-15 ht-15"
				// 					/>
				// 					Delete
				// 				</a>
				// 			</div>
				// 		</div>
				// 	</Fragment>
				// ),
			});
		});
		return data;
	};

	return (
		<Fragment>
			{loading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Sender ID"} />
					<div className="content-body">
						<div className="container pd-x-0">
							<div className="d-flex flex-wrap justify-content-between">
								<p className="mg-b-0 tx-26 tx-bold">
									Sender ID
								</p>
								<p>
									<Link
										to="/app/request-sender-id"
										className="btn btn-primary w-100"
									>
										{" "}
										New Sender ID
									</Link>
								</p>
							</div>
							<div className="card card rounded bd-0 shadow-sm">
								<div className="card-header bd-b-0 pd-b-0 pd-t-40 pd-md-x-30"></div>
								<div className="card-body pd-md-x-30 pd-t- mg-t-20 mg-md-t-0">
									<MDBDataTable
										responsive
										data={setSenderID()}
										className="px-3 scroll"
										bordered
										striped
										hover
									/>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default SenderID;
