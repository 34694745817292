import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoutes = () => {
	const { isAuthenticated, user } = useSelector((state) => state.auth);

	const handleRedirect = () => {
		window.location.href = "https://affiliate.mysogi.com.ng";
		return null;
	};

	return isAuthenticated ? (
		user?.user?.role === "user" ? (
			<Navigate to="/app" />
		) : user?.user?.role === "influencer" ? (
			<Navigate to="/influencer" />
		) : user?.user?.role === "user" && user?.user?.isAdmin ? (
			<Navigate to="/admin" />
		) : user?.user?.role === "billboard_provider" ? (
			<Navigate to="/billboard" />
		) : user?.user?.role === "radio_provider" ? (
			<Navigate to="/radio" />
		) : user?.user?.role === "affiliate_marketer" ||
		  user?.user?.role === "affiliate_admin" ? (
			handleRedirect()
		) : (
			<Navigate to="/login" />
		)
	) : (
		<div>
			<Outlet />
		</div>
	);
};

export default PublicRoutes;
