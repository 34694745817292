export const CREATE_STORE_REQUEST = "CREATE_STORE_REQUEST";
export const CREATE_STORE_SUCCESS = "CREATE_STORE_SUCCESS";
export const CREATE_STORE_RESET = "CREATE_STORE_RESET";
export const CREATE_STORE_FAIL = "CREATE_STORE_FAIL";
export const EDIT_STORE_REQUEST = "EDIT_STORE_REQUEST";
export const EDIT_STORE_SUCCESS = "EDIT_STORE_SUCCESS";
export const EDIT_STORE_RESET = "EDIT_STORE_RESET";
export const EDIT_STORE_FAIL = "EDIT_STORE_FAIL";
export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_RESET = "ADD_PRODUCT_RESET";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";
export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_RESET = "EDIT_PRODUCT_RESET";
export const EDIT_PRODUCT_FAIL = "EDIT_PRODUCT_FAIL";
export const GET_STORE_REQUEST = "GET_STORE_REQUEST";
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS";
export const GET_STORE_FAIL = "GET_STORE_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
