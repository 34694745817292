import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import parse from "html-react-parser";

import MetaData from "../../../layout/MetaData";
import NumberFormat from "react-number-format";
import { getWallet } from "../../../../actions/billingActions";
import {
	createRadioCampaignAction,
	clearErrors,
} from "../../../../actions/radioActions";
import { CREATE_RADIO_CAMPAIGN_RESET } from "../../../../constants/radioConstants";
import Loader from "../../../loader";

import useAnalyticsEventTracker from "../../../../_helpers/GoogleAnalytics/GoogleAnalytics";
import AudioPlayer from "../../../../_helpers/AudioPlayer";

const PreviewRadioCampaign = ({
	nextStep,
	prevStep,
	radioAdsData,
	selectedStation,
	arconFee,
}) => {
	const { error, createRadioCampaign, loading } = useSelector(
		(state) => state.radioCampaign || []
	);
	// const alert = useAlert();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { wallet } = useSelector((state) => state.wallet);
	const gaEventTracker = useAnalyticsEventTracker("Radio Campaign");

	const Continue = (e) => {
		e.preventDefault();
		nextStep();
	};

	const Previous = (e) => {
		e.preventDefault();
		prevStep();
	};

	const setEndDate = () => {
		const date = new Date(radioAdsData.startDate);
		date.setDate(date.getDate() + parseInt(radioAdsData.numOfDays));

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");

		return `${year}-${month}-${day}`;
	};

	useEffect(() => {
		if (createRadioCampaign?.status === "success") {
			gaEventTracker("Radio Campaign", "User created radio campaign");
			toast.success(createRadioCampaign.message);
			dispatch(getWallet());
			navigate("/app/campaigns");
			dispatch({ type: CREATE_RADIO_CAMPAIGN_RESET });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, createRadioCampaign, navigate]);

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch(clearErrors());
			dispatch(getWallet());
		}
	}, [dispatch, error]);

	const submitInfluencerCampaignHandler = (e) => {
		e.preventDefault();
		const payload = {
			stationId: radioAdsData.stationId,
			session: radioAdsData.session,
			adType: radioAdsData.adType,
			attachment: radioAdsData.attachment,
			arconAttachment: radioAdsData.arconAttachment,
			campaignMessage: radioAdsData.campaignMessage,
			hasJingle: radioAdsData.hasJingle,
			spot: radioAdsData.spot,
			numOfDays: radioAdsData.numOfDays,
			startDate: radioAdsData.startDate,
			language: radioAdsData.language,
			createJingle: radioAdsData.createJingle,
			createArcon: radioAdsData.createArcon,
			hasArcon: radioAdsData.hasArcon,
			doNotRequireArcon: radioAdsData.doNotRequireArcon,
			arconType: radioAdsData.arconType,
		};
		dispatch(createRadioCampaignAction(payload));
	};

	return (
		<Fragment>
			{loading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Preview Radio Campaign"} />
					<div className="content-body">
						<div className="container pd-x-0">
							<p className="tx-24 tx-bold tx-com">
								Selection Preview
							</p>
							<div className="card bd-0 rounded shadow-sm">
								<div className="card-body pd-md-x-30">
									<div className="d-flex-center">
										<div className="wd-60p mg-b-20">
											{radioAdsData.hasJingle &&
											radioAdsData.adType === "Jingle" ? (
												<AudioPlayer
													src={
														radioAdsData.attachment
													}
													mainPreview={true}
												/>
											) : (
												<div className="radio-preview text-center">
													<h2 className="radio-preview-header">
														Here’s a preview of your
														Campaign Message
													</h2>
													{parse(
														radioAdsData.campaignMessage
													)}
												</div>
											)}
										</div>
									</div>
									<div className="table-responsive mg-t-40">
										<table
											className="table inf-table"
											id="campaig"
										>
											<thead className="tx-uppercase tx-medium text-center">
												<tr>
													<th
														className="text-left"
														scope="col"
													>
														NAME
													</th>
													<th
														className="text-left"
														scope="col"
													>
														SESSION
													</th>
													<th scope="col">SPOT</th>
													<th scope="col">DAYS</th>
													<th scope="col">
														Start Date
													</th>
													<th scope="col">
														End Date
													</th>
													<th scope="col">
														PRICE/SPOT
													</th>
												</tr>
											</thead>
											<tbody className="text-center">
												<tr>
													<td className="text-left">
														{selectedStation.name}
													</td>
													<td className="text-left">
														{radioAdsData.session}
													</td>
													<td>{radioAdsData.spot}</td>
													<td>
														{radioAdsData.numOfDays}
													</td>
													<td>
														{radioAdsData.startDate !==
														""
															? radioAdsData.startDate
															: " - "}
													</td>
													<td>
														{radioAdsData.startDate !==
														""
															? setEndDate()
															: " - "}
													</td>
													<td>
														{
															<NumberFormat
																value={
																	radioAdsData.sessionCost
																}
																displayType={
																	"text"
																}
																thousandSeparator={
																	true
																}
																prefix={"₦"}
															/>
														}
													</td>
												</tr>
											</tbody>
										</table>
										<div className="col-md-6 col-12 offset-md-6 pd-md-x-0">
											<div className="card card-body bg-pink bd-0 rounded">
												<div className="row">
													<div className="col-md-8 col-sm-12">
														<p className="mb-0 tx-right">
															price x{" "}
															{radioAdsData.spot}{" "}
															spot(s) x{" "}
															{
																radioAdsData.numOfDays
															}{" "}
															day(s)
														</p>
														<p className="mb-0 tx-right">
															{radioAdsData.adType ===
																"Jingle" &&
																!radioAdsData.hasJingle &&
																radioAdsData.terms &&
																"Cost for creating jingle"}
														</p>
														<p className="mb-0 tx-right">
															{radioAdsData.adType ===
																"Jingle" &&
																!radioAdsData.hasArcon &&
																radioAdsData.arconTerms &&
																"Cost for ARCON permit"}
														</p>
														<p className=" tx-bold my-2 tx-right">
															Total Amount
														</p>
													</div>
													<div className="col-4">
														<p className="mb-0 tx-right tx-medium">
															{
																<NumberFormat
																	value={parseInt(
																		radioAdsData.sessionCost *
																			radioAdsData.numOfDays *
																			radioAdsData.spot
																	)}
																	displayType={
																		"text"
																	}
																	thousandSeparator={
																		true
																	}
																	prefix={"₦"}
																/>
															}
														</p>
														{radioAdsData.adType ===
															"Jingle" &&
															!radioAdsData.hasJingle &&
															radioAdsData.terms && (
																<p className="mb-0 tx-right tx-medium">
																	<NumberFormat
																		value={parseInt(
																			radioAdsData.jinglePrice
																		)}
																		displayType={
																			"text"
																		}
																		thousandSeparator={
																			true
																		}
																		prefix={
																			"₦"
																		}
																	/>
																</p>
															)}
														{radioAdsData.adType ===
															"Jingle" &&
															!radioAdsData.hasArcon &&
															radioAdsData.arconTerms && (
																<p className="mb-0 tx-right tx-medium">
																	<NumberFormat
																		value={parseInt(
																			arconFee
																		)}
																		displayType={
																			"text"
																		}
																		thousandSeparator={
																			true
																		}
																		prefix={
																			"₦"
																		}
																	/>
																</p>
															)}

														<p className="my-2 tx-right tx-medium">
															{
																<NumberFormat
																	value={parseInt(
																		radioAdsData.price
																	)}
																	displayType={
																		"text"
																	}
																	thousandSeparator={
																		true
																	}
																	prefix={"₦"}
																/>
															}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4 pd-x-0 mg-y-40">
										<div className="d-flex">
											{parseInt(
												wallet && wallet.balance
											) < parseInt(radioAdsData.price) ? (
												<button
													className="btn btn-primary w-100 tx-com  mg-r-15"
													onClick={Continue}
													disabled={
														loading ? true : false
													}
													type="submit"
													variant="contained"
												>
													Fund Wallet
												</button>
											) : (
												<button
													className="btn btn-primary w-100 tx-com  mg-r-15"
													type="button"
													data-toggle="modal"
													data-target="#successModal"
													data-dismiss="modal"
												>
													Done
												</button>
											)}
											<button
												className="btn btn-outline-primary w-100"
												onClick={Previous}
												disabled={
													loading ? true : false
												}
												type="submit"
												variant="contained"
											>
												Go Back
											</button>
										</div>
									</div>
									{/*Success  Modal */}
									<div
										className="modal fade"
										id="successModal"
										tabIndex={-1}
										aria-labelledby="assignModalLabel"
										aria-hidden="true"
									>
										<div className="modal-dialog modal-dialog-centered modal-smm">
											<div className="modal-content pd-md-x-30 pd-x-20 pd-y-20">
												<div className="modal-header bd-b-0"></div>
												<div className="modal-body tx-center pd-x-10">
													<div className="form-group">
														<svg
															width="101"
															height="101"
															viewBox="0 0 101 101"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<rect
																width="101"
																height="101"
																rx="50.5"
																fill="#FEE8E8"
															/>
															<path
																d="M56 55L57.75 56.75L54.5 60H66V62.5H54.5L57.75 65.75L56 67.5L49.75 61.25L56 55ZM48.5 48.75H58.5V51.25H48.5V48.75ZM48.5 40H58.5V42.5H48.5V40Z"
																fill="#FF0000"
															/>
															<path
																d="M47.25 65H41V60H43.5V57.5H41V51.25H43.5V48.75H41V42.5H43.5V40H41V35H63.5V55H66V35C66 33.625 64.875 32.5 63.5 32.5H41C39.625 32.5 38.5 33.625 38.5 35V40H36V42.5H38.5V48.75H36V51.25H38.5V57.5H36V60H38.5V65C38.5 66.375 39.625 67.5 41 67.5H47.25V65Z"
																fill="#FF0000"
															/>
														</svg>

														<p className="tx-26 tx-com tx-bold">
															Thanks For Your
															Order
														</p>
														<p className="tx-16 mb-0">
															Your campaign will
															be vetted by ARCON
															and would be
															published within 48
															hours if successful
														</p>
													</div>
												</div>
												<div className="tx-center bd-t-0 pd-b-30">
													<button
														type="button"
														value="submit"
														form="senderIdForm"
														className="btn btn-primary w-50"
														data-dismiss="modal"
														onClick={
															submitInfluencerCampaignHandler
														}
														disabled={
															loading
																? true
																: false
														}
														variant="contained"
													>
														Publish
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default PreviewRadioCampaign;
