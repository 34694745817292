import { Fragment } from "react";
import NumberFormat from "react-number-format";

const StationCard = ({ station, toggleHandler }) => {
	return (
		<Fragment>
			<div className="col-md-6 col-lg-6 col-12 mg-b-20">
				<div className="card card-body shadow-sm pd-0 card-height bd-radius">
					<div className="custom-control custom-radio pd-0 h-100">
						<div className="d-flex h-100 flex-column">
							<div className="div pd-20 w-100 ht-300">
								<figure
									className={"billboard-img"}
									onClick={toggleHandler(station)}
								>
									<img
										src={
											station.image_url
												? station.image_url
												: "https://via.placeholder.com/500"
										}
										className=""
										alt={"Radio"}
									/>
								</figure>
							</div>

							<div className="pd-x-20 pd-b-20 flex-1">
								<input
									type="radio"
									name="radio"
									className="custom-control-input pd-r-0 mg-r-0"
									value={station}
									onClick={toggleHandler(station)}
									id={station.id}
								/>
								<label
									className="custom-control-label d-inline-flex flex-column billboard-checkbox w-100 h-100 clickable"
									htmlFor={station.id}
								>
									<div className="pd-x-5 d-flex flex-column h-100">
										<h2 className="tx-18 tx-bold mg-b-10 tx-com text-capitalize tx-primary">
											{station.name}
										</h2>
										<p>{station.description}</p>
										<div className="split-content flex-1">
											<div>
												<p className="tx-bold">
													State:{" "}
													<span className="tx-normal">
														{station.state}
													</span>
												</p>
											</div>
											<div>
												<p className="tx-bold">
													Language:{" "}
													<span className="tx-normal">
														{station.language
															? station.language
															: "N/A"}
													</span>
												</p>
											</div>
											<div>
												<p className="tx-bold">
													Runtime:{" "}
													<span className="tx-normal">
														{station.runtime}
													</span>
												</p>
											</div>
											<div>
												<p className="tx-bold">
													Starting Rate:{" "}
													<span className="tx-normal">
														From{" "}
														<NumberFormat
															value={parseInt(
																station.startRate
															)}
															displayType={"text"}
															thousandSeparator={
																true
															}
															prefix={"₦"}
														/>
														/slot
													</span>
												</p>
											</div>
											<div>
												<p className="tx-bold">
													Spots:{" "}
													<span className="tx-normal">
														{station.spot}/day
													</span>
												</p>
											</div>
										</div>
										<div className=" d-flex justify-content-end">
											<span
												className="btn btn-primary pd-x-50 w-50 mg-t-20"
												onClick={toggleHandler(station)}
											>
												Select Station
											</span>
										</div>
									</div>
								</label>
							</div>
							{/* <button
                className="btn btn-primary pd-x-50"
                // onClick={Continue}
                type="submit"
                // variant="contained"
              >
                Select Board
              </button> */}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default StationCard;
