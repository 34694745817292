import React from "react";

const PriceList = () => {
	return (
		<div className="priceList-container">
			<div className="price-container">
				<div className="led-container">Broad Street LED</div>
				<div className="month-container">
					<div className="day-container">
						<p>Daily</p>
						<p>₦30,000</p>
					</div>
					<div className="day-container">
						<p>Weekly</p>
						<p>₦200,000</p>
					</div>
					<div className="day-container">
						<p>Monthly</p>
						<p>₦750,000</p>
					</div>
				</div>
			</div>
			<div className="price-container">
				<div className="led-container" id="blue-led-container">
					Eko Hotel LED
				</div>
				<div className="month-container">
					<div className="day-container">
						<p>Daily</p>
						<p>₦50,000</p>
					</div>
					<div className="day-container">
						<p>Weekly</p>
						<p>₦300,000</p>
					</div>
					<div className="day-container">
						<p>Monthly</p>
						<p>₦1,100,000</p>
					</div>
				</div>
			</div>
			<div className="price-container">
				<div className="led-container " id="green-led-container">
					Akin Adesola LED
				</div>
				<div className="month-container">
					<div className="day-container">
						<p>Daily</p>
						<p>₦50,000</p>
					</div>
					<div className="day-container">
						<p>Weekly</p>
						<p>₦300,000</p>
					</div>
					<div className="day-container">
						<p>Monthly</p>
						<p>₦1,100,000</p>
					</div>
				</div>
			</div>
			<div className="price-container">
				<div className="led-container" id="yellow-led-container">
					Adetokumbo Ademola LED
				</div>
				<div className="month-container">
					<div className="day-container">
						<p>Daily</p>
						<p>₦50,000</p>
					</div>
					<div className="day-container">
						<p>Weekly</p>
						<p>₦300,000</p>
					</div>
					<div className="day-container">
						<p>Monthly</p>
						<p>₦1,100,000</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PriceList;
