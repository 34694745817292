import React, { useRef } from "react";
import "./ourClient.css";

const clientLogos = [
	{
		id: 1,
		name: "Food Court",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101894/FOOD_COURT_bh9tnk.png",
	},
	{
		id: 2,
		name: "Expose",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101893/expose_logo_ochdvt.png",
	},
	{
		id: 3,
		name: "Cadbury",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101893/pngwing.com_27_fchbze.png",
	},
	{
		id: 4,
		name: "Ebony Life",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101893/EbonyLife-TV_fyhkwk.png",
	},
	{
		id: 5,
		name: "Few Chore",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101893/Few_Chore_qflsoy.png",
	},
	{
		id: 6,
		name: "Burger KIng",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101892/pngwing.com_26_mhlr2l.png",
	},
	{
		id: 7,
		name: "Wema Bank",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101892/pngwing.com_29_lg8pfs.png",
	},
	{
		id: 8,
		name: "Panarottis",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101892/PANAROTTIS_LOGO_tod32f.png",
	},
	{
		id: 9,
		name: "MIM",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101892/money_in_minutes_d4u9qh.png",
	},
	{
		id: 10,
		name: "Airtel",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101892/pngwing.com_28_ce0bbr.png",
	},
	{
		id: 11,
		name: "Onburd",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101891/Onburd_logo_400x400_v1sm8r.jpg",
	},
	{
		id: 12,
		name: "Mavin Records",
		imageUrl:
			"https://res.cloudinary.com/mysogi/image/upload/v1695101891/Mavin_Records_logo_1_phwibm.jpg",
	},
	// Add more client objects with unique IDs and image URLs
];

const OurClients = () => {
	const carouselRef = useRef(null);

	const handleCarouselHover = () => {
		// Pause the animation on hover
		carouselRef.current.style.animationPlayState = "paused";
	};

	const handleCarouselLeave = () => {
		// Resume the animation when the cursor leaves
		carouselRef.current.style.animationPlayState = "running";
	};

	return (
		<div
			className="logo-carousel"
			onMouseEnter={handleCarouselHover}
			onMouseLeave={handleCarouselLeave}
		>
			<div className="carousel-container" ref={carouselRef}>
				{clientLogos.map((logo) => (
					<div className="logo-container" key={logo.id}>
						<img
							src={logo.imageUrl}
							alt={logo.name}
							className="logo-image"
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default OurClients;
