export const GET_STATION_REQUEST = "GET_STATION_REQUEST";
export const GET_STATION_SUCCESS = "GET_STATION_SUCCESS";
export const GET_STATION_FAIL = "GET_STATION_FAIL";

export const CREATE_RADIO_CAMPAIGN_REQUEST = "CREATE_RADIO_CAMPAIGN_REQUEST";
export const CREATE_RADIO_CAMPAIGN_SUCCESS = "CREATE_RADIO_CAMPAIGN_SUCCESS";
export const CREATE_RADIO_CAMPAIGN_RESET = "CREATE_RADIO_CAMPAIGN_RESET";
export const CREATE_RADIO_CAMPAIGN_FAIL = "CREATE_RADIO_CAMPAIGN_FAIL";

export const GET_RADIO_CAMPAIGN_REQUEST = "GET_RADIO_CAMPAIGN_REQUEST";
export const GET_RADIO_CAMPAIGN_SUCCESS = "GET_RADIO_CAMPAIGN_SUCCESS";
export const GET_RADIO_CAMPAIGN_RESET = "GET_RADIO_CAMPAIGN_RESET";
export const GET_RADIO_CAMPAIGN_FAIL = "GET_RADIO_CAMPAIGN_FAIL";
export const GET_CAMPAIGN_JINGLE_FEE = "GET_CAMPAIGN_JINGLE_FEE";
export const GET_CAMPAIGN_ARCON_FEE = "GET_CAMPAIGN_ARCON_FEE";

export const GET_SINGLE_RADIO_CAMPAIGN_REQUEST =
	"GET_SINGLE_RADIO_CAMPAIGN_REQUEST";
export const GET_SINGLE_RADIO_CAMPAIGN_SUCCESS =
	"GET_SINGLE_RADIO_CAMPAIGN_SUCCESS";
export const GET_SINGLE_RADIO_CAMPAIGN_RESET =
	"GET_SINGLE_RADIO_CAMPAIGN_RESET";
export const GET_SINGLE_RADIO_CAMPAIGN_FAIL = "GET_SINGLE_RADIO_CAMPAIGN_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
