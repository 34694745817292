import {
	GET_STATION_REQUEST,
	GET_STATION_SUCCESS,
	GET_STATION_FAIL,
	CREATE_RADIO_CAMPAIGN_REQUEST,
	CREATE_RADIO_CAMPAIGN_SUCCESS,
	CREATE_RADIO_CAMPAIGN_RESET,
	CREATE_RADIO_CAMPAIGN_FAIL,
	GET_RADIO_CAMPAIGN_REQUEST,
	GET_RADIO_CAMPAIGN_SUCCESS,
	GET_RADIO_CAMPAIGN_RESET,
	GET_RADIO_CAMPAIGN_FAIL,
	GET_CAMPAIGN_JINGLE_FEE,
	GET_CAMPAIGN_ARCON_FEE,
	GET_SINGLE_RADIO_CAMPAIGN_REQUEST,
	GET_SINGLE_RADIO_CAMPAIGN_SUCCESS,
	GET_SINGLE_RADIO_CAMPAIGN_RESET,
	GET_SINGLE_RADIO_CAMPAIGN_FAIL,
	CLEAR_ERRORS,
} from "../constants/radioConstants";

export const radioReducer = (state = { radio: [] }, action) => {
	switch (action.type) {
		case GET_STATION_REQUEST:
		case CREATE_RADIO_CAMPAIGN_REQUEST:
		case GET_RADIO_CAMPAIGN_REQUEST:
		case GET_SINGLE_RADIO_CAMPAIGN_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_STATION_SUCCESS:
			return {
				loading: false,
				radio: action.payload,
			};
		case GET_RADIO_CAMPAIGN_SUCCESS:
			return {
				...state,
				loading: false,
				radioCampaigns: action.payload,
			};
		case GET_CAMPAIGN_JINGLE_FEE:
			return {
				...state,
				loading: false,
				jingleFee: action.payload,
			};
		case GET_CAMPAIGN_ARCON_FEE:
			return {
				...state,
				loading: false,
				arconFee: action.payload,
			};
		case GET_SINGLE_RADIO_CAMPAIGN_SUCCESS:
			return {
				loading: false,
				singleRadioCampaign: action.payload,
			};
		case CREATE_RADIO_CAMPAIGN_SUCCESS:
			return {
				...state,
				loading: false,
				createRadioCampaign: action.payload,
			};
		case CREATE_RADIO_CAMPAIGN_RESET:
			return {
				...state,
				createRadioCampaign: null,
			};
		case GET_RADIO_CAMPAIGN_RESET:
			return {
				...state,
				radioCampaigns: null,
			};
		case GET_SINGLE_RADIO_CAMPAIGN_RESET:
			return {
				...state,
				singleRadioCampaign: null,
			};
		case GET_STATION_FAIL:
			return {
				...state,
				loading: false,
				radio: null,
				error: action.payload,
			};
		case GET_RADIO_CAMPAIGN_FAIL:
			return {
				...state,
				loading: false,
				radioCampaigns: null,
				error: action.payload,
			};
		case GET_SINGLE_RADIO_CAMPAIGN_FAIL:
			return {
				...state,
				loading: false,
				singleRadioCampaign: null,
				error: action.payload,
			};
		case CREATE_RADIO_CAMPAIGN_FAIL:
			return {
				...state,
				loading: false,
				radioCampaigns: null,
				error: action.payload,
			};
		case CLEAR_ERRORS:
			return {
				...state,
				loading: false,
				error: null,
				createRadioCampaign: null,
				radioCampaigns: null,
			};
		default:
			return state;
	}
};
