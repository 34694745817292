import React, { Fragment, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

import MetaData from "../components/layout/MetaData";
import Loader from "../components/loader";
// import FeatherIcon from "feather-icons-react";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import CustomPagination from "../_helpers/pagination/CustomPagination";
// import NumberFormat from "react-number-format";

import { MDBDataTable } from "mdbreact";
import {
	getCampaignStats,
	getAllInfluencerCampaign,
	getInfluencerDetails,
	getAllPoviderCampaign,
	getAllRadioPoviderCampaign,
	clearErrors,
} from "../actions/campaignActions";

import { getUser } from "../actions/authActions";

const Dashboard = () => {
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1);

	const { loading, influencerCampaignList } = useSelector(
		(state) => state.influencerCampaignList
	);

	const {
		providerCampaignList,
		providerCampaignStat,
		billboardLoading,
		error: BillboardError,
	} = useSelector((state) => state.providerCampaignList);

	const {
		radioLoading,
		radioProviderCampaignList,
		error: radioError,
	} = useSelector((state) => state.radioProviderCampaign);

	const { idLoading } = useSelector((state) => state.influencerDetails);
	const { user } = useSelector((state) => state.auth);
	const { error } = useSelector((state) => state.wallet);

	const setEndDate = (startDate, numOfDays) => {
		const date = new Date(startDate);
		date.setDate(date.getDate() + parseInt(numOfDays));

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");

		return `${year}/${month}/${day}`;
	};

	const setAllCampaigns = () => {
		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					// sort: "desc",
				},
				{
					label: "CHANNEL",
					field: "channel",
					// sort: "desc",
				},
				{
					label: "DATE CREATED",
					field: "dateCreated",
					// sort: "desc",
				},
				{
					label: "STATUS",
					field: "status",
					// sort: "desc",
				},
				{
					label: "ACTIONS",
					field: "actions",
					// sort: "desc",
				},
			],
			rows: [],
		};

		let reverseInfluencerCampaignList = [
			...influencerCampaignList.reverse(),
		];
		// let reverseproviderCampaignList = [
		//   ...providerCampaignList.boards.reverse(),
		// ];

		reverseInfluencerCampaignList.forEach((campaign) => {
			data.rows.push({
				id: campaign.id,
				// campaignName: campaign.name,
				channel: campaign.platforms,
				dateCreated: DateTime.fromJSDate(
					new Date(campaign.createdAt)
				).toFormat("dd MMM, yyyy"),
				status: (
					<span
						className={`badge d-flex-center ${
							campaign.isApproved &&
							!campaign.isPublished &&
							!campaign.isRejected
								? "badge-pending"
								: ""
						} ${
							campaign.isApproved &&
							campaign.isPublished &&
							!campaign.isRejected
								? "badge-active"
								: ""
						} ${
							!campaign.isApproved &&
							!campaign.isPublished &&
							campaign.isRejected
								? "badge-primary"
								: ""
						}
             ${
					!campaign.isApproved &&
					!campaign.isPublished &&
					!campaign.isRejected
						? "badge-pink"
						: ""
				}`}
					>
						{
							// (!campaign.isApproved ? "Pending" : "Approved")
							campaign.isApproved &&
							!campaign.isPublished &&
							!campaign.isRejected
								? "Unpublished"
								: null ||
								  (campaign.isApproved &&
										campaign.isPublished &&
										!campaign.isRejected)
								? "Published"
								: null ||
								  (!campaign.isApproved &&
										!campaign.isPublished &&
										campaign.isRejected)
								? "Rejected"
								: null ||
								  (!campaign.isApproved &&
										!campaign.isPublished &&
										!campaign.isRejected)
								? "Pending"
								: null

							// if (campaign.marketingData.isApproved && !campaign.marketingData.isPublished && !campaign.marketingData.isRejected) {
							//   return "Approved"
							// }
						}
					</span>
				),
				actions: (
					<Fragment>
						<div className="tx-black tx-14">
							<Link
								to={`/influencer/view-campaign/${campaign.influenceMarketingId}`}
								className="d-flex"
							>
								<i className="fa fa-eye tx-orange pd-t-4 mg-r-5"></i>
								{campaign.isApproved &&
								!campaign.isPublished &&
								!campaign.isRejected
									? "Confirm"
									: "View"}
							</Link>
						</div>
					</Fragment>
				),
			});
		});
		return data;
	};

	const setAllBillbordCampaigns = () => {
		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					// sort: "desc",
				},
				{
					label: "BILLBOARD",
					field: "billboard",
					// sort: "desc",
				},
				{
					label: "RATE TYPE",
					field: "rateType",
					// sort: "desc",
				},
				// {
				// 	label: "PRICE",
				// 	field: "price",
				// 	// sort: "desc",
				// },
				{
					label: "DATE CREATED",
					field: "dateCreated",
					// sort: "desc",
				},
				{
					label: "START DATE",
					field: "startDate",
					// sort: "desc",
				},
				{
					label: "END DATE",
					field: "endDate",
					// sort: "desc",
				},
				{
					label: "STATUS",
					field: "status",
					// sort: "desc",
				},
				{
					label: "ACTIONS",
					field: "actions",
					// sort: "desc",
				},
			],
			rows: [],
		};

		let reverseProviderCampaignList = providerCampaignList?.approved
			? providerCampaignList?.approved.sort(
					(a, b) => b.billBoardCampaignId - a.billBoardCampaignId
			  )
			: [];

		reverseProviderCampaignList.forEach((campaign) => {
			data.rows.push({
				id: campaign.billBoardCampaignId,
				// campaignName: campaign.name,
				rateType: (
					<span className="text-capitalize">{campaign.rateType}</span>
				),
				billboard: campaign.billBoardTitle,
				price: (
					<NumberFormat
						value={parseInt(campaign.totalCost)}
						displayType={"text"}
						thousandSeparator={true}
						prefix={"₦"}
					/>
				),
				dateCreated: DateTime.fromJSDate(
					new Date(campaign.createdAt)
				).toFormat("dd MMM, yyyy"),
				startDate: DateTime.fromJSDate(
					new Date(campaign.startDate)
				).toFormat("dd MMM, yyyy"),
				endDate: DateTime.fromJSDate(
					new Date(campaign.endDate)
				).toFormat("dd MMM, yyyy"),
				status: (
					<span
						className={`badge d-flex-center ${
							campaign.isApproved &&
							!campaign.isPublished &&
							!campaign.isRejected
								? "badge-pending"
								: ""
						} ${
							campaign.isApproved &&
							campaign.isPublished &&
							!campaign.isRejected
								? "badge-active"
								: ""
						} ${
							!campaign.isApproved &&
							!campaign.isPublished &&
							campaign.isRejected
								? "badge-primary"
								: ""
						}
             ${
					!campaign.isApproved &&
					!campaign.isPublished &&
					!campaign.isRejected
						? "badge-pink"
						: ""
				}`}
					>
						{campaign.isApproved &&
						!campaign.isPublished &&
						!campaign.isRejected
							? "Unpublished"
							: null ||
							  (campaign.isApproved &&
									campaign.isPublished &&
									!campaign.isRejected)
							? "Published"
							: null ||
							  (!campaign.isApproved &&
									!campaign.isPublished &&
									campaign.isRejected)
							? "Rejected"
							: null ||
							  (!campaign.isApproved &&
									!campaign.isPublished &&
									!campaign.isRejected)
							? "Pending"
							: null}
					</span>
				),
				actions: (
					<Fragment>
						<div className="tx-black tx-14">
							<Link
								to={`/billboard/view-campaign/${campaign.billBoardCampaignId}`}
								className="d-flex"
							>
								<i className="fa fa-eye tx-orange pd-t-4 mg-r-5"></i>
								{campaign.isApproved &&
								!campaign.isPublished &&
								!campaign.isRejected
									? "Confirm"
									: "View"}
							</Link>
						</div>
					</Fragment>
				),
			});
		});
		return data;
	};

	const setRadioCampaigns = () => {
		const data = {
			columns: [
				{
					label: "CAMPAIGN ID",
					field: "campaignId",
					sort: "asc",
				},
				{
					label: "ADS TYPE",
					field: "adType",
					sort: "asc",
				},
				{
					label: "DATE CREATED",
					field: "dateCreated",
					sort: "asc",
				},
				{
					label: "START DATE",
					field: "startDate",
					sort: "asc",
				},
				{
					label: "END DATE",
					field: "endDate",
					sort: "asc",
				},
				{
					label: "PRICE",
					field: "price",
					sort: "asc",
				},
				{
					label: "STATUS",
					field: "status",
					sort: "asc",
				},
				{
					label: "ACTIONS",
					field: "actions",
					sort: "asc",
				},
			],
			rows: [],
		};

		let reverseRadioProviderCampaignList =
			radioProviderCampaignList?.campaign
				? radioProviderCampaignList?.campaign.sort(
						(a, b) => b.radioCampaignId - a.radioCampaignId
				  )
				: [];

		reverseRadioProviderCampaignList.forEach((campaign) => {
			data.rows.push({
				checkBoxes: (
					<Fragment>
						<div className="custom-control custom-checkbox">
							<input
								type="checkbox"
								className="custom-control-input"
								id="customCheck1"
							/>
							<label
								className="custom-control-label"
								htmlFor="customCheck1"
							></label>
						</div>
					</Fragment>
				),
				campaignId: campaign.id,
				adType: campaign?.campaign_info?.adType,
				price: (
					<NumberFormat
						value={parseInt(campaign.totalCost)}
						displayType={"text"}
						thousandSeparator={true}
						prefix={"₦"}
					/>
				),
				dateCreated: DateTime.fromJSDate(
					new Date(campaign.createdAt)
				).toFormat("dd MMM, yyyy"),
				startDate: DateTime.fromJSDate(
					new Date(campaign?.campaign_info?.startDate)
				).toFormat("dd MMM, yyyy"),
				endDate: DateTime.fromJSDate(
					new Date(
						setEndDate(
							campaign?.campaign_info?.startDate,
							campaign?.campaign_info?.numOfDays
						)
					)
				).toFormat("dd MMM, yyyy"),
				status: (
					<span
						className={`badge d-flex-center ${
							(!campaign.isAdminApproved ||
								campaign.isAdminApproved) &&
							!campaign.isPublished &&
							(!campaign.isRejected || campaign.isRejected) &&
							!campaign.isApproved &&
							!campaign.isAdminRejected
								? "badge-pink"
								: ""
						} 
              ${
					campaign.isAdminApproved &&
					campaign.isPublished &&
					!campaign.isRejected &&
					campaign.isApproved
						? "badge-active"
						: ""
				}
              ${
					!campaign.isAdminApproved &&
					!campaign.isPublished &&
					campaign.isAdminRejected &&
					!campaign.isApproved
						? "badge-danger"
						: ""
				}
              ${
					campaign.isAdminApproved &&
					!campaign.isPublished &&
					!campaign.isRejected &&
					campaign.isApproved
						? "badge-pending"
						: ""
				}
              `}
					>
						{campaign.isAdminApproved &&
						!campaign.isPublished &&
						!campaign.isRejected &&
						campaign.isApproved
							? "Approved"
							: null ||
							  ((!campaign.isAdminApproved ||
									campaign.isAdminApproved) &&
									!campaign.isPublished &&
									(!campaign.isRejected ||
										campaign.isRejected) &&
									!campaign.isApproved &&
									!campaign.isAdminRejected)
							? "Pending"
							: null ||
							  (campaign.isAdminApproved &&
									campaign.isPublished &&
									!campaign.isRejected &&
									campaign.isApproved)
							? "Published"
							: null ||
							  (!campaign.isAdminApproved &&
									!campaign.isPublished &&
									!campaign.isApproved &&
									campaign.isAdminRejected)
							? "Rejected"
							: null}
					</span>
				),
				actions: (
					<Fragment>
						<div className="tx-black tx-14">
							<div className="d-flex">
								<Link
									to={`/radio/view-campaign/${campaign.radioCampaignId}`}
								>
									<i className="fa fa-eye tx-orange pd-t-4 mg-r-5" />{" "}
									View{" "}
								</Link>
							</div>
						</div>
					</Fragment>
				),
			});
		});
		return data;
	};

	useEffect(() => {
		if (user?.user?.role === "billboard_provider") {
			dispatch(getCampaignStats(user?.user?.billboard_provider_id));
			dispatch(
				getAllPoviderCampaign(
					user?.user?.billboard_provider_id,
					currentPage
				)
			);
		}
	}, [dispatch, user, currentPage]);

	const handlePreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const handleNextPage = () => {
		setCurrentPage(currentPage + 1);
	};

	useEffect(() => {
		if (user && user.user.role === "influencer") {
			dispatch(getAllInfluencerCampaign(user.user.influencer_id));
			dispatch(getInfluencerDetails(user.user.influencer_id));
		} else if (user && user.user.role === "radio_provider") {
			dispatch(getAllRadioPoviderCampaign(user.user.radio_provider_id));
		}
		dispatch(getUser());
	}, [dispatch, user]);

	useEffect(() => {
		if (error || BillboardError || radioError) {
			toast.error(error || BillboardError || radioError);
			dispatch(clearErrors());
		}
	}, [dispatch, error, BillboardError, radioError]);

	return (
		<Fragment>
			{loading || idLoading || billboardLoading || radioLoading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Dashboard"} />
					<div className="content-body">
						<div className="container-fluid">
							<div className="row justify-content-between">
								<div className="col-md-6 col-6">
									<p className="mg-b-0 tx-26 tx-bold">
										Overview
									</p>
								</div>
								<div className="col-12 col-md-4 col-xl-3 pd-xl-l-0">
									<div className="d-flex justify-content-end"></div>
								</div>
							</div>
							{user && user.user.role === "influencer" && (
								<>
									<div className="row row-xs">
										<div className="col-sm-6 col-xs-6 col-lg-3 mg-t-10">
											<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
												<div className="d-flex d-lg-block d-xl-flex">
													<span>
														<img
															className="icon img-fluid"
															src="https://res.cloudinary.com/mysogi/image/upload/v1687138546/website/Total_Ads_Played_odsbat.svg"
															alt="Total Campaign"
														/>
													</span>
													<div className="ml-3">
														<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
															{influencerCampaignList &&
															influencerCampaignList?.length
																? influencerCampaignList?.length
																: 0}
															{influencerCampaignList?.length >
															1000
																? "k"
																: ""}
														</p>
														<p className="tx-gray tx-12 tx-14 mb-0">
															Total Campaigns
														</p>
													</div>
												</div>
											</div>
										</div>
										{/* col */}
										<div className="col-sm-6 col-xs-6 col-lg-3 mg-t-10">
											<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
												<div className="d-flex d-lg-block d-xl-flex">
													<span>
														<img
															className="icon img-fluid"
															src="https://res.cloudinary.com/mysogi/image/upload/v1687138646/website/my2_gg97zw.svg"
															alt="Approved Campaign"
														/>
													</span>
													<div className="ml-3">
														<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
															{influencerCampaignList
																? influencerCampaignList &&
																  influencerCampaignList.filter(
																		(x) =>
																			x.isRejected ===
																				false &&
																			x.isApproved ===
																				true &&
																			x.isPublished ===
																				true
																  )?.length
																: 0}
															{influencerCampaignList?.length >
															1000
																? "k"
																: ""}
														</p>
														<p className="tx-gray tx-12 tx-14 mb-0">
															Published Campaigns
														</p>
													</div>
												</div>
											</div>
										</div>
										{/* col */}
										<div className="col-sm-6 col-xs-6 col-lg-3 mg-t-10">
											<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
												<div className="d-flex d-lg-block d-xl-flex">
													<span>
														<img
															className="icon img-fluid"
															src="https://res.cloudinary.com/mysogi/image/upload/v1687138709/website/my3_tkbs81.svg"
															alt="Pending Campaign"
														/>
													</span>
													<div className="ml-3">
														<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
															{influencerCampaignList
																? influencerCampaignList &&
																  influencerCampaignList.filter(
																		(x) =>
																			x.isRejected ===
																				false &&
																			x.isApproved ===
																				false &&
																			x.isPublished ===
																				false
																  )?.length
																: 0}
															{influencerCampaignList?.length >
															1000
																? "k"
																: ""}
														</p>
														<p className="tx-gray tx-12 tx-14 mb-0">
															Pending Campaigns
														</p>
													</div>
												</div>
											</div>
										</div>
										{/* col */}
										<div className="col-sm-6 col-xs-6 col-lg-3 mg-t-10">
											<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
												<div className="d-flex d-lg-block d-xl-flex">
													<span>
														<img
															className="icon img-fluid"
															src="https://res.cloudinary.com/mysogi/image/upload/v1687138789/website/Reported_Ads_ctrnqc.svg"
															alt="Rejected Campaign"
														/>
													</span>
													<div className="ml-3">
														<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
															{influencerCampaignList
																? influencerCampaignList &&
																  influencerCampaignList.filter(
																		(x) =>
																			x.isRejected ===
																			true
																  )?.length
																: 0}
															{influencerCampaignList?.length >
															1000
																? "k"
																: ""}
														</p>
														<p className="tx-gray tx-12 tx-14 mb-0">
															Rejected Campaigns
														</p>
													</div>
												</div>
											</div>
										</div>
										{/* col */}
									</div>
									<div className="card rounded bd-0 shadow-sm mg-t-40">
										<div className="card-body pd-md-x-30 pd-t- mg-t-20 mg-md-t-0">
											<MDBDataTable
												responsive
												data={setAllCampaigns()}
												className="px-3 scroll"
												bordered
												striped
												hover
												barReverse
											/>
										</div>
									</div>
								</>
							)}
							{user &&
								user.user.role === "billboard_provider" && (
									<>
										<div className="row row-xs">
											<div className="col-sm-6 col-xs-6 col-lg-3 mg-t-10">
												<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
													<div className="d-flex d-lg-block d-xl-flex">
														<span>
															<img
																className="icon img-fluid"
																src="https://res.cloudinary.com/mysogi/image/upload/v1687138546/website/Total_Ads_Played_odsbat.svg"
																alt="Total Campaign"
															/>
														</span>
														<div className="ml-3">
															<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
																{providerCampaignStat
																	?.approved
																	?.length
																	? providerCampaignStat
																			?.approved
																			?.length
																	: 0}
																{providerCampaignStat
																	?.approved
																	?.length >
																1000
																	? "k"
																	: ""}
															</p>
															<p className="tx-gray tx-12 tx-14 mb-0">
																Total Campaigns
															</p>
														</div>
													</div>
												</div>
											</div>
											{/* col */}
											<div className="col-sm-6 col-xs-6 col-lg-3 mg-t-10">
												<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
													<div className="d-flex d-lg-block d-xl-flex">
														<span>
															<img
																className="icon img-fluid"
																src="https://res.cloudinary.com/mysogi/image/upload/v1687138646/website/my2_gg97zw.svg"
																alt="Approved Campaign"
															/>
														</span>
														<div className="ml-3">
															<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
																{providerCampaignStat
																	?.approved
																	?.length
																	? providerCampaignStat?.approved?.filter(
																			(
																				x
																			) =>
																				x.isRejected ===
																					false &&
																				x.isApproved ===
																					true &&
																				x.isPublished ===
																					true
																	  )?.length
																	: 0}
																{providerCampaignStat
																	?.approved
																	?.length >
																1000
																	? "k"
																	: ""}
															</p>
															<p className="tx-gray tx-12 tx-14 mb-0">
																Published
																Campaigns
															</p>
														</div>
													</div>
												</div>
											</div>
											{/* col */}
											<div className="col-sm-6 col-xs-6 col-lg-3 mg-t-10">
												<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
													<div className="d-flex d-lg-block d-xl-flex">
														<span>
															<img
																className="icon img-fluid"
																src="https://res.cloudinary.com/mysogi/image/upload/v1687138709/website/my3_tkbs81.svg"
																alt="Pending Campaign"
															/>
														</span>
														<div className="ml-3">
															<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
																{providerCampaignStat
																	? providerCampaignStat?.approved?.filter(
																			(
																				x
																			) =>
																				x.isRejected ===
																					false &&
																				x.isApproved ===
																					false &&
																				x.isPublished ===
																					false
																	  )?.length
																	: 0}
																{providerCampaignStat
																	?.approved
																	?.length >
																1000
																	? "k"
																	: ""}
															</p>
															<p className="tx-gray tx-12 tx-14 mb-0">
																Pending
																Campaigns
															</p>
														</div>
													</div>
												</div>
											</div>
											{/* col */}
											<div className="col-sm-6 col-xs-6 col-lg-3 mg-t-10">
												<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
													<div className="d-flex d-lg-block d-xl-flex">
														<span>
															<img
																className="icon img-fluid"
																src="https://res.cloudinary.com/mysogi/image/upload/v1687138789/website/Reported_Ads_ctrnqc.svg"
																alt="Rejected Campaign"
															/>
														</span>
														<div className="ml-3">
															<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
																{providerCampaignStat
																	? providerCampaignStat?.rejected?.filter(
																			(
																				x
																			) =>
																				x.isRejected ===
																				true
																	  )?.length
																	: 0}
																{providerCampaignStat
																	?.rejected
																	?.length >
																1000
																	? "k"
																	: ""}
															</p>
															<p className="tx-gray tx-12 tx-14 mb-0">
																Rejected
																Campaigns
															</p>
														</div>
													</div>
												</div>
											</div>
											{/* col */}
										</div>
										<div className="card rounded bd-0 shadow-sm mg-t-40">
											<div className="card-body pd-md-x-30 pd-t- mg-t-20 mg-md-t-0">
												<CustomPagination
													hasPreviousPage={
														providerCampaignList?.hasPreviousPage
													} // Determine if there is a previous page
													hasNextPage={
														providerCampaignList?.hasNextPage
													} // Determine if there is a next page (totalPages should be calculated based on recordCount and pageSize)
													previousPage={
														handlePreviousPage
													} // Pass the handlePreviousPage function
													nextPage={handleNextPage} // Pass the handleNextPage function
													pageSize={
														providerCampaignList?.pageSize
													} // Replace with your server pagination data
													recordCount={
														providerCampaignList?.recordCount
													} // Replace with your server pagination data
													currentPage={currentPage}
												/>
												<MDBDataTable
													responsive
													data={setAllBillbordCampaigns()}
													className="px-3 scroll billboard-admin"
													bordered
													striped
													hover
													barReverse
													searching={false}
												/>
											</div>
										</div>
									</>
								)}
							{user && user.user.role === "radio_provider" && (
								<>
									<div className="row row-xs">
										<div className="col-sm-6 col-xs-6 col-lg-2 mg-t-10">
											<Link
												to={
													"/radio/campaigns/status?q=pending"
												}
												className="radioCard"
											>
												<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
													<div className="d-flex d-lg-block d-xl-flex">
														<span>
															<img
																className="icon img-fluid"
																src="https://res.cloudinary.com/mysogi/image/upload/v1687138709/website/my3_tkbs81.svg"
																alt="Pending Campaign"
															/>
														</span>
														<div className="ml-3">
															<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
																{radioProviderCampaignList
																	? radioProviderCampaignList?.campaign?.filter(
																			(
																				x
																			) =>
																				x.isRejected ===
																					false &&
																				x.isApproved ===
																					false &&
																				x.isPublished ===
																					false
																	  )?.length
																	: 0}
																{radioProviderCampaignList
																	?.campaign
																	?.length >
																1000
																	? "k"
																	: ""}
															</p>
															<p className="tx-gray tx-12 tx-14 mb-0">
																Pending
																Campaigns
															</p>
														</div>
													</div>
												</div>
											</Link>
										</div>
										{/* col */}
										<div className="col-sm-6 col-xs-6 col-lg-2 mg-t-10">
											<Link
												to={
													"/radio/campaigns/status?q=approved"
												}
												className="radioCard"
											>
												<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
													<div className="d-flex d-lg-block d-xl-flex">
														<span>
															<img
																className="icon img-fluid"
																src="https://res.cloudinary.com/mysogi/image/upload/v1687138546/website/Total_Ads_Played_odsbat.svg"
																alt="Approved Campaign"
															/>
														</span>
														<div className="ml-3">
															<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
																{radioProviderCampaignList
																	?.campaign
																	?.length
																	? radioProviderCampaignList?.campaign?.filter(
																			(
																				x
																			) =>
																				x.isRejected ===
																					false &&
																				x.isApproved ===
																					true &&
																				x.isPublished ===
																					false
																	  )?.length
																	: 0}
																{radioProviderCampaignList
																	?.campaign
																	?.length >
																1000
																	? "k"
																	: ""}
															</p>
															<p className="tx-gray tx-12 tx-14 mb-0">
																Approved
																Campaigns
															</p>
														</div>
													</div>
												</div>
											</Link>
										</div>
										{/* col */}
										<div className="col-sm-6 col-xs-6 col-lg-2 mg-t-10">
											<Link
												to={
													"/radio/campaigns/status?q=published"
												}
												className="radioCard"
											>
												<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
													<div className="d-flex d-lg-block d-xl-flex">
														<span>
															<img
																className="icon img-fluid"
																src="https://res.cloudinary.com/mysogi/image/upload/v1687138646/website/my2_gg97zw.svg"
																alt="Approved Campaign"
															/>
														</span>
														<div className="ml-3">
															<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
																{radioProviderCampaignList
																	?.campaign
																	?.length
																	? radioProviderCampaignList?.campaign?.filter(
																			(
																				x
																			) =>
																				x.isRejected ===
																					false &&
																				x.isApproved ===
																					true &&
																				x.isPublished ===
																					true
																	  )?.length
																	: 0}
																{radioProviderCampaignList
																	?.campaign
																	?.length >
																1000
																	? "k"
																	: ""}
															</p>
															<p className="tx-gray tx-12 tx-14 mb-0">
																Published
																Campaigns
															</p>
														</div>
													</div>
												</div>
											</Link>
										</div>
										{/* col */}
										<div className="col-sm-6 col-xs-6 col-lg-2 mg-t-10">
											<Link
												to={
													"/radio/campaigns/status?q=completed"
												}
												className="radioCard"
											>
												<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
													<div className="d-flex d-lg-block d-xl-flex">
														<span>
															<img
																className="icon img-fluid"
																src="https://res.cloudinary.com/mysogi/image/upload/v1687140664/website/Reach_Conversion_Goals_kzlivd.svg"
																alt="Completed Campaign"
															/>
														</span>
														<div className="ml-3">
															<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
																{radioProviderCampaignList
																	? radioProviderCampaignList?.campaign?.filter(
																			(
																				item
																			) => {
																				const startDate =
																					DateTime.fromISO(
																						item
																							.campaign_info
																							.startDate
																					);
																				const numOfDays =
																					parseInt(
																						item
																							.campaign_info
																							.numOfDays
																					);

																				// Calculate the expiration date by adding numOfDays to the startDate
																				const expirationDate =
																					startDate.plus(
																						{
																							days: numOfDays,
																						}
																					);

																				// Check if the expiration date is less than today's date
																				return (
																					expirationDate <
																					DateTime.local()
																				);
																			}
																	  ).length
																	: 0}
																{radioProviderCampaignList
																	?.rejected
																	?.length >
																1000
																	? "k"
																	: ""}
															</p>
															<p className="tx-gray tx-12 tx-14 mb-0">
																Completed
																Campaigns
															</p>
														</div>
													</div>
												</div>
											</Link>
										</div>
										{/* col */}
										<div className="col-sm-6 col-xs-6 col-lg-2 mg-t-10">
											<Link
												to={
													"/radio/campaigns/status?q=rejected"
												}
												className="radioCard"
											>
												<div className="card card-body p-3 rounded bd-0 shadow-sm analytics-card">
													<div className="d-flex d-lg-block d-xl-flex">
														<span>
															<img
																className="icon img-fluid"
																src="https://res.cloudinary.com/mysogi/image/upload/v1687138789/website/Reported_Ads_ctrnqc.svg"
																alt="Rejected Campaign"
															/>
														</span>
														<div className="ml-3">
															<p className="tx-bold tx-bold tx-28 mg-b-0 lh-1 white">
																{radioProviderCampaignList
																	? radioProviderCampaignList?.rejected?.filter(
																			(
																				x
																			) =>
																				x.isRejected ===
																				true
																	  )?.length
																	: 0}
																{radioProviderCampaignList
																	?.rejected
																	?.length >
																1000
																	? "k"
																	: ""}
															</p>
															<p className="tx-gray tx-12 tx-14 mb-0">
																Rejected
																Campaigns
															</p>
														</div>
													</div>
												</div>
											</Link>
										</div>
										{/* col */}
									</div>
									<div className="card rounded bd-0 shadow-sm mg-t-40">
										<div className="card-body pd-md-x-30 pd-t- mg-t-20 mg-md-t-0">
											<MDBDataTable
												responsive
												data={setRadioCampaigns()}
												className="px-3 scroll"
												bordered
												striped
												hover
												barReverse
											/>
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default Dashboard;
