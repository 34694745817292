import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import NumberFormat from "react-number-format";
import { DateTime } from "luxon";
import parse from "html-react-parser";

import MetaData from "../components/layout/MetaData";
import AudioPlayer from "../_helpers/AudioPlayer";
import {
	updateRadioCampaignStatusAction,
	updateRadioPublishStatusAction,
	clearErrors,
} from "../actions/campaignActions";
import {
	UPDATE_RADIO_CAMPAIGN_STATUS_RESET,
	UPDATE_RADIO_PUBLISHED_STATUS_RESET,
} from "../constants/campaignConstants";
import Loader from "../components/loader";

const ViewRadioCampaignDetails = () => {
	const { radioCampaignId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { radioProviderCampaignList } = useSelector(
		(state) => state.radioProviderCampaign || []
	);

	const {
		updateRadioPublishedStatus,
		updateRadioCampaignStatus,
		error,
		radioLoading,
	} = useSelector((state) => state.radioProviderCampaign || []);

	const [rejectInput, setRejectInput] = useState("");
	const [ammendInput, setAmmendInput] = useState("");
	const [publisherNote, setPublisherNote] = useState(
		"Thank You for Advertising with us."
	);
	const [airtimeSchedule, setAirtimeSchedule] = useState([""]);

	const addMoreNote = () => {
		const tempData = [...airtimeSchedule, ""];
		setAirtimeSchedule(tempData);
	};

	const handleDeleteDataTime = (index) => {
		if (airtimeSchedule.length <= 1) {
			return;
		}
		const tempData = [...airtimeSchedule];
		tempData.splice(index, 1);
		setAirtimeSchedule(tempData);
	};

	const handleDateTimeChange = (event, index) => {
		const { value } = event.target;
		const tempData = [...airtimeSchedule];
		tempData[index] = value;
		setAirtimeSchedule(tempData);
	};

	const details = (arr, id) => {
		for (var i in arr?.campaign) {
			if (arr?.campaign[i]?.radioCampaignId === parseInt(id)) {
				return arr.campaign ? arr.campaign[i] : [];
			}
		}
	};

	const campaignDetails = details(radioProviderCampaignList, radioCampaignId);

	const approvedPayload = {
		radioId: campaignDetails && campaignDetails?.radioId,
		campaignId: campaignDetails && campaignDetails?.radioCampaignId,
		approvalType: "approved",
	};

	const rejectPayload = {
		radioId: campaignDetails && campaignDetails?.radioId,
		campaignId: campaignDetails && campaignDetails?.radioCampaignId,
		approvalType: "rejected",
		rejectionMessage: rejectInput,
	};

	const ammendPayload = {
		radioId: campaignDetails && campaignDetails?.radioId,
		campaignId: campaignDetails && campaignDetails?.radioCampaignId,
		approvalType: "ammend",
		rejectionMessage: ammendInput,
	};

	const publishPayload = {
		stationId: campaignDetails && campaignDetails?.radioId,
		campaignId: campaignDetails && campaignDetails?.radioCampaignId,
		confirmationMessage: publisherNote,
		publisherNote: airtimeSchedule,
	};

	const setAsset = () => {
		fetch(campaignDetails?.campaign_info?.attachment)
			.then((res) => res.blob())
			.then((blob) => saveAs(blob, "campaign_asset"));
	};

	const setEndDate = () => {
		const date = new Date(campaignDetails?.campaign_info?.startDate);
		date.setDate(
			date.getDate() + parseInt(campaignDetails?.campaign_info?.numOfDays)
		);

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");

		return `${year}-${month}-${day}`;
	};

	const acceptCampaignHandler = (e) => {
		e.preventDefault();

		dispatch(updateRadioCampaignStatusAction(approvedPayload));
	};

	const ammendCampaignHandler = (e) => {
		e.preventDefault();

		dispatch(updateRadioCampaignStatusAction(ammendPayload));
	};

	const rejectCampaignHandler = (e) => {
		e.preventDefault();

		dispatch(updateRadioCampaignStatusAction(rejectPayload));
	};

	const publishCampaignHandler = (e) => {
		e.preventDefault();

		dispatch(updateRadioPublishStatusAction(publishPayload));
	};

	const loadActionButtons = () => {
		if (
			campaignDetails &&
			!campaignDetails.isApproved &&
			!campaignDetails.isPublished &&
			!campaignDetails.isRejected
		) {
			return (
				<div className="d-flex mg-t-40 mg-b-60">
					<button
						type="button"
						onClick={acceptCampaignHandler}
						className="btn btn-primary w-100 pd-x-40 tx-com mg-r-15"
						data-toggle="modal"
						data-dismiss="modal"
					>
						Approve
					</button>
					<button
						className="btn btn-outline-primary w-100 pd-x-30"
						data-toggle="modal"
						data-dismiss="modal"
						data-target="#rejectModal"
					>
						Reject
					</button>
				</div>
			);
		} else if (
			campaignDetails &&
			campaignDetails.isApproved &&
			!campaignDetails.isPublished &&
			!campaignDetails.isRejected
		) {
			return (
				<div className="mg-t-40 mg-b-60 d-flex justify-content-end">
					<button
						type="button"
						className="btn btn-primary pd-x-40 tx-com"
						data-toggle="modal"
						data-dismiss="modal"
						data-target="#publishModal"
					>
						Confirm Publishing
					</button>
				</div>
			);
		} else if (
			campaignDetails &&
			campaignDetails.isApproved &&
			campaignDetails &&
			campaignDetails.isPublished &&
			!campaignDetails.isRejected
		) {
			return "";
		}
	};

	useEffect(() => {
		if (
			updateRadioCampaignStatus &&
			updateRadioCampaignStatus.status === "success"
		) {
			toast.success(updateRadioCampaignStatus.message);
			dispatch({ type: UPDATE_RADIO_CAMPAIGN_STATUS_RESET });
			navigate("/radio");
		} else if (
			updateRadioPublishedStatus &&
			updateRadioPublishedStatus.status === "success"
		) {
			toast.success(updateRadioPublishedStatus.message);
			dispatch({ type: UPDATE_RADIO_PUBLISHED_STATUS_RESET });
			navigate("/radio");
		}
	}, [
		dispatch,
		error,
		updateRadioCampaignStatus,
		updateRadioPublishedStatus,
		navigate,
	]);

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch(clearErrors());
		}
	}, [dispatch, error]);

	return (
		<Fragment>
			{radioLoading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Preview Radio Campaign"} />
					<div className="content-body">
						<div className="container pd-x-0">
							<div className="row justify-content-between mg-b-30">
								<div className="col-md-6">
									<Link to="/radio" className="tx-black">
										<div className="d-flex align-items-center">
											<i className="fa fa-angle-left mg-r-10 tx-18" />
											<p className="tx-28 tx-bold mb-0">
												Preview
											</p>
										</div>
									</Link>
								</div>
								<div className="col-md-6 d-flex justify-content-end">
									{campaignDetails?.campaign_info?.adType ===
										"Jingle" && (
										<button
											type="button"
											onClick={setAsset}
											className="btn btn-primary mg-r-20"
										>
											<i className="fa fa-download mg-r-10" />
											Download
										</button>
									)}
									<Link
										to="/radio"
										className="btn btn-outline-primary"
									>
										Go Back
									</Link>
								</div>
							</div>
							<div className="card bd-0 rounded shadow-sm">
								<div className="card-body pd-md-x-30">
									<div className="d-flex-center">
										<div className="wd-60p mg-b-20">
											{campaignDetails?.campaign_info
												?.adType === "Jingle" ? (
												<AudioPlayer
													src={
														campaignDetails
															?.campaign_info
															?.attachment
													}
													mainPreview={true}
												/>
											) : (
												<div className="radio-preview">
													<h2 className="radio-preview-header tx-underline">
														Here’s a preview of the
														requested Announcement
													</h2>
													{parse(
														campaignDetails
															?.campaign_info
															.campaignMessage
													)}
												</div>
											)}
										</div>
									</div>
									<div className="table-responsive mg-t-20">
										<table
											className="table inf-table"
											id="campaig"
										>
											<thead className="tx-uppercase tx-medium">
												<tr>
													<th scope="col">NAME</th>
													<th scope="col">SESSION</th>
													<th scope="col">SPOT</th>
													<th scope="col">DAYS</th>
													<th scope="col">
														Start Date
													</th>
													<th scope="col">
														End Date
													</th>
													<th scope="col">Cost</th>
												</tr>
											</thead>
											<tbody className="text-left">
												<tr>
													<td>
														{
															campaignDetails
																?.campaign_info
																?.stationName
														}
													</td>
													<td>
														{
															campaignDetails
																?.campaign_info
																?.session
														}
													</td>
													<td>
														{
															campaignDetails
																?.campaign_info
																?.spot
														}
													</td>
													<td>
														{
															campaignDetails
																?.campaign_info
																?.numOfDays
														}
													</td>
													<td>
														{DateTime.fromJSDate(
															new Date(
																campaignDetails?.campaign_info?.startDate
															)
														).toFormat(
															"dd MMM, yyyy"
														)}
													</td>
													<td>
														{campaignDetails
															?.campaign_info
															?.startDate !== ""
															? setEndDate()
															: " - "}
													</td>
													<td>
														<NumberFormat
															value={parseInt(
																campaignDetails?.totalCost
															)}
															displayType={"text"}
															thousandSeparator={
																true
															}
															prefix={"₦"}
														/>
													</td>
												</tr>
											</tbody>
										</table>
										<div className="col-md-5 col-12 offset-md-7 pd-md-x-0">
											<div className="card card-body bg-pink bd-0 rounded pd-y-10">
												<div className="row">
													<div className="col-6">
														<p className="mb-0 tx-right">
															Total Amount
														</p>
													</div>
													<div className="col-6">
														<p className="mb-0 tx-right tx-medium">
															<NumberFormat
																value={parseInt(
																	campaignDetails?.totalCost
																)}
																displayType={
																	"text"
																}
																thousandSeparator={
																	true
																}
																prefix={"₦"}
															/>
														</p>
														<p className="tx-bold mb-0 tx-right">
															Cost x{" "}
															{
																campaignDetails
																	?.campaign_info
																	?.numOfDays
															}{" "}
															day(s)
														</p>
													</div>
												</div>
											</div>
											{loadActionButtons()}
										</div>
									</div>
									<div
										className="modal fade"
										id="downloadModal"
										tabIndex={-1}
										aria-labelledby="assignModalLabel"
										aria-hidden="true"
									>
										<div className="modal-dialog modal-dialog-centered">
											<div className="modal-content pd-md-x-30 pd-x-20 pd-y-20">
												<div className="modal-header bd-b-0">
													<p
														className="tx-20 tx-bold tx-com modal-title"
														id="downloadModalLabel"
													/>
													<button
														type="button"
														className="close"
														data-dismiss="modal"
														aria-label="Close"
													>
														<span aria-hidden="true">
															×
														</span>
													</button>
												</div>
												<div className="modal-body">
													<div>
														<img
															src={
																campaignDetails
																	?.campaign_info
																	?.attachment
															}
															alt="campaign asset"
															className="img-fluid"
														/>
													</div>
												</div>
												<div className="modal-footer bd-t-0 col-md-7 mx-auto">
													<button
														type="button"
														onClick={setAsset}
														className="btn btn-primary w-100"
														// data-dismiss="modal"
													>
														<i className="fa fa-download mg-r-10" />
														Download
													</button>
												</div>
											</div>
										</div>
									</div>
									{/*Reject  Modal */}
									<div
										id="rejectModal"
										className="modal fade"
										tabIndex={-1}
										aria-labelledby="assignModalLabel"
										aria-hidden="true"
									>
										<div className="modal-dialog modal-dialog-centered modal-smm">
											<div className="modal-content pd-md-x-30 pd-x-20 pd-y-20">
												<div className="modal-header bd-b-0"></div>
												<div className="modal-body tx-center pd-x-10">
													<div className="form-group">
														<p className="tx-26 tx-com tx-bold">
															Reject Campaign
														</p>
														<p className="tx-16 mb-10">
															Please specify the
															reason for the
															rejection.
														</p>
														<textarea
															className="form-control"
															rows={4}
															onChange={(e) =>
																setRejectInput(
																	e.target
																		.value
																)
															}
															placeholder="Enter Reasons"
															defaultValue={
																rejectInput
															}
														/>
													</div>
												</div>
												<div className="tx-center modal-footer bd-t-0 pd-b-30">
													<button
														type="button"
														className="btn btn-primary w-45 mg-r-20"
														onClick={
															rejectCampaignHandler
														}
														data-dismiss="modal"
													>
														Reject
													</button>
													<button
														type="button"
														className="btn btn-outline-primary w-45"
														data-dismiss="modal"
													>
														Cancel
													</button>
												</div>
											</div>
										</div>
									</div>
									{/*Ammend  Modal */}
									<div
										id="amendModal"
										className="modal fade"
										tabIndex={-1}
										aria-labelledby="assignModalLabel"
										aria-hidden="true"
									>
										<div className="modal-dialog modal-dialog-centered modal-smm">
											<div className="modal-content pd-md-x-30 pd-x-20 pd-y-20">
												<div className="modal-header bd-b-0"></div>
												<div className="modal-body tx-center pd-x-10">
													<div className="form-group">
														<p className="tx-26 tx-com tx-bold">
															Ammend Campaign
														</p>
														<p className="tx-16 mb-10">
															Please specify the
															reason for the
															ammendment.
														</p>
														<textarea
															className="form-control"
															rows={4}
															onChange={(e) =>
																setAmmendInput(
																	e.target
																		.value
																)
															}
															placeholder="Enter Reasons"
															defaultValue={
																ammendInput
															}
														/>
													</div>
												</div>
												<div className="tx-center modal-footer bd-t-0 pd-b-30">
													<button
														type="button"
														className="btn btn-primary w-45 mg-r-20"
														onClick={
															ammendCampaignHandler
														}
														data-dismiss="modal"
													>
														Ammend
													</button>
													<button
														type="button"
														className="btn btn-outline-primary w-45"
														data-dismiss="modal"
													>
														Cancel
													</button>
												</div>
											</div>
										</div>
									</div>
									{/*Confirm Publish Modal */}
									<div
										id="publishModal"
										className="modal fade"
										tabIndex={-1}
										aria-labelledby="assignModalLabel"
										aria-hidden="true"
									>
										<div className="modal-dialog modal-dialog-centered modal-smm">
											<div className="modal-content pd-md-x-30 pd-x-20 pd-y-20">
												<div className="modal-header bd-b-0"></div>
												<div className="modal-body tx-center pd-x-10">
													<div className="form-group">
														<img
															src="https://res.cloudinary.com/mysogi/image/upload/v1687138199/website/Check_lyxikg.svg"
															className="img-fluid wd-100 ht-100"
															alt=""
														/>
														<p className="tx-26 tx-com tx-bold">
															Publish Radio Ad
														</p>
														<p className="tx-16 mb-3">
															Please provide
															detailed info on
															when campaign will
															be aired.
														</p>
														<div className="col-md-12 d-flex flex-column text-left form-group mt-4">
															<label className="mb-2">
																Airtime
																Schedule:
															</label>
															{airtimeSchedule.map(
																(
																	dateInput,
																	index
																) => (
																	<div
																		key={
																			index
																		}
																		className="d-flex align-items-center mg-b-10"
																	>
																		<input
																			type="datetime-local"
																			className="form-control"
																			value={
																				dateInput
																			}
																			onChange={(
																				e
																			) =>
																				handleDateTimeChange(
																					e,
																					index
																				)
																			}
																		/>
																		<button
																			type="button"
																			className="btn btn-danger ml-2 pd-0"
																			onClick={() =>
																				handleDeleteDataTime(
																					index
																				)
																			}
																		>
																			<i className="fa fa-minus-circle"></i>
																		</button>
																	</div>
																)
															)}

															<button
																type="button"
																onClick={
																	addMoreNote
																}
																className="btn btn-primary mg-t-20 align-self-end"
															>
																<i className="fa fa-plus tx-10 mg-r-5 lh-base"></i>
																<span>
																	Add More
																</span>
															</button>
														</div>

														<div className="col-md-12 text-left form-group">
															<label className="mb-2">
																Note For
																Advertiser:
															</label>
															<textarea
																// name
																className="form-control"
																rows={4}
																onChange={(e) =>
																	setPublisherNote(
																		e.target
																			.value
																	)
																}
																placeholder="Optional Message For Advertiser"
																defaultValue={
																	publisherNote
																}
															/>
														</div>
													</div>
												</div>
												<div className="tx-center justify-content-between modal-footer bd-t-0 pd-b-30">
													<button
														type="button"
														onClick={
															publishCampaignHandler
														}
														className="btn btn-primary w-45 mg-r-20"
														data-dismiss="modal"
														disabled={
															airtimeSchedule ===
															""
														}
													>
														Confirm
													</button>
													<button
														type="button"
														className="btn btn-outline-primary w-45"
														data-dismiss="modal"
													>
														Dismiss
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default ViewRadioCampaignDetails;
