import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import { AppError } from "./_helpers/errorBoundaries/ErrorBoundaries";
import App from "./App";

import { Provider } from "react-redux";
import store, { persistor } from "./store";

import { PersistGate } from "redux-persist/integration/react";

Sentry.init({
	dsn: "https://09409ae496504f629a14052828d9ff66@o1206994.ingest.sentry.io/6340457",
	environment: process.env.NODE_ENV,
	integrations: [new BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<AppError>
					<App />
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</AppError>
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);
