import React, { Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import NumberFormat from "react-number-format";

import Loader from "../../../loader";
import MetaData from "../../../layout/MetaData";

import {
	getRadioCampaigns,
	clearErrors,
} from "../../../../actions/radioActions";

const ViewRadioCampaign = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { loading, error, radioCampaigns } = useSelector(
		(state) => state.radioCampaign || {}
	);

	const setEndDate = (startDate, numOfDays) => {
		const date = new Date(startDate);
		date.setDate(date.getDate() + parseInt(numOfDays));

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");

		return `${year}/${month}/${day}`;
	};

	const setBillboardCampaigns = () => {
		const data = {
			columns: [
				{
					label: "CAMPAIGN ID",
					field: "campaignId",
					sort: "asc",
				},
				{
					label: "ADS TYPE",
					field: "adType",
					sort: "asc",
				},
				{
					label: "DATE CREATED",
					field: "dateCreated",
					sort: "asc",
				},
				{
					label: "START DATE",
					field: "startDate",
					sort: "asc",
				},
				{
					label: "END DATE",
					field: "endDate",
					sort: "asc",
				},
				{
					label: "PRICE",
					field: "price",
					sort: "asc",
				},
				{
					label: "STATUS",
					field: "status",
					sort: "asc",
				},
				{
					label: "ACTIONS",
					field: "actions",
					sort: "asc",
				},
			],
			rows: [],
		};

		radioCampaigns &&
			radioCampaigns.reverse().forEach((campaign) => {
				data.rows.push({
					checkBoxes: (
						<Fragment>
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="customCheck1"
								/>
								<label
									className="custom-control-label"
									htmlFor="customCheck1"
								></label>
							</div>
						</Fragment>
					),
					campaignId: campaign.id,
					adType: campaign.adType,
					price: (
						<NumberFormat
							value={parseInt(campaign.cost)}
							displayType={"text"}
							thousandSeparator={true}
							prefix={"₦"}
						/>
					),
					dateCreated: DateTime.fromJSDate(
						new Date(campaign.createdAt)
					).toFormat("dd MMM, yyyy"),
					startDate: DateTime.fromJSDate(
						new Date(campaign.startDate)
					).toFormat("dd MMM, yyyy"),
					endDate: DateTime.fromJSDate(
						new Date(
							setEndDate(campaign.startDate, campaign.numOfDays)
						)
					).toFormat("dd MMM, yyyy"),
					status: (
						<span
							className={`badge d-flex-center ${
								(!campaign.isAdminApproved ||
									campaign.isAdminApproved) &&
								!campaign.isPublished &&
								(!campaign.isRejected || campaign.isRejected) &&
								!campaign.isApproved &&
								!campaign.isAdminRejected
									? "badge-pink"
									: ""
							} 
              ${
					campaign.isAdminApproved &&
					campaign.isPublished &&
					!campaign.isRejected &&
					campaign.isApproved
						? "badge-active"
						: ""
				}
              ${
					!campaign.isAdminApproved &&
					!campaign.isPublished &&
					campaign.isAdminRejected &&
					!campaign.isApproved
						? "badge-danger"
						: ""
				}
              ${
					campaign.isAdminApproved &&
					!campaign.isPublished &&
					!campaign.isRejected &&
					campaign.isApproved
						? "badge-pending"
						: ""
				}
              `}
						>
							{
								// campaign.isAdminApproved &&
								// campaign.isApproved &&
								// !campaign.isRejected &&
								// !campaign.isPublished
								//   ? "Approved"
								//   : null ||
								campaign.isAdminApproved &&
								!campaign.isPublished &&
								!campaign.isRejected &&
								campaign.isApproved
									? "Approved"
									: null ||
									  ((!campaign.isAdminApproved ||
											campaign.isAdminApproved) &&
											!campaign.isPublished &&
											(!campaign.isRejected ||
												campaign.isRejected) &&
											!campaign.isApproved &&
											!campaign.isAdminRejected)
									? "Pending"
									: null ||
									  (campaign.isAdminApproved &&
											campaign.isPublished &&
											!campaign.isRejected &&
											campaign.isApproved)
									? "Published"
									: null ||
									  (!campaign.isAdminApproved &&
											!campaign.isPublished &&
											!campaign.isApproved &&
											campaign.isAdminRejected)
									? "Rejected"
									: null
							}
						</span>
					),
					actions: (
						<Fragment>
							<div className="tx-black tx-14">
								<div className="d-flex">
									<Link
										to={`../campaign/radio/${campaign.id}`}
									>
										<i className="fa fa-eye tx-orange pd-t-4 mg-r-5" />{" "}
										View{" "}
									</Link>
								</div>
							</div>
						</Fragment>
					),
				});
			});
		return data;
	};

	useEffect(() => {
		dispatch(getRadioCampaigns());
	}, [dispatch]);

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch(clearErrors());
			navigate("/app/campaigns");
		}
	}, [dispatch, error, navigate]);

	return (
		<Fragment>
			<MetaData title={"Radio Campaigns"} />
			{loading ? (
				<Loader />
			) : (
				<MDBDataTable
					data={setBillboardCampaigns()}
					className="px-3 scroll"
					bordered
					striped
					hover
					responsive
				/>
			)}
		</Fragment>
	);
};

export default ViewRadioCampaign;
