import React, { useState, useEffect, useRef } from "react";
import { EditorState, RichUtils, Modifier } from "draft-js";
import Editor, { createEditorStateWithText } from "@draft-js-plugins/editor";
import { stateToHTML } from "draft-js-export-html";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar"; // Separator, // Import the static toolbar plugin
// import {
// 	ItalicButton,
// 	BoldButton,
// 	UnderlineButton,
// 	CodeButton,
// 	HeadlineOneButton,
// 	HeadlineTwoButton,
// 	HeadlineThreeButton,
// 	UnorderedListButton,
// 	OrderedListButton,
// 	BlockquoteButton,
// 	CodeBlockButton,
// } from "@draft-js-plugins/buttons";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";

// const HeadlinesPicker = ({ onOverrideContent }) => {
// 	const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];

// 	return (
// 		<div>
// 			{buttons.map((Button, i) => (
// 				<Button key={i} onOverrideContent={onOverrideContent} />
// 			))}
// 		</div>
// 	);
// };

// const HeadlinesButton = ({ onOverrideContent }) => {
// 	const onClick = () => {
// 		onOverrideContent(HeadlinesPicker);
// 	};

// 	return (
// 		<div className="headlineButtonWrapper">
// 			<button onClick={onClick} className="headlineButton">
// 				H
// 			</button>
// 		</div>
// 	);
// };

const TextEditor = ({ hasCount, handleChange, maxWordCount, placeholder }) => {
	const [editorState, setEditorState] = useState(
		createEditorStateWithText("")
	);
	const editorRef = useRef(null);

	const handleTextChange = (newEditorState) => {
		const wordCount = newEditorState
			.getCurrentContent()
			.getPlainText("")
			.trim()
			.split(/\s+/)
			.filter((word) => word !== "").length;

		if (hasCount) {
			if (wordCount <= maxWordCount) {
				setEditorState(newEditorState);
				handleChange(stateToHTML(newEditorState.getCurrentContent()));
			}
		} else {
			setEditorState(newEditorState);
			handleChange(stateToHTML(newEditorState.getCurrentContent()));
		}
	};

	const handleKeyCommand = (command, editorState) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			handleTextChange(newState);
			return "handled";
		}
		return "not-handled";
	};

	const handleBeforeInput = (chars, editorState) => {
		const contentState = editorState.getCurrentContent();
		const currentWordCount = contentState
			.getPlainText("")
			.trim()
			.split(/\s+/)
			.filter((word) => word !== "").length;

		if (hasCount) {
			if (currentWordCount >= maxWordCount) {
				return "handled";
			}
		} else {
			return "not-handled";
		}
		return "not-handled";
	};

	const handlePaste = (text, html, editorState) => {
		const contentState = editorState.getCurrentContent();
		const currentWordCount = contentState
			.getPlainText("")
			.trim()
			.split(/\s+/)
			.filter((word) => word !== "").length;
		const words = text.trim().split(/\s+/);

		if (
			!hasCount ||
			(hasCount && currentWordCount + words.length > maxWordCount)
		) {
			const excessWords = currentWordCount + words.length - maxWordCount;
			const trimmedText = words
				.slice(0, words.length - excessWords)
				.join(" ");

			const selectionState = editorState.getSelection();
			const newContentState = Modifier.replaceText(
				contentState,
				selectionState,
				trimmedText
			);

			handleTextChange(
				EditorState.push(
					editorState,
					newContentState,
					"insert-characters"
				)
			);
			return true;
		}

		return false;
	};

	const toolbarPlugin = createToolbarPlugin();
	// const { Toolbar } = toolbarPlugin;
	const plugins = [toolbarPlugin];

	useEffect(() => {
		// Fixing issue with SSR
		setEditorState(createEditorStateWithText(""));
	}, []);

	return (
		<div>
			<div className="editor" onClick={() => editorRef.current.focus()}>
				<div className="toolbar-wrapper">
					{/* <Toolbar>
						{
							(externalProps) => (
								<div>
									<BoldButton {...externalProps} />
									<ItalicButton {...externalProps} />
									<UnderlineButton {...externalProps} />
									<CodeButton {...externalProps} />
									<Separator {...externalProps} />
									<HeadlinesButton {...externalProps} />
									<UnorderedListButton {...externalProps} />
									<OrderedListButton {...externalProps} />
									<BlockquoteButton {...externalProps} />
									<CodeBlockButton {...externalProps} />
								</div>
							)
						}
					</Toolbar> */}
				</div>
				<Editor
					editorState={editorState}
					onChange={handleTextChange}
					handleKeyCommand={handleKeyCommand}
					handleBeforeInput={handleBeforeInput}
					handlePastedText={handlePaste}
					placeholder={placeholder}
					spellCheck={true}
					plugins={plugins}
					ref={editorRef}
				/>
			</div>
			{hasCount && (
				<p className="word-count">
					Word count:{" "}
					{
						editorState
							.getCurrentContent()
							.getPlainText("")
							.trim()
							.split(/\s+/)
							.filter((word) => word !== "").length
					}
					/{maxWordCount}
				</p>
			)}
		</div>
	);
};

export default TextEditor;
