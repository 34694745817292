import React from "react";

const CustomPagination = ({
	hasNextPage,
	hasPreviousPage,
	nextPage,
	pageSize,
	previousPage,
	recordCount,
	currentPage,
}) => {
	return (
		<nav aria-label="Page navigation">
			<ul className="pagination justify-content-start mt-4">
				<li
					className={`page-item ${hasPreviousPage ? "" : "disabled"}`}
				>
					<button
						className="page-link"
						onClick={previousPage}
						tabIndex="-1"
					>
						Previous
					</button>
				</li>
				<li className="page-item disabled">
					<span className="page-link">
						Page {currentPage} of{" "}
						{Math.ceil(recordCount / pageSize)}
					</span>
				</li>
				<li className={`page-item ${hasNextPage ? "" : "disabled"}`}>
					<button className="page-link" onClick={nextPage}>
						Next
					</button>
				</li>
			</ul>
		</nav>
	);
};

export default CustomPagination;
