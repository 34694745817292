import Axios from "axios";

import {
	GET_STATION_REQUEST,
	GET_STATION_SUCCESS,
	GET_STATION_FAIL,
	CREATE_RADIO_CAMPAIGN_REQUEST,
	CREATE_RADIO_CAMPAIGN_SUCCESS,
	CREATE_RADIO_CAMPAIGN_FAIL,
	GET_RADIO_CAMPAIGN_REQUEST,
	GET_RADIO_CAMPAIGN_SUCCESS,
	GET_RADIO_CAMPAIGN_FAIL,
	GET_CAMPAIGN_JINGLE_FEE,
	GET_CAMPAIGN_ARCON_FEE,
	GET_SINGLE_RADIO_CAMPAIGN_REQUEST,
	GET_SINGLE_RADIO_CAMPAIGN_SUCCESS,
	GET_SINGLE_RADIO_CAMPAIGN_FAIL,
	CLEAR_ERRORS,
} from "../constants/radioConstants";

const baseURL = process.env.REACT_APP_MYSOGI_BASE_URL;
const axios = Axios.create({
	baseURL,
});

//Get Radio Stations
export const getAllRadioStations = () => async (dispatch) => {
	try {
		dispatch({ type: GET_STATION_REQUEST });
		let user = JSON.parse(sessionStorage.getItem("user"));
		const token = user.user.token;

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.get(`/api/radio`, config);
		const arconFeePayload = {
			arcon_2days: parseInt(data.arcon_2days),
			arcon_2weeks: parseInt(data.arcon_2weeks),
		};

		if (data.status === "success") {
			dispatch({
				type: GET_STATION_SUCCESS,
				payload: data.data,
			});
			dispatch({
				type: GET_CAMPAIGN_JINGLE_FEE,
				payload: parseInt(data.fees),
			});
			dispatch({
				type: GET_CAMPAIGN_ARCON_FEE,
				payload: arconFeePayload,
			});
		} else {
			console.log(data);
			dispatch({
				type: GET_STATION_FAIL,
				payload: "Something went wrong!",
			});
		}
	} catch (data) {
		dispatch({
			type: GET_STATION_FAIL,
			payload: data.message,
		});
	}
};

export const createRadioCampaignAction = (campaignData) => async (dispatch) => {
	try {
		dispatch({ type: CREATE_RADIO_CAMPAIGN_REQUEST });
		let user = JSON.parse(sessionStorage.getItem("user"));
		const token = user.user.token;

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		};
		const { data } = await axios.post(
			`/api/campaign/radio`,
			campaignData,
			config
		);

		if (data.status === "success") {
			dispatch({
				type: CREATE_RADIO_CAMPAIGN_SUCCESS,
				payload: data,
			});
		} else {
			dispatch({
				type: CREATE_RADIO_CAMPAIGN_FAIL,
				payload: data.message,
			});
		}
	} catch (data) {
		dispatch({
			type: CREATE_RADIO_CAMPAIGN_FAIL,
			payload: "Something went wrong!",
		});
	}
};

export const getRadioCampaigns = () => async (dispatch) => {
	try {
		dispatch({ type: GET_RADIO_CAMPAIGN_REQUEST });
		let user = JSON.parse(sessionStorage.getItem("user"));
		const token = user.user.token;

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const { data } = await axios.get(
			`/api/campaign/radio/radio-campaigns`,
			config
		);

		if (data.status === "success") {
			dispatch({
				type: GET_RADIO_CAMPAIGN_SUCCESS,
				payload: data.data,
			});
		} else {
			dispatch({
				type: GET_RADIO_CAMPAIGN_FAIL,
				payload: data.message,
			});
		}
	} catch (error) {
		dispatch({
			type: GET_RADIO_CAMPAIGN_FAIL,
			payload: error.message,
		});
	}
};

export const getRadioCampaignDetails = (campaignId) => async (dispatch) => {
	try {
		dispatch({ type: GET_SINGLE_RADIO_CAMPAIGN_REQUEST });
		let user = JSON.parse(sessionStorage.getItem("user"));
		const token = user.user.token;

		const config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

		const { data } = await axios.get(
			`/api/campaign/radio/${campaignId}`,
			config
		);

		if (data.status === "success") {
			dispatch({
				type: GET_SINGLE_RADIO_CAMPAIGN_SUCCESS,
				payload: data.data,
			});
		} else {
			dispatch({
				type: GET_SINGLE_RADIO_CAMPAIGN_FAIL,
				payload: data.message,
			});
		}
	} catch (error) {
		dispatch({
			type: GET_SINGLE_RADIO_CAMPAIGN_FAIL,
			payload: error.message,
		});
	}
};

// Clear Errors
export const clearErrors = () => async (dispatch) => {
	dispatch({
		type: CLEAR_ERRORS,
	});
};
