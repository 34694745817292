import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { getAllBillBoard } from "../../../../actions/campaignActions";
import Loader from "../../../loader";
import MetaData from "../../../layout/MetaData";
import BillBoardCard from "./BillBoardCard";
import BillBoardModal from "./BillBoardModal";
import { toast } from "react-toastify";
import Select from "react-select";
import NaijaStates from "../../../../_helpers/NaijaStates/NaijaStates.js";

const statesData = [
	{
		id: 1,
		name: "LAGOS",
	},
	{
		id: 2,
		name: "KANO",
	},
	{
		id: 3,
		name: "PLATEAU",
	},
	{
		id: 4,
		name: "KWARA",
	},
	{
		id: 5,
		name: "OYO",
	},
	{
		id: 6,
		name: "BAUCHI",
	},
	{
		id: 7,
		name: "BORNO",
	},
	{
		id: 8,
		name: "FCT",
	},
	{
		id: 9,
		name: "NIGER",
	},
	{
		id: 10,
		name: "EKITI",
	},
	{
		id: 12,
		name: "IMO",
	},
	{
		id: 13,
		name: "ONDO",
	},
	{
		id: 14,
		name: "TARABA",
	},
	{
		id: 15,
		name: "KOGI",
	},
	{
		id: 16,
		name: "KEBBI",
	},
	{
		id: 17,
		name: "YOBE",
	},
	{
		id: 18,
		name: "EDO",
	},
	{
		id: 19,
		name: "BENUE",
	},
	{
		id: 20,
		name: "NASSARAWA",
	},
	{
		id: 21,
		name: "ABIA",
	},
	{
		id: 22,
		name: "DELTA",
	},
	{
		id: 23,
		name: "BAYELSA",
	},
	{
		id: 24,
		name: "KADUNA",
	},
	{
		id: 25,
		name: "JIGAWA",
	},
	{
		id: 26,
		name: "ADAMAWA",
	},
	{
		id: 27,
		name: "AKWA IBOM",
	},
	{
		id: 28,
		name: "RIVERS",
	},
	{
		id: 29,
		name: "ANAMBRA",
	},
	{
		id: 30,
		name: "GOMBE",
	},
	{
		id: 31,
		name: "ZAMFARA",
	},
	{
		id: 32,
		name: "KATSINA",
	},
	{
		id: 34,
		name: "EBONYI",
	},
	{
		id: 35,
		name: "OSUN",
	},
	{
		id: 36,
		name: "ENUGU",
	},
	{
		id: 37,
		name: "OGUN",
	},
	{
		id: 38,
		name: "SOKOTO",
	},
	{
		id: 39,
		name: "CROSS RIVER",
	},
];

const areaData = [
	{
		id: 129,
		name: "AIYETORO/LAGBADE/AGBALA/OLOTA",
		lga_id: 15,
	},
	{
		id: 130,
		name: "IJAIYE GBIRINMI",
		lga_id: 11,
	},
	{
		id: 131,
		name: "IJU OBAWOLE",
		lga_id: 11,
	},
	{
		id: 132,
		name: "AGA/IJOMU",
		lga_id: 18,
	},
	{
		id: 133,
		name: "1004/ABOYADE",
		lga_id: 17,
	},
	{
		id: 134,
		name: "NAFORIJA/POKA",
		lga_id: 15,
	},
	{
		id: 135,
		name: "ILOGBO",
		lga_id: 10,
	},
	{
		id: 136,
		name: "APA",
		lga_id: 10,
	},
	{
		id: 137,
		name: "OWOSENI",
		lga_id: 13,
	},
	{
		id: 138,
		name: "AGBADO DALEMO",
		lga_id: 11,
	},
	{
		id: 139,
		name: "IWORO-GBANKO",
		lga_id: 10,
	},
	{
		id: 140,
		name: "ALAFIA",
		lga_id: 13,
	},
	{
		id: 141,
		name: "ORIMEDU 2",
		lga_id: 12,
	},
	{
		id: 142,
		name: "ALAUSA",
		lga_id: 16,
	},
	{
		id: 143,
		name: "SIRIWON/ IGBEKODO 1",
		lga_id: 12,
	},
	{
		id: 144,
		name: "AYEGBAMI/ODORO",
		lga_id: 18,
	},
	{
		id: 145,
		name: "IJU OGUNDIMU",
		lga_id: 11,
	},
	{
		id: 146,
		name: "IBEJU 2",
		lga_id: 12,
	},
	{
		id: 147,
		name: "ALAGBADO/KOLLINTON",
		lga_id: 11,
	},
	{
		id: 148,
		name: "ODORAGUNSIN",
		lga_id: 15,
	},
	{
		id: 149,
		name: "ISAWO",
		lga_id: 18,
	},
	{
		id: 150,
		name: "IFAKO PELE",
		lga_id: 11,
	},
	{
		id: 151,
		name: "IJORA",
		lga_id: 13,
	},
	{
		id: 152,
		name: "SIRIWON/ IGBEKODO 2",
		lga_id: 12,
	},
	{
		id: 153,
		name: "BAIYEKU/ORITA/OFFIN",
		lga_id: 18,
	},
	{
		id: 154,
		name: "ABULE EGBA/ILUPEJU",
		lga_id: 11,
	},
	{
		id: 155,
		name: "ANJORIN",
		lga_id: 13,
	},
	{
		id: 156,
		name: "AJAH",
		lga_id: 17,
	},
	{
		id: 157,
		name: "IJU ISHAGA",
		lga_id: 11,
	},
	{
		id: 158,
		name: "IBEJU 1",
		lga_id: 12,
	},
	{
		id: 159,
		name: "TARKWA BAY",
		lga_id: 17,
	},
	{
		id: 160,
		name: "ALAKUKO AJEGUNLE",
		lga_id: 11,
	},
	{
		id: 161,
		name: "AJEBO ORUGBO",
		lga_id: 15,
	},
	{
		id: 162,
		name: "IGBOKUTA/OKE-AGBO/IGBALU",
		lga_id: 18,
	},
	{
		id: 163,
		name: "POSUKOH",
		lga_id: 10,
	},
	{
		id: 164,
		name: "ORIMEDU 3",
		lga_id: 12,
	},
	{
		id: 165,
		name: "OGBA/OLUWOLE",
		lga_id: 16,
	},
	{
		id: 166,
		name: "OLUGBOKERE/ABOMITI",
		lga_id: 15,
	},
	{
		id: 167,
		name: "IJEH/DOLPHIN ESTATE",
		lga_id: 17,
	},
	{
		id: 168,
		name: "IREPODUN",
		lga_id: 14,
	},
	{
		id: 169,
		name: "GBETHROME",
		lga_id: 10,
	},
	{
		id: 170,
		name: "ONILEKERE",
		lga_id: 16,
	},
	{
		id: 171,
		name: "IBEREKO",
		lga_id: 10,
	},
	{
		id: 172,
		name: "ODOFIN",
		lga_id: 14,
	},
	{
		id: 173,
		name: "ORIMEDU 1",
		lga_id: 12,
	},
	{
		id: 174,
		name: "AGURA/GBERIGBE",
		lga_id: 18,
	},
	{
		id: 175,
		name: "AJARA TOPA",
		lga_id: 10,
	},
	{
		id: 176,
		name: "AKORO",
		lga_id: 10,
	},
	{
		id: 177,
		name: "AKIODE",
		lga_id: 16,
	},
	{
		id: 178,
		name: "AGUDA",
		lga_id: 16,
	},
	{
		id: 179,
		name: "IGBO-EFON/MAIYEGUN/IKOTA",
		lga_id: 17,
	},
	{
		id: 180,
		name: "IFAKO/COKER",
		lga_id: 11,
	},
	{
		id: 181,
		name: "ARAROMI-ALE",
		lga_id: 10,
	},
	{
		id: 182,
		name: "ELEPE",
		lga_id: 18,
	},
	{
		id: 183,
		name: "MAYUNRE-ORIBA",
		lga_id: 15,
	},
	{
		id: 184,
		name: "OKE-IRA CENTRAL",
		lga_id: 16,
	},
	{
		id: 185,
		name: "IKOYI 1",
		lga_id: 17,
	},
	{
		id: 186,
		name: "ORILE IKEJA",
		lga_id: 16,
	},
	{
		id: 187,
		name: "IJAIYE/IFAKO",
		lga_id: 11,
	},
	{
		id: 188,
		name: "G R A",
		lga_id: 16,
	},
	{
		id: 189,
		name: "IJAIYE OKE-IRA",
		lga_id: 11,
	},
	{
		id: 190,
		name: "AJAGURO",
		lga_id: 18,
	},
	{
		id: 191,
		name: "IKOGA",
		lga_id: 10,
	},
	{
		id: 192,
		name: "JEGBA QUARTERS",
		lga_id: 10,
	},
	{
		id: 193,
		name: "LEKKI 1",
		lga_id: 12,
	},
	{
		id: 194,
		name: "SERIKI-ARO",
		lga_id: 16,
	},
	{
		id: 195,
		name: "KURAMO SCHOOL/LEKKI",
		lga_id: 17,
	},
	{
		id: 196,
		name: "OSAPA/CHEVRON/VGC",
		lga_id: 17,
	},
	{
		id: 197,
		name: "IWEREKUN 2",
		lga_id: 12,
	},
	{
		id: 198,
		name: "TOPO-IDALE",
		lga_id: 10,
	},
	{
		id: 199,
		name: "IJEGUN",
		lga_id: 14,
	},
	{
		id: 200,
		name: "ISALU/IYAFIN",
		lga_id: 10,
	},
	{
		id: 201,
		name: "OJODU",
		lga_id: 16,
	},
	{
		id: 202,
		name: "ERIKORODO",
		lga_id: 18,
	},
	{
		id: 203,
		name: "ABOSAN",
		lga_id: 18,
	},
	{
		id: 204,
		name: "ALADE",
		lga_id: 16,
	},
	{
		id: 205,
		name: "SANGOTEDO",
		lga_id: 17,
	},
	{
		id: 206,
		name: "SAGIDA/OKEPOSU",
		lga_id: 15,
	},
	{
		id: 207,
		name: "VI EXTENSION/ONIRU",
		lga_id: 17,
	},
	{
		id: 208,
		name: "OLUSOSUN",
		lga_id: 16,
	},
	{
		id: 209,
		name: "AJIDO",
		lga_id: 10,
	},
	{
		id: 210,
		name: "AHOVIKOH",
		lga_id: 10,
	},
	{
		id: 211,
		name: "KUJE",
		lga_id: 14,
	},
	{
		id: 212,
		name: "IKATE/LEKKI",
		lga_id: 17,
	},
	{
		id: 213,
		name: "ARADAGUN-MOWO",
		lga_id: 10,
	},
	{
		id: 214,
		name: "VICTORIA ISLAND",
		lga_id: 17,
	},
	{
		id: 215,
		name: "OBALENDE",
		lga_id: 17,
	},
	{
		id: 216,
		name: "APAPA",
		lga_id: 13,
	},
	{
		id: 217,
		name: "OKUN AJAH/OKUNMOPO",
		lga_id: 17,
	},
	{
		id: 218,
		name: "KIRIKIRI AND ENVIRON",
		lga_id: 14,
	},
	{
		id: 219,
		name: "ILASAN/ORILE",
		lga_id: 17,
	},
	{
		id: 220,
		name: "OWU/OTA",
		lga_id: 15,
	},
	{
		id: 221,
		name: "LEKKI 2",
		lga_id: 12,
	},
	{
		id: 222,
		name: "TABON TABON/EGBIR",
		lga_id: 11,
	},
	{
		id: 223,
		name: "BADIA",
		lga_id: 13,
	},
	{
		id: 224,
		name: "ANIFOWOSHE",
		lga_id: 16,
	},
	{
		id: 225,
		name: "ILADO TOMARO",
		lga_id: 14,
	},
	{
		id: 226,
		name: "ATERE",
		lga_id: 18,
	},
	{
		id: 227,
		name: "IBESHE",
		lga_id: 18,
	},
	{
		id: 228,
		name: "PAPA",
		lga_id: 15,
	},
	{
		id: 229,
		name: "ORIRE",
		lga_id: 14,
	},
	{
		id: 230,
		name: "MAROKO/OKUN ALFA",
		lga_id: 17,
	},
	{
		id: 231,
		name: "ODOMOLA",
		lga_id: 15,
	},
	{
		id: 232,
		name: "ADDO/OKEIRA",
		lga_id: 17,
	},
	{
		id: 233,
		name: "IWEREKUN 1",
		lga_id: 12,
	},
	{
		id: 234,
		name: "ODUDUWA",
		lga_id: 13,
	},
	{
		id: 235,
		name: "AGBOWA 2/ADO",
		lga_id: 15,
	},
	{
		id: 236,
		name: "FALOMO/OYINKAN ABAYOMI",
		lga_id: 17,
	},
	{
		id: 237,
		name: "IJAIYE OJOKORO",
		lga_id: 11,
	},
	{
		id: 238,
		name: "ONIGBONGBO",
		lga_id: 16,
	},
	{
		id: 239,
		name: "AJARA VETHO",
		lga_id: 10,
	},
	{
		id: 240,
		name: "AJAGANABE",
		lga_id: 15,
	},
	{
		id: 241,
		name: "OPEBI",
		lga_id: 16,
	},
	{
		id: 242,
		name: "IPAKODO",
		lga_id: 18,
	},
	{
		id: 243,
		name: "AGBOWA 1",
		lga_id: 15,
	},
	{
		id: 244,
		name: "SARI",
		lga_id: 13,
	},
	{
		id: 245,
		name: "MARINE BEACH",
		lga_id: 13,
	},
	{
		id: 246,
		name: "OREGUN",
		lga_id: 16,
	},
	{
		id: 247,
		name: "IKOYI 2",
		lga_id: 17,
	},
	{
		id: 248,
		name: "IFESOWAPO/TEMU OKE-OSO",
		lga_id: 15,
	},
	{
		id: 249,
		name: "AIGE/SOLOMADE",
		lga_id: 18,
	},
	{
		id: 250,
		name: "BADORE/LANGBASA",
		lga_id: 17,
	},
	{
		id: 251,
		name: "EJIRIN/KETU",
		lga_id: 15,
	},
	{
		id: 252,
		name: "OKE-IRA POWERLINE",
		lga_id: 16,
	},
	{
		id: 253,
		name: "WESERE",
		lga_id: 10,
	},
	{
		id: 254,
		name: "AGBALA/ LASUNWON",
		lga_id: 18,
	},
	{
		id: 255,
		name: "WASIMI",
		lga_id: 16,
	},
	{
		id: 256,
		name: "ABRAHAM ADESANYA",
		lga_id: 13,
	},
	{
		id: 257,
		name: "IKEM",
		lga_id: 499,
	},
	{
		id: 258,
		name: "ADEOYO",
		lga_id: 183,
	},
	{
		id: 259,
		name: "RIJIYAR ZAKI",
		lga_id: 142,
	},
	{
		id: 260,
		name: "NEW SAWMILL, OSOGBO",
		lga_id: 711,
	},
	{
		id: 261,
		name: "ISOKUN, OYO WEST",
		lga_id: 282,
	},
	{
		id: 262,
		name: "PRISON SERVICE AREA KEBBI",
		lga_id: 706,
	},
	{
		id: 263,
		name: "MAIKULKI",
		lga_id: 596,
	},
	{
		id: 264,
		name: "IGBETI",
		lga_id: 355,
	},
	{
		id: 265,
		name: "OKPUI TUMO",
		lga_id: 68,
	},
	{
		id: 266,
		name: "IDOFIN, OKE ERO",
		lga_id: 555,
	},
	{
		id: 267,
		name: "EFIRAN",
		lga_id: 751,
	},
	{
		id: 268,
		name: "MORIKI",
		lga_id: 44,
	},
	{
		id: 269,
		name: "YADI CHAM",
		lga_id: 405,
	},
	{
		id: 270,
		name: "DANDUME",
		lga_id: 721,
	},
	{
		id: 271,
		name: "JERE, KAGARKO",
		lga_id: 225,
	},
	{
		id: 272,
		name: "NNNOBI",
		lga_id: 679,
	},
	{
		id: 273,
		name: "BAKURA",
		lga_id: 589,
	},
	{
		id: 274,
		name: "EBANGI",
		lga_id: 374,
	},
	{
		id: 275,
		name: "AKPUGBO",
		lga_id: 330,
	},
	{
		id: 276,
		name: "MOROCCO",
		lga_id: 404,
	},
	{
		id: 277,
		name: "ABAKPA CENTRAL",
		lga_id: 93,
	},
	{
		id: 278,
		name: "ORILE",
		lga_id: 577,
	},
	{
		id: 279,
		name: "REFINERY ROAD",
		lga_id: 532,
	},
	{
		id: 280,
		name: "SASA AREA",
		lga_id: 219,
	},
	{
		id: 281,
		name: "OLUMBANASA",
		lga_id: 538,
	},
	{
		id: 282,
		name: "OKE EDA",
		lga_id: 199,
	},
	{
		id: 283,
		name: "ALAWAYE",
		lga_id: 25,
	},
	{
		id: 284,
		name: "DALHATU ARAF SPECIALIST HOSPITAL",
		lga_id: 452,
	},
	{
		id: 285,
		name: "ONIKOLOBO",
		lga_id: 118,
	},
	{
		id: 286,
		name: "OBULEKE",
		lga_id: 264,
	},
	{
		id: 287,
		name: "SHANGISA",
		lga_id: 465,
	},
	{
		id: 288,
		name: "UMEZE",
		lga_id: 536,
	},
	{
		id: 289,
		name: "GANDI",
		lga_id: 363,
	},
	{
		id: 290,
		name: "AGWADA",
		lga_id: 335,
	},
	{
		id: 291,
		name: "OKE AYO, EGBEDORE",
		lga_id: 711,
	},
	{
		id: 292,
		name: "IRE AKARI ESTATE",
		lga_id: 509,
	},
	{
		id: 293,
		name: "ALAKPO LAYOUT",
		lga_id: 532,
	},
	{
		id: 294,
		name: "YONOV",
		lga_id: 495,
	},
	{
		id: 295,
		name: "SOUTH UKELE",
		lga_id: 761,
	},
	{
		id: 296,
		name: "EWHOKPAKA",
		lga_id: 494,
	},
	{
		id: 297,
		name: "KATSINA GRA",
		lga_id: 399,
	},
	{
		id: 298,
		name: "ATAKUMOSA",
		lga_id: 386,
	},
	{
		id: 299,
		name: "WARU",
		lga_id: 476,
	},
	{
		id: 300,
		name: "TUNDUN FULANI",
		lga_id: 82,
	},
	{
		id: 301,
		name: "IBA TOWN, ODO OTIN",
		lga_id: 771,
	},
	{
		id: 302,
		name: "GRA PHASE 2",
		lga_id: 594,
	},
	{
		id: 303,
		name: "ISINGWU",
		lga_id: 598,
	},
	{
		id: 304,
		name: "FADIYA",
		lga_id: 539,
	},
	{
		id: 305,
		name: "UNI ILORIN PS",
		lga_id: 108,
	},
	{
		id: 306,
		name: "ARAROMI OYO-WEST",
		lga_id: 282,
	},
	{
		id: 307,
		name: "AGBABIAKA",
		lga_id: 316,
	},
	{
		id: 308,
		name: "EMEZU",
		lga_id: 241,
	},
	{
		id: 309,
		name: "CHIBOK",
		lga_id: 561,
	},
	{
		id: 310,
		name: "ORITA CHALLENGE",
		lga_id: 126,
	},
	{
		id: 311,
		name: "OROWOROKO",
		lga_id: 594,
	},
	{
		id: 312,
		name: "IKATE",
		lga_id: 641,
	},
	{
		id: 313,
		name: "ITCHI",
		lga_id: 338,
	},
	{
		id: 314,
		name: "EWET VILLAGE",
		lga_id: 162,
	},
	{
		id: 315,
		name: "UMUKABIA OGODO",
		lga_id: 676,
	},
	{
		id: 316,
		name: "THE POLYTECHNIC IBADAN SOUTH CAMPUS",
		lga_id: 524,
	},
	{
		id: 317,
		name: "SABON GIDA KEFFI",
		lga_id: 265,
	},
	{
		id: 318,
		name: "EZZAGU",
		lga_id: 241,
	},
	{
		id: 319,
		name: "LADE",
		lga_id: 260,
	},
	{
		id: 320,
		name: "GWONG",
		lga_id: 732,
	},
	{
		id: 321,
		name: "IFANA",
		lga_id: 686,
	},
	{
		id: 322,
		name: "BUGUMA",
		lga_id: 163,
	},
	{
		id: 323,
		name: "ONIRU ESTATE",
		lga_id: 410,
	},
	{
		id: 324,
		name: "ACHARA UTURU",
		lga_id: 65,
	},
	{
		id: 325,
		name: "IRE AKARI ESTATE, OSOGBO",
		lga_id: 219,
	},
	{
		id: 326,
		name: "DAKATA",
		lga_id: 142,
	},
	{
		id: 327,
		name: "ATBU TEACHING HOSPITAL",
		lga_id: 653,
	},
	{
		id: 328,
		name: "OKE APENA",
		lga_id: 268,
	},
	{
		id: 329,
		name: "ALAKA ESTATE",
		lga_id: 641,
	},
	{
		id: 330,
		name: "OLABAYO HOUSING ESTATE",
		lga_id: 187,
	},
	{
		id: 331,
		name: "SABO-YABA",
		lga_id: 442,
	},
	{
		id: 332,
		name: "OZIZZA",
		lga_id: 337,
	},
	{
		id: 333,
		name: "BENUE STATE UNIVERSITY",
		lga_id: 357,
	},
	{
		id: 334,
		name: "BIDA ROAD",
		lga_id: 517,
	},
	{
		id: 335,
		name: "BODIJA ASHI",
		lga_id: 524,
	},
	{
		id: 336,
		name: "SABUWA",
		lga_id: 286,
	},
	{
		id: 337,
		name: "AMAKU",
		lga_id: 358,
	},
	{
		id: 338,
		name: "SALISU IBRAHIM, LOKOJA",
		lga_id: 187,
	},
	{
		id: 339,
		name: "UHI",
		lga_id: 279,
	},
	{
		id: 340,
		name: "ISU, ONICHA",
		lga_id: 76,
	},
	{
		id: 341,
		name: "KINTAKO",
		lga_id: 307,
	},
	{
		id: 342,
		name: "IBOM TROPICANNA",
		lga_id: 162,
	},
	{
		id: 343,
		name: "PRESIDENTIAL ESTATE PH",
		lga_id: 78,
	},
	{
		id: 344,
		name: "WEST END, ASABA",
		lga_id: 574,
	},
	{
		id: 345,
		name: "UMUNACHI",
		lga_id: 554,
	},
	{
		id: 346,
		name: "OWA",
		lga_id: 125,
	},
	{
		id: 347,
		name: "WAWA, IFO",
		lga_id: 549,
	},
	{
		id: 348,
		name: "IBA, LAGOS",
		lga_id: 228,
	},
	{
		id: 349,
		name: "EUPI NEW LAYOUT",
		lga_id: 333,
	},
	{
		id: 350,
		name: "KADAWA",
		lga_id: 425,
	},
	{
		id: 351,
		name: "LIFATU",
		lga_id: 497,
	},
	{
		id: 352,
		name: "OGBOMOSHO RURAL SOUTH",
		lga_id: 121,
	},
	{
		id: 353,
		name: "NUWAKOTA",
		lga_id: 106,
	},
	{
		id: 354,
		name: "KAFINSULI",
		lga_id: 523,
	},
	{
		id: 355,
		name: "IFETEDO",
		lga_id: 383,
	},
	{
		id: 356,
		name: "FETEDO",
		lga_id: 299,
	},
	{
		id: 357,
		name: "TAURA",
		lga_id: 414,
	},
	{
		id: 358,
		name: "ILAJE",
		lga_id: 720,
	},
	{
		id: 359,
		name: "ELUME",
		lga_id: 754,
	},
	{
		id: 360,
		name: "GABI",
		lga_id: 556,
	},
	{
		id: 361,
		name: "IMOTA",
		lga_id: 212,
	},
	{
		id: 362,
		name: "ORAIFITE",
		lga_id: 773,
	},
	{
		id: 363,
		name: "WARRI REFINERY",
		lga_id: 417,
	},
	{
		id: 364,
		name: "EWOHIMI",
		lga_id: 55,
	},
	{
		id: 365,
		name: "IMEZU IHEAGU ESTATE",
		lga_id: 358,
	},
	{
		id: 366,
		name: "OBOKUN",
		lga_id: 203,
	},
	{
		id: 367,
		name: "IROJO QUARTERS",
		lga_id: 470,
	},
	{
		id: 368,
		name: "ASHAKA, FUNAKAYE",
		lga_id: 716,
	},
	{
		id: 369,
		name: "MOPA-MURO",
		lga_id: 283,
	},
	{
		id: 370,
		name: "HUNKUYI",
		lga_id: 462,
	},
	{
		id: 371,
		name: "YABO",
		lga_id: 418,
	},
	{
		id: 372,
		name: "ARAROMI IBEJU",
		lga_id: 751,
	},
	{
		id: 373,
		name: "ENAGI",
		lga_id: 427,
	},
	{
		id: 374,
		name: "EWU OSI AREA",
		lga_id: 378,
	},
	{
		id: 375,
		name: "OKE BALE",
		lga_id: 219,
	},
	{
		id: 376,
		name: "ZURMI",
		lga_id: 44,
	},
	{
		id: 377,
		name: "IDIRISU",
		lga_id: 113,
	},
	{
		id: 378,
		name: "BASIRI",
		lga_id: 573,
	},
	{
		id: 379,
		name: "AGIDINGBI",
		lga_id: 584,
	},
	{
		id: 380,
		name: "NEW NETIM",
		lga_id: 543,
	},
	{
		id: 381,
		name: "DAKACHE",
		lga_id: 579,
	},
	{
		id: 382,
		name: "SOYINDO",
		lga_id: 378,
	},
	{
		id: 383,
		name: "AKIN ADESOLA",
		lga_id: 410,
	},
	{
		id: 384,
		name: "WUSE 1 ZONE 3",
		lga_id: 476,
	},
	{
		id: 385,
		name: "NNEMPI",
		lga_id: 668,
	},
	{
		id: 386,
		name: "AWA",
		lga_id: 288,
	},
	{
		id: 387,
		name: "DAMBOA ROAD",
		lga_id: 693,
	},
	{
		id: 388,
		name: "OJODU BERGER, LAGOS",
		lga_id: 584,
	},
	{
		id: 389,
		name: "WAPAN VAKU",
		lga_id: 140,
	},
	{
		id: 390,
		name: "OSUMENYI NNEWI",
		lga_id: 170,
	},
	{
		id: 391,
		name: "AFOR UDO",
		lga_id: 241,
	},
	{
		id: 392,
		name: "NSULU",
		lga_id: 119,
	},
	{
		id: 393,
		name: "COMMAND ALIMOSHO",
		lga_id: 577,
	},
	{
		id: 394,
		name: "MOSAFEJO",
		lga_id: 10,
	},
	{
		id: 395,
		name: "ONIGBONGBO",
		lga_id: 584,
	},
	{
		id: 396,
		name: "UKANA",
		lga_id: 760,
	},
	{
		id: 397,
		name: "GENERAL HOSPITAL OBAGANZA VILLAGE",
		lga_id: 333,
	},
	{
		id: 398,
		name: "IBESIT NUNG IKOT",
		lga_id: 511,
	},
	{
		id: 399,
		name: "AMUKOKO",
		lga_id: 526,
	},
	{
		id: 400,
		name: "BAMBAM",
		lga_id: 150,
	},
	{
		id: 401,
		name: "EKPAN ROAD",
		lga_id: 532,
	},
	{
		id: 402,
		name: "OKPAEZE",
		lga_id: 257,
	},
	{
		id: 403,
		name: "OWHUM",
		lga_id: 760,
	},
	{
		id: 404,
		name: "MAIKONI",
		lga_id: 59,
	},
	{
		id: 405,
		name: "EMURE",
		lga_id: 481,
	},
	{
		id: 406,
		name: "EKEOBA",
		lga_id: 598,
	},
	{
		id: 407,
		name: "BOGOBIRI",
		lga_id: 559,
	},
	{
		id: 408,
		name: "IFAKO",
		lga_id: 552,
	},
	{
		id: 409,
		name: "JAFAT HOUSE, KANO",
		lga_id: 236,
	},
	{
		id: 410,
		name: "GELENGU",
		lga_id: 150,
	},
	{
		id: 411,
		name: "SAMONDA",
		lga_id: 183,
	},
	{
		id: 412,
		name: "PEN CINEMA",
		lga_id: 552,
	},
	{
		id: 413,
		name: "OGBOKO",
		lga_id: 519,
	},
	{
		id: 414,
		name: "NIPOST GPO DUTSE",
		lga_id: 464,
	},
	{
		id: 415,
		name: "ILEPA",
		lga_id: 258,
	},
	{
		id: 416,
		name: "MAIKUNKELE",
		lga_id: 19,
	},
	{
		id: 417,
		name: "NKWELLE EZUNAK",
		lga_id: 717,
	},
	{
		id: 418,
		name: "ABBA",
		lga_id: 554,
	},
	{
		id: 419,
		name: "GOKANA",
		lga_id: 638,
	},
	{
		id: 420,
		name: "EDJEBA TOWN",
		lga_id: 417,
	},
	{
		id: 421,
		name: "ADIMULAS PALACE",
		lga_id: 720,
	},
	{
		id: 422,
		name: "MARYLAND KOSOFE",
		lga_id: 465,
	},
	{
		id: 423,
		name: "UMUNZE",
		lga_id: 320,
	},
	{
		id: 424,
		name: "OGOJA",
		lga_id: 310,
	},
	{
		id: 425,
		name: "YAURI FLAT AREA",
		lga_id: 450,
	},
	{
		id: 426,
		name: "KULENDE SOUTH",
		lga_id: 316,
	},
	{
		id: 427,
		name: "ALI MARAMI",
		lga_id: 259,
	},
	{
		id: 428,
		name: "UMUEBILI ULA",
		lga_id: 747,
	},
	{
		id: 429,
		name: "OKE OROKI",
		lga_id: 282,
	},
	{
		id: 430,
		name: "OBASANJO FARM, OTA",
		lga_id: 629,
	},
	{
		id: 431,
		name: "OBI, NASARAWA",
		lga_id: 428,
	},
	{
		id: 432,
		name: "CARLTON GATE ESTATE",
		lga_id: 17,
	},
	{
		id: 433,
		name: "AMAUJU",
		lga_id: 192,
	},
	{
		id: 434,
		name: "DANKOGI",
		lga_id: 400,
	},
	{
		id: 435,
		name: "IGBODO",
		lga_id: 125,
	},
	{
		id: 436,
		name: "KALTUNGO EAST",
		lga_id: 245,
	},
	{
		id: 437,
		name: "TUDUN WADA LOWCOST HOUSE GUSAU",
		lga_id: 247,
	},
	{
		id: 438,
		name: "GOWON ESTATE",
		lga_id: 577,
	},
	{
		id: 439,
		name: "MOWE",
		lga_id: 690,
	},
	{
		id: 440,
		name: "IJEBU IJESA",
		lga_id: 57,
	},
	{
		id: 441,
		name: "ADAZI NNUKWU",
		lga_id: 387,
	},
	{
		id: 442,
		name: "AJEBO IJEBU NORTH",
		lga_id: 184,
	},
	{
		id: 443,
		name: "KAIAMA",
		lga_id: 24,
	},
	{
		id: 444,
		name: "OBASA",
		lga_id: 719,
	},
	{
		id: 445,
		name: "WAKO",
		lga_id: 643,
	},
	{
		id: 446,
		name: "IBEREKO",
		lga_id: 10,
	},
	{
		id: 447,
		name: "ETINAN URBAN",
		lga_id: 617,
	},
	{
		id: 448,
		name: "ESA ODO",
		lga_id: 203,
	},
	{
		id: 449,
		name: "IKOYI, LAGOS",
		lga_id: 410,
	},
	{
		id: 450,
		name: "NANGERE",
		lga_id: 22,
	},
	{
		id: 451,
		name: "UGBOJU",
		lga_id: 333,
	},
	{
		id: 452,
		name: "BILBIS",
		lga_id: 527,
	},
	{
		id: 453,
		name: "RANTYA LOWCOST",
		lga_id: 732,
	},
	{
		id: 454,
		name: "RIYOM",
		lga_id: 622,
	},
	{
		id: 455,
		name: "ABRIBA",
		lga_id: 522,
	},
	{
		id: 456,
		name: "MBAAWA",
		lga_id: 277,
	},
	{
		id: 457,
		name: "OMU ARAN",
		lga_id: 164,
	},
	{
		id: 458,
		name: "NEW GARAGE EXTENSION",
		lga_id: 126,
	},
	{
		id: 459,
		name: "EKWEREAZU",
		lga_id: 264,
	},
	{
		id: 460,
		name: "BARNAWA GRA CHIKUN",
		lga_id: 480,
	},
	{
		id: 461,
		name: "JEMO",
		lga_id: 118,
	},
	{
		id: 462,
		name: "BARKIN SALE, MINNA",
		lga_id: 97,
	},
	{
		id: 463,
		name: "IKONO SOUTH",
		lga_id: 373,
	},
	{
		id: 464,
		name: "OGIJO",
		lga_id: 378,
	},
	{
		id: 465,
		name: "LANTORO",
		lga_id: 118,
	},
	{
		id: 466,
		name: "ISALE ORA",
		lga_id: 529,
	},
	{
		id: 467,
		name: "SHARADA BATA",
		lga_id: 349,
	},
	{
		id: 468,
		name: "CHAGWA",
		lga_id: 50,
	},
	{
		id: 469,
		name: "ZAZAGA",
		lga_id: 175,
	},
	{
		id: 470,
		name: "URATTA ABA SOUTH",
		lga_id: 100,
	},
	{
		id: 471,
		name: "INDEPENDENCE LAYOUT PHASE 2, ENUGU",
		lga_id: 197,
	},
	{
		id: 472,
		name: "SHANGEV YA",
		lga_id: 136,
	},
	{
		id: 473,
		name: "WETHERAL RD OWERRI",
		lga_id: 318,
	},
	{
		id: 474,
		name: "RAWAIYA",
		lga_id: 34,
	},
	{
		id: 475,
		name: "ILE OLUJI",
		lga_id: 602,
	},
	{
		id: 476,
		name: "MBIASO CLAN",
		lga_id: 294,
	},
	{
		id: 477,
		name: "MAGRET EKPO INTERNATIONAL AIRPORT",
		lga_id: 559,
	},
	{
		id: 478,
		name: "KWANDU",
		lga_id: 216,
	},
	{
		id: 479,
		name: "NDAGBACHI",
		lga_id: 106,
	},
	{
		id: 480,
		name: "ODO-OBA, OGBOMOSHO",
		lga_id: 305,
	},
	{
		id: 481,
		name: "NASARAWA EGON",
		lga_id: 67,
	},
	{
		id: 482,
		name: "ISERO IKONIFIN",
		lga_id: 576,
	},
	{
		id: 483,
		name: "IREMO ROAD",
		lga_id: 152,
	},
	{
		id: 484,
		name: "UME",
		lga_id: 428,
	},
	{
		id: 485,
		name: "OPOROMA",
		lga_id: 230,
	},
	{
		id: 486,
		name: "OLORA",
		lga_id: 573,
	},
	{
		id: 487,
		name: "FURSUM",
		lga_id: 148,
	},
	{
		id: 488,
		name: "LAFIYA LAMURDE",
		lga_id: 405,
	},
	{
		id: 489,
		name: "OBO ERUKU",
		lga_id: 313,
	},
	{
		id: 490,
		name: "ALULU",
		lga_id: 676,
	},
	{
		id: 491,
		name: "INISAN",
		lga_id: 164,
	},
	{
		id: 492,
		name: "OBIDO",
		lga_id: 535,
	},
	{
		id: 493,
		name: "OBE",
		lga_id: 330,
	},
	{
		id: 494,
		name: "IHEAKA",
		lga_id: 338,
	},
	{
		id: 495,
		name: "AKWA IBOM HOUSE OF ASSEMBLY",
		lga_id: 162,
	},
	{
		id: 496,
		name: "BASHORUN AKINYELE",
		lga_id: 524,
	},
	{
		id: 497,
		name: "GINT IGBE",
		lga_id: 18,
	},
	{
		id: 498,
		name: "MAPI",
		lga_id: 19,
	},
	{
		id: 499,
		name: "IWOPIN OGUN",
		lga_id: 350,
	},
	{
		id: 500,
		name: "NKALEKE ACHARA",
		lga_id: 520,
	},
	{
		id: 501,
		name: "ALOGBA",
		lga_id: 212,
	},
	{
		id: 502,
		name: "SHEHU PALACE",
		lga_id: 693,
	},
	{
		id: 503,
		name: "WASE TOWN",
		lga_id: 644,
	},
	{
		id: 504,
		name: "SABUWAR TASHA",
		lga_id: 213,
	},
	{
		id: 505,
		name: "AKWAETE",
		lga_id: 471,
	},
	{
		id: 506,
		name: "EZZAMGBO",
		lga_id: 642,
	},
	{
		id: 507,
		name: "LUSADA",
		lga_id: 629,
	},
	{
		id: 508,
		name: "SOKOBUTU",
		lga_id: 743,
	},
	{
		id: 509,
		name: "BADORE",
		lga_id: 17,
	},
	{
		id: 510,
		name: "YADA KUNYA",
		lga_id: 142,
	},
	{
		id: 511,
		name: "MADONNA UNIVERSITY, RIVERS",
		lga_id: 611,
	},
	{
		id: 512,
		name: "EJIGBO, ADO EKITI",
		lga_id: 573,
	},
	{
		id: 513,
		name: "MUSHERE",
		lga_id: 339,
	},
	{
		id: 514,
		name: "BASIN AREA, ILORIN EAST",
		lga_id: 129,
	},
	{
		id: 515,
		name: "ALAMALA TOWN",
		lga_id: 719,
	},
	{
		id: 516,
		name: "BAKIN RIJIYA",
		lga_id: 452,
	},
	{
		id: 517,
		name: "AJOWO",
		lga_id: 258,
	},
	{
		id: 518,
		name: "ABATETE",
		lga_id: 669,
	},
	{
		id: 519,
		name: "GULU",
		lga_id: 556,
	},
	{
		id: 520,
		name: "WOJI PH",
		lga_id: 594,
	},
	{
		id: 521,
		name: "EKE NNORIE",
		lga_id: 676,
	},
	{
		id: 522,
		name: "OFIKI",
		lga_id: 132,
	},
	{
		id: 523,
		name: "OKELELE WEST",
		lga_id: 108,
	},
	{
		id: 524,
		name: "ALL HALLOWS SEMINARY",
		lga_id: 500,
	},
	{
		id: 525,
		name: "IGUOBAZUWA",
		lga_id: 654,
	},
	{
		id: 526,
		name: "IGEDE",
		lga_id: 420,
	},
	{
		id: 527,
		name: "OKPOFE",
		lga_id: 134,
	},
	{
		id: 528,
		name: "DEBA",
		lga_id: 712,
	},
	{
		id: 529,
		name: "OKPOPO OGBARU",
		lga_id: 353,
	},
	{
		id: 530,
		name: "VICTORIA GARDEN CITY",
		lga_id: 410,
	},
	{
		id: 531,
		name: "KUMO",
		lga_id: 103,
	},
	{
		id: 532,
		name: "FUTY STAFF QUATERS, YOLA",
		lga_id: 60,
	},
	{
		id: 533,
		name: "OBICHIE OVIM",
		lga_id: 65,
	},
	{
		id: 534,
		name: "AWAKA",
		lga_id: 741,
	},
	{
		id: 535,
		name: "TAKAI",
		lga_id: 308,
	},
	{
		id: 536,
		name: "ATBU HOUSING ESTATE",
		lga_id: 653,
	},
	{
		id: 537,
		name: "ZAZZAU EMIRATES",
		lga_id: 579,
	},
	{
		id: 538,
		name: "AFOR, NDOKWA EAST",
		lga_id: 591,
	},
	{
		id: 539,
		name: "AKIJAGUNLA",
		lga_id: 285,
	},
	{
		id: 540,
		name: "OBUBRA URBAN",
		lga_id: 48,
	},
	{
		id: 541,
		name: "OKEHO",
		lga_id: 303,
	},
	{
		id: 542,
		name: "AGBOJU AMUWO",
		lga_id: 694,
	},
	{
		id: 543,
		name: "EKWE ELU",
		lga_id: 650,
	},
	{
		id: 544,
		name: "RUMUODOMAYA",
		lga_id: 78,
	},
	{
		id: 545,
		name: "MIGA",
		lga_id: 375,
	},
	{
		id: 546,
		name: "UMUOZU",
		lga_id: 27,
	},
	{
		id: 547,
		name: "OLOKO",
		lga_id: 650,
	},
	{
		id: 548,
		name: "OSHODI",
		lga_id: 509,
	},
	{
		id: 549,
		name: "MAKARFI",
		lga_id: 406,
	},
	{
		id: 550,
		name: "NKPOLO",
		lga_id: 221,
	},
	{
		id: 551,
		name: "MAGODO",
		lga_id: 465,
	},
	{
		id: 552,
		name: "UGWO LAWO",
		lga_id: 731,
	},
	{
		id: 553,
		name: "MICHIKA",
		lga_id: 92,
	},
	{
		id: 554,
		name: "AGUNPOPO",
		lga_id: 282,
	},
	{
		id: 555,
		name: "IDAJATI",
		lga_id: 90,
	},
	{
		id: 556,
		name: "FEDERAL LOW COST, GOMBE",
		lga_id: 347,
	},
	{
		id: 557,
		name: "JAMDA",
		lga_id: 664,
	},
	{
		id: 558,
		name: "SOUTHERN UKANAFUN",
		lga_id: 269,
	},
	{
		id: 559,
		name: "OKEIGBO",
		lga_id: 602,
	},
	{
		id: 560,
		name: "NORTH OBAN LIKU",
		lga_id: 385,
	},
	{
		id: 561,
		name: "ALE",
		lga_id: 595,
	},
	{
		id: 562,
		name: "ALABATA VILLAGE",
		lga_id: 719,
	},
	{
		id: 563,
		name: "OBALENDE ETI-OSA",
		lga_id: 410,
	},
	{
		id: 564,
		name: "IBIONO",
		lga_id: 426,
	},
	{
		id: 565,
		name: "MAJALISA",
		lga_id: 60,
	},
	{
		id: 566,
		name: "IKEJI ARAKEJI",
		lga_id: 57,
	},
	{
		id: 567,
		name: "KOGORO ROAD",
		lga_id: 754,
	},
	{
		id: 568,
		name: "IJAIYE ALIMOSO",
		lga_id: 577,
	},
	{
		id: 569,
		name: "IKOV",
		lga_id: 79,
	},
	{
		id: 570,
		name: "ADEOLA ODEKU",
		lga_id: 410,
	},
	{
		id: 571,
		name: "AFAHA",
		lga_id: 369,
	},
	{
		id: 572,
		name: "TUNDUN GWANDARA",
		lga_id: 452,
	},
	{
		id: 573,
		name: "GWASORO",
		lga_id: 205,
	},
	{
		id: 574,
		name: "EZIONUM",
		lga_id: 610,
	},
	{
		id: 575,
		name: "OJODU BERGER, LAGOS",
		lga_id: 16,
	},
	{
		id: 576,
		name: "GEGU",
		lga_id: 531,
	},
	{
		id: 577,
		name: "AUCHI POLYTECHNIC",
		lga_id: 697,
	},
	{
		id: 578,
		name: "FUTO",
		lga_id: 189,
	},
	{
		id: 579,
		name: "MBAGEN",
		lga_id: 459,
	},
	{
		id: 580,
		name: "OTOLO",
		lga_id: 327,
	},
	{
		id: 581,
		name: "AJIDO",
		lga_id: 595,
	},
	{
		id: 582,
		name: "ISU AWA",
		lga_id: 547,
	},
	{
		id: 583,
		name: "TUDUN KAWAI",
		lga_id: 452,
	},
	{
		id: 584,
		name: "EKO DASHIN WORO",
		lga_id: 374,
	},
	{
		id: 585,
		name: "UBOMIRI TOWN",
		lga_id: 37,
	},
	{
		id: 586,
		name: "SHYLLON",
		lga_id: 115,
	},
	{
		id: 587,
		name: "YELWA, YOLA",
		lga_id: 553,
	},
	{
		id: 588,
		name: "ARIMOGIJA",
		lga_id: 413,
	},
	{
		id: 589,
		name: "OSUN STEEL ROLLING",
		lga_id: 219,
	},
	{
		id: 590,
		name: "EKE",
		lga_id: 760,
	},
	{
		id: 591,
		name: "OVWOR OLOMU",
		lga_id: 210,
	},
	{
		id: 592,
		name: "IJORA",
		lga_id: 526,
	},
	{
		id: 593,
		name: "ANANAMMONG",
		lga_id: 685,
	},
	{
		id: 594,
		name: "MBOSI",
		lga_id: 207,
	},
	{
		id: 595,
		name: "IMOGA",
		lga_id: 479,
	},
	{
		id: 596,
		name: "GAYA",
		lga_id: 95,
	},
	{
		id: 597,
		name: "BALLE",
		lga_id: 233,
	},
	{
		id: 598,
		name: "ONICHA, ONICHA",
		lga_id: 76,
	},
	{
		id: 599,
		name: "SOUTHERN ETUNG OLULUM",
		lga_id: 304,
	},
	{
		id: 600,
		name: "AGURA",
		lga_id: 18,
	},
	{
		id: 601,
		name: "LEKKI PHASE 1",
		lga_id: 410,
	},
	{
		id: 602,
		name: "DALLET BARRACKS",
		lga_id: 284,
	},
	{
		id: 603,
		name: "ABBATOIR, KATSINA",
		lga_id: 399,
	},
	{
		id: 604,
		name: "ALIFEKEDE NE",
		lga_id: 441,
	},
	{
		id: 605,
		name: "UMUEZIKE OFEME",
		lga_id: 598,
	},
	{
		id: 606,
		name: "ONYADEGA",
		lga_id: 446,
	},
	{
		id: 607,
		name: "SHARE",
		lga_id: 309,
	},
	{
		id: 608,
		name: "OTUTU",
		lga_id: 87,
	},
	{
		id: 609,
		name: "NAGUIN",
		lga_id: 19,
	},
	{
		id: 610,
		name: "JANGARU",
		lga_id: 19,
	},
	{
		id: 611,
		name: "IDI OSE",
		lga_id: 524,
	},
	{
		id: 612,
		name: "EMMANUEL ALAYANDE COLLEGE OF EDUCATION",
		lga_id: 282,
	},
	{
		id: 613,
		name: "IFEWARA",
		lga_id: 386,
	},
	{
		id: 614,
		name: "OLO KORO",
		lga_id: 26,
	},
	{
		id: 615,
		name: "AUYO",
		lga_id: 20,
	},
	{
		id: 616,
		name: "MARYLAND",
		lga_id: 584,
	},
	{
		id: 617,
		name: "ITU MBAISE",
		lga_id: 134,
	},
	{
		id: 618,
		name: "OGBAERURU ORLU",
		lga_id: 542,
	},
	{
		id: 619,
		name: "SABO, LOKOJA",
		lga_id: 187,
	},
	{
		id: 620,
		name: "ANIFOWOSHE",
		lga_id: 584,
	},
	{
		id: 621,
		name: "IGIEDUMA",
		lga_id: 279,
	},
	{
		id: 622,
		name: "GABARIN",
		lga_id: 586,
	},
	{
		id: 623,
		name: "NGOR",
		lga_id: 676,
	},
	{
		id: 624,
		name: "BILLE, DEGEMA",
		lga_id: 603,
	},
	{
		id: 625,
		name: "OBILIBI OBAZU",
		lga_id: 37,
	},
	{
		id: 626,
		name: "FAGOJI",
		lga_id: 464,
	},
	{
		id: 627,
		name: "UYANGHA",
		lga_id: 177,
	},
	{
		id: 628,
		name: "BAUCHI AIRPORT",
		lga_id: 653,
	},
	{
		id: 629,
		name: "OGO",
		lga_id: 430,
	},
	{
		id: 630,
		name: "TAPA, IBARAPA NORTH",
		lga_id: 733,
	},
	{
		id: 631,
		name: "KADOBE",
		lga_id: 138,
	},
	{
		id: 632,
		name: "DAGAUDA",
		lga_id: 326,
	},
	{
		id: 633,
		name: "EJEME ANLOGOR",
		lga_id: 744,
	},
	{
		id: 634,
		name: "ABRAHAM ADESANYA",
		lga_id: 410,
	},
	{
		id: 635,
		name: "THE FEDERAL POLYTECHNIC BAUCHI",
		lga_id: 653,
	},
	{
		id: 636,
		name: "GUNI",
		lga_id: 175,
	},
	{
		id: 637,
		name: "OKE ARO QUARTERS",
		lga_id: 748,
	},
	{
		id: 638,
		name: "ANGUWAN KWARARAFA",
		lga_id: 140,
	},
	{
		id: 639,
		name: "ITEM",
		lga_id: 344,
	},
	{
		id: 640,
		name: "YAN ZARRA WARD",
		lga_id: 425,
	},
	{
		id: 641,
		name: "ALAKIA",
		lga_id: 607,
	},
	{
		id: 642,
		name: "ODORO IKOT",
		lga_id: 263,
	},
	{
		id: 643,
		name: "OCHEKWU",
		lga_id: 154,
	},
	{
		id: 644,
		name: "NSIT UBIUM",
		lga_id: 635,
	},
	{
		id: 645,
		name: "OKPATU",
		lga_id: 338,
	},
	{
		id: 646,
		name: "CHINADE",
		lga_id: 39,
	},
	{
		id: 647,
		name: "MATOGUN",
		lga_id: 549,
	},
	{
		id: 648,
		name: "POLICE COLLEGE, BAUCHI",
		lga_id: 653,
	},
	{
		id: 649,
		name: "FUKA",
		lga_id: 175,
	},
	{
		id: 650,
		name: "OYELEHIN ESTATE",
		lga_id: 394,
	},
	{
		id: 651,
		name: "OKELUSE",
		lga_id: 413,
	},
	{
		id: 652,
		name: "ORHUWHORUN",
		lga_id: 331,
	},
	{
		id: 653,
		name: "RAJI RASAKI",
		lga_id: 694,
	},
	{
		id: 654,
		name: "UMUEBE",
		lga_id: 503,
	},
	{
		id: 655,
		name: "AJANGBADI",
		lga_id: 228,
	},
	{
		id: 656,
		name: "OKO-OBA, OYO",
		lga_id: 670,
	},
	{
		id: 657,
		name: "OLOWOIRA",
		lga_id: 465,
	},
	{
		id: 658,
		name: "NEW GRA GOMBE",
		lga_id: 347,
	},
	{
		id: 659,
		name: "ICHAMA",
		lga_id: 475,
	},
	{
		id: 660,
		name: "ANKPA, MAKURDI",
		lga_id: 357,
	},
	{
		id: 661,
		name: "GARKI AREA 1",
		lga_id: 476,
	},
	{
		id: 662,
		name: "KWANO",
		lga_id: 50,
	},
	{
		id: 663,
		name: "DANKO",
		lga_id: 409,
	},
	{
		id: 664,
		name: "NSUDE",
		lga_id: 760,
	},
	{
		id: 665,
		name: "JAJI",
		lga_id: 130,
	},
	{
		id: 666,
		name: "OSOGUN",
		lga_id: 748,
	},
	{
		id: 667,
		name: "OHORDUA",
		lga_id: 55,
	},
	{
		id: 668,
		name: "ABOGUNDE VILLAGE",
		lga_id: 529,
	},
	{
		id: 669,
		name: "OLORISA OKO",
		lga_id: 524,
	},
	{
		id: 670,
		name: "MASHEGU",
		lga_id: 50,
	},
	{
		id: 671,
		name: "RANO",
		lga_id: 507,
	},
	{
		id: 672,
		name: "DOGURU",
		lga_id: 74,
	},
	{
		id: 673,
		name: "LAFIAGI, MOKWA",
		lga_id: 400,
	},
	{
		id: 674,
		name: "DALIJANI",
		lga_id: 563,
	},
	{
		id: 675,
		name: "AKOKA",
		lga_id: 404,
	},
	{
		id: 676,
		name: "ANGWAN TASHI",
		lga_id: 649,
	},
	{
		id: 677,
		name: "IMANI",
		lga_id: 702,
	},
	{
		id: 678,
		name: "BAJULAIYE",
		lga_id: 404,
	},
	{
		id: 679,
		name: "IJESHA-TEDO",
		lga_id: 641,
	},
	{
		id: 680,
		name: "OTUONCHA",
		lga_id: 499,
	},
	{
		id: 681,
		name: "IZOMBE",
		lga_id: 288,
	},
	{
		id: 682,
		name: "AFOBAJE ESTATE",
		lga_id: 629,
	},
	{
		id: 683,
		name: "NASARAWA GRA",
		lga_id: 335,
	},
	{
		id: 684,
		name: "NRU",
		lga_id: 557,
	},
	{
		id: 685,
		name: "OHABIAM LAYOUT",
		lga_id: 100,
	},
	{
		id: 686,
		name: "EZIR",
		lga_id: 320,
	},
	{
		id: 687,
		name: "TOPO",
		lga_id: 10,
	},
	{
		id: 688,
		name: "AGO ISAGA, ADO ODO OTA",
		lga_id: 629,
	},
	{
		id: 689,
		name: "RUMUOKWUTA",
		lga_id: 78,
	},
	{
		id: 690,
		name: "MOJODA",
		lga_id: 15,
	},
	{
		id: 691,
		name: "UGHELLI",
		lga_id: 411,
	},
	{
		id: 692,
		name: "OREMEJI IBADAN NORTH",
		lga_id: 183,
	},
	{
		id: 693,
		name: "IJEDE",
		lga_id: 212,
	},
	{
		id: 694,
		name: "ASORO QUARTERS",
		lga_id: 470,
	},
	{
		id: 695,
		name: "NDONI",
		lga_id: 535,
	},
	{
		id: 696,
		name: "GLOVER RD IKOYI",
		lga_id: 17,
	},
	{
		id: 697,
		name: "ERIN ILE",
		lga_id: 99,
	},
	{
		id: 698,
		name: "MORUKO",
		lga_id: 599,
	},
	{
		id: 699,
		name: "KAREWA GRA",
		lga_id: 362,
	},
	{
		id: 700,
		name: "NNPC DEPOT, KADUNA",
		lga_id: 480,
	},
	{
		id: 701,
		name: "ELEKAHIA",
		lga_id: 594,
	},
	{
		id: 702,
		name: "IRAGON",
		lga_id: 595,
	},
	{
		id: 703,
		name: "NGBILEBIRIMEIN/OGBULUBIRIMEIN",
		lga_id: 743,
	},
	{
		id: 704,
		name: "AKUME. ORSU",
		lga_id: 298,
	},
	{
		id: 705,
		name: "OBA ILE, AKURE SOUTH",
		lga_id: 199,
	},
	{
		id: 706,
		name: "COLLEGE OF EDUCATION IKERE",
		lga_id: 681,
	},
	{
		id: 707,
		name: "BESSE",
		lga_id: 656,
	},
	{
		id: 708,
		name: "DAWAKI, KATAGUM",
		lga_id: 39,
	},
	{
		id: 709,
		name: "KABALA WEST, KADUNA SOUTH",
		lga_id: 757,
	},
	{
		id: 710,
		name: "KAGARA",
		lga_id: 80,
	},
	{
		id: 711,
		name: "IBAGBA",
		lga_id: 285,
	},
	{
		id: 712,
		name: "IMINI",
		lga_id: 735,
	},
	{
		id: 713,
		name: "DIOBU MILE 1",
		lga_id: 594,
	},
	{
		id: 714,
		name: "ALAGYE",
		lga_id: 180,
	},
	{
		id: 715,
		name: "AGBAJAH ISU",
		lga_id: 120,
	},
	{
		id: 716,
		name: "EJIRAN",
		lga_id: 541,
	},
	{
		id: 717,
		name: "ORILE AGEGE",
		lga_id: 240,
	},
	{
		id: 718,
		name: "FAHA OKU AKPAN",
		lga_id: 162,
	},
	{
		id: 719,
		name: "ODO ADO",
		lga_id: 573,
	},
	{
		id: 720,
		name: "UMUOSHIMILI OSSISSA",
		lga_id: 591,
	},
	{
		id: 721,
		name: "OSOSUN",
		lga_id: 212,
	},
	{
		id: 722,
		name: "BIU CENTRAL",
		lga_id: 169,
	},
	{
		id: 723,
		name: "DANDAWA",
		lga_id: 175,
	},
	{
		id: 724,
		name: "NEW GRA LAYOUT BAUCHI",
		lga_id: 653,
	},
	{
		id: 725,
		name: "NEKEDE POLY",
		lga_id: 189,
	},
	{
		id: 726,
		name: "YAR AZARA KANO",
		lga_id: 182,
	},
	{
		id: 727,
		name: "JERE, JERE",
		lga_id: 671,
	},
	{
		id: 728,
		name: "OKE AKO",
		lga_id: 315,
	},
	{
		id: 729,
		name: "GDSS SHAGARI QTRS, YOLA SOUTH",
		lga_id: 362,
	},
	{
		id: 730,
		name: "MOWO IKORODU",
		lga_id: 212,
	},
	{
		id: 731,
		name: "BALOGUN",
		lga_id: 766,
	},
	{
		id: 732,
		name: "TARABA STATE UNIVERSITY",
		lga_id: 222,
	},
	{
		id: 733,
		name: "NEW GARAGE",
		lga_id: 126,
	},
	{
		id: 734,
		name: "SOKU",
		lga_id: 467,
	},
	{
		id: 735,
		name: "OBA ILE, OLORUNDA",
		lga_id: 394,
	},
	{
		id: 736,
		name: "EZINIHITTE",
		lga_id: 134,
	},
	{
		id: 737,
		name: "ETEGBIN",
		lga_id: 228,
	},
	{
		id: 738,
		name: "AGULU",
		lga_id: 387,
	},
	{
		id: 739,
		name: "AMAZU",
		lga_id: 332,
	},
	{
		id: 740,
		name: "URUDU",
		lga_id: 50,
	},
	{
		id: 741,
		name: "NYIMAN LAYOUT",
		lga_id: 357,
	},
	{
		id: 742,
		name: "AGULUCHUK",
		lga_id: 747,
	},
	{
		id: 743,
		name: "BAGWAI",
		lga_id: 484,
	},
	{
		id: 744,
		name: "AWA CLAN",
		lga_id: 728,
	},
	{
		id: 745,
		name: "SHIRA",
		lga_id: 460,
	},
	{
		id: 746,
		name: "SAGAMU",
		lga_id: 378,
	},
	{
		id: 747,
		name: "EGBOMU",
		lga_id: 652,
	},
	{
		id: 748,
		name: "IPOGUN",
		lga_id: 239,
	},
	{
		id: 749,
		name: "AMANGWU OHAFIA",
		lga_id: 522,
	},
	{
		id: 750,
		name: "OGU, OGUTA",
		lga_id: 288,
	},
	{
		id: 751,
		name: "AGUBIA IKWO",
		lga_id: 627,
	},
	{
		id: 752,
		name: "CENTRAL MARKET, LAFIA",
		lga_id: 452,
	},
	{
		id: 753,
		name: "ANABA",
		lga_id: 686,
	},
	{
		id: 754,
		name: "BAJOGA",
		lga_id: 716,
	},
	{
		id: 755,
		name: "NAMU",
		lga_id: 513,
	},
	{
		id: 756,
		name: "ADA OBOKUN",
		lga_id: 203,
	},
	{
		id: 757,
		name: "EGBIN",
		lga_id: 18,
	},
	{
		id: 758,
		name: "CATHOLIC MISSION ROAD",
		lga_id: 754,
	},
	{
		id: 759,
		name: "GAYAM",
		lga_id: 752,
	},
	{
		id: 760,
		name: "ESA OKE",
		lga_id: 203,
	},
	{
		id: 761,
		name: "NKOMORU",
		lga_id: 332,
	},
	{
		id: 762,
		name: "GAMBORU",
		lga_id: 693,
	},
	{
		id: 763,
		name: "KANGIWA",
		lga_id: 666,
	},
	{
		id: 764,
		name: "AGUOBU UMUMBA",
		lga_id: 613,
	},
	{
		id: 765,
		name: "KWARE",
		lga_id: 364,
	},
	{
		id: 766,
		name: "SADE",
		lga_id: 586,
	},
	{
		id: 767,
		name: "UNGUWAN PAMA",
		lga_id: 480,
	},
	{
		id: 768,
		name: "EJULE",
		lga_id: 731,
	},
	{
		id: 769,
		name: "TSANNI",
		lga_id: 560,
	},
	{
		id: 770,
		name: "IYE EKITI",
		lga_id: 623,
	},
	{
		id: 771,
		name: "GULAK",
		lga_id: 518,
	},
	{
		id: 772,
		name: "BENKALIO ESTATE",
		lga_id: 259,
	},
	{
		id: 773,
		name: "DOGON BAUCHI",
		lga_id: 579,
	},
	{
		id: 774,
		name: "BUJI",
		lga_id: 672,
	},
	{
		id: 775,
		name: "SCHOOL OF AGRICULTURE LAFIA",
		lga_id: 452,
	},
	{
		id: 776,
		name: "LIFE CAMP, ABUJA",
		lga_id: 476,
	},
	{
		id: 777,
		name: "OWHELOGBO",
		lga_id: 691,
	},
	{
		id: 778,
		name: "OBANTOKO",
		lga_id: 356,
	},
	{
		id: 779,
		name: "IDEMBIA",
		lga_id: 160,
	},
	{
		id: 780,
		name: "RANTIYA",
		lga_id: 683,
	},
	{
		id: 781,
		name: "KPIRI PIRI",
		lga_id: 520,
	},
	{
		id: 782,
		name: "UMUCHIMA",
		lga_id: 519,
	},
	{
		id: 783,
		name: "OGUDU GRA, KOSOFE",
		lga_id: 465,
	},
	{
		id: 784,
		name: "OZUBULU NNEWI NORTH",
		lga_id: 327,
	},
	{
		id: 785,
		name: "ORJI RIVER",
		lga_id: 209,
	},
	{
		id: 786,
		name: "UMUKWATA",
		lga_id: 503,
	},
	{
		id: 787,
		name: "MBUTU UMUAGAA",
		lga_id: 765,
	},
	{
		id: 788,
		name: "MICHEAL OKPARA UNIVERSITY",
		lga_id: 650,
	},
	{
		id: 789,
		name: "KWAMI",
		lga_id: 569,
	},
	{
		id: 790,
		name: "RIJIYAN DORAWA",
		lga_id: 81,
	},
	{
		id: 791,
		name: "OMUMA",
		lga_id: 77,
	},
	{
		id: 792,
		name: "IPERU",
		lga_id: 151,
	},
	{
		id: 793,
		name: "AGEGE",
		lga_id: 240,
	},
	{
		id: 794,
		name: "MAKERA, WURNO",
		lga_id: 700,
	},
	{
		id: 795,
		name: "SHEN JOS",
		lga_id: 683,
	},
	{
		id: 796,
		name: "TORO",
		lga_id: 489,
	},
	{
		id: 797,
		name: "MOBALUFON",
		lga_id: 159,
	},
	{
		id: 798,
		name: "BIRNIN GWARI",
		lga_id: 752,
	},
	{
		id: 799,
		name: "UTAGBE UNO",
		lga_id: 610,
	},
	{
		id: 800,
		name: "ABU TEACHING HOSPITAL",
		lga_id: 616,
	},
	{
		id: 801,
		name: "UMUOZUO, ABA SOUTH",
		lga_id: 100,
	},
	{
		id: 802,
		name: "AFAHA CLAN EKET",
		lga_id: 51,
	},
	{
		id: 803,
		name: "JERDEDI",
		lga_id: 59,
	},
	{
		id: 804,
		name: "KUMBOEI",
		lga_id: 345,
	},
	{
		id: 805,
		name: "OMI ADIO",
		lga_id: 85,
	},
	{
		id: 806,
		name: "NAIBAWA",
		lga_id: 425,
	},
	{
		id: 807,
		name: "GARKI AREA 3",
		lga_id: 476,
	},
	{
		id: 808,
		name: "ODE OMU",
		lga_id: 38,
	},
	{
		id: 809,
		name: "AFOR OROMA ETITI",
		lga_id: 538,
	},
	{
		id: 810,
		name: "OREG BENI",
		lga_id: 114,
	},
	{
		id: 811,
		name: "IGBEAGU",
		lga_id: 220,
	},
	{
		id: 812,
		name: "AMAKWARA",
		lga_id: 542,
	},
	{
		id: 813,
		name: "ORITA APERIN",
		lga_id: 246,
	},
	{
		id: 814,
		name: "KURMI ASHI",
		lga_id: 284,
	},
	{
		id: 815,
		name: "NDIBINOHA",
		lga_id: 598,
	},
	{
		id: 816,
		name: "DANGOMA",
		lga_id: 514,
	},
	{
		id: 817,
		name: "UNIVERSITY OF PORT-HARCOURT",
		lga_id: 78,
	},
	{
		id: 818,
		name: "AKKO",
		lga_id: 103,
	},
	{
		id: 819,
		name: "GYAWANA",
		lga_id: 405,
	},
	{
		id: 820,
		name: "LIKORO",
		lga_id: 462,
	},
	{
		id: 821,
		name: "AMUZI OBOWU",
		lga_id: 688,
	},
	{
		id: 822,
		name: "GWALAM",
		lga_id: 70,
	},
	{
		id: 823,
		name: "IHE",
		lga_id: 547,
	},
	{
		id: 824,
		name: "UFUMA",
		lga_id: 257,
	},
	{
		id: 825,
		name: "OLOTEN",
		lga_id: 371,
	},
	{
		id: 826,
		name: "FARASIME",
		lga_id: 595,
	},
	{
		id: 827,
		name: "UBIUM SOUTH",
		lga_id: 635,
	},
	{
		id: 828,
		name: "NYANYAN",
		lga_id: 317,
	},
	{
		id: 829,
		name: "ARAROMI-OBU",
		lga_id: 25,
	},
	{
		id: 830,
		name: "ABAOMEGE",
		lga_id: 76,
	},
	{
		id: 831,
		name: "LIGHT HOUSE ESTATE",
		lga_id: 17,
	},
	{
		id: 832,
		name: "ENDEHU",
		lga_id: 67,
	},
	{
		id: 833,
		name: "AKUBZAN KWORO",
		lga_id: 452,
	},
	{
		id: 834,
		name: "AIYETORO JARAN AREA",
		lga_id: 383,
	},
	{
		id: 835,
		name: "DUTSE, FCT",
		lga_id: 82,
	},
	{
		id: 836,
		name: "AGODI",
		lga_id: 183,
	},
	{
		id: 837,
		name: "TUDUN WADA, KADUNA SOUTH",
		lga_id: 757,
	},
	{
		id: 838,
		name: "CENTRAL MARKET, KEBBI",
		lga_id: 706,
	},
	{
		id: 839,
		name: "PAIKO",
		lga_id: 750,
	},
	{
		id: 840,
		name: "OROKAM",
		lga_id: 564,
	},
	{
		id: 841,
		name: "GUPO",
		lga_id: 307,
	},
	{
		id: 842,
		name: "ASOKORO, MUNICIPAL AREA COUNCIL",
		lga_id: 476,
	},
	{
		id: 843,
		name: "IKENGA",
		lga_id: 747,
	},
	{
		id: 844,
		name: "OGWASHI UKU",
		lga_id: 744,
	},
	{
		id: 845,
		name: "MGBIDI, ENUGU",
		lga_id: 547,
	},
	{
		id: 846,
		name: "MALALI GRA",
		lga_id: 284,
	},
	{
		id: 847,
		name: "PAKATA EAST",
		lga_id: 129,
	},
	{
		id: 848,
		name: "BUKAN SIDI",
		lga_id: 452,
	},
	{
		id: 849,
		name: "GOVERNMENT QUARTERS, KATSINA",
		lga_id: 399,
	},
	{
		id: 850,
		name: "AMAITOLI MBUTU",
		lga_id: 765,
	},
	{
		id: 851,
		name: "EJIGBO",
		lga_id: 545,
	},
	{
		id: 852,
		name: "DAMBATTA",
		lga_id: 281,
	},
	{
		id: 853,
		name: "UWANI ENUGU NORTH",
		lga_id: 197,
	},
	{
		id: 854,
		name: "GAKEM",
		lga_id: 440,
	},
	{
		id: 855,
		name: "EDIBA, ABI",
		lga_id: 94,
	},
	{
		id: 856,
		name: "ARAROMI KABBA",
		lga_id: 178,
	},
	{
		id: 857,
		name: "IGBARIAM",
		lga_id: 499,
	},
	{
		id: 858,
		name: "AGBOKETE",
		lga_id: 266,
	},
	{
		id: 859,
		name: "GADIP VILLAGE",
		lga_id: 187,
	},
	{
		id: 860,
		name: "IWOYE, EGBADO SOUTH",
		lga_id: 680,
	},
	{
		id: 861,
		name: "BARA",
		lga_id: 69,
	},
	{
		id: 862,
		name: "OKWE, DELTA",
		lga_id: 574,
	},
	{
		id: 863,
		name: "BAASI COMPOUND",
		lga_id: 132,
	},
	{
		id: 864,
		name: "KUBAU",
		lga_id: 204,
	},
	{
		id: 865,
		name: "ILOKUN",
		lga_id: 573,
	},
	{
		id: 866,
		name: "IDOLOFIN",
		lga_id: 573,
	},
	{
		id: 867,
		name: "WARARI",
		lga_id: 235,
	},
	{
		id: 868,
		name: "YEMETU",
		lga_id: 648,
	},
	{
		id: 869,
		name: "OSAA UKWU",
		lga_id: 463,
	},
	{
		id: 870,
		name: "MADOBI, MADOBI",
		lga_id: 433,
	},
	{
		id: 871,
		name: "GBELEKALE",
		lga_id: 648,
	},
	{
		id: 872,
		name: "UNGUWAR KOFA",
		lga_id: 560,
	},
	{
		id: 873,
		name: "GWARINPA HOSUING ESTATE",
		lga_id: 476,
	},
	{
		id: 874,
		name: "BENUE BREWERIES",
		lga_id: 357,
	},
	{
		id: 875,
		name: "MMA 2",
		lga_id: 16,
	},
	{
		id: 876,
		name: "EKUTH",
		lga_id: 573,
	},
	{
		id: 877,
		name: "SHADAREKI",
		lga_id: 586,
	},
	{
		id: 878,
		name: "AMOKWE",
		lga_id: 760,
	},
	{
		id: 879,
		name: "ILUGUN",
		lga_id: 356,
	},
	{
		id: 880,
		name: "TOTAL GARDEN",
		lga_id: 183,
	},
	{
		id: 881,
		name: "OHOVBEOKAO",
		lga_id: 114,
	},
	{
		id: 882,
		name: "FEDERAL SECRETARIAT DAMATURU",
		lga_id: 259,
	},
	{
		id: 883,
		name: "UBULENU",
		lga_id: 669,
	},
	{
		id: 884,
		name: "OGBIA",
		lga_id: 256,
	},
	{
		id: 885,
		name: "MAIGOGE",
		lga_id: 361,
	},
	{
		id: 886,
		name: "ICHIDA",
		lga_id: 387,
	},
	{
		id: 887,
		name: "ILASAMAJA",
		lga_id: 509,
	},
	{
		id: 888,
		name: "KOSO",
		lga_id: 282,
	},
	{
		id: 889,
		name: "UDUBO",
		lga_id: 759,
	},
	{
		id: 890,
		name: "SATELLITE TOWN",
		lga_id: 694,
	},
	{
		id: 891,
		name: "GADA GARAKU",
		lga_id: 265,
	},
	{
		id: 892,
		name: "MGBUOBA",
		lga_id: 78,
	},
	{
		id: 893,
		name: "EKAN MEJE",
		lga_id: 555,
	},
	{
		id: 894,
		name: "AMARAKU",
		lga_id: 27,
	},
	{
		id: 895,
		name: "ODE EKITI",
		lga_id: 323,
	},
	{
		id: 896,
		name: "ERINMO IJESHA",
		lga_id: 57,
	},
	{
		id: 897,
		name: "IGBANKE",
		lga_id: 371,
	},
	{
		id: 898,
		name: "UKALA",
		lga_id: 626,
	},
	{
		id: 899,
		name: "OLLA",
		lga_id: 312,
	},
	{
		id: 900,
		name: "UNIVERSITY OF BENIN",
		lga_id: 23,
	},
	{
		id: 901,
		name: "BASIN AREA, ILORIN SOUTH",
		lga_id: 316,
	},
	{
		id: 902,
		name: "MAKWALLA",
		lga_id: 20,
	},
	{
		id: 903,
		name: "EKE OHA",
		lga_id: 134,
	},
	{
		id: 904,
		name: "MALOKO ASIPA",
		lga_id: 690,
	},
	{
		id: 905,
		name: "FAUNBI FAGUN",
		lga_id: 285,
	},
	{
		id: 906,
		name: "LAGUN",
		lga_id: 372,
	},
	{
		id: 907,
		name: "IPAKODO",
		lga_id: 212,
	},
	{
		id: 908,
		name: "OBALENDE ETI-OSA",
		lga_id: 17,
	},
	{
		id: 909,
		name: "TAMBAWAL",
		lga_id: 651,
	},
	{
		id: 910,
		name: "DUJA",
		lga_id: 403,
	},
	{
		id: 911,
		name: "IBESIT",
		lga_id: 511,
	},
	{
		id: 912,
		name: "SAKWA, HAWUL",
		lga_id: 562,
	},
	{
		id: 913,
		name: "IPOKIA",
		lga_id: 141,
	},
	{
		id: 914,
		name: "BARIGA",
		lga_id: 404,
	},
	{
		id: 915,
		name: "BAKIN KOGI, LAFIA",
		lga_id: 452,
	},
	{
		id: 916,
		name: "EASTERN BOKI",
		lga_id: 190,
	},
	{
		id: 917,
		name: "SSS TRAINING SCHOOL, KADUNA",
		lga_id: 130,
	},
	{
		id: 918,
		name: "SOKOTO SECRETARIAT",
		lga_id: 450,
	},
	{
		id: 919,
		name: "SOYOYE",
		lga_id: 719,
	},
	{
		id: 920,
		name: "THE BELLS UNIVERSITY",
		lga_id: 629,
	},
	{
		id: 921,
		name: "OKWU",
		lga_id: 741,
	},
	{
		id: 922,
		name: "ETCHE, ETCHE",
		lga_id: 348,
	},
	{
		id: 923,
		name: "JOLLY NYAME STADIUM",
		lga_id: 379,
	},
	{
		id: 924,
		name: "ITO",
		lga_id: 396,
	},
	{
		id: 925,
		name: "SANYINWA",
		lga_id: 651,
	},
	{
		id: 926,
		name: "IGBATIM",
		lga_id: 186,
	},
	{
		id: 927,
		name: "ISIAMAIGBO",
		lga_id: 257,
	},
	{
		id: 928,
		name: "MILITARY BARRACKS ONITSHA NORTH",
		lga_id: 500,
	},
	{
		id: 929,
		name: "OWO",
		lga_id: 593,
	},
	{
		id: 930,
		name: "AJEBAMIDELE",
		lga_id: 713,
	},
	{
		id: 931,
		name: "COSTAIN, APAPA",
		lga_id: 13,
	},
	{
		id: 932,
		name: "ODO-ORI, IWO",
		lga_id: 155,
	},
	{
		id: 933,
		name: "AWOYOKUN",
		lga_id: 115,
	},
	{
		id: 934,
		name: "UNIVERSITY OF ABUJA",
		lga_id: 476,
	},
	{
		id: 935,
		name: "SHIPO",
		lga_id: 307,
	},
	{
		id: 936,
		name: "KWALI",
		lga_id: 643,
	},
	{
		id: 937,
		name: "AUGIE",
		lga_id: 46,
	},
	{
		id: 938,
		name: "AKUTE IFO",
		lga_id: 549,
	},
	{
		id: 939,
		name: "EGBE, ALIMOSHO",
		lga_id: 577,
	},
	{
		id: 940,
		name: "OKE AFIN",
		lga_id: 282,
	},
	{
		id: 941,
		name: "NNPC HOUSING COMPLEX",
		lga_id: 417,
	},
	{
		id: 942,
		name: "IPOTI EKITI",
		lga_id: 678,
	},
	{
		id: 943,
		name: "LOKUMBASA",
		lga_id: 175,
	},
	{
		id: 944,
		name: "AGLICAN BISHOP COURT, ONDO",
		lga_id: 285,
	},
	{
		id: 945,
		name: "AZIKORO",
		lga_id: 167,
	},
	{
		id: 946,
		name: "UMUAGU, OSHIMILI SOUTH",
		lga_id: 574,
	},
	{
		id: 947,
		name: "THREE ARMS ZONE",
		lga_id: 476,
	},
	{
		id: 948,
		name: "AMUCHA",
		lga_id: 96,
	},
	{
		id: 949,
		name: "IJANA GENERAL HOSPITAL, ILARO",
		lga_id: 680,
	},
	{
		id: 950,
		name: "OYEMEKUN",
		lga_id: 199,
	},
	{
		id: 951,
		name: "KASHERE",
		lga_id: 103,
	},
	{
		id: 952,
		name: "VICTORIA GARDEN CITY",
		lga_id: 17,
	},
	{
		id: 953,
		name: "NJIRIV",
		lga_id: 495,
	},
	{
		id: 954,
		name: "GRA BAUCHI",
		lga_id: 653,
	},
	{
		id: 955,
		name: "MKPAT ENIN",
		lga_id: 618,
	},
	{
		id: 956,
		name: "OKE AFA",
		lga_id: 509,
	},
	{
		id: 957,
		name: "MAIGATARI",
		lga_id: 447,
	},
	{
		id: 958,
		name: "CHINCO",
		lga_id: 60,
	},
	{
		id: 959,
		name: "OSSISSA UMUDIKE",
		lga_id: 591,
	},
	{
		id: 960,
		name: "ITOKIN EPE",
		lga_id: 541,
	},
	{
		id: 961,
		name: "OKESA",
		lga_id: 470,
	},
	{
		id: 962,
		name: "GBOKO MAIN MARKET",
		lga_id: 351,
	},
	{
		id: 963,
		name: "AMAFOR",
		lga_id: 676,
	},
	{
		id: 964,
		name: "NKWORJI",
		lga_id: 192,
	},
	{
		id: 965,
		name: "NDI UDUMA",
		lga_id: 522,
	},
	{
		id: 966,
		name: "KABOJI",
		lga_id: 50,
	},
	{
		id: 967,
		name: "KWANDE",
		lga_id: 513,
	},
	{
		id: 968,
		name: "MBAYION",
		lga_id: 351,
	},
	{
		id: 969,
		name: "IREPODUN, OGUN",
		lga_id: 151,
	},
	{
		id: 970,
		name: "ADEOYO HOSPITAL",
		lga_id: 126,
	},
	{
		id: 971,
		name: "LADIPO",
		lga_id: 115,
	},
	{
		id: 972,
		name: "IJARE",
		lga_id: 239,
	},
	{
		id: 973,
		name: "ISSIEKE",
		lga_id: 207,
	},
	{
		id: 974,
		name: "IBIGHI",
		lga_id: 369,
	},
	{
		id: 975,
		name: "USE",
		lga_id: 23,
	},
	{
		id: 976,
		name: "IFAKO-GBAGADA",
		lga_id: 465,
	},
	{
		id: 977,
		name: "IGEDE EKITI",
		lga_id: 43,
	},
	{
		id: 978,
		name: "ILLELA",
		lga_id: 35,
	},
	{
		id: 979,
		name: "MGBAKWU",
		lga_id: 730,
	},
	{
		id: 980,
		name: "ABOCHI",
		lga_id: 202,
	},
	{
		id: 981,
		name: "BONI HOUSING ESTATE",
		lga_id: 553,
	},
	{
		id: 982,
		name: "AMPER",
		lga_id: 238,
	},
	{
		id: 983,
		name: "UNGOGO",
		lga_id: 142,
	},
	{
		id: 984,
		name: "ILALA",
		lga_id: 164,
	},
	{
		id: 985,
		name: "WOJI OBIO-AKPOR",
		lga_id: 78,
	},
	{
		id: 986,
		name: "IDI-ORO, LAGOS",
		lga_id: 115,
	},
	{
		id: 987,
		name: "IJEBU ODE",
		lga_id: 619,
	},
	{
		id: 988,
		name: "JUWA",
		lga_id: 476,
	},
	{
		id: 989,
		name: "ILUTITUN",
		lga_id: 248,
	},
	{
		id: 990,
		name: "BODE SAADU",
		lga_id: 36,
	},
	{
		id: 991,
		name: "OBEHIE",
		lga_id: 658,
	},
	{
		id: 992,
		name: "MKO ABIOLA STADIUM",
		lga_id: 118,
	},
	{
		id: 993,
		name: "UMUOFEKE",
		lga_id: 542,
	},
	{
		id: 994,
		name: "TIN CAN ISLAND",
		lga_id: 526,
	},
	{
		id: 995,
		name: "DAWAKI, ABUJA",
		lga_id: 476,
	},
	{
		id: 996,
		name: "OBORO, BOMADI",
		lga_id: 614,
	},
	{
		id: 997,
		name: "AJARA TOPA",
		lga_id: 595,
	},
	{
		id: 998,
		name: "KWAYA KUSAR",
		lga_id: 104,
	},
	{
		id: 999,
		name: "ULI",
		lga_id: 207,
	},
	{
		id: 1000,
		name: "ORA, OVIA SOUTH-WEST",
		lga_id: 654,
	},
	{
		id: 1001,
		name: "AUJARA",
		lga_id: 271,
	},
	{
		id: 1002,
		name: "FIKA",
		lga_id: 102,
	},
	{
		id: 1003,
		name: "IBIAKU NTUK OKPO",
		lga_id: 373,
	},
	{
		id: 1004,
		name: "GIRNIYA",
		lga_id: 531,
	},
	{
		id: 1005,
		name: "SWALI",
		lga_id: 167,
	},
	{
		id: 1006,
		name: "JEBBA",
		lga_id: 36,
	},
	{
		id: 1007,
		name: "MALALI",
		lga_id: 130,
	},
	{
		id: 1008,
		name: "ENUGU STATE UNIVERSITY",
		lga_id: 330,
	},
	{
		id: 1009,
		name: "PANDOGARI",
		lga_id: 80,
	},
	{
		id: 1010,
		name: "NEW OGBE IJOH",
		lga_id: 417,
	},
	{
		id: 1011,
		name: "IYANA-IPAJA",
		lga_id: 240,
	},
	{
		id: 1012,
		name: "EKE EKWE MARKET",
		lga_id: 742,
	},
	{
		id: 1013,
		name: "TUDUN KADANYA",
		lga_id: 755,
	},
	{
		id: 1014,
		name: "ADAMU JUMBA",
		lga_id: 653,
	},
	{
		id: 1015,
		name: "NKWO ORODO",
		lga_id: 37,
	},
	{
		id: 1016,
		name: "NAYINNAWA",
		lga_id: 259,
	},
	{
		id: 1017,
		name: "AKUTE IFAKO IJAIYE",
		lga_id: 552,
	},
	{
		id: 1018,
		name: "KORO",
		lga_id: 260,
	},
	{
		id: 1019,
		name: "UMUEZE",
		lga_id: 63,
	},
	{
		id: 1020,
		name: "ASA DAM",
		lga_id: 108,
	},
	{
		id: 1021,
		name: "JIBIN",
		lga_id: 514,
	},
	{
		id: 1022,
		name: "OWENA",
		lga_id: 605,
	},
	{
		id: 1023,
		name: "IDI AYUNRE",
		lga_id: 430,
	},
	{
		id: 1024,
		name: "MANGORO, NIGER",
		lga_id: 175,
	},
	{
		id: 1025,
		name: "IYALEWA IJOUN",
		lga_id: 62,
	},
	{
		id: 1026,
		name: "RUMUEME",
		lga_id: 78,
	},
	{
		id: 1027,
		name: "RUBUKAWA",
		lga_id: 81,
	},
	{
		id: 1028,
		name: "DALIMORE",
		lga_id: 573,
	},
	{
		id: 1029,
		name: "IKOLA",
		lga_id: 577,
	},
	{
		id: 1030,
		name: "JEKA DA FARI",
		lga_id: 347,
	},
	{
		id: 1031,
		name: "TUDUN NUPAWA",
		lga_id: 757,
	},
	{
		id: 1032,
		name: "EZENEI",
		lga_id: 574,
	},
	{
		id: 1033,
		name: "OGBUNKA",
		lga_id: 320,
	},
	{
		id: 1034,
		name: "ILUPKA",
		lga_id: 178,
	},
	{
		id: 1035,
		name: "COCA-COLA ISEYIN",
		lga_id: 748,
	},
	{
		id: 1036,
		name: "HIGH LEVEL ASSOCIATION",
		lga_id: 357,
	},
	{
		id: 1037,
		name: "SHASHA, LAGOS",
		lga_id: 577,
	},
	{
		id: 1038,
		name: "EWU OLUWO",
		lga_id: 378,
	},
	{
		id: 1039,
		name: "AWAK",
		lga_id: 245,
	},
	{
		id: 1040,
		name: "KOFAR MARUSA LAYOUT",
		lga_id: 399,
	},
	{
		id: 1041,
		name: "GUNDUMI RD",
		lga_id: 699,
	},
	{
		id: 1042,
		name: "APAPA WHARF",
		lga_id: 13,
	},
	{
		id: 1043,
		name: "OSUPA",
		lga_id: 529,
	},
	{
		id: 1044,
		name: "UMUAHIA URBAN 1",
		lga_id: 598,
	},
	{
		id: 1045,
		name: "ISUOFIA",
		lga_id: 747,
	},
	{
		id: 1046,
		name: "AJALI",
		lga_id: 320,
	},
	{
		id: 1047,
		name: "OSBORNE",
		lga_id: 17,
	},
	{
		id: 1048,
		name: "CHALLENGE, OYO",
		lga_id: 126,
	},
	{
		id: 1049,
		name: "KWABI",
		lga_id: 489,
	},
	{
		id: 1050,
		name: "ONICHA UKWUANI",
		lga_id: 610,
	},
	{
		id: 1051,
		name: "ASARI ESO LAYOUT",
		lga_id: 559,
	},
	{
		id: 1052,
		name: "GBANCHITAGI",
		lga_id: 175,
	},
	{
		id: 1053,
		name: "FEDERAL POLY, MUBI",
		lga_id: 477,
	},
	{
		id: 1054,
		name: "MBASAAN",
		lga_id: 277,
	},
	{
		id: 1055,
		name: "AMANATO",
		lga_id: 542,
	},
	{
		id: 1056,
		name: "IFE, IMO",
		lga_id: 134,
	},
	{
		id: 1057,
		name: "ZONKWA",
		lga_id: 539,
	},
	{
		id: 1058,
		name: "IKOLABA",
		lga_id: 183,
	},
	{
		id: 1059,
		name: "UNYEADA",
		lga_id: 628,
	},
	{
		id: 1060,
		name: "EFFURUN ROUND ABOUT",
		lga_id: 532,
	},
	{
		id: 1061,
		name: "NIMBO",
		lga_id: 684,
	},
	{
		id: 1062,
		name: "DANKALGO",
		lga_id: 523,
	},
	{
		id: 1063,
		name: "NDOT ABAK",
		lga_id: 540,
	},
	{
		id: 1064,
		name: "UGWOLAWO OLA-OFU",
		lga_id: 731,
	},
	{
		id: 1065,
		name: "APONGBON-CMS",
		lga_id: 766,
	},
	{
		id: 1066,
		name: "AWODI-ORA",
		lga_id: 267,
	},
	{
		id: 1067,
		name: "UMUAGBAGHI",
		lga_id: 100,
	},
	{
		id: 1068,
		name: "KULENDE EAST",
		lga_id: 129,
	},
	{
		id: 1069,
		name: "GASHAKA",
		lga_id: 314,
	},
	{
		id: 1070,
		name: "CHANCHAGA",
		lga_id: 19,
	},
	{
		id: 1071,
		name: "ERINJIYAN",
		lga_id: 419,
	},
	{
		id: 1072,
		name: "KUKKA",
		lga_id: 643,
	},
	{
		id: 1073,
		name: "UMUONAJE",
		lga_id: 574,
	},
	{
		id: 1074,
		name: "AWULU",
		lga_id: 113,
	},
	{
		id: 1075,
		name: "UMUTU",
		lga_id: 503,
	},
	{
		id: 1076,
		name: "IJEDA IJESHA",
		lga_id: 57,
	},
	{
		id: 1077,
		name: "KOFAR WAMBAI",
		lga_id: 653,
	},
	{
		id: 1078,
		name: "OKPELLA",
		lga_id: 585,
	},
	{
		id: 1079,
		name: "ASERO ESTATE",
		lga_id: 118,
	},
	{
		id: 1080,
		name: "SABO OGBOMOSO",
		lga_id: 529,
	},
	{
		id: 1081,
		name: "OKE AYEDUN",
		lga_id: 315,
	},
	{
		id: 1082,
		name: "EWEKORO",
		lga_id: 572,
	},
	{
		id: 1083,
		name: "EGBE, YAGBA WEST",
		lga_id: 214,
	},
	{
		id: 1084,
		name: "WUSE 1 ZONE 1",
		lga_id: 476,
	},
	{
		id: 1085,
		name: "AMILEGBE",
		lga_id: 129,
	},
	{
		id: 1086,
		name: "ORANRE",
		lga_id: 206,
	},
	{
		id: 1087,
		name: "IDANASEKE",
		lga_id: 697,
	},
	{
		id: 1088,
		name: "NBEM OHAFIA",
		lga_id: 522,
	},
	{
		id: 1089,
		name: "BENUE AIRPORT",
		lga_id: 357,
	},
	{
		id: 1090,
		name: "SANGO ISOPAKO IBADAN",
		lga_id: 183,
	},
	{
		id: 1091,
		name: "ODUNA",
		lga_id: 393,
	},
	{
		id: 1092,
		name: "GWADANGWAJI VILLAGES",
		lga_id: 706,
	},
	{
		id: 1093,
		name: "ARUGO LAYOUT",
		lga_id: 318,
	},
	{
		id: 1094,
		name: "OLD TOWNSHIP, PH",
		lga_id: 594,
	},
	{
		id: 1095,
		name: "OWODE OTA",
		lga_id: 629,
	},
	{
		id: 1096,
		name: "OKPE",
		lga_id: 385,
	},
	{
		id: 1097,
		name: "LAYIN",
		lga_id: 399,
	},
	{
		id: 1098,
		name: "BUKURU",
		lga_id: 683,
	},
	{
		id: 1099,
		name: "SABON GARI, OTURKPO",
		lga_id: 333,
	},
	{
		id: 1100,
		name: "IKOYI, LAGOS",
		lga_id: 17,
	},
	{
		id: 1101,
		name: "SHAKWATU",
		lga_id: 429,
	},
	{
		id: 1102,
		name: "UGBERIKOKO SAPELE",
		lga_id: 754,
	},
	{
		id: 1103,
		name: "MODO",
		lga_id: 423,
	},
	{
		id: 1104,
		name: "AFAHA OKU",
		lga_id: 162,
	},
	{
		id: 1105,
		name: "OKOTA",
		lga_id: 509,
	},
	{
		id: 1106,
		name: "EKUKU AGBOR",
		lga_id: 441,
	},
	{
		id: 1107,
		name: "INGAWA",
		lga_id: 455,
	},
	{
		id: 1108,
		name: "MBALAGH",
		lga_id: 746,
	},
	{
		id: 1109,
		name: "AYETOTO-ASOGUN",
		lga_id: 228,
	},
	{
		id: 1110,
		name: "DANJANKU",
		lga_id: 380,
	},
	{
		id: 1111,
		name: "MAROKO",
		lga_id: 410,
	},
	{
		id: 1112,
		name: "UMUEZENDU",
		lga_id: 747,
	},
	{
		id: 1113,
		name: "OKE MOSAN ABEOKUTA SOUTH",
		lga_id: 118,
	},
	{
		id: 1114,
		name: "KURU",
		lga_id: 683,
	},
	{
		id: 1115,
		name: "ARMY BARRACKS, BAUCHI",
		lga_id: 653,
	},
	{
		id: 1116,
		name: "OWODE IFO",
		lga_id: 549,
	},
	{
		id: 1117,
		name: "OGUTA, ONITSHA NORTH",
		lga_id: 500,
	},
	{
		id: 1118,
		name: "OBA ABOJI",
		lga_id: 679,
	},
	{
		id: 1119,
		name: "AFOWO DOFORO",
		lga_id: 10,
	},
	{
		id: 1120,
		name: "MUBI CENTRAL",
		lga_id: 477,
	},
	{
		id: 1121,
		name: "CHALLAWA INSUTRIAL",
		lga_id: 425,
	},
	{
		id: 1122,
		name: "GANDUN ALBASA",
		lga_id: 236,
	},
	{
		id: 1123,
		name: "IRESADU",
		lga_id: 381,
	},
	{
		id: 1124,
		name: "ERIPA BOLUWADURO",
		lga_id: 302,
	},
	{
		id: 1125,
		name: "NACB FARM CENTRE",
		lga_id: 236,
	},
	{
		id: 1126,
		name: "GRA, MAKURDI",
		lga_id: 357,
	},
	{
		id: 1127,
		name: "RUMUOGBA",
		lga_id: 78,
	},
	{
		id: 1128,
		name: "KAFANCHAN",
		lga_id: 514,
	},
	{
		id: 1129,
		name: "IBESHE",
		lga_id: 212,
	},
	{
		id: 1130,
		name: "SCHOOL OF HEALTH AND TECH, MINNA",
		lga_id: 97,
	},
	{
		id: 1131,
		name: "GADABA",
		lga_id: 750,
	},
	{
		id: 1132,
		name: "OKE ODO, OSOGBO",
		lga_id: 219,
	},
	{
		id: 1133,
		name: "TUMU",
		lga_id: 103,
	},
	{
		id: 1134,
		name: "TUNGAN KUKA PANJAM",
		lga_id: 235,
	},
	{
		id: 1135,
		name: "FASHINA",
		lga_id: 713,
	},
	{
		id: 1136,
		name: "IKORO",
		lga_id: 678,
	},
	{
		id: 1137,
		name: "UGBO",
		lga_id: 547,
	},
	{
		id: 1138,
		name: "IDU INDUSTRIAL DISTRICT",
		lga_id: 476,
	},
	{
		id: 1139,
		name: "GIDAN BAMAIYI",
		lga_id: 284,
	},
	{
		id: 1140,
		name: "UNIVERSITY OF CALABAR",
		lga_id: 559,
	},
	{
		id: 1141,
		name: "OGOMBO",
		lga_id: 17,
	},
	{
		id: 1142,
		name: "EMMANUEL COLLEGE OWERRI",
		lga_id: 318,
	},
	{
		id: 1143,
		name: "GWAGWA",
		lga_id: 476,
	},
	{
		id: 1144,
		name: "SABON GARI, ZARIA",
		lga_id: 616,
	},
	{
		id: 1145,
		name: "COSTAIN, OSOGBO",
		lga_id: 219,
	},
	{
		id: 1146,
		name: "BASHORUN LAGELU",
		lga_id: 372,
	},
	{
		id: 1147,
		name: "OGOTUN",
		lga_id: 624,
	},
	{
		id: 1148,
		name: "KOBAPE",
		lga_id: 690,
	},
	{
		id: 1149,
		name: "IBALA IJESHA",
		lga_id: 203,
	},
	{
		id: 1150,
		name: "OKPUNO NNEWICHI",
		lga_id: 327,
	},
	{
		id: 1151,
		name: "UMUOSI ALAOMA",
		lga_id: 765,
	},
	{
		id: 1152,
		name: "OKUTA",
		lga_id: 205,
	},
	{
		id: 1153,
		name: "KADO",
		lga_id: 476,
	},
	{
		id: 1154,
		name: "OKUKU, OSUN",
		lga_id: 771,
	},
	{
		id: 1155,
		name: "BADORE",
		lga_id: 410,
	},
	{
		id: 1156,
		name: "DOSUNMU",
		lga_id: 766,
	},
	{
		id: 1157,
		name: "AFRIKE",
		lga_id: 440,
	},
	{
		id: 1158,
		name: "ANGWAR KASAIRE",
		lga_id: 399,
	},
	{
		id: 1159,
		name: "OLODE, IBADAN",
		lga_id: 607,
	},
	{
		id: 1160,
		name: "FEDERAL MEDICAL CENTRE, AZARE",
		lga_id: 39,
	},
	{
		id: 1161,
		name: "GINT IGBE",
		lga_id: 212,
	},
	{
		id: 1162,
		name: "UGIRINNA",
		lga_id: 27,
	},
	{
		id: 1163,
		name: "AMULOKO",
		lga_id: 246,
	},
	{
		id: 1164,
		name: "RISHI",
		lga_id: 489,
	},
	{
		id: 1165,
		name: "KOFA MATA",
		lga_id: 182,
	},
	{
		id: 1166,
		name: "OMALA",
		lga_id: 113,
	},
	{
		id: 1167,
		name: "ASKIRA",
		lga_id: 188,
	},
	{
		id: 1168,
		name: "ALUU",
		lga_id: 611,
	},
	{
		id: 1169,
		name: "APESE",
		lga_id: 10,
	},
	{
		id: 1170,
		name: "GARKO",
		lga_id: 336,
	},
	{
		id: 1171,
		name: "TSOHUWAR LOW COST",
		lga_id: 399,
	},
	{
		id: 1172,
		name: "DANLADI ZAKARI ROAD",
		lga_id: 187,
	},
	{
		id: 1173,
		name: "GRA JOS",
		lga_id: 732,
	},
	{
		id: 1174,
		name: "IBRAHIM TAIWO, ILORIN WEST",
		lga_id: 108,
	},
	{
		id: 1175,
		name: "OFOROLA",
		lga_id: 189,
	},
	{
		id: 1176,
		name: "IBAGWA-ANI",
		lga_id: 557,
	},
	{
		id: 1177,
		name: "GAZA",
		lga_id: 175,
	},
	{
		id: 1178,
		name: "MARINE WORKER QUARTERS",
		lga_id: 187,
	},
	{
		id: 1179,
		name: "IJORA BADIA",
		lga_id: 526,
	},
	{
		id: 1180,
		name: "SUMMIT ROAD",
		lga_id: 574,
	},
	{
		id: 1181,
		name: "SUMAILA",
		lga_id: 416,
	},
	{
		id: 1182,
		name: "NTA PH, MGUOBA",
		lga_id: 78,
	},
	{
		id: 1183,
		name: "MGBIDI",
		lga_id: 668,
	},
	{
		id: 1184,
		name: "ORETA",
		lga_id: 212,
	},
	{
		id: 1185,
		name: "AISEGBA EKITI",
		lga_id: 323,
	},
	{
		id: 1186,
		name: "BAIYEKU",
		lga_id: 18,
	},
	{
		id: 1187,
		name: "MAYO BELWA",
		lga_id: 401,
	},
	{
		id: 1188,
		name: "UMUAGU, OBI NGWA",
		lga_id: 463,
	},
	{
		id: 1189,
		name: "OBOMPKA",
		lga_id: 370,
	},
	{
		id: 1190,
		name: "KAURAN NAMODA",
		lga_id: 352,
	},
	{
		id: 1191,
		name: "KABAMO LAYOUT",
		lga_id: 616,
	},
	{
		id: 1192,
		name: "UGA",
		lga_id: 747,
	},
	{
		id: 1193,
		name: "ISEMI ILE",
		lga_id: 303,
	},
	{
		id: 1194,
		name: "OWORONSHOKI",
		lga_id: 465,
	},
	{
		id: 1195,
		name: "AKAGBE AMODU",
		lga_id: 704,
	},
	{
		id: 1196,
		name: "SABON FEGI",
		lga_id: 122,
	},
	{
		id: 1197,
		name: "ODOE IDANRE",
		lga_id: 605,
	},
	{
		id: 1198,
		name: "OBIAKPU",
		lga_id: 244,
	},
	{
		id: 1199,
		name: "EVWRENI",
		lga_id: 411,
	},
	{
		id: 1200,
		name: "OKUNNEGUN",
		lga_id: 751,
	},
	{
		id: 1201,
		name: "BASOKO MARABA",
		lga_id: 750,
	},
	{
		id: 1202,
		name: "AKINYELE",
		lga_id: 524,
	},
	{
		id: 1203,
		name: "PARKVIEW ESTATE",
		lga_id: 410,
	},
	{
		id: 1204,
		name: "UGBORI",
		lga_id: 417,
	},
	{
		id: 1205,
		name: "UJIOGBA",
		lga_id: 756,
	},
	{
		id: 1206,
		name: "OJULOKUN",
		lga_id: 541,
	},
	{
		id: 1207,
		name: "UPPER LAWANI",
		lga_id: 23,
	},
	{
		id: 1208,
		name: "AGBADO ADO-ODO/OTA",
		lga_id: 629,
	},
	{
		id: 1209,
		name: "EFFURUN GRA",
		lga_id: 532,
	},
	{
		id: 1210,
		name: "GWANDU",
		lga_id: 563,
	},
	{
		id: 1211,
		name: "DAWAKIN KUDU",
		lga_id: 72,
	},
	{
		id: 1212,
		name: "BODO",
		lga_id: 638,
	},
	{
		id: 1213,
		name: "KOFAR KAURA LAYOUT",
		lga_id: 399,
	},
	{
		id: 1214,
		name: "OSIMORE OLIWORO QUARTER",
		lga_id: 619,
	},
	{
		id: 1215,
		name: "IJAGEMO, LAGOS",
		lga_id: 577,
	},
	{
		id: 1216,
		name: "REGENT, NNEWI",
		lga_id: 327,
	},
	{
		id: 1217,
		name: "BIRNIWA",
		lga_id: 726,
	},
	{
		id: 1218,
		name: "WESTMINISTER",
		lga_id: 694,
	},
	{
		id: 1219,
		name: "OKE SUNA",
		lga_id: 629,
	},
	{
		id: 1220,
		name: "IKOLE RURAL",
		lga_id: 315,
	},
	{
		id: 1221,
		name: "OKPOPO ONITSHA",
		lga_id: 500,
	},
	{
		id: 1222,
		name: "DORAWA",
		lga_id: 222,
	},
	{
		id: 1223,
		name: "KEBBE",
		lga_id: 453,
	},
	{
		id: 1224,
		name: "IGUEBEN",
		lga_id: 461,
	},
	{
		id: 1225,
		name: "ALOMAJA",
		lga_id: 430,
	},
	{
		id: 1226,
		name: "FATE EAST",
		lga_id: 129,
	},
	{
		id: 1227,
		name: "OTU",
		lga_id: 86,
	},
	{
		id: 1228,
		name: "SARKI KUDU",
		lga_id: 133,
	},
	{
		id: 1229,
		name: "IDEANI",
		lga_id: 669,
	},
	{
		id: 1230,
		name: "AGBAJE IJOKODO",
		lga_id: 85,
	},
	{
		id: 1231,
		name: "IJOMU",
		lga_id: 199,
	},
	{
		id: 1232,
		name: "WUNDI",
		lga_id: 489,
	},
	{
		id: 1233,
		name: "ONIRU ESTATE",
		lga_id: 17,
	},
	{
		id: 1234,
		name: "OKOKOMAIKO",
		lga_id: 228,
	},
	{
		id: 1235,
		name: "ONA OLA",
		lga_id: 680,
	},
	{
		id: 1236,
		name: "IWOROKOGBASA QUATERS",
		lga_id: 199,
	},
	{
		id: 1237,
		name: "ABOJUPA",
		lga_id: 25,
	},
	{
		id: 1238,
		name: "SAKPE",
		lga_id: 374,
	},
	{
		id: 1239,
		name: "LASOJU",
		lga_id: 193,
	},
	{
		id: 1240,
		name: "KABUGA",
		lga_id: 703,
	},
	{
		id: 1241,
		name: "YANLEMO",
		lga_id: 425,
	},
	{
		id: 1242,
		name: "AJAGANABE",
		lga_id: 541,
	},
	{
		id: 1243,
		name: "ADEMOLA ORESANYA",
		lga_id: 378,
	},
	{
		id: 1244,
		name: "BABA",
		lga_id: 357,
	},
	{
		id: 1245,
		name: "BAKUNDI",
		lga_id: 707,
	},
	{
		id: 1246,
		name: "IGBA TORO",
		lga_id: 199,
	},
	{
		id: 1247,
		name: "YANDAKI",
		lga_id: 497,
	},
	{
		id: 1248,
		name: "GBOKO",
		lga_id: 351,
	},
	{
		id: 1249,
		name: "SURULERE SAGAMU",
		lga_id: 378,
	},
	{
		id: 1250,
		name: "UGBOKO",
		lga_id: 371,
	},
	{
		id: 1251,
		name: "ASENI",
		lga_id: 531,
	},
	{
		id: 1252,
		name: "OGIDI",
		lga_id: 669,
	},
	{
		id: 1253,
		name: "KAKA",
		lga_id: 219,
	},
	{
		id: 1254,
		name: "KARAYE",
		lga_id: 510,
	},
	{
		id: 1255,
		name: "UNGWAR MUAZU",
		lga_id: 757,
	},
	{
		id: 1256,
		name: "NNAMDI AZIKWE UNIVERSITY",
		lga_id: 730,
	},
	{
		id: 1257,
		name: "BABURA",
		lga_id: 490,
	},
	{
		id: 1258,
		name: "BAKANA, RIVERS",
		lga_id: 603,
	},
	{
		id: 1259,
		name: "NEW MARKET, ONITSHA",
		lga_id: 500,
	},
	{
		id: 1260,
		name: "UTAKO",
		lga_id: 476,
	},
	{
		id: 1261,
		name: "OBIOR UMUNE",
		lga_id: 370,
	},
	{
		id: 1262,
		name: "AKPARABONG",
		lga_id: 250,
	},
	{
		id: 1263,
		name: "OWERRE ANIE",
		lga_id: 557,
	},
	{
		id: 1264,
		name: "SABIYEL",
		lga_id: 211,
	},
	{
		id: 1265,
		name: "EBU",
		lga_id: 626,
	},
	{
		id: 1266,
		name: "FALANA",
		lga_id: 18,
	},
	{
		id: 1267,
		name: "OLABISI ONABANJO UNIVERSITY",
		lga_id: 184,
	},
	{
		id: 1268,
		name: "MGBOWO",
		lga_id: 547,
	},
	{
		id: 1269,
		name: "KATSINA ARMY BARRACKS",
		lga_id: 399,
	},
	{
		id: 1270,
		name: "KOJOLI",
		lga_id: 262,
	},
	{
		id: 1271,
		name: "ALABATA COMMUNITY",
		lga_id: 356,
	},
	{
		id: 1272,
		name: "ILA ORANGAN",
		lga_id: 280,
	},
	{
		id: 1273,
		name: "KAURA, MUNICIPAL AREA COUNCIL",
		lga_id: 476,
	},
	{
		id: 1274,
		name: "DAN DUTSE",
		lga_id: 122,
	},
	{
		id: 1275,
		name: "AIYEDE OGBESE",
		lga_id: 52,
	},
	{
		id: 1276,
		name: "IDOBI",
		lga_id: 680,
	},
	{
		id: 1277,
		name: "KAGINI",
		lga_id: 476,
	},
	{
		id: 1278,
		name: "ILU ISEGUN",
		lga_id: 227,
	},
	{
		id: 1279,
		name: "APAPA GRA 2",
		lga_id: 526,
	},
	{
		id: 1280,
		name: "NNAMDI AZIKWE INTERNATIONAL AIRPORT",
		lga_id: 476,
	},
	{
		id: 1281,
		name: "OSIELE",
		lga_id: 356,
	},
	{
		id: 1282,
		name: "NKWO OBOHIA",
		lga_id: 264,
	},
	{
		id: 1283,
		name: "LAU",
		lga_id: 722,
	},
	{
		id: 1284,
		name: "IFAKI",
		lga_id: 377,
	},
	{
		id: 1285,
		name: "RIMI",
		lga_id: 443,
	},
	{
		id: 1286,
		name: "OLD ABA ROAD, PH",
		lga_id: 78,
	},
	{
		id: 1287,
		name: "UMANYARE",
		lga_id: 531,
	},
	{
		id: 1288,
		name: "ABABUA",
		lga_id: 492,
	},
	{
		id: 1289,
		name: "ARAB ROAD",
		lga_id: 82,
	},
	{
		id: 1290,
		name: "IKOM CITY",
		lga_id: 250,
	},
	{
		id: 1291,
		name: "UGBOHA",
		lga_id: 55,
	},
	{
		id: 1292,
		name: "YAKUDIMA ESTATE",
		lga_id: 718,
	},
	{
		id: 1293,
		name: "GULANI",
		lga_id: 322,
	},
	{
		id: 1294,
		name: "NASARAWA POLYTECHNIC",
		lga_id: 452,
	},
	{
		id: 1295,
		name: "OYAN",
		lga_id: 771,
	},
	{
		id: 1296,
		name: "LIBERTY DAM",
		lga_id: 732,
	},
	{
		id: 1297,
		name: "NUMAN",
		lga_id: 70,
	},
	{
		id: 1298,
		name: "DILIMI",
		lga_id: 732,
	},
	{
		id: 1299,
		name: "ETUA ELIOGO",
		lga_id: 610,
	},
	{
		id: 1300,
		name: "MADONNA UNIVERSITY, IHIALA",
		lga_id: 207,
	},
	{
		id: 1301,
		name: "UTE",
		lga_id: 114,
	},
	{
		id: 1302,
		name: "EKPO ABASI YELLOW DUKE",
		lga_id: 737,
	},
	{
		id: 1303,
		name: "TEMIDIRE",
		lga_id: 267,
	},
	{
		id: 1304,
		name: "NEW LAYOUT GANYE",
		lga_id: 262,
	},
	{
		id: 1305,
		name: "IPAKODO",
		lga_id: 18,
	},
	{
		id: 1306,
		name: "ISHARA",
		lga_id: 184,
	},
	{
		id: 1307,
		name: "KIRFI",
		lga_id: 69,
	},
	{
		id: 1308,
		name: "MAYO INNE",
		lga_id: 755,
	},
	{
		id: 1309,
		name: "EVIAM",
		lga_id: 508,
	},
	{
		id: 1310,
		name: "ABOR",
		lga_id: 760,
	},
	{
		id: 1311,
		name: "APESE",
		lga_id: 595,
	},
	{
		id: 1312,
		name: "KURAR MOTA",
		lga_id: 34,
	},
	{
		id: 1313,
		name: "AMUKPE",
		lga_id: 754,
	},
	{
		id: 1314,
		name: "EJIRIN",
		lga_id: 15,
	},
	{
		id: 1315,
		name: "IGBOUKWU",
		lga_id: 747,
	},
	{
		id: 1316,
		name: "KYURAN TEV",
		lga_id: 289,
	},
	{
		id: 1317,
		name: "GUMMI",
		lga_id: 537,
	},
	{
		id: 1318,
		name: "YANDAKA",
		lga_id: 399,
	},
	{
		id: 1319,
		name: "AUNA",
		lga_id: 686,
	},
	{
		id: 1320,
		name: "EZIOWELLE ABALETE",
		lga_id: 554,
	},
	{
		id: 1321,
		name: "JAMBUTU",
		lga_id: 362,
	},
	{
		id: 1322,
		name: "MANI",
		lga_id: 40,
	},
	{
		id: 1323,
		name: "TRANS EGBU OWERRI",
		lga_id: 318,
	},
	{
		id: 1324,
		name: "OBIAKU",
		lga_id: 658,
	},
	{
		id: 1325,
		name: "IVA VALLEY",
		lga_id: 197,
	},
	{
		id: 1326,
		name: "SAKI POLYTECHNIC",
		lga_id: 615,
	},
	{
		id: 1327,
		name: "KAYAWA",
		lga_id: 745,
	},
	{
		id: 1328,
		name: "AGBA UMANA",
		lga_id: 613,
	},
	{
		id: 1329,
		name: "UNGUWAR MALAMAI",
		lga_id: 59,
	},
	{
		id: 1330,
		name: "KACHIA",
		lga_id: 647,
	},
	{
		id: 1331,
		name: "USELU",
		lga_id: 23,
	},
	{
		id: 1332,
		name: "KABALA WEST, IGABI",
		lga_id: 130,
	},
	{
		id: 1333,
		name: "OKE OLA, OSOGBO",
		lga_id: 394,
	},
	{
		id: 1334,
		name: "LONDON CIKI",
		lga_id: 671,
	},
	{
		id: 1335,
		name: "AKPAKSAK ESTATE",
		lga_id: 162,
	},
	{
		id: 1336,
		name: "BARUWA",
		lga_id: 577,
	},
	{
		id: 1337,
		name: "OREMEJI IBADAN NE",
		lga_id: 173,
	},
	{
		id: 1338,
		name: "ONNE ELEME",
		lga_id: 251,
	},
	{
		id: 1339,
		name: "RUMUEPIRIKOM",
		lga_id: 78,
	},
	{
		id: 1340,
		name: "SCOUT CAMP",
		lga_id: 126,
	},
	{
		id: 1341,
		name: "TSAFE",
		lga_id: 527,
	},
	{
		id: 1342,
		name: "AKU",
		lga_id: 637,
	},
	{
		id: 1343,
		name: "NISE",
		lga_id: 772,
	},
	{
		id: 1344,
		name: "IDU",
		lga_id: 91,
	},
	{
		id: 1345,
		name: "MATSAI",
		lga_id: 497,
	},
	{
		id: 1346,
		name: "MAYO LAMJA",
		lga_id: 401,
	},
	{
		id: 1347,
		name: "IJU, LAGOS",
		lga_id: 552,
	},
	{
		id: 1348,
		name: "ALIERO",
		lga_id: 211,
	},
	{
		id: 1349,
		name: "WAMAKO",
		lga_id: 521,
	},
	{
		id: 1350,
		name: "UMU ENU",
		lga_id: 358,
	},
	{
		id: 1351,
		name: "UGU",
		lga_id: 371,
	},
	{
		id: 1352,
		name: "IGARRA",
		lga_id: 479,
	},
	{
		id: 1353,
		name: "DASS",
		lga_id: 567,
	},
	{
		id: 1354,
		name: "LANDING GROUND, WUKARI",
		lga_id: 140,
	},
	{
		id: 1355,
		name: "KWAMBULA",
		lga_id: 53,
	},
	{
		id: 1356,
		name: "BUNUNU",
		lga_id: 664,
	},
	{
		id: 1357,
		name: "FGC JOS",
		lga_id: 732,
	},
	{
		id: 1358,
		name: "EWU",
		lga_id: 621,
	},
	{
		id: 1359,
		name: "AHMADIYA",
		lga_id: 577,
	},
	{
		id: 1360,
		name: "SAABE",
		lga_id: 132,
	},
	{
		id: 1361,
		name: "OKEKOTO",
		lga_id: 240,
	},
	{
		id: 1362,
		name: "POMPOMMARI HOUSING ESTATE",
		lga_id: 693,
	},
	{
		id: 1363,
		name: "ILUPEJU IKEJA",
		lga_id: 584,
	},
	{
		id: 1364,
		name: "ILORA",
		lga_id: 735,
	},
	{
		id: 1365,
		name: "ERIC MOORE",
		lga_id: 641,
	},
	{
		id: 1366,
		name: "OGUNGBE",
		lga_id: 10,
	},
	{
		id: 1367,
		name: "KUSADA",
		lga_id: 84,
	},
	{
		id: 1368,
		name: "BOSSO LOW COST",
		lga_id: 97,
	},
	{
		id: 1369,
		name: "GOVT HOUSE LAFIA",
		lga_id: 452,
	},
	{
		id: 1370,
		name: "QUARRY",
		lga_id: 118,
	},
	{
		id: 1371,
		name: "APAPA GRA 2",
		lga_id: 13,
	},
	{
		id: 1372,
		name: "GALAMBI",
		lga_id: 653,
	},
	{
		id: 1373,
		name: "JAHUN",
		lga_id: 271,
	},
	{
		id: 1374,
		name: "IYALE",
		lga_id: 202,
	},
	{
		id: 1375,
		name: "AGO OKO",
		lga_id: 118,
	},
	{
		id: 1376,
		name: "ODO AYEDUN",
		lga_id: 315,
	},
	{
		id: 1377,
		name: "OGODU, OMALA",
		lga_id: 113,
	},
	{
		id: 1378,
		name: "GULBIN BOKA",
		lga_id: 361,
	},
	{
		id: 1379,
		name: "ITIAM IKOT ABIA",
		lga_id: 162,
	},
	{
		id: 1380,
		name: "MUBI CITY CENTER",
		lga_id: 477,
	},
	{
		id: 1381,
		name: "ONIPANU",
		lga_id: 115,
	},
	{
		id: 1382,
		name: "KOKHUO",
		lga_id: 393,
	},
	{
		id: 1383,
		name: "MOLETE",
		lga_id: 126,
	},
	{
		id: 1384,
		name: "FEGGE",
		lga_id: 343,
	},
	{
		id: 1385,
		name: "EKE OBODOUKWU",
		lga_id: 687,
	},
	{
		id: 1386,
		name: "MAKERA, KADUNA SOUTH",
		lga_id: 757,
	},
	{
		id: 1387,
		name: "TUNKUS",
		lga_id: 341,
	},
	{
		id: 1388,
		name: "KAZAURE",
		lga_id: 739,
	},
	{
		id: 1389,
		name: "BACITA",
		lga_id: 272,
	},
	{
		id: 1390,
		name: "GRA, ADO EKITI",
		lga_id: 573,
	},
	{
		id: 1391,
		name: "OSOSA IJEBU",
		lga_id: 159,
	},
	{
		id: 1392,
		name: "OTUKPO ICHO",
		lga_id: 333,
	},
	{
		id: 1393,
		name: "SABON GERI YORRO",
		lga_id: 379,
	},
	{
		id: 1394,
		name: "ALIMOSHO",
		lga_id: 577,
	},
	{
		id: 1395,
		name: "RAYFIELD",
		lga_id: 683,
	},
	{
		id: 1396,
		name: "TUDUN WADA, ZARIA",
		lga_id: 579,
	},
	{
		id: 1397,
		name: "LOCALGOVTSECRETARIAT",
		lga_id: 327,
	},
	{
		id: 1398,
		name: "IKEMGA",
		lga_id: 669,
	},
	{
		id: 1399,
		name: "IKONO ETIM EKPO",
		lga_id: 156,
	},
	{
		id: 1400,
		name: "MGBIRICHI OHAJI",
		lga_id: 244,
	},
	{
		id: 1401,
		name: "UMUCHIAKUMA IHECHIOWA",
		lga_id: 87,
	},
	{
		id: 1402,
		name: "JEGA",
		lga_id: 472,
	},
	{
		id: 1403,
		name: "OLD GRA, MAIDUGURI",
		lga_id: 693,
	},
	{
		id: 1404,
		name: "COLLEGE OF EDUCATION, MAIDUGURI",
		lga_id: 693,
	},
	{
		id: 1405,
		name: "IDAMA",
		lga_id: 467,
	},
	{
		id: 1406,
		name: "GODOGODO",
		lga_id: 514,
	},
	{
		id: 1407,
		name: "NJABA",
		lga_id: 96,
	},
	{
		id: 1408,
		name: "AGULUZIGBO, AGUATA",
		lga_id: 747,
	},
	{
		id: 1409,
		name: "MANYI",
		lga_id: 514,
	},
	{
		id: 1410,
		name: "EMEABIAM",
		lga_id: 189,
	},
	{
		id: 1411,
		name: "IKOT AMBANG",
		lga_id: 426,
	},
	{
		id: 1412,
		name: "FEDERAL HOUSING ESTATE, YOLA",
		lga_id: 60,
	},
	{
		id: 1413,
		name: "OFAT ADUN",
		lga_id: 48,
	},
	{
		id: 1414,
		name: "EGBELE",
		lga_id: 741,
	},
	{
		id: 1415,
		name: "AJAWA",
		lga_id: 485,
	},
	{
		id: 1416,
		name: "EBURUTU ARMY BARRACKS",
		lga_id: 559,
	},
	{
		id: 1417,
		name: "YABA, ONDO",
		lga_id: 285,
	},
	{
		id: 1418,
		name: "NKPOLOGU",
		lga_id: 684,
	},
	{
		id: 1419,
		name: "BANJIRAM",
		lga_id: 194,
	},
	{
		id: 1420,
		name: "AYEYE",
		lga_id: 648,
	},
	{
		id: 1421,
		name: "EDIBA, CALABAR MUNICIPAL",
		lga_id: 559,
	},
	{
		id: 1422,
		name: "THE POLYTECHNIC IBADAN NORTH CAMPUS",
		lga_id: 524,
	},
	{
		id: 1423,
		name: "MIYA",
		lga_id: 729,
	},
	{
		id: 1424,
		name: "BARDE LANKA",
		lga_id: 379,
	},
	{
		id: 1425,
		name: "OHANKU",
		lga_id: 471,
	},
	{
		id: 1426,
		name: "LALUPON",
		lga_id: 372,
	},
	{
		id: 1427,
		name: "SABON SARKI",
		lga_id: 647,
	},
	{
		id: 1428,
		name: "GUSSORO",
		lga_id: 429,
	},
	{
		id: 1429,
		name: "OWODE EDE",
		lga_id: 268,
	},
	{
		id: 1430,
		name: "FEDERAL SECRETARIAT DUTSE",
		lga_id: 464,
	},
	{
		id: 1431,
		name: "DALA",
		lga_id: 182,
	},
	{
		id: 1432,
		name: "GIDAN IDI",
		lga_id: 140,
	},
	{
		id: 1433,
		name: "IGANMU",
		lga_id: 442,
	},
	{
		id: 1434,
		name: "DAWAKI, KANKE",
		lga_id: 238,
	},
	{
		id: 1435,
		name: "OLD MURI",
		lga_id: 677,
	},
	{
		id: 1436,
		name: "KWAKUTI",
		lga_id: 750,
	},
	{
		id: 1437,
		name: "TAFARE",
		lga_id: 60,
	},
	{
		id: 1438,
		name: "AMADI FLAT",
		lga_id: 594,
	},
	{
		id: 1439,
		name: "SUBUWAR MARINJUWA",
		lga_id: 464,
	},
	{
		id: 1440,
		name: "ONICHA, EZINIHITTE",
		lga_id: 134,
	},
	{
		id: 1441,
		name: "AJEGUNLE AJEROMI",
		lga_id: 267,
	},
	{
		id: 1442,
		name: "ABRAKA MARKET, ASABA",
		lga_id: 574,
	},
	{
		id: 1443,
		name: "KUMSHE",
		lga_id: 693,
	},
	{
		id: 1444,
		name: "NGURU MBAISE, ABOH-MBAISE",
		lga_id: 71,
	},
	{
		id: 1445,
		name: "WASINMI AREA, OSOGBO",
		lga_id: 219,
	},
	{
		id: 1446,
		name: "SABONGARI KARADU",
		lga_id: 437,
	},
	{
		id: 1447,
		name: "LOKONGOMA",
		lga_id: 187,
	},
	{
		id: 1448,
		name: "ENUGU AGIDI",
		lga_id: 772,
	},
	{
		id: 1449,
		name: "1004",
		lga_id: 410,
	},
	{
		id: 1450,
		name: "DOGON KARFE",
		lga_id: 732,
	},
	{
		id: 1451,
		name: "NNEWI",
		lga_id: 327,
	},
	{
		id: 1452,
		name: "JANOKE",
		lga_id: 212,
	},
	{
		id: 1453,
		name: "OKRIKA",
		lga_id: 342,
	},
	{
		id: 1454,
		name: "SAMARU, BUNGUDU",
		lga_id: 34,
	},
	{
		id: 1455,
		name: "FUTUK",
		lga_id: 232,
	},
	{
		id: 1456,
		name: "FHA, ABUJA",
		lga_id: 476,
	},
	{
		id: 1457,
		name: "OKUKU, IMO",
		lga_id: 189,
	},
	{
		id: 1458,
		name: "IGBAJA",
		lga_id: 309,
	},
	{
		id: 1459,
		name: "ONA EGBO",
		lga_id: 680,
	},
	{
		id: 1460,
		name: "OLASORE",
		lga_id: 577,
	},
	{
		id: 1461,
		name: "ODOGUNYAN",
		lga_id: 18,
	},
	{
		id: 1462,
		name: "ADENIJI ADELE",
		lga_id: 766,
	},
	{
		id: 1463,
		name: "DADIN KOWA, BIU",
		lga_id: 169,
	},
	{
		id: 1464,
		name: "GENERAL GAS",
		lga_id: 524,
	},
	{
		id: 1465,
		name: "TELEMU",
		lga_id: 576,
	},
	{
		id: 1466,
		name: "GARU",
		lga_id: 89,
	},
	{
		id: 1467,
		name: "KETU",
		lga_id: 465,
	},
	{
		id: 1468,
		name: "ATAN ETOI ROAD",
		lga_id: 162,
	},
	{
		id: 1469,
		name: "ARGUNGU RURAL",
		lga_id: 172,
	},
	{
		id: 1470,
		name: "AGO ERE",
		lga_id: 132,
	},
	{
		id: 1471,
		name: "TUNGAN SAWADNA",
		lga_id: 175,
	},
	{
		id: 1472,
		name: "OSISIOMA/ARIARA JUNCTION",
		lga_id: 590,
	},
	{
		id: 1473,
		name: "KATSINA GENERAL HOSPITAL",
		lga_id: 399,
	},
	{
		id: 1474,
		name: "AGULU AWKA SOUTH",
		lga_id: 358,
	},
	{
		id: 1475,
		name: "UNGUWAN SHANU",
		lga_id: 284,
	},
	{
		id: 1476,
		name: "YENAGOA POLICE HQ",
		lga_id: 167,
	},
	{
		id: 1477,
		name: "MAKERA, FUNTUA",
		lga_id: 122,
	},
	{
		id: 1478,
		name: "ABAK MIDIM",
		lga_id: 511,
	},
	{
		id: 1479,
		name: "ATANI",
		lga_id: 353,
	},
	{
		id: 1480,
		name: "TSAMAYE",
		lga_id: 61,
	},
	{
		id: 1481,
		name: "ZANGON KATAF",
		lga_id: 539,
	},
	{
		id: 1482,
		name: "NDIAGUO",
		lga_id: 520,
	},
	{
		id: 1483,
		name: "GARTA",
		lga_id: 92,
	},
	{
		id: 1484,
		name: "UMUECHEM UMUHU",
		lga_id: 676,
	},
	{
		id: 1485,
		name: "AGBAJA, LOKOJA",
		lga_id: 187,
	},
	{
		id: 1486,
		name: "IDOA",
		lga_id: 756,
	},
	{
		id: 1487,
		name: "OVIM",
		lga_id: 65,
	},
	{
		id: 1488,
		name: "OLUKOTUN",
		lga_id: 155,
	},
	{
		id: 1489,
		name: "FCE ASABA",
		lga_id: 574,
	},
	{
		id: 1490,
		name: "EMU, NDOKWA WEST",
		lga_id: 610,
	},
	{
		id: 1491,
		name: "EDE OBALLA",
		lga_id: 557,
	},
	{
		id: 1492,
		name: "TEDI",
		lga_id: 694,
	},
	{
		id: 1493,
		name: "RUKPOKWU",
		lga_id: 78,
	},
	{
		id: 1494,
		name: "KOFAR GALADIMA",
		lga_id: 579,
	},
	{
		id: 1495,
		name: "OGERE",
		lga_id: 151,
	},
	{
		id: 1496,
		name: "ROGUN",
		lga_id: 260,
	},
	{
		id: 1497,
		name: "ISALU",
		lga_id: 10,
	},
	{
		id: 1498,
		name: "ELEWEERAN COMMUNITY",
		lga_id: 713,
	},
	{
		id: 1499,
		name: "OHAKITI",
		lga_id: 90,
	},
	{
		id: 1500,
		name: "KARSHI",
		lga_id: 317,
	},
	{
		id: 1501,
		name: "ALFA-NLA",
		lga_id: 240,
	},
	{
		id: 1502,
		name: "OKOM",
		lga_id: 48,
	},
	{
		id: 1503,
		name: "KANKARA",
		lga_id: 533,
	},
	{
		id: 1504,
		name: "AYETORO, OGUN",
		lga_id: 62,
	},
	{
		id: 1505,
		name: "IKOLO",
		lga_id: 637,
	},
	{
		id: 1506,
		name: "OWOAHIAFIA",
		lga_id: 463,
	},
	{
		id: 1507,
		name: "OREROKPE",
		lga_id: 354,
	},
	{
		id: 1508,
		name: "ELELE ALIMINI",
		lga_id: 412,
	},
	{
		id: 1509,
		name: "ANFANI, NIGER",
		lga_id: 106,
	},
	{
		id: 1510,
		name: "UKHUN",
		lga_id: 756,
	},
	{
		id: 1511,
		name: "OLIHA",
		lga_id: 508,
	},
	{
		id: 1512,
		name: "OBINZE",
		lga_id: 189,
	},
	{
		id: 1513,
		name: "BWARI",
		lga_id: 82,
	},
	{
		id: 1514,
		name: "KWAKO",
		lga_id: 216,
	},
	{
		id: 1515,
		name: "VWANG",
		lga_id: 683,
	},
	{
		id: 1516,
		name: "AYETORO",
		lga_id: 386,
	},
	{
		id: 1517,
		name: "WARAWA",
		lga_id: 42,
	},
	{
		id: 1518,
		name: "IMESI EKITI",
		lga_id: 323,
	},
	{
		id: 1519,
		name: "MADA STATION",
		lga_id: 67,
	},
	{
		id: 1520,
		name: "PAPA",
		lga_id: 586,
	},
	{
		id: 1521,
		name: "BARUNDERI",
		lga_id: 347,
	},
	{
		id: 1522,
		name: "AMANGWU",
		lga_id: 128,
	},
	{
		id: 1523,
		name: "KALOMA",
		lga_id: 69,
	},
	{
		id: 1524,
		name: "OSOLO WAY",
		lga_id: 509,
	},
	{
		id: 1525,
		name: "OKO-OBA, AGEGE",
		lga_id: 240,
	},
	{
		id: 1526,
		name: "IDI IROKO",
		lga_id: 16,
	},
	{
		id: 1527,
		name: "EGBEDA, RIVERS",
		lga_id: 412,
	},
	{
		id: 1528,
		name: "BOLORI",
		lga_id: 693,
	},
	{
		id: 1529,
		name: "OWERRI TOWN",
		lga_id: 318,
	},
	{
		id: 1530,
		name: "MABOLAJE LAYOUT",
		lga_id: 282,
	},
	{
		id: 1531,
		name: "KIRI KASAMMA",
		lga_id: 696,
	},
	{
		id: 1532,
		name: "GWALE",
		lga_id: 703,
	},
	{
		id: 1533,
		name: "POST OFFICE, OREDO",
		lga_id: 508,
	},
	{
		id: 1534,
		name: "OSOSO",
		lga_id: 479,
	},
	{
		id: 1535,
		name: "DAMBAM",
		lga_id: 326,
	},
	{
		id: 1536,
		name: "IWORO",
		lga_id: 10,
	},
	{
		id: 1537,
		name: "NOMANS LAND",
		lga_id: 718,
	},
	{
		id: 1538,
		name: "NUMG NDEM CLAN",
		lga_id: 728,
	},
	{
		id: 1539,
		name: "AZEEZ ADEBAYO",
		lga_id: 282,
	},
	{
		id: 1540,
		name: "OBOSI",
		lga_id: 669,
	},
	{
		id: 1541,
		name: "AGHAROKPE",
		lga_id: 354,
	},
	{
		id: 1542,
		name: "AKANU NGWA",
		lga_id: 528,
	},
	{
		id: 1543,
		name: "IFIRA-AKOKO",
		lga_id: 466,
	},
	{
		id: 1544,
		name: "UMUOKORO EZIAMA",
		lga_id: 676,
	},
	{
		id: 1545,
		name: "MPU",
		lga_id: 422,
	},
	{
		id: 1546,
		name: "AHMADU BELLO STADIUM",
		lga_id: 284,
	},
	{
		id: 1547,
		name: "OBOLLO AFOR",
		lga_id: 276,
	},
	{
		id: 1548,
		name: "ONIWAYA",
		lga_id: 240,
	},
	{
		id: 1549,
		name: "EZZODO",
		lga_id: 243,
	},
	{
		id: 1550,
		name: "ABIA",
		lga_id: 760,
	},
	{
		id: 1551,
		name: "OBOR",
		lga_id: 590,
	},
	{
		id: 1552,
		name: "ILO",
		lga_id: 629,
	},
	{
		id: 1553,
		name: "OKPE IJAJA",
		lga_id: 479,
	},
	{
		id: 1554,
		name: "IKENEGBU LAYOUT",
		lga_id: 318,
	},
	{
		id: 1555,
		name: "KINKINU NEW LAYOUT",
		lga_id: 757,
	},
	{
		id: 1556,
		name: "ADOKA",
		lga_id: 333,
	},
	{
		id: 1557,
		name: "JANGI",
		lga_id: 400,
	},
	{
		id: 1558,
		name: "OKUNOLA",
		lga_id: 577,
	},
	{
		id: 1559,
		name: "UMUNKIRI",
		lga_id: 463,
	},
	{
		id: 1560,
		name: "OKOPEDI",
		lga_id: 587,
	},
	{
		id: 1561,
		name: "ESIT EKET OFFIONG",
		lga_id: 491,
	},
	{
		id: 1562,
		name: "LAGOS LINE MARKET",
		lga_id: 343,
	},
	{
		id: 1563,
		name: "AMAEKPU",
		lga_id: 474,
	},
	{
		id: 1564,
		name: "ORISUNBARE",
		lga_id: 577,
	},
	{
		id: 1565,
		name: "GBAGADA",
		lga_id: 404,
	},
	{
		id: 1566,
		name: "KOKANI",
		lga_id: 172,
	},
	{
		id: 1567,
		name: "IBESIKPO",
		lga_id: 231,
	},
	{
		id: 1568,
		name: "IBEREKODO",
		lga_id: 719,
	},
	{
		id: 1569,
		name: "EKITI STATE UNIVERSITY",
		lga_id: 43,
	},
	{
		id: 1570,
		name: "OWODE EGBA",
		lga_id: 690,
	},
	{
		id: 1571,
		name: "MAKAMA",
		lga_id: 480,
	},
	{
		id: 1572,
		name: "UMUEJE",
		lga_id: 229,
	},
	{
		id: 1573,
		name: "IKU",
		lga_id: 736,
	},
	{
		id: 1574,
		name: "AKUMAZI",
		lga_id: 125,
	},
	{
		id: 1575,
		name: "AGIDINGBI",
		lga_id: 16,
	},
	{
		id: 1576,
		name: "ANGWAR YARI",
		lga_id: 399,
	},
	{
		id: 1577,
		name: "ISI NORTH",
		lga_id: 279,
	},
	{
		id: 1578,
		name: "OKEREMBETE VILLAGE",
		lga_id: 599,
	},
	{
		id: 1579,
		name: "BIRJI",
		lga_id: 712,
	},
	{
		id: 1580,
		name: "KWOI",
		lga_id: 21,
	},
	{
		id: 1581,
		name: "KOGI STATE HOUSE OF ASSEMBLY",
		lga_id: 187,
	},
	{
		id: 1582,
		name: "PAKATA WEST",
		lga_id: 108,
	},
	{
		id: 1583,
		name: "IKEJA GRA",
		lga_id: 16,
	},
	{
		id: 1584,
		name: "PTI ROAD EFFURUN",
		lga_id: 532,
	},
	{
		id: 1585,
		name: "CBD PHASE 1, ABUJA",
		lga_id: 476,
	},
	{
		id: 1586,
		name: "IBRAHIM TAIWO, ILORIN EAST",
		lga_id: 129,
	},
	{
		id: 1587,
		name: "OWINNI ESTATE",
		lga_id: 630,
	},
	{
		id: 1588,
		name: "ISHEAGU",
		lga_id: 744,
	},
	{
		id: 1589,
		name: "AMECHI",
		lga_id: 128,
	},
	{
		id: 1590,
		name: "OGBO MARKET",
		lga_id: 343,
	},
	{
		id: 1591,
		name: "UMUOJI",
		lga_id: 669,
	},
	{
		id: 1592,
		name: "ALAKUKO",
		lga_id: 552,
	},
	{
		id: 1593,
		name: "OLUYOLE EXTENSION IDO",
		lga_id: 85,
	},
	{
		id: 1594,
		name: "ISGOGO DAGO",
		lga_id: 655,
	},
	{
		id: 1595,
		name: "EQUARE EWATTO",
		lga_id: 55,
	},
	{
		id: 1596,
		name: "GALADIMA, YOLA NORTH",
		lga_id: 553,
	},
	{
		id: 1597,
		name: "ABUA",
		lga_id: 392,
	},
	{
		id: 1598,
		name: "BAYAWA",
		lga_id: 46,
	},
	{
		id: 1599,
		name: "ILOROMU",
		lga_id: 599,
	},
	{
		id: 1600,
		name: "MMA 1",
		lga_id: 16,
	},
	{
		id: 1601,
		name: "SEJE",
		lga_id: 10,
	},
	{
		id: 1602,
		name: "SAWMILL EDE",
		lga_id: 268,
	},
	{
		id: 1603,
		name: "MAIYAMA",
		lga_id: 88,
	},
	{
		id: 1604,
		name: "JENGI",
		lga_id: 632,
	},
	{
		id: 1605,
		name: "UMUAJI UMUEZE",
		lga_id: 574,
	},
	{
		id: 1606,
		name: "IBAGWA AKA",
		lga_id: 338,
	},
	{
		id: 1607,
		name: "SAGANUWA PACHI",
		lga_id: 106,
	},
	{
		id: 1608,
		name: "ONIKAN",
		lga_id: 766,
	},
	{
		id: 1609,
		name: "OKPOGA",
		lga_id: 475,
	},
	{
		id: 1610,
		name: "OFUOMA",
		lga_id: 411,
	},
	{
		id: 1611,
		name: "ALA UKWU",
		lga_id: 463,
	},
	{
		id: 1612,
		name: "OKE-ARO, AKURE SOUTH",
		lga_id: 199,
	},
	{
		id: 1613,
		name: "KAMPANNI WAYA",
		lga_id: 493,
	},
	{
		id: 1614,
		name: "UMUENE OBIAGWU",
		lga_id: 676,
	},
	{
		id: 1615,
		name: "PANKSHIN",
		lga_id: 296,
	},
	{
		id: 1616,
		name: "ILORO",
		lga_id: 599,
	},
	{
		id: 1617,
		name: "LEGACY ESTATE",
		lga_id: 372,
	},
	{
		id: 1618,
		name: "ABIAKPO",
		lga_id: 540,
	},
	{
		id: 1619,
		name: "AGBANI, ENUGU SOUTH",
		lga_id: 128,
	},
	{
		id: 1620,
		name: "MAKOKO",
		lga_id: 442,
	},
	{
		id: 1621,
		name: "AKAMKPA",
		lga_id: 177,
	},
	{
		id: 1622,
		name: "MAFOLUKU",
		lga_id: 509,
	},
	{
		id: 1623,
		name: "AKOWONJO",
		lga_id: 577,
	},
	{
		id: 1624,
		name: "GWAGWADA",
		lga_id: 480,
	},
	{
		id: 1625,
		name: "GITATA",
		lga_id: 317,
	},
	{
		id: 1626,
		name: "ARMY CANTONMENT, MARYLAND",
		lga_id: 584,
	},
	{
		id: 1627,
		name: "USSA",
		lga_id: 682,
	},
	{
		id: 1628,
		name: "ONILE-IMO AREA, IWO",
		lga_id: 155,
	},
	{
		id: 1629,
		name: "SYRIAN QUARTER",
		lga_id: 718,
	},
	{
		id: 1630,
		name: "UNIVERSITY OF LAGOS",
		lga_id: 442,
	},
	{
		id: 1631,
		name: "AVU OWERRI",
		lga_id: 189,
	},
	{
		id: 1632,
		name: "KABBA",
		lga_id: 56,
	},
	{
		id: 1633,
		name: "IBEGWA-ANI",
		lga_id: 557,
	},
	{
		id: 1634,
		name: "DOGO AGOGO",
		lga_id: 732,
	},
	{
		id: 1635,
		name: "IFITE IRIDANA",
		lga_id: 772,
	},
	{
		id: 1636,
		name: "SULEJA",
		lga_id: 634,
	},
	{
		id: 1637,
		name: "ILE IFE ROAD",
		lga_id: 713,
	},
	{
		id: 1638,
		name: "GBOGBO",
		lga_id: 18,
	},
	{
		id: 1639,
		name: "PETER ODILI",
		lga_id: 594,
	},
	{
		id: 1640,
		name: "UGBOFU UMUEBU",
		lga_id: 503,
	},
	{
		id: 1641,
		name: "MAYO FARAN",
		lga_id: 401,
	},
	{
		id: 1642,
		name: "WURIANKA RIVER",
		lga_id: 116,
	},
	{
		id: 1643,
		name: "OGBELAKA",
		lga_id: 114,
	},
	{
		id: 1644,
		name: "TRANS AMUSEMENT PARK",
		lga_id: 524,
	},
	{
		id: 1645,
		name: "UBODUNG",
		lga_id: 369,
	},
	{
		id: 1646,
		name: "AUNO",
		lga_id: 287,
	},
	{
		id: 1647,
		name: "ONOGHOLO",
		lga_id: 55,
	},
	{
		id: 1648,
		name: "MIRINGA",
		lga_id: 169,
	},
	{
		id: 1649,
		name: "FINIMA",
		lga_id: 165,
	},
	{
		id: 1650,
		name: "UCH",
		lga_id: 183,
	},
	{
		id: 1651,
		name: "KAJIJI",
		lga_id: 213,
	},
	{
		id: 1652,
		name: "KUFFANG",
		lga_id: 732,
	},
	{
		id: 1653,
		name: "OCHIMA",
		lga_id: 708,
	},
	{
		id: 1654,
		name: "EKWUOMA",
		lga_id: 125,
	},
	{
		id: 1655,
		name: "IBUME",
		lga_id: 186,
	},
	{
		id: 1656,
		name: "ARALOPAN COMMUNITY",
		lga_id: 470,
	},
	{
		id: 1657,
		name: "ODIOLOWO",
		lga_id: 219,
	},
	{
		id: 1658,
		name: "EBUTE-METTA",
		lga_id: 442,
	},
	{
		id: 1659,
		name: "MANCHOK",
		lga_id: 609,
	},
	{
		id: 1660,
		name: "ODO EPE",
		lga_id: 15,
	},
	{
		id: 1661,
		name: "OKPOSI",
		lga_id: 673,
	},
	{
		id: 1662,
		name: "IJERO EKITI",
		lga_id: 678,
	},
	{
		id: 1663,
		name: "KUDAN",
		lga_id: 462,
	},
	{
		id: 1664,
		name: "ADELABU",
		lga_id: 641,
	},
	{
		id: 1665,
		name: "COSTAIN, APAPA",
		lga_id: 526,
	},
	{
		id: 1666,
		name: "TSANYAWA",
		lga_id: 502,
	},
	{
		id: 1667,
		name: "ORIMEDU",
		lga_id: 751,
	},
	{
		id: 1668,
		name: "OKO-EKO",
		lga_id: 18,
	},
	{
		id: 1669,
		name: "ARMY BARRACKS, ENUGU",
		lga_id: 276,
	},
	{
		id: 1670,
		name: "UGWUNCHARA",
		lga_id: 598,
	},
	{
		id: 1671,
		name: "KWEME",
		lga_id: 595,
	},
	{
		id: 1672,
		name: "MONKIN",
		lga_id: 111,
	},
	{
		id: 1673,
		name: "OROKI, OSOGBO",
		lga_id: 394,
	},
	{
		id: 1674,
		name: "WARJI",
		lga_id: 620,
	},
	{
		id: 1675,
		name: "KWALE NDOKWA WEST",
		lga_id: 591,
	},
	{
		id: 1676,
		name: "UMUODE",
		lga_id: 765,
	},
	{
		id: 1677,
		name: "AMUKOKO",
		lga_id: 13,
	},
	{
		id: 1678,
		name: "ALADJA",
		lga_id: 139,
	},
	{
		id: 1679,
		name: "MAIDUGURI STADIUM",
		lga_id: 693,
	},
	{
		id: 1680,
		name: "TEGINA",
		lga_id: 80,
	},
	{
		id: 1681,
		name: "BAURE",
		lga_id: 334,
	},
	{
		id: 1682,
		name: "SHENDAM",
		lga_id: 341,
	},
	{
		id: 1683,
		name: "CENTAL MARKET",
		lga_id: 56,
	},
	{
		id: 1684,
		name: "BODIJA MARKET",
		lga_id: 524,
	},
	{
		id: 1685,
		name: "OGHARA, WARRI SOUTH",
		lga_id: 417,
	},
	{
		id: 1686,
		name: "OBOTEBE",
		lga_id: 743,
	},
	{
		id: 1687,
		name: "OGHENE ROAD",
		lga_id: 754,
	},
	{
		id: 1688,
		name: "IQUITA",
		lga_id: 325,
	},
	{
		id: 1689,
		name: "DEI-DEI",
		lga_id: 476,
	},
	{
		id: 1690,
		name: "AGWANTASHI",
		lga_id: 428,
	},
	{
		id: 1691,
		name: "UMUOCHAM",
		lga_id: 765,
	},
	{
		id: 1692,
		name: "LAKUNDUM",
		lga_id: 124,
	},
	{
		id: 1693,
		name: "ILOGBO",
		lga_id: 228,
	},
	{
		id: 1694,
		name: "ALAUSA",
		lga_id: 584,
	},
	{
		id: 1695,
		name: "ONIONG CLAN",
		lga_id: 728,
	},
	{
		id: 1696,
		name: "NOK",
		lga_id: 21,
	},
	{
		id: 1697,
		name: "NIGER",
		lga_id: 129,
	},
	{
		id: 1698,
		name: "OKE",
		lga_id: 549,
	},
	{
		id: 1699,
		name: "RUFUS GIWA POLYTECHNIC",
		lga_id: 593,
	},
	{
		id: 1700,
		name: "IMO STATE UNIVERSITY",
		lga_id: 741,
	},
	{
		id: 1701,
		name: "ITIRE MUSHIN",
		lga_id: 115,
	},
	{
		id: 1702,
		name: "NTIGHA",
		lga_id: 119,
	},
	{
		id: 1703,
		name: "NASARA ESTATE",
		lga_id: 452,
	},
	{
		id: 1704,
		name: "UZOAGBA",
		lga_id: 63,
	},
	{
		id: 1705,
		name: "AGURA",
		lga_id: 212,
	},
	{
		id: 1706,
		name: "OSARA",
		lga_id: 421,
	},
	{
		id: 1707,
		name: "OKOHIA",
		lga_id: 519,
	},
	{
		id: 1708,
		name: "YENAGOA EPIE",
		lga_id: 167,
	},
	{
		id: 1709,
		name: "FESTAC EXTENSION",
		lga_id: 694,
	},
	{
		id: 1710,
		name: "NKALAGU",
		lga_id: 241,
	},
	{
		id: 1711,
		name: "GRA ONITSHA",
		lga_id: 500,
	},
	{
		id: 1712,
		name: "ADOGO",
		lga_id: 775,
	},
	{
		id: 1713,
		name: "GADA, BUNGUDU",
		lga_id: 34,
	},
	{
		id: 1714,
		name: "UMUAKA",
		lga_id: 96,
	},
	{
		id: 1715,
		name: "LABOZHI",
		lga_id: 400,
	},
	{
		id: 1716,
		name: "UBIUM NORTH",
		lga_id: 635,
	},
	{
		id: 1717,
		name: "SAMINAKA",
		lga_id: 556,
	},
	{
		id: 1718,
		name: "INEN",
		lga_id: 511,
	},
	{
		id: 1719,
		name: "OIL MILL",
		lga_id: 78,
	},
	{
		id: 1720,
		name: "UNIVERSITY OF IBADAN",
		lga_id: 524,
	},
	{
		id: 1721,
		name: "ISOKO",
		lga_id: 494,
	},
	{
		id: 1722,
		name: "IKOTUN, LAGOS",
		lga_id: 577,
	},
	{
		id: 1723,
		name: "URUAN CENTRAL",
		lga_id: 367,
	},
	{
		id: 1724,
		name: "EBE",
		lga_id: 760,
	},
	{
		id: 1725,
		name: "EKOTEDO",
		lga_id: 648,
	},
	{
		id: 1726,
		name: "MANDO IGABI",
		lga_id: 130,
	},
	{
		id: 1727,
		name: "OLOGBA",
		lga_id: 202,
	},
	{
		id: 1728,
		name: "LATAWA",
		lga_id: 378,
	},
	{
		id: 1729,
		name: "APAPA GRA 1",
		lga_id: 13,
	},
	{
		id: 1730,
		name: "RINZE",
		lga_id: 98,
	},
	{
		id: 1731,
		name: "PALMS SHOPPING MALL",
		lga_id: 410,
	},
	{
		id: 1732,
		name: "AGUNBELEWO EXTENSION",
		lga_id: 394,
	},
	{
		id: 1733,
		name: "FOLA-AGORO",
		lga_id: 404,
	},
	{
		id: 1734,
		name: "GASSOL",
		lga_id: 171,
	},
	{
		id: 1735,
		name: "IKEJA GRA",
		lga_id: 584,
	},
	{
		id: 1736,
		name: "EKE AFAHA CLAN",
		lga_id: 491,
	},
	{
		id: 1737,
		name: "AJIBAMIDELE",
		lga_id: 573,
	},
	{
		id: 1738,
		name: "POKA",
		lga_id: 15,
	},
	{
		id: 1739,
		name: "UTE OKPU",
		lga_id: 125,
	},
	{
		id: 1740,
		name: "OSSOMALA",
		lga_id: 353,
	},
	{
		id: 1741,
		name: "YARI",
		lga_id: 716,
	},
	{
		id: 1742,
		name: "BENDE",
		lga_id: 344,
	},
	{
		id: 1743,
		name: "UMUOBOM",
		lga_id: 519,
	},
	{
		id: 1744,
		name: "GATAWA",
		lga_id: 61,
	},
	{
		id: 1745,
		name: "BERE",
		lga_id: 648,
	},
	{
		id: 1746,
		name: "ALESINLOYE",
		lga_id: 648,
	},
	{
		id: 1747,
		name: "BURUTU WARRI SW",
		lga_id: 139,
	},
	{
		id: 1748,
		name: "POWER LINE, OSOGBO",
		lga_id: 394,
	},
	{
		id: 1749,
		name: "AWO",
		lga_id: 711,
	},
	{
		id: 1750,
		name: "IJU, ONDO",
		lga_id: 52,
	},
	{
		id: 1751,
		name: "EGBEADA ESTATE",
		lga_id: 318,
	},
	{
		id: 1752,
		name: "AGBO",
		lga_id: 94,
	},
	{
		id: 1753,
		name: "IYANA OFFA",
		lga_id: 372,
	},
	{
		id: 1754,
		name: "ALFRED REWANE",
		lga_id: 410,
	},
	{
		id: 1755,
		name: "BOLANTA",
		lga_id: 529,
	},
	{
		id: 1756,
		name: "BADAGRY TOWN",
		lga_id: 10,
	},
	{
		id: 1757,
		name: "AJAO ESTATE",
		lga_id: 509,
	},
	{
		id: 1758,
		name: "ATAN IJEBU NORTH-EAST",
		lga_id: 395,
	},
	{
		id: 1759,
		name: "ILUPEJU OYE EKITI",
		lga_id: 328,
	},
	{
		id: 1760,
		name: "OLD MARKET, SOKOTO",
		lga_id: 81,
	},
	{
		id: 1761,
		name: "JAGUNA",
		lga_id: 15,
	},
	{
		id: 1762,
		name: "OLUWOLE",
		lga_id: 766,
	},
	{
		id: 1763,
		name: "FIDITI",
		lga_id: 735,
	},
	{
		id: 1764,
		name: "UTAI",
		lga_id: 306,
	},
	{
		id: 1765,
		name: "TOTO",
		lga_id: 41,
	},
	{
		id: 1766,
		name: "FEDERAL SCHOOL OF SURVEYING",
		lga_id: 670,
	},
	{
		id: 1767,
		name: "UDO ETTE",
		lga_id: 162,
	},
	{
		id: 1768,
		name: "ADAT IFANG",
		lga_id: 269,
	},
	{
		id: 1769,
		name: "MAITUMBI GWADAI",
		lga_id: 97,
	},
	{
		id: 1770,
		name: "MKARANTAR MAIMARI",
		lga_id: 349,
	},
	{
		id: 1771,
		name: "UWOKWU",
		lga_id: 420,
	},
	{
		id: 1772,
		name: "ISHIEKE",
		lga_id: 520,
	},
	{
		id: 1773,
		name: "AJEBO OBAFEMI OWODE",
		lga_id: 690,
	},
	{
		id: 1774,
		name: "MADOBI, SHIRA",
		lga_id: 460,
	},
	{
		id: 1775,
		name: "MBATEREM",
		lga_id: 218,
	},
	{
		id: 1776,
		name: "SABON GARI, MINNA",
		lga_id: 97,
	},
	{
		id: 1777,
		name: "MAI ARI",
		lga_id: 232,
	},
	{
		id: 1778,
		name: "AJEGUNLE SAKI WEST",
		lga_id: 615,
	},
	{
		id: 1779,
		name: "SHASHA IBADAN",
		lga_id: 524,
	},
	{
		id: 1780,
		name: "JESSE",
		lga_id: 161,
	},
	{
		id: 1781,
		name: "OJE QUARTERS",
		lga_id: 125,
	},
	{
		id: 1782,
		name: "UMUOKWARA IHEOMA",
		lga_id: 120,
	},
	{
		id: 1783,
		name: "MAGAMI, JALINGO",
		lga_id: 222,
	},
	{
		id: 1784,
		name: "BIDA",
		lga_id: 234,
	},
	{
		id: 1785,
		name: "IFEKE ORLU",
		lga_id: 542,
	},
	{
		id: 1786,
		name: "IKOT ENUA",
		lga_id: 540,
	},
	{
		id: 1787,
		name: "TSONGA",
		lga_id: 272,
	},
	{
		id: 1788,
		name: "IVOROGBOR",
		lga_id: 494,
	},
	{
		id: 1789,
		name: "GOVERNORS HOUSE",
		lga_id: 187,
	},
	{
		id: 1790,
		name: "YEMETU ALADORIN",
		lga_id: 183,
	},
	{
		id: 1791,
		name: "JANOKE",
		lga_id: 18,
	},
	{
		id: 1792,
		name: "BIOGBOLO",
		lga_id: 167,
	},
	{
		id: 1793,
		name: "ADELEKE UNIVERSITY EDE",
		lga_id: 546,
	},
	{
		id: 1794,
		name: "KASO",
		lga_id: 480,
	},
	{
		id: 1795,
		name: "RUMUOKORO",
		lga_id: 78,
	},
	{
		id: 1796,
		name: "OWODE YEWA",
		lga_id: 680,
	},
	{
		id: 1797,
		name: "IMALEFALAFIA",
		lga_id: 126,
	},
	{
		id: 1798,
		name: "AJEGUNLE OYO EAST",
		lga_id: 670,
	},
	{
		id: 1799,
		name: "ZIKPAK",
		lga_id: 514,
	},
	{
		id: 1800,
		name: "OLODE, IFE",
		lga_id: 383,
	},
	{
		id: 1801,
		name: "DANGOTE CEMENT FACTORY",
		lga_id: 351,
	},
	{
		id: 1802,
		name: "TUDUN WADA PANTAMI",
		lga_id: 347,
	},
	{
		id: 1803,
		name: "OKE IMOLE",
		lga_id: 219,
	},
	{
		id: 1804,
		name: "EMEWA",
		lga_id: 202,
	},
	{
		id: 1805,
		name: "EMEKUKU OWERRI",
		lga_id: 741,
	},
	{
		id: 1806,
		name: "ABAKI",
		lga_id: 471,
	},
	{
		id: 1807,
		name: "EDIDI",
		lga_id: 312,
	},
	{
		id: 1808,
		name: "AMAIMO",
		lga_id: 63,
	},
	{
		id: 1809,
		name: "ETIKAN KINGDOM",
		lga_id: 389,
	},
	{
		id: 1810,
		name: "LATOGUN IJEBU ODE",
		lga_id: 619,
	},
	{
		id: 1811,
		name: "FAGBA",
		lga_id: 552,
	},
	{
		id: 1812,
		name: "D LINE",
		lga_id: 594,
	},
	{
		id: 1813,
		name: "ADAVI",
		lga_id: 421,
	},
	{
		id: 1814,
		name: "PALI",
		lga_id: 232,
	},
	{
		id: 1815,
		name: "AZIA",
		lga_id: 207,
	},
	{
		id: 1816,
		name: "OKE ONITI, OLORUNDA",
		lga_id: 394,
	},
	{
		id: 1817,
		name: "ATTA",
		lga_id: 96,
	},
	{
		id: 1818,
		name: "ABAKPA KADUNA",
		lga_id: 284,
	},
	{
		id: 1819,
		name: "UMUEGBE",
		lga_id: 636,
	},
	{
		id: 1820,
		name: "WOLEX POLYTECHNIC",
		lga_id: 155,
	},
	{
		id: 1821,
		name: "IGBOSERE",
		lga_id: 766,
	},
	{
		id: 1822,
		name: "UMUANYIM",
		lga_id: 676,
	},
	{
		id: 1823,
		name: "UGBOLU TOWN AREA",
		lga_id: 626,
	},
	{
		id: 1824,
		name: "MBARA",
		lga_id: 770,
	},
	{
		id: 1825,
		name: "IGBOHO",
		lga_id: 674,
	},
	{
		id: 1826,
		name: "DUNDAYE",
		lga_id: 521,
	},
	{
		id: 1827,
		name: "GBEDE",
		lga_id: 178,
	},
	{
		id: 1828,
		name: "HUDCO QUARTERS",
		lga_id: 357,
	},
	{
		id: 1829,
		name: "AGUDAMA EPIE",
		lga_id: 167,
	},
	{
		id: 1830,
		name: "GWARAM, GWARAM",
		lga_id: 439,
	},
	{
		id: 1831,
		name: "EZZ INYI MAGU",
		lga_id: 220,
	},
	{
		id: 1832,
		name: "CCECE YARD",
		lga_id: 162,
	},
	{
		id: 1833,
		name: "OCHASI",
		lga_id: 542,
	},
	{
		id: 1834,
		name: "SOUTH UNEME",
		lga_id: 431,
	},
	{
		id: 1835,
		name: "ELIGBOLO",
		lga_id: 78,
	},
	{
		id: 1836,
		name: "STATE HOUSING ESTATE CALABAR",
		lga_id: 559,
	},
	{
		id: 1837,
		name: "FEDERAL POLYTECHNIC NASARAWA",
		lga_id: 335,
	},
	{
		id: 1838,
		name: "AJARA VETHO",
		lga_id: 10,
	},
	{
		id: 1839,
		name: "VICTORY ESTATE",
		lga_id: 17,
	},
	{
		id: 1840,
		name: "INDUSTRIAL LAYOUT, MUBI",
		lga_id: 477,
	},
	{
		id: 1841,
		name: "INDEPENDENCE LAYOUT, ENUGU NORTH",
		lga_id: 197,
	},
	{
		id: 1842,
		name: "ADO",
		lga_id: 18,
	},
	{
		id: 1843,
		name: "SHIKA",
		lga_id: 616,
	},
	{
		id: 1844,
		name: "ERINFUN",
		lga_id: 573,
	},
	{
		id: 1845,
		name: "IKPE ANNANG",
		lga_id: 263,
	},
	{
		id: 1846,
		name: "KAFUR",
		lga_id: 112,
	},
	{
		id: 1847,
		name: "NSIE ATAK",
		lga_id: 587,
	},
	{
		id: 1848,
		name: "OBUNAGU",
		lga_id: 358,
	},
	{
		id: 1849,
		name: "EKPERI",
		lga_id: 431,
	},
	{
		id: 1850,
		name: "OZARA",
		lga_id: 668,
	},
	{
		id: 1851,
		name: "AWADA ONITSHA NORTH",
		lga_id: 500,
	},
	{
		id: 1852,
		name: "BENIN AIRPORT",
		lga_id: 508,
	},
	{
		id: 1853,
		name: "DOGGIRE",
		lga_id: 362,
	},
	{
		id: 1854,
		name: "AGILA",
		lga_id: 506,
	},
	{
		id: 1855,
		name: "WAJE",
		lga_id: 718,
	},
	{
		id: 1856,
		name: "BOURDILLON",
		lga_id: 410,
	},
	{
		id: 1857,
		name: "GIDAN BAHURI",
		lga_id: 81,
	},
	{
		id: 1858,
		name: "IJIO",
		lga_id: 565,
	},
	{
		id: 1859,
		name: "NDENKUM",
		lga_id: 250,
	},
	{
		id: 1860,
		name: "ARDO KOLA",
		lga_id: 504,
	},
	{
		id: 1861,
		name: "IGBO OWU",
		lga_id: 309,
	},
	{
		id: 1862,
		name: "LADIGBOLU ESTATE",
		lga_id: 282,
	},
	{
		id: 1863,
		name: "MILE 2",
		lga_id: 694,
	},
	{
		id: 1864,
		name: "POST SERVICE HOUSING SCHEME, JOS",
		lga_id: 732,
	},
	{
		id: 1865,
		name: "MARABA, ILORIN",
		lga_id: 129,
	},
	{
		id: 1866,
		name: "LOS",
		lga_id: 212,
	},
	{
		id: 1867,
		name: "ILAWE",
		lga_id: 624,
	},
	{
		id: 1868,
		name: "TUNGO",
		lga_id: 245,
	},
	{
		id: 1869,
		name: "OLOSUN AGBONGBE",
		lga_id: 268,
	},
	{
		id: 1870,
		name: "BABARE ZAURE",
		lga_id: 106,
	},
	{
		id: 1871,
		name: "HONG",
		lga_id: 53,
	},
	{
		id: 1872,
		name: "IKODI",
		lga_id: 91,
	},
	{
		id: 1873,
		name: "EBUTE IKORODU",
		lga_id: 212,
	},
	{
		id: 1874,
		name: "OKE ITUNU IBADAN",
		lga_id: 183,
	},
	{
		id: 1875,
		name: "MALLAM AMINU KANO AIRPORT",
		lga_id: 718,
	},
	{
		id: 1876,
		name: "RIMAWA",
		lga_id: 699,
	},
	{
		id: 1877,
		name: "IHEMBOSI",
		lga_id: 773,
	},
	{
		id: 1878,
		name: "AKIN ADESOLA",
		lga_id: 17,
	},
	{
		id: 1879,
		name: "ATIBA",
		lga_id: 630,
	},
	{
		id: 1880,
		name: "CHEVRON ESCRAVOS",
		lga_id: 139,
	},
	{
		id: 1881,
		name: "IJAGBO OFFA",
		lga_id: 99,
	},
	{
		id: 1882,
		name: "OPOJI",
		lga_id: 621,
	},
	{
		id: 1883,
		name: "USHIE",
		lga_id: 591,
	},
	{
		id: 1884,
		name: "GWAGWALADA",
		lga_id: 28,
	},
	{
		id: 1885,
		name: "NASARAWA",
		lga_id: 732,
	},
	{
		id: 1886,
		name: "HANWA HOUSING ESTATE",
		lga_id: 616,
	},
	{
		id: 1887,
		name: "ADEKUNLE AJASIN UNIVERSITY",
		lga_id: 64,
	},
	{
		id: 1888,
		name: "MAHIN KINGDOM",
		lga_id: 389,
	},
	{
		id: 1889,
		name: "OTULU",
		lga_id: 668,
	},
	{
		id: 1890,
		name: "OBOKUN GC",
		lga_id: 720,
	},
	{
		id: 1891,
		name: "MFM CAMP",
		lga_id: 690,
	},
	{
		id: 1892,
		name: "BALANGA",
		lga_id: 150,
	},
	{
		id: 1893,
		name: "BOLARI",
		lga_id: 347,
	},
	{
		id: 1894,
		name: "ILOGBO ARAROMI",
		lga_id: 595,
	},
	{
		id: 1895,
		name: "TAFAWA BALEWA",
		lga_id: 664,
	},
	{
		id: 1896,
		name: "AGBADI",
		lga_id: 357,
	},
	{
		id: 1897,
		name: "ONIDUNDU",
		lga_id: 690,
	},
	{
		id: 1898,
		name: "OGBESO",
		lga_id: 114,
	},
	{
		id: 1899,
		name: "ZOGIRMA",
		lga_id: 612,
	},
	{
		id: 1900,
		name: "ALOR",
		lga_id: 679,
	},
	{
		id: 1901,
		name: "LANGBASA",
		lga_id: 17,
	},
	{
		id: 1902,
		name: "OGBAKU, MBAITOLI",
		lga_id: 37,
	},
	{
		id: 1903,
		name: "OLO",
		lga_id: 613,
	},
	{
		id: 1904,
		name: "GIDAN MAJI",
		lga_id: 293,
	},
	{
		id: 1905,
		name: "UMUENE ALAOMA",
		lga_id: 474,
	},
	{
		id: 1906,
		name: "IBERE, IKORODU",
		lga_id: 18,
	},
	{
		id: 1907,
		name: "OHAZU",
		lga_id: 590,
	},
	{
		id: 1908,
		name: "OJUORE",
		lga_id: 629,
	},
	{
		id: 1909,
		name: "LILU",
		lga_id: 207,
	},
	{
		id: 1910,
		name: "UKPUM ETE",
		lga_id: 432,
	},
	{
		id: 1911,
		name: "ARMY BARRACKS, BIDA",
		lga_id: 374,
	},
	{
		id: 1912,
		name: "GINI",
		lga_id: 175,
	},
	{
		id: 1913,
		name: "TAKWA BAY",
		lga_id: 526,
	},
	{
		id: 1914,
		name: "GANWAZA",
		lga_id: 262,
	},
	{
		id: 1915,
		name: "MBIABONG",
		lga_id: 685,
	},
	{
		id: 1916,
		name: "SANGOTEDO",
		lga_id: 410,
	},
	{
		id: 1917,
		name: "TECHNICAL COLLEGE JUNCTION IFE",
		lga_id: 152,
	},
	{
		id: 1918,
		name: "ISIEKENESI",
		lga_id: 519,
	},
	{
		id: 1919,
		name: "AMAMPUTU",
		lga_id: 288,
	},
	{
		id: 1920,
		name: "OZAM",
		lga_id: 704,
	},
	{
		id: 1921,
		name: "IKOT ANSA",
		lga_id: 559,
	},
	{
		id: 1922,
		name: "OFATEDO",
		lga_id: 711,
	},
	{
		id: 1923,
		name: "OJIBOGO OKENYI",
		lga_id: 93,
	},
	{
		id: 1924,
		name: "LAMBA",
		lga_id: 644,
	},
	{
		id: 1925,
		name: "URUM",
		lga_id: 730,
	},
	{
		id: 1926,
		name: "ADEYEMII, ONDO",
		lga_id: 285,
	},
	{
		id: 1927,
		name: "KIRI, ILLELA",
		lga_id: 35,
	},
	{
		id: 1928,
		name: "KUTO",
		lga_id: 118,
	},
	{
		id: 1929,
		name: "ISOLO",
		lga_id: 509,
	},
	{
		id: 1930,
		name: "AGBARO",
		lga_id: 411,
	},
	{
		id: 1931,
		name: "DAN MUSA",
		lga_id: 695,
	},
	{
		id: 1932,
		name: "ODOGUNYAN",
		lga_id: 212,
	},
	{
		id: 1933,
		name: "JAL",
		lga_id: 622,
	},
	{
		id: 1934,
		name: "AKOLI",
		lga_id: 100,
	},
	{
		id: 1935,
		name: "AYEKA",
		lga_id: 248,
	},
	{
		id: 1936,
		name: "IGBUDU",
		lga_id: 627,
	},
	{
		id: 1937,
		name: "AGUMAGU, ANKPA",
		lga_id: 93,
	},
	{
		id: 1938,
		name: "OMAGBA",
		lga_id: 500,
	},
	{
		id: 1939,
		name: "OKPUNO AWKA",
		lga_id: 358,
	},
	{
		id: 1940,
		name: "ASAMU",
		lga_id: 576,
	},
	{
		id: 1941,
		name: "GOLDIE",
		lga_id: 559,
	},
	{
		id: 1942,
		name: "FATE SOUTH",
		lga_id: 316,
	},
	{
		id: 1943,
		name: "ISIOKWU STREET",
		lga_id: 500,
	},
	{
		id: 1944,
		name: "MAITAMA EXTENSION",
		lga_id: 476,
	},
	{
		id: 1945,
		name: "AYEDE, OYE",
		lga_id: 328,
	},
	{
		id: 1946,
		name: "AGBASHI",
		lga_id: 180,
	},
	{
		id: 1947,
		name: "EZINIFITE",
		lga_id: 170,
	},
	{
		id: 1948,
		name: "ELEYELE LAYOUT",
		lga_id: 152,
	},
	{
		id: 1949,
		name: "DANJA",
		lga_id: 158,
	},
	{
		id: 1950,
		name: "AFIKPO SOUTH",
		lga_id: 253,
	},
	{
		id: 1951,
		name: "ATA OJA PALACE",
		lga_id: 219,
	},
	{
		id: 1952,
		name: "ISUANIOCHA",
		lga_id: 730,
	},
	{
		id: 1953,
		name: "NNEATO",
		lga_id: 27,
	},
	{
		id: 1954,
		name: "UMUAFOR",
		lga_id: 463,
	},
	{
		id: 1955,
		name: "ODUAHA",
		lga_id: 492,
	},
	{
		id: 1956,
		name: "ODOAKPU ONITSHA SOUTH",
		lga_id: 343,
	},
	{
		id: 1957,
		name: "ELEME",
		lga_id: 251,
	},
	{
		id: 1958,
		name: "OGU, OGU/BOLO",
		lga_id: 275,
	},
	{
		id: 1959,
		name: "ONIKOKORO",
		lga_id: 524,
	},
	{
		id: 1960,
		name: "AMANDUGBA ISU",
		lga_id: 742,
	},
	{
		id: 1961,
		name: "CHAMPION BREWERY",
		lga_id: 162,
	},
	{
		id: 1962,
		name: "OKON CLAN EKET",
		lga_id: 51,
	},
	{
		id: 1963,
		name: "ARMY BARRACKS, UNGOGO",
		lga_id: 142,
	},
	{
		id: 1964,
		name: "KULODE ESTATE",
		lga_id: 607,
	},
	{
		id: 1965,
		name: "UGBODO ECHARA",
		lga_id: 627,
	},
	{
		id: 1966,
		name: "ACHI",
		lga_id: 760,
	},
	{
		id: 1967,
		name: "BORI",
		lga_id: 249,
	},
	{
		id: 1968,
		name: "AGBA",
		lga_id: 515,
	},
	{
		id: 1969,
		name: "IKOYI, ILE-IFE",
		lga_id: 152,
	},
	{
		id: 1970,
		name: "ZAGGA",
		lga_id: 255,
	},
	{
		id: 1971,
		name: "KIRIKIRI",
		lga_id: 694,
	},
	{
		id: 1972,
		name: "ISE ORUN",
		lga_id: 456,
	},
	{
		id: 1973,
		name: "OGOGORO",
		lga_id: 751,
	},
	{
		id: 1974,
		name: "IWOROKO",
		lga_id: 43,
	},
	{
		id: 1975,
		name: "FEDERAL POLYTECHNIC EDE",
		lga_id: 268,
	},
	{
		id: 1976,
		name: "IMO QUARTERS",
		lga_id: 470,
	},
	{
		id: 1977,
		name: "KARARA",
		lga_id: 187,
	},
	{
		id: 1978,
		name: "IWAYA",
		lga_id: 442,
	},
	{
		id: 1979,
		name: "GEIDAM",
		lga_id: 478,
	},
	{
		id: 1980,
		name: "INDUSTRIAL LAYOUT MAKURDI",
		lga_id: 357,
	},
	{
		id: 1981,
		name: "OMUGO",
		lga_id: 309,
	},
	{
		id: 1982,
		name: "IDALU",
		lga_id: 697,
	},
	{
		id: 1983,
		name: "OWELU TOWN",
		lga_id: 741,
	},
	{
		id: 1984,
		name: "GANGARE",
		lga_id: 732,
	},
	{
		id: 1985,
		name: "ONITSHA RIVER PORT",
		lga_id: 343,
	},
	{
		id: 1986,
		name: "GARIN JERENG",
		lga_id: 401,
	},
	{
		id: 1987,
		name: "OLD BODIJA",
		lga_id: 183,
	},
	{
		id: 1988,
		name: "EAGLE ISLAND",
		lga_id: 594,
	},
	{
		id: 1989,
		name: "IJAN EKITI",
		lga_id: 323,
	},
	{
		id: 1990,
		name: "BORRONG",
		lga_id: 217,
	},
	{
		id: 1991,
		name: "ABULE IBAAMO",
		lga_id: 751,
	},
	{
		id: 1992,
		name: "EZIOKPOR",
		lga_id: 503,
	},
	{
		id: 1993,
		name: "RIBI",
		lga_id: 186,
	},
	{
		id: 1994,
		name: "ITORI",
		lga_id: 572,
	},
	{
		id: 1995,
		name: "ABDUL AZEEZ WEST",
		lga_id: 108,
	},
	{
		id: 1996,
		name: "MGBUOSIMINI",
		lga_id: 78,
	},
	{
		id: 1997,
		name: "EFFIUM",
		lga_id: 642,
	},
	{
		id: 1998,
		name: "UMUEZE UMUHU",
		lga_id: 676,
	},
	{
		id: 1999,
		name: "EBBO",
		lga_id: 556,
	},
	{
		id: 2000,
		name: "BOJI-BOJI AGBOR",
		lga_id: 441,
	},
	{
		id: 2001,
		name: "BUKKUYUM",
		lga_id: 473,
	},
	{
		id: 2002,
		name: "PARKVIEW ESTATE",
		lga_id: 17,
	},
	{
		id: 2003,
		name: "MVOSI",
		lga_id: 474,
	},
	{
		id: 2004,
		name: "SANYINWA",
		lga_id: 423,
	},
	{
		id: 2005,
		name: "BEHIND GOLF CLUB , DELSU",
		lga_id: 574,
	},
	{
		id: 2006,
		name: "NKUKWO UNUBI",
		lga_id: 170,
	},
	{
		id: 2007,
		name: "MMA 2",
		lga_id: 584,
	},
	{
		id: 2008,
		name: "FEGGE HOSUING ESTATE",
		lga_id: 343,
	},
	{
		id: 2009,
		name: "OGOR",
		lga_id: 411,
	},
	{
		id: 2010,
		name: "ALAGBADO ALIMOSHO",
		lga_id: 577,
	},
	{
		id: 2011,
		name: "AFONTA MARKET",
		lga_id: 519,
	},
	{
		id: 2012,
		name: "ONITIRI",
		lga_id: 442,
	},
	{
		id: 2013,
		name: "EGUP IPA",
		lga_id: 30,
	},
	{
		id: 2014,
		name: "PARAKIN EXTENSION",
		lga_id: 152,
	},
	{
		id: 2015,
		name: "KOLOKUMA OPOKUMA",
		lga_id: 570,
	},
	{
		id: 2016,
		name: "OJOTO",
		lga_id: 679,
	},
	{
		id: 2017,
		name: "KWAREN GANUWA",
		lga_id: 527,
	},
	{
		id: 2018,
		name: "ARULOGUN AJEGUNLE",
		lga_id: 268,
	},
	{
		id: 2019,
		name: "WUSE 1 ZONE 7",
		lga_id: 476,
	},
	{
		id: 2020,
		name: "AJAKA AREA",
		lga_id: 378,
	},
	{
		id: 2021,
		name: "KASUWAR KURMI",
		lga_id: 182,
	},
	{
		id: 2022,
		name: "ASHAKA, NDOKWA EAST",
		lga_id: 591,
	},
	{
		id: 2023,
		name: "BURSARI",
		lga_id: 758,
	},
	{
		id: 2024,
		name: "KOIDA",
		lga_id: 97,
	},
	{
		id: 2025,
		name: "GBOGBO",
		lga_id: 212,
	},
	{
		id: 2026,
		name: "OFFA",
		lga_id: 215,
	},
	{
		id: 2027,
		name: "EZILLO",
		lga_id: 241,
	},
	{
		id: 2028,
		name: "CHIBIANI",
		lga_id: 175,
	},
	{
		id: 2029,
		name: "ISUA-AKOKO",
		lga_id: 466,
	},
	{
		id: 2030,
		name: "AYU",
		lga_id: 117,
	},
	{
		id: 2031,
		name: "KOPA",
		lga_id: 374,
	},
	{
		id: 2032,
		name: "ORA, IFEDAYO",
		lga_id: 359,
	},
	{
		id: 2033,
		name: "SABON GARI, DAURA",
		lga_id: 181,
	},
	{
		id: 2034,
		name: "ARIARIA",
		lga_id: 590,
	},
	{
		id: 2035,
		name: "AFARA, UMUAHIA NORTH",
		lga_id: 598,
	},
	{
		id: 2036,
		name: "IJESA ISU",
		lga_id: 315,
	},
	{
		id: 2037,
		name: "EGBEMA",
		lga_id: 244,
	},
	{
		id: 2038,
		name: "AKA AVENUE",
		lga_id: 532,
	},
	{
		id: 2039,
		name: "IMESI ILE",
		lga_id: 203,
	},
	{
		id: 2040,
		name: "UTUH",
		lga_id: 170,
	},
	{
		id: 2041,
		name: "IGRITA UMUOKO ROAD",
		lga_id: 611,
	},
	{
		id: 2042,
		name: "NKWO UMUAGAGBA",
		lga_id: 688,
	},
	{
		id: 2043,
		name: "TANGLANG",
		lga_id: 107,
	},
	{
		id: 2044,
		name: "OWODE LOW COST ESTATE",
		lga_id: 219,
	},
	{
		id: 2045,
		name: "MAIRI",
		lga_id: 671,
	},
	{
		id: 2046,
		name: "UNDER G, OGBOMOSHO",
		lga_id: 529,
	},
	{
		id: 2047,
		name: "KONTAGORA",
		lga_id: 493,
	},
	{
		id: 2048,
		name: "AYETORO ITELE",
		lga_id: 629,
	},
	{
		id: 2049,
		name: "JADA",
		lga_id: 262,
	},
	{
		id: 2050,
		name: "TELA BALA",
		lga_id: 776,
	},
	{
		id: 2051,
		name: "MAISANDARI",
		lga_id: 259,
	},
	{
		id: 2052,
		name: "OKE IJEBU",
		lga_id: 199,
	},
	{
		id: 2053,
		name: "BABA ODE",
		lga_id: 86,
	},
	{
		id: 2054,
		name: "SALEM UNIVERSITY",
		lga_id: 775,
	},
	{
		id: 2055,
		name: "AKESAN MARKET",
		lga_id: 282,
	},
	{
		id: 2056,
		name: "OGBUNIKE",
		lga_id: 717,
	},
	{
		id: 2057,
		name: "MAJEMA",
		lga_id: 290,
	},
	{
		id: 2058,
		name: "UGHEDE",
		lga_id: 23,
	},
	{
		id: 2059,
		name: "OGBAFU, OGUTA",
		lga_id: 288,
	},
	{
		id: 2060,
		name: "AKINYEMI",
		lga_id: 126,
	},
	{
		id: 2061,
		name: "SAGAMU GRA",
		lga_id: 378,
	},
	{
		id: 2062,
		name: "AFIAMA",
		lga_id: 499,
	},
	{
		id: 2063,
		name: "GWADABE",
		lga_id: 97,
	},
	{
		id: 2064,
		name: "UYIEV",
		lga_id: 277,
	},
	{
		id: 2065,
		name: "OGBE IJOH",
		lga_id: 139,
	},
	{
		id: 2066,
		name: "TUDUN NUFAWA",
		lga_id: 284,
	},
	{
		id: 2067,
		name: "KOBI",
		lga_id: 755,
	},
	{
		id: 2068,
		name: "ALVAN IKOKU FCE",
		lga_id: 318,
	},
	{
		id: 2069,
		name: "EDENYIN",
		lga_id: 266,
	},
	{
		id: 2070,
		name: "AKPERAN ORSHI COLLEGE OF AGRICULTURE",
		lga_id: 351,
	},
	{
		id: 2071,
		name: "ULAYI",
		lga_id: 506,
	},
	{
		id: 2072,
		name: "IPAV",
		lga_id: 351,
	},
	{
		id: 2073,
		name: "OBAFEMI AWOLOWO UNIVERSITY",
		lga_id: 152,
	},
	{
		id: 2074,
		name: "GANI, SUMAILA",
		lga_id: 416,
	},
	{
		id: 2075,
		name: "OKPE ROAD",
		lga_id: 754,
	},
	{
		id: 2076,
		name: "UHUNA OWEERE",
		lga_id: 243,
	},
	{
		id: 2077,
		name: "NEKEDE EXTENSION",
		lga_id: 318,
	},
	{
		id: 2078,
		name: "UKPOM OKOM",
		lga_id: 432,
	},
	{
		id: 2079,
		name: "OJERE",
		lga_id: 690,
	},
	{
		id: 2080,
		name: "METROLOGICAL DEPARTMENT HADEJIA",
		lga_id: 290,
	},
	{
		id: 2081,
		name: "FALAWO",
		lga_id: 378,
	},
	{
		id: 2082,
		name: "UMUDOBIA",
		lga_id: 658,
	},
	{
		id: 2083,
		name: "TARAUNI",
		lga_id: 236,
	},
	{
		id: 2084,
		name: "NRI",
		lga_id: 387,
	},
	{
		id: 2085,
		name: "GURI",
		lga_id: 600,
	},
	{
		id: 2086,
		name: "G.R.A. ASA VILLAGE",
		lga_id: 333,
	},
	{
		id: 2087,
		name: "UMUORU",
		lga_id: 463,
	},
	{
		id: 2088,
		name: "IGBAYE",
		lga_id: 771,
	},
	{
		id: 2089,
		name: "OTUKPO NOBI",
		lga_id: 333,
	},
	{
		id: 2090,
		name: "ITESI",
		lga_id: 356,
	},
	{
		id: 2091,
		name: "WASAGU",
		lga_id: 409,
	},
	{
		id: 2092,
		name: "LOW COST KADUNA",
		lga_id: 480,
	},
	{
		id: 2093,
		name: "OWUKPA",
		lga_id: 564,
	},
	{
		id: 2094,
		name: "EBUTE-LEKKI",
		lga_id: 751,
	},
	{
		id: 2095,
		name: "NDIOKOLO",
		lga_id: 320,
	},
	{
		id: 2096,
		name: "AIYEPE",
		lga_id: 159,
	},
	{
		id: 2097,
		name: "BUILDING MATERIAL MARKET NKPOR",
		lga_id: 669,
	},
	{
		id: 2098,
		name: "SEDECO",
		lga_id: 532,
	},
	{
		id: 2099,
		name: "OLORUNDA AREA, OSOGBO",
		lga_id: 219,
	},
	{
		id: 2100,
		name: "KAPANA",
		lga_id: 175,
	},
	{
		id: 2101,
		name: "NDUGBA",
		lga_id: 742,
	},
	{
		id: 2102,
		name: "CEMENT, LAGOS",
		lga_id: 16,
	},
	{
		id: 2103,
		name: "NORTHERN FORESHORE ESTATE",
		lga_id: 17,
	},
	{
		id: 2104,
		name: "ALAGBADO IFAKO",
		lga_id: 552,
	},
	{
		id: 2105,
		name: "MABERA GIDAN GODOBE",
		lga_id: 450,
	},
	{
		id: 2106,
		name: "AZUIYI EDENE",
		lga_id: 68,
	},
	{
		id: 2107,
		name: "NGWA GIMBIA",
		lga_id: 480,
	},
	{
		id: 2108,
		name: "ASA SOUTH",
		lga_id: 658,
	},
	{
		id: 2109,
		name: "MUSLIM",
		lga_id: 173,
	},
	{
		id: 2110,
		name: "IRESE",
		lga_id: 18,
	},
	{
		id: 2111,
		name: "PAI, BOSSO",
		lga_id: 19,
	},
	{
		id: 2112,
		name: "OHAFOR OHAFIA",
		lga_id: 522,
	},
	{
		id: 2113,
		name: "KAFIN HAUSA",
		lga_id: 174,
	},
	{
		id: 2114,
		name: "DONG, DEMSA",
		lga_id: 217,
	},
	{
		id: 2115,
		name: "NEW LAYOUT PH",
		lga_id: 594,
	},
	{
		id: 2116,
		name: "UMUBOCHI ROAD",
		lga_id: 77,
	},
	{
		id: 2117,
		name: "ILERO",
		lga_id: 303,
	},
	{
		id: 2118,
		name: "ALAGBAKA",
		lga_id: 52,
	},
	{
		id: 2119,
		name: "GOVERNMENT HOUSE KANO",
		lga_id: 718,
	},
	{
		id: 2120,
		name: "EGBEDA, LAGOS",
		lga_id: 577,
	},
	{
		id: 2121,
		name: "IBASSA",
		lga_id: 694,
	},
	{
		id: 2122,
		name: "TSADUKASHI",
		lga_id: 106,
	},
	{
		id: 2123,
		name: "SANGO OTTA",
		lga_id: 629,
	},
	{
		id: 2124,
		name: "ASANG CLAN",
		lga_id: 294,
	},
	{
		id: 2125,
		name: "UHUMWUIDUM",
		lga_id: 508,
	},
	{
		id: 2126,
		name: "GOVERNMENT HOUSE GOMBE",
		lga_id: 347,
	},
	{
		id: 2127,
		name: "DUGURI",
		lga_id: 232,
	},
	{
		id: 2128,
		name: "ACHINA",
		lga_id: 747,
	},
	{
		id: 2129,
		name: "DANDAGARO KATSINA",
		lga_id: 560,
	},
	{
		id: 2130,
		name: "MAKERA, BIRNIN KEBBI",
		lga_id: 706,
	},
	{
		id: 2131,
		name: "AGBURA",
		lga_id: 256,
	},
	{
		id: 2132,
		name: "AGO IWOYE",
		lga_id: 184,
	},
	{
		id: 2133,
		name: "SAKABA",
		lga_id: 496,
	},
	{
		id: 2134,
		name: "COMPUTER VILLAGE, LAGOS",
		lga_id: 16,
	},
	{
		id: 2135,
		name: "AFAHA CLAN",
		lga_id: 263,
	},
	{
		id: 2136,
		name: "KUFANI",
		lga_id: 273,
	},
	{
		id: 2137,
		name: "KABOWEI",
		lga_id: 345,
	},
	{
		id: 2138,
		name: "UKWONG",
		lga_id: 369,
	},
	{
		id: 2139,
		name: "ALAGBAGBA",
		lga_id: 356,
	},
	{
		id: 2140,
		name: "EBONYI UNIVERSITY MAIN CAMPUS",
		lga_id: 68,
	},
	{
		id: 2141,
		name: "NSUGBE",
		lga_id: 499,
	},
	{
		id: 2142,
		name: "AKUFO",
		lga_id: 85,
	},
	{
		id: 2143,
		name: "AJAGBA",
		lga_id: 576,
	},
	{
		id: 2144,
		name: "ADO AWAYE",
		lga_id: 748,
	},
	{
		id: 2145,
		name: "OKPARA WATERSIDE",
		lga_id: 438,
	},
	{
		id: 2146,
		name: "NKPA",
		lga_id: 344,
	},
	{
		id: 2147,
		name: "ODI",
		lga_id: 570,
	},
	{
		id: 2148,
		name: "AREPO",
		lga_id: 690,
	},
	{
		id: 2149,
		name: "PANKO",
		lga_id: 10,
	},
	{
		id: 2150,
		name: "AJEGUNLE IFAKO",
		lga_id: 552,
	},
	{
		id: 2151,
		name: "KWANGILA",
		lga_id: 97,
	},
	{
		id: 2152,
		name: "RIJAU",
		lga_id: 235,
	},
	{
		id: 2153,
		name: "UMUKALIKA",
		lga_id: 463,
	},
	{
		id: 2154,
		name: "IPOLE",
		lga_id: 227,
	},
	{
		id: 2155,
		name: "YABATECH",
		lga_id: 442,
	},
	{
		id: 2156,
		name: "BURUTU",
		lga_id: 743,
	},
	{
		id: 2157,
		name: "ADA BORIPE",
		lga_id: 515,
	},
	{
		id: 2158,
		name: "JATTU",
		lga_id: 697,
	},
	{
		id: 2159,
		name: "NGWAOBI",
		lga_id: 474,
	},
	{
		id: 2160,
		name: "JEDDO COMMUNITY",
		lga_id: 354,
	},
	{
		id: 2161,
		name: "HALLELEUYAH ESTATE",
		lga_id: 711,
	},
	{
		id: 2162,
		name: "SABON GIDA, JOS",
		lga_id: 292,
	},
	{
		id: 2163,
		name: "FUFORE",
		lga_id: 755,
	},
	{
		id: 2164,
		name: "IYALAJE AREA",
		lga_id: 285,
	},
	{
		id: 2165,
		name: "ODO EPE",
		lga_id: 541,
	},
	{
		id: 2166,
		name: "NABI",
		lga_id: 175,
	},
	{
		id: 2167,
		name: "NASSARAWA, FUNTUA",
		lga_id: 122,
	},
	{
		id: 2168,
		name: "MARU",
		lga_id: 451,
	},
	{
		id: 2169,
		name: "ISALA",
		lga_id: 714,
	},
	{
		id: 2170,
		name: "OGUI NEW LAYOUT",
		lga_id: 197,
	},
	{
		id: 2171,
		name: "MOSAFEJO",
		lga_id: 595,
	},
	{
		id: 2172,
		name: "AGIP, PH",
		lga_id: 78,
	},
	{
		id: 2173,
		name: "MAGAGUM",
		lga_id: 609,
	},
	{
		id: 2174,
		name: "DADIN KOWA, JOS SOUTH",
		lga_id: 683,
	},
	{
		id: 2175,
		name: "AMUMARA",
		lga_id: 134,
	},
	{
		id: 2176,
		name: "MBABAI",
		lga_id: 277,
	},
	{
		id: 2177,
		name: "UKANA WEST CLAN",
		lga_id: 263,
	},
	{
		id: 2178,
		name: "IJOKO IFO",
		lga_id: 549,
	},
	{
		id: 2179,
		name: "ASA TOWN",
		lga_id: 576,
	},
	{
		id: 2180,
		name: "CHINA TOWN, ENUGU",
		lga_id: 197,
	},
	{
		id: 2181,
		name: "FARINU AREA ILE-IFE",
		lga_id: 713,
	},
	{
		id: 2182,
		name: "UDUK USUNG",
		lga_id: 376,
	},
	{
		id: 2183,
		name: "EKORO",
		lga_id: 577,
	},
	{
		id: 2184,
		name: "ODO ONA",
		lga_id: 85,
	},
	{
		id: 2185,
		name: "ONIDUNDU AKINYELE",
		lga_id: 524,
	},
	{
		id: 2186,
		name: "AKWUDO",
		lga_id: 327,
	},
	{
		id: 2187,
		name: "IBADO AKPACHA",
		lga_id: 113,
	},
	{
		id: 2188,
		name: "DEKINA",
		lga_id: 202,
	},
	{
		id: 2189,
		name: "ERIN",
		lga_id: 109,
	},
	{
		id: 2190,
		name: "KOFAR KAURA",
		lga_id: 399,
	},
	{
		id: 2191,
		name: "RUMUKURUSHI",
		lga_id: 78,
	},
	{
		id: 2192,
		name: "OLUWO",
		lga_id: 118,
	},
	{
		id: 2193,
		name: "OGBODUABA",
		lga_id: 753,
	},
	{
		id: 2194,
		name: "AMUZUKWU",
		lga_id: 598,
	},
	{
		id: 2195,
		name: "BIRNIN MAGAJI",
		lga_id: 208,
	},
	{
		id: 2196,
		name: "MAGAMA GUMAU",
		lga_id: 489,
	},
	{
		id: 2197,
		name: "EJIRIN",
		lga_id: 541,
	},
	{
		id: 2198,
		name: "OKIGWE PARK",
		lga_id: 598,
	},
	{
		id: 2199,
		name: "UNGUWAR KAI KALWA",
		lga_id: 425,
	},
	{
		id: 2200,
		name: "SABON GAYAN",
		lga_id: 480,
	},
	{
		id: 2201,
		name: "IGBOUGHUI",
		lga_id: 428,
	},
	{
		id: 2202,
		name: "OBU EZE",
		lga_id: 358,
	},
	{
		id: 2203,
		name: "EKIADOLOR",
		lga_id: 393,
	},
	{
		id: 2204,
		name: "OBIMA",
		lga_id: 760,
	},
	{
		id: 2205,
		name: "NGURORE",
		lga_id: 553,
	},
	{
		id: 2206,
		name: "DDPA",
		lga_id: 417,
	},
	{
		id: 2207,
		name: "UZERE",
		lga_id: 494,
	},
	{
		id: 2208,
		name: "UMU ITODO",
		lga_id: 243,
	},
	{
		id: 2209,
		name: "GIDAN KUKA",
		lga_id: 97,
	},
	{
		id: 2210,
		name: "IKEREKU",
		lga_id: 524,
	},
	{
		id: 2211,
		name: "UGBEKUN",
		lga_id: 114,
	},
	{
		id: 2212,
		name: "TAMBAFTRI VILLAGE",
		lga_id: 653,
	},
	{
		id: 2213,
		name: "TUDUN JUKUN SABON GARI",
		lga_id: 616,
	},
	{
		id: 2214,
		name: "OJULOKUN",
		lga_id: 15,
	},
	{
		id: 2215,
		name: "NSUKWA",
		lga_id: 744,
	},
	{
		id: 2216,
		name: "GOVT HOUSE KATSINA",
		lga_id: 399,
	},
	{
		id: 2217,
		name: "UTUR",
		lga_id: 746,
	},
	{
		id: 2218,
		name: "SHAO",
		lga_id: 36,
	},
	{
		id: 2219,
		name: "KUJE, MUNICIPAL AREA COUNCIL",
		lga_id: 476,
	},
	{
		id: 2220,
		name: "OTORO ABAK",
		lga_id: 540,
	},
	{
		id: 2221,
		name: "MOWO",
		lga_id: 10,
	},
	{
		id: 2222,
		name: "SONG",
		lga_id: 368,
	},
	{
		id: 2223,
		name: "IKOT OBIO EMA",
		lga_id: 156,
	},
	{
		id: 2224,
		name: "EYENKORIN",
		lga_id: 193,
	},
	{
		id: 2225,
		name: "OMUO EKITI RURAL",
		lga_id: 75,
	},
	{
		id: 2226,
		name: "IBEJU",
		lga_id: 751,
	},
	{
		id: 2227,
		name: "ADUMU",
		lga_id: 731,
	},
	{
		id: 2228,
		name: "UTU IKOT",
		lga_id: 162,
	},
	{
		id: 2229,
		name: "WAREHOUSE",
		lga_id: 13,
	},
	{
		id: 2230,
		name: "AGU AKPU",
		lga_id: 68,
	},
	{
		id: 2231,
		name: "KANKO",
		lga_id: 427,
	},
	{
		id: 2232,
		name: "ADAMAWA STATE UNIVERSITY",
		lga_id: 477,
	},
	{
		id: 2233,
		name: "BOJE ABO",
		lga_id: 190,
	},
	{
		id: 2234,
		name: "ILUOMOBA EKITI",
		lga_id: 323,
	},
	{
		id: 2235,
		name: "HYEMAH",
		lga_id: 53,
	},
	{
		id: 2236,
		name: "KETERE",
		lga_id: 629,
	},
	{
		id: 2237,
		name: "GWANTU",
		lga_id: 117,
	},
	{
		id: 2238,
		name: "LAFIA GRA",
		lga_id: 452,
	},
	{
		id: 2239,
		name: "EMEYAL",
		lga_id: 256,
	},
	{
		id: 2240,
		name: "WADATA",
		lga_id: 357,
	},
	{
		id: 2241,
		name: "SANGO INDUSTIAL AREA",
		lga_id: 629,
	},
	{
		id: 2242,
		name: "PAKI",
		lga_id: 200,
	},
	{
		id: 2243,
		name: "ODE AGBALA",
		lga_id: 529,
	},
	{
		id: 2244,
		name: "OLODO, OGUN",
		lga_id: 356,
	},
	{
		id: 2245,
		name: "IDEMUJE UNOR",
		lga_id: 370,
	},
	{
		id: 2246,
		name: "OKE UREJE EXTENSION",
		lga_id: 573,
	},
	{
		id: 2247,
		name: "EMUREN",
		lga_id: 18,
	},
	{
		id: 2248,
		name: "AMAIGBO NKWERRE",
		lga_id: 192,
	},
	{
		id: 2249,
		name: "OFODUAADUN",
		lga_id: 48,
	},
	{
		id: 2250,
		name: "IJAIYE IFAKO",
		lga_id: 552,
	},
	{
		id: 2251,
		name: "ANAKU",
		lga_id: 229,
	},
	{
		id: 2252,
		name: "BADON BARDE",
		lga_id: 521,
	},
	{
		id: 2253,
		name: "LEKKI FREE TRADE ZONE",
		lga_id: 15,
	},
	{
		id: 2254,
		name: "OLORUNDA, ILESA",
		lga_id: 720,
	},
	{
		id: 2255,
		name: "LASU",
		lga_id: 228,
	},
	{
		id: 2256,
		name: "GLOVER RD IKOYI",
		lga_id: 410,
	},
	{
		id: 2257,
		name: "BADEGGI",
		lga_id: 374,
	},
	{
		id: 2258,
		name: "CREEK RD, APAPA",
		lga_id: 526,
	},
	{
		id: 2259,
		name: "LAGOS ROAD, KADUNA",
		lga_id: 480,
	},
	{
		id: 2260,
		name: "SHOGUNLE",
		lga_id: 509,
	},
	{
		id: 2261,
		name: "SIMAWA",
		lga_id: 378,
	},
	{
		id: 2262,
		name: "OPOPO GBORO",
		lga_id: 573,
	},
	{
		id: 2263,
		name: "BARKIN SALE",
		lga_id: 97,
	},
	{
		id: 2264,
		name: "OLUPONA",
		lga_id: 505,
	},
	{
		id: 2265,
		name: "AKA ROAD",
		lga_id: 162,
	},
	{
		id: 2266,
		name: "APAPA GRA 1",
		lga_id: 526,
	},
	{
		id: 2267,
		name: "AGBOR OBI",
		lga_id: 441,
	},
	{
		id: 2268,
		name: "AIGAJI",
		lga_id: 445,
	},
	{
		id: 2269,
		name: "BARRACKS OJO",
		lga_id: 228,
	},
	{
		id: 2270,
		name: "OVWIAN-ALADJA",
		lga_id: 331,
	},
	{
		id: 2271,
		name: "NGALA, AWKA SOUTH",
		lga_id: 358,
	},
	{
		id: 2272,
		name: "OYERE",
		lga_id: 713,
	},
	{
		id: 2273,
		name: "AZU OWUCHELE",
		lga_id: 642,
	},
	{
		id: 2274,
		name: "IRIEBE",
		lga_id: 78,
	},
	{
		id: 2275,
		name: "FCE OSIELE",
		lga_id: 356,
	},
	{
		id: 2276,
		name: "GARKI",
		lga_id: 449,
	},
	{
		id: 2277,
		name: "OGBOESERE QUARTERS",
		lga_id: 441,
	},
	{
		id: 2278,
		name: "UDI",
		lga_id: 760,
	},
	{
		id: 2279,
		name: "UKWONG MBO",
		lga_id: 767,
	},
	{
		id: 2280,
		name: "ACME",
		lga_id: 16,
	},
	{
		id: 2281,
		name: "OSHOKOSHOKO",
		lga_id: 187,
	},
	{
		id: 2282,
		name: "IBIONO I",
		lga_id: 426,
	},
	{
		id: 2283,
		name: "AGATU, OMALA",
		lga_id: 113,
	},
	{
		id: 2284,
		name: "IKOTA VILLA",
		lga_id: 410,
	},
	{
		id: 2285,
		name: "ALAYI",
		lga_id: 344,
	},
	{
		id: 2286,
		name: "ADEOLA ODEKU",
		lga_id: 17,
	},
	{
		id: 2287,
		name: "WUSHISHI",
		lga_id: 427,
	},
	{
		id: 2288,
		name: "UMUOKPIA AMUNE",
		lga_id: 65,
	},
	{
		id: 2289,
		name: "ALAKIA, EGBEDA",
		lga_id: 607,
	},
	{
		id: 2290,
		name: "IPATA",
		lga_id: 129,
	},
	{
		id: 2291,
		name: "ANGUWAN RIMI IGABI",
		lga_id: 130,
	},
	{
		id: 2292,
		name: "AJUONA OBIKPA",
		lga_id: 557,
	},
	{
		id: 2293,
		name: "IBEKU",
		lga_id: 598,
	},
	{
		id: 2294,
		name: "UNGWAR ROGO",
		lga_id: 450,
	},
	{
		id: 2295,
		name: "IDI APE",
		lga_id: 183,
	},
	{
		id: 2296,
		name: "DANTAKUN",
		lga_id: 399,
	},
	{
		id: 2297,
		name: "EGERTON",
		lga_id: 559,
	},
	{
		id: 2298,
		name: "SABUWAR CARA CARA",
		lga_id: 39,
	},
	{
		id: 2299,
		name: "ALOR AGU",
		lga_id: 243,
	},
	{
		id: 2300,
		name: "LAGWA",
		lga_id: 71,
	},
	{
		id: 2301,
		name: "OROGUN, IBADAN",
		lga_id: 524,
	},
	{
		id: 2302,
		name: "IJU ISHAGA",
		lga_id: 549,
	},
	{
		id: 2303,
		name: "RUMUJI",
		lga_id: 412,
	},
	{
		id: 2304,
		name: "AKUNZA",
		lga_id: 452,
	},
	{
		id: 2305,
		name: "PORT HARCOURT INTERNATIONAL AIRPORT",
		lga_id: 78,
	},
	{
		id: 2306,
		name: "NICON ESTATE",
		lga_id: 410,
	},
	{
		id: 2307,
		name: "JAKPA ROAD",
		lga_id: 532,
	},
	{
		id: 2308,
		name: "ITOKIN IKORODU",
		lga_id: 18,
	},
	{
		id: 2309,
		name: "IBEJU LEKKI COASTAL ROAD",
		lga_id: 751,
	},
	{
		id: 2310,
		name: "IBESHE",
		lga_id: 18,
	},
	{
		id: 2311,
		name: "IKOT NAKANDA",
		lga_id: 543,
	},
	{
		id: 2312,
		name: "AMAFOR OHAJI",
		lga_id: 189,
	},
	{
		id: 2313,
		name: "AKEETAN TITUN",
		lga_id: 282,
	},
	{
		id: 2314,
		name: "IFETE",
		lga_id: 154,
	},
	{
		id: 2315,
		name: "YALWA QUARTERS",
		lga_id: 182,
	},
	{
		id: 2316,
		name: "ABAKPA",
		lga_id: 68,
	},
	{
		id: 2317,
		name: "TUNGA MAJE",
		lga_id: 28,
	},
	{
		id: 2318,
		name: "IGORI TOWN",
		lga_id: 305,
	},
	{
		id: 2319,
		name: "MARGAI",
		lga_id: 453,
	},
	{
		id: 2320,
		name: "KUDURU",
		lga_id: 82,
	},
	{
		id: 2321,
		name: "KOKO, WARRI NORTH",
		lga_id: 636,
	},
	{
		id: 2322,
		name: "SHUNI",
		lga_id: 660,
	},
	{
		id: 2323,
		name: "OKPULOR MBARANKWA",
		lga_id: 119,
	},
	{
		id: 2324,
		name: "NEW SINGER MARKET",
		lga_id: 425,
	},
	{
		id: 2325,
		name: "IDIBI ENIN",
		lga_id: 587,
	},
	{
		id: 2326,
		name: "TABERU",
		lga_id: 205,
	},
	{
		id: 2327,
		name: "KAFIN MADAKI",
		lga_id: 729,
	},
	{
		id: 2328,
		name: "UMUKPO OHUBA",
		lga_id: 244,
	},
	{
		id: 2329,
		name: "FATIKA",
		lga_id: 198,
	},
	{
		id: 2330,
		name: "TUNGA, NIGER",
		lga_id: 97,
	},
	{
		id: 2331,
		name: "FEDERAL SECRETARIAT, YOLA",
		lga_id: 362,
	},
	{
		id: 2332,
		name: "AGBELE",
		lga_id: 378,
	},
	{
		id: 2333,
		name: "MBONU",
		lga_id: 522,
	},
	{
		id: 2334,
		name: "OKE IGBEIN",
		lga_id: 690,
	},
	{
		id: 2335,
		name: "KUDETI",
		lga_id: 126,
	},
	{
		id: 2336,
		name: "BOKKOS",
		lga_id: 339,
	},
	{
		id: 2337,
		name: "GARIN BAHAGO",
		lga_id: 232,
	},
	{
		id: 2338,
		name: "IMALA",
		lga_id: 719,
	},
	{
		id: 2339,
		name: "NENI",
		lga_id: 387,
	},
	{
		id: 2340,
		name: "ATAN ADO ODO-OTA",
		lga_id: 629,
	},
	{
		id: 2341,
		name: "AMORKA",
		lga_id: 207,
	},
	{
		id: 2342,
		name: "MADA",
		lga_id: 247,
	},
	{
		id: 2343,
		name: "YANDEV",
		lga_id: 351,
	},
	{
		id: 2344,
		name: "IYANA MOTUARY",
		lga_id: 118,
	},
	{
		id: 2345,
		name: "KENTE",
		lga_id: 140,
	},
	{
		id: 2346,
		name: "NKISI PALACE",
		lga_id: 500,
	},
	{
		id: 2347,
		name: "UMUOGI",
		lga_id: 676,
	},
	{
		id: 2348,
		name: "TAFAWA BALEWA STADIUM",
		lga_id: 653,
	},
	{
		id: 2349,
		name: "OFIN",
		lga_id: 212,
	},
	{
		id: 2350,
		name: "IKOT EKPENE ROAD",
		lga_id: 162,
	},
	{
		id: 2351,
		name: "AFIKPO CITY",
		lga_id: 337,
	},
	{
		id: 2352,
		name: "OLABISI ONABANJO UNIVERSITY IFO",
		lga_id: 549,
	},
	{
		id: 2353,
		name: "COKER SURULERE",
		lga_id: 641,
	},
	{
		id: 2354,
		name: "OKE IRA NLA",
		lga_id: 410,
	},
	{
		id: 2355,
		name: "BONDONG",
		lga_id: 609,
	},
	{
		id: 2356,
		name: "OHUHU COMMUNITY",
		lga_id: 598,
	},
	{
		id: 2357,
		name: "OLOJO COMPOUND",
		lga_id: 529,
	},
	{
		id: 2358,
		name: "SHARADA PHASE 1",
		lga_id: 349,
	},
	{
		id: 2359,
		name: "ELEYELE, OYO",
		lga_id: 85,
	},
	{
		id: 2360,
		name: "ILUPEJU MUSHIN",
		lga_id: 115,
	},
	{
		id: 2361,
		name: "OBATEDO",
		lga_id: 155,
	},
	{
		id: 2362,
		name: "MALETE",
		lga_id: 36,
	},
	{
		id: 2363,
		name: "FAGGO",
		lga_id: 460,
	},
	{
		id: 2364,
		name: "RESA",
		lga_id: 284,
	},
	{
		id: 2365,
		name: "IGBARA ODO EKITI",
		lga_id: 624,
	},
	{
		id: 2366,
		name: "SAMARU, SABON GARI",
		lga_id: 616,
	},
	{
		id: 2367,
		name: "KUNU",
		lga_id: 477,
	},
	{
		id: 2368,
		name: "MUTUM BIYU",
		lga_id: 171,
	},
	{
		id: 2369,
		name: "EMUREN",
		lga_id: 212,
	},
	{
		id: 2370,
		name: "BARIKI LAU",
		lga_id: 247,
	},
	{
		id: 2371,
		name: "UGEP",
		lga_id: 221,
	},
	{
		id: 2372,
		name: "BUNZA",
		lga_id: 612,
	},
	{
		id: 2373,
		name: "OGUU ANIOCHA",
		lga_id: 353,
	},
	{
		id: 2374,
		name: "NANKA",
		lga_id: 747,
	},
	{
		id: 2375,
		name: "AHAMADU BELLO WAY GOMBE",
		lga_id: 347,
	},
	{
		id: 2376,
		name: "OTA INDUSTRIAL ESTATE",
		lga_id: 629,
	},
	{
		id: 2377,
		name: "AIYETORO",
		lga_id: 394,
	},
	{
		id: 2378,
		name: "KPAKUNGU",
		lga_id: 97,
	},
	{
		id: 2379,
		name: "GBAREMU IBADAN",
		lga_id: 607,
	},
	{
		id: 2380,
		name: "UMUOHIA AMATTA",
		lga_id: 63,
	},
	{
		id: 2381,
		name: "DUGURAWA",
		lga_id: 484,
	},
	{
		id: 2382,
		name: "AKATTA",
		lga_id: 77,
	},
	{
		id: 2383,
		name: "IRETE",
		lga_id: 189,
	},
	{
		id: 2384,
		name: "SOKOH ESTATE",
		lga_id: 532,
	},
	{
		id: 2385,
		name: "ILOFA",
		lga_id: 555,
	},
	{
		id: 2386,
		name: "STATE SECRETARIAT, UYO",
		lga_id: 162,
	},
	{
		id: 2387,
		name: "FILIYA",
		lga_id: 143,
	},
	{
		id: 2388,
		name: "EMIRS PALACE",
		lga_id: 129,
	},
	{
		id: 2389,
		name: "DEGEMA",
		lga_id: 603,
	},
	{
		id: 2390,
		name: "KANKIA",
		lga_id: 523,
	},
	{
		id: 2391,
		name: "ACME",
		lga_id: 584,
	},
	{
		id: 2392,
		name: "OLODE KUTAYI",
		lga_id: 372,
	},
	{
		id: 2393,
		name: "IGBOGBO",
		lga_id: 212,
	},
	{
		id: 2394,
		name: "APETE IDO",
		lga_id: 85,
	},
	{
		id: 2395,
		name: "IKOT AKPABIO",
		lga_id: 540,
	},
	{
		id: 2396,
		name: "BINJI",
		lga_id: 596,
	},
	{
		id: 2397,
		name: "NJALAKA UMUNYA",
		lga_id: 717,
	},
	{
		id: 2398,
		name: "OKE ODAN",
		lga_id: 680,
	},
	{
		id: 2399,
		name: "KOFAR NASARAWA",
		lga_id: 653,
	},
	{
		id: 2400,
		name: "WUYE",
		lga_id: 476,
	},
	{
		id: 2401,
		name: "HIGH COURT",
		lga_id: 126,
	},
	{
		id: 2402,
		name: "GREATER PORT HARCOURT",
		lga_id: 594,
	},
	{
		id: 2403,
		name: "PATEGI",
		lga_id: 260,
	},
	{
		id: 2404,
		name: "MBAVAA",
		lga_id: 319,
	},
	{
		id: 2405,
		name: "OHUAN",
		lga_id: 279,
	},
	{
		id: 2406,
		name: "BALARE",
		lga_id: 723,
	},
	{
		id: 2407,
		name: "KATAGUM GAMAWA",
		lga_id: 759,
	},
	{
		id: 2408,
		name: "SHANGEVTIEV",
		lga_id: 319,
	},
	{
		id: 2409,
		name: "TAPA, TAFA",
		lga_id: 698,
	},
	{
		id: 2410,
		name: "LOW COST",
		lga_id: 122,
	},
	{
		id: 2411,
		name: "ARABA UMOZOCHA",
		lga_id: 358,
	},
	{
		id: 2412,
		name: "KUCHIWORO",
		lga_id: 374,
	},
	{
		id: 2413,
		name: "EGWI",
		lga_id: 348,
	},
	{
		id: 2414,
		name: "SABORI GARI TOUNGO",
		lga_id: 274,
	},
	{
		id: 2415,
		name: "AROGBO",
		lga_id: 127,
	},
	{
		id: 2416,
		name: "OGUTA, OGUTA",
		lga_id: 288,
	},
	{
		id: 2417,
		name: "BABARI",
		lga_id: 61,
	},
	{
		id: 2418,
		name: "MALAM SIDI",
		lga_id: 569,
	},
	{
		id: 2419,
		name: "GURIN",
		lga_id: 755,
	},
	{
		id: 2420,
		name: "BADAWA",
		lga_id: 578,
	},
	{
		id: 2421,
		name: "DELSU ANWAI CAMPUS",
		lga_id: 626,
	},
	{
		id: 2422,
		name: "IJEBU IGBO",
		lga_id: 184,
	},
	{
		id: 2423,
		name: "IGABI",
		lga_id: 130,
	},
	{
		id: 2424,
		name: "ERO TOWN",
		lga_id: 413,
	},
	{
		id: 2425,
		name: "IHITE UKWA",
		lga_id: 298,
	},
	{
		id: 2426,
		name: "TUDUN JUKUN ZARIA",
		lga_id: 579,
	},
	{
		id: 2427,
		name: "IDIMU",
		lga_id: 577,
	},
	{
		id: 2428,
		name: "GAA AKANBI",
		lga_id: 129,
	},
	{
		id: 2429,
		name: "DAKINGARI",
		lga_id: 705,
	},
	{
		id: 2430,
		name: "OROKI ESTATE EXTENSION",
		lga_id: 394,
	},
	{
		id: 2431,
		name: "PAN SHANU",
		lga_id: 489,
	},
	{
		id: 2432,
		name: "UGONDO",
		lga_id: 592,
	},
	{
		id: 2433,
		name: "SOBA",
		lga_id: 675,
	},
	{
		id: 2434,
		name: "NDEGWU",
		lga_id: 189,
	},
	{
		id: 2435,
		name: "LEKKI COUNTY HOME",
		lga_id: 410,
	},
	{
		id: 2436,
		name: "AMAMBA",
		lga_id: 77,
	},
	{
		id: 2437,
		name: "OHI OWERRI",
		lga_id: 37,
	},
	{
		id: 2438,
		name: "ANGWI YAII",
		lga_id: 588,
	},
	{
		id: 2439,
		name: "ITOKIN IKORODU",
		lga_id: 212,
	},
	{
		id: 2440,
		name: "OGADA",
		lga_id: 741,
	},
	{
		id: 2441,
		name: "UBA, MUBI NORTH",
		lga_id: 477,
	},
	{
		id: 2442,
		name: "ALAPERE",
		lga_id: 465,
	},
	{
		id: 2443,
		name: "NEW BUSSA",
		lga_id: 608,
	},
	{
		id: 2444,
		name: "OBADORE",
		lga_id: 577,
	},
	{
		id: 2445,
		name: "WURNO",
		lga_id: 700,
	},
	{
		id: 2446,
		name: "IWOLLO IMEZI",
		lga_id: 613,
	},
	{
		id: 2447,
		name: "KENYI",
		lga_id: 225,
	},
	{
		id: 2448,
		name: "SHELL ROAD, SAPELE",
		lga_id: 754,
	},
	{
		id: 2449,
		name: "MBIRI",
		lga_id: 125,
	},
	{
		id: 2450,
		name: "URALLA",
		lga_id: 687,
	},
	{
		id: 2451,
		name: "IWARE",
		lga_id: 735,
	},
	{
		id: 2452,
		name: "OKHA OLOGBO",
		lga_id: 114,
	},
	{
		id: 2453,
		name: "BADAGRY TOWN",
		lga_id: 595,
	},
	{
		id: 2454,
		name: "MILE 12",
		lga_id: 465,
	},
	{
		id: 2455,
		name: "EFON ALAAYE",
		lga_id: 176,
	},
	{
		id: 2456,
		name: "ODEKPE",
		lga_id: 353,
	},
	{
		id: 2457,
		name: "ADIASIM",
		lga_id: 263,
	},
	{
		id: 2458,
		name: "OSUSU",
		lga_id: 590,
	},
	{
		id: 2459,
		name: "EDO STATE FERTILIZER COMPANY",
		lga_id: 697,
	},
	{
		id: 2460,
		name: "AMACHI RURAL",
		lga_id: 520,
	},
	{
		id: 2461,
		name: "MINJIBIR",
		lga_id: 185,
	},
	{
		id: 2462,
		name: "ISHINWEKE",
		lga_id: 701,
	},
	{
		id: 2463,
		name: "WADUKU",
		lga_id: 405,
	},
	{
		id: 2464,
		name: "GINDI",
		lga_id: 651,
	},
	{
		id: 2465,
		name: "RAAV",
		lga_id: 581,
	},
	{
		id: 2466,
		name: "USHONGO",
		lga_id: 79,
	},
	{
		id: 2467,
		name: "UPENMEN",
		lga_id: 593,
	},
	{
		id: 2468,
		name: "KONKIYEL",
		lga_id: 586,
	},
	{
		id: 2469,
		name: "ESIE IJAN",
		lga_id: 164,
	},
	{
		id: 2470,
		name: "KUMBOTSO",
		lga_id: 425,
	},
	{
		id: 2471,
		name: "OKWAGBE",
		lga_id: 210,
	},
	{
		id: 2472,
		name: "KUSA NUGBAGI",
		lga_id: 335,
	},
	{
		id: 2473,
		name: "AJEGUNLE SAGAMU",
		lga_id: 378,
	},
	{
		id: 2474,
		name: "ARONDIZUOGU",
		lga_id: 687,
	},
	{
		id: 2475,
		name: "OGUME",
		lga_id: 610,
	},
	{
		id: 2476,
		name: "KOBAOPE IWO",
		lga_id: 155,
	},
	{
		id: 2477,
		name: "ETSU ISA",
		lga_id: 106,
	},
	{
		id: 2478,
		name: "OKE AGBE",
		lga_id: 258,
	},
	{
		id: 2479,
		name: "LAFIA BERIBERI",
		lga_id: 452,
	},
	{
		id: 2480,
		name: "BULUMKUTU",
		lga_id: 693,
	},
	{
		id: 2481,
		name: "ISIAMA OHAFIA",
		lga_id: 522,
	},
	{
		id: 2482,
		name: "DUTSINMA",
		lga_id: 738,
	},
	{
		id: 2483,
		name: "SABO OJO",
		lga_id: 228,
	},
	{
		id: 2484,
		name: "AFAM",
		lga_id: 137,
	},
	{
		id: 2485,
		name: "MAGUMERI",
		lga_id: 583,
	},
	{
		id: 2486,
		name: "UKAWU",
		lga_id: 76,
	},
	{
		id: 2487,
		name: "FRSC OFFICE SHARADA",
		lga_id: 349,
	},
	{
		id: 2488,
		name: "UMUONA",
		lga_id: 747,
	},
	{
		id: 2489,
		name: "EMU, ESAN SOUTH-EAST",
		lga_id: 55,
	},
	{
		id: 2490,
		name: "SADOKASHI",
		lga_id: 106,
	},
	{
		id: 2491,
		name: "NABORDO",
		lga_id: 489,
	},
	{
		id: 2492,
		name: "EKE EZEACHI",
		lga_id: 542,
	},
	{
		id: 2493,
		name: "OKON AKU OHAFIA",
		lga_id: 522,
	},
	{
		id: 2494,
		name: "WARRI AIRPORT, UVWIE",
		lga_id: 532,
	},
	{
		id: 2495,
		name: "THREE IBIES",
		lga_id: 585,
	},
	{
		id: 2496,
		name: "ERE",
		lga_id: 541,
	},
	{
		id: 2497,
		name: "BANTAKE",
		lga_id: 85,
	},
	{
		id: 2498,
		name: "ALAPA",
		lga_id: 193,
	},
	{
		id: 2499,
		name: "ONIKOKO AREA",
		lga_id: 713,
	},
	{
		id: 2500,
		name: "OTAN ILE",
		lga_id: 203,
	},
	{
		id: 2501,
		name: "ITOKO",
		lga_id: 118,
	},
	{
		id: 2502,
		name: "ADEKOLA OLODE",
		lga_id: 607,
	},
	{
		id: 2503,
		name: "ABESAN ESTATE",
		lga_id: 577,
	},
	{
		id: 2504,
		name: "GELLA",
		lga_id: 261,
	},
	{
		id: 2505,
		name: "SABON PEGI",
		lga_id: 116,
	},
	{
		id: 2506,
		name: "ALABA",
		lga_id: 267,
	},
	{
		id: 2507,
		name: "GRA OSHODI",
		lga_id: 394,
	},
	{
		id: 2508,
		name: "IJERU QUARTERS",
		lga_id: 529,
	},
	{
		id: 2509,
		name: "WAREHOUSE",
		lga_id: 526,
	},
	{
		id: 2510,
		name: "IREE BOLUWADURO",
		lga_id: 302,
	},
	{
		id: 2511,
		name: "AYOBO",
		lga_id: 577,
	},
	{
		id: 2512,
		name: "MAITAMA",
		lga_id: 476,
	},
	{
		id: 2513,
		name: "OLIOGO UMUSETI",
		lga_id: 610,
	},
	{
		id: 2514,
		name: "UMUNGASIABAYI LAYOUT",
		lga_id: 765,
	},
	{
		id: 2515,
		name: "BETTEE",
		lga_id: 398,
	},
	{
		id: 2516,
		name: "ISIOKOLO",
		lga_id: 438,
	},
	{
		id: 2517,
		name: "EBENATOR",
		lga_id: 170,
	},
	{
		id: 2518,
		name: "GIDAN AMAMATA",
		lga_id: 35,
	},
	{
		id: 2519,
		name: "MARADUN",
		lga_id: 397,
	},
	{
		id: 2520,
		name: "EKOSIN",
		lga_id: 771,
	},
	{
		id: 2521,
		name: "CHEGUNGI",
		lga_id: 374,
	},
	{
		id: 2522,
		name: "MBOKO UMUETE",
		lga_id: 765,
	},
	{
		id: 2523,
		name: "IKOTA VILLA",
		lga_id: 17,
	},
	{
		id: 2524,
		name: "NDEMILI",
		lga_id: 441,
	},
	{
		id: 2525,
		name: "AGBANI, NKANU WEST",
		lga_id: 330,
	},
	{
		id: 2526,
		name: "UMUOKWE",
		lga_id: 77,
	},
	{
		id: 2527,
		name: "LAILABA",
		lga_id: 172,
	},
	{
		id: 2528,
		name: "IYIOWA ODEKPE",
		lga_id: 353,
	},
	{
		id: 2529,
		name: "UMEREM",
		lga_id: 119,
	},
	{
		id: 2530,
		name: "AKWANGA",
		lga_id: 98,
	},
	{
		id: 2531,
		name: "FISHING FESTIVAL, ARGUNGU",
		lga_id: 172,
	},
	{
		id: 2532,
		name: "ABADE COMPOUND",
		lga_id: 691,
	},
	{
		id: 2533,
		name: "NORTHERN URUAN",
		lga_id: 367,
	},
	{
		id: 2534,
		name: "ODE LEMO",
		lga_id: 378,
	},
	{
		id: 2535,
		name: "AJAH",
		lga_id: 17,
	},
	{
		id: 2536,
		name: "GUYUK",
		lga_id: 194,
	},
	{
		id: 2537,
		name: "BICHI",
		lga_id: 311,
	},
	{
		id: 2538,
		name: "MAISANGO QUARTERS",
		lga_id: 39,
	},
	{
		id: 2539,
		name: "OZU ABAM",
		lga_id: 87,
	},
	{
		id: 2540,
		name: "PORT HARCOURT RURAL",
		lga_id: 594,
	},
	{
		id: 2541,
		name: "WAGINI",
		lga_id: 568,
	},
	{
		id: 2542,
		name: "NASSARAWU",
		lga_id: 368,
	},
	{
		id: 2543,
		name: "AROYE",
		lga_id: 607,
	},
	{
		id: 2544,
		name: "ORILE IMO",
		lga_id: 690,
	},
	{
		id: 2545,
		name: "IBETO",
		lga_id: 686,
	},
	{
		id: 2546,
		name: "JUTH HOSPITAL",
		lga_id: 732,
	},
	{
		id: 2547,
		name: "MAITO",
		lga_id: 427,
	},
	{
		id: 2548,
		name: "OGOMBO",
		lga_id: 410,
	},
	{
		id: 2549,
		name: "OSUN STATE UNIVERSITY",
		lga_id: 219,
	},
	{
		id: 2550,
		name: "KOFAR KUDU",
		lga_id: 443,
	},
	{
		id: 2551,
		name: "KUNINI",
		lga_id: 722,
	},
	{
		id: 2552,
		name: "IGBOKIN",
		lga_id: 18,
	},
	{
		id: 2553,
		name: "OBANIKORO",
		lga_id: 404,
	},
	{
		id: 2554,
		name: "PTI EFFURUN",
		lga_id: 532,
	},
	{
		id: 2555,
		name: "IKPESHI",
		lga_id: 479,
	},
	{
		id: 2556,
		name: "CHOBA TOWN",
		lga_id: 78,
	},
	{
		id: 2557,
		name: "ARAMOKO",
		lga_id: 419,
	},
	{
		id: 2558,
		name: "GRA, FUNTUA",
		lga_id: 122,
	},
	{
		id: 2559,
		name: "OPOBO",
		lga_id: 628,
	},
	{
		id: 2560,
		name: "AKA OFOT",
		lga_id: 162,
	},
	{
		id: 2561,
		name: "IBOWON",
		lga_id: 541,
	},
	{
		id: 2562,
		name: "IGBOJAYE",
		lga_id: 86,
	},
	{
		id: 2563,
		name: "SAKI EIOKOKAN",
		lga_id: 615,
	},
	{
		id: 2564,
		name: "SANYO IBADAN SOUTH WEST",
		lga_id: 126,
	},
	{
		id: 2565,
		name: "CANTONMENT, LOKOJA",
		lga_id: 187,
	},
	{
		id: 2566,
		name: "ARMY BARRACKS, YOLA",
		lga_id: 362,
	},
	{
		id: 2567,
		name: "UNGWUWAN NASSARA",
		lga_id: 39,
	},
	{
		id: 2568,
		name: "NKWOT ETUK",
		lga_id: 685,
	},
	{
		id: 2569,
		name: "GBAGBA ASA",
		lga_id: 193,
	},
	{
		id: 2570,
		name: "GRA PHASE 1",
		lga_id: 594,
	},
	{
		id: 2571,
		name: "AJAH",
		lga_id: 410,
	},
	{
		id: 2572,
		name: "DIKENAFAI",
		lga_id: 519,
	},
	{
		id: 2573,
		name: "ERUNWEN",
		lga_id: 18,
	},
	{
		id: 2574,
		name: "OVWIAN",
		lga_id: 331,
	},
	{
		id: 2575,
		name: "ABAK URBAN",
		lga_id: 540,
	},
	{
		id: 2576,
		name: "AFE BABALOLA UNIVERSITY",
		lga_id: 573,
	},
	{
		id: 2577,
		name: "SHEKA GIDAN SARKI",
		lga_id: 236,
	},
	{
		id: 2578,
		name: "SHONGOM",
		lga_id: 143,
	},
	{
		id: 2579,
		name: "NDIAGU",
		lga_id: 241,
	},
	{
		id: 2580,
		name: "OKIGWE",
		lga_id: 147,
	},
	{
		id: 2581,
		name: "ISIOKPO",
		lga_id: 611,
	},
	{
		id: 2582,
		name: "DADA ESTATE",
		lga_id: 711,
	},
	{
		id: 2583,
		name: "CHAM",
		lga_id: 150,
	},
	{
		id: 2584,
		name: "ALADE",
		lga_id: 605,
	},
	{
		id: 2585,
		name: "OKUMAGBA LAYOUT",
		lga_id: 417,
	},
	{
		id: 2586,
		name: "STADIUM OGBOMOSHO NORTH",
		lga_id: 529,
	},
	{
		id: 2587,
		name: "ODEDA",
		lga_id: 356,
	},
	{
		id: 2588,
		name: "IHIEVBE",
		lga_id: 667,
	},
	{
		id: 2589,
		name: "GAWON NAMA",
		lga_id: 450,
	},
	{
		id: 2590,
		name: "LEKKI SCHEME 2",
		lga_id: 17,
	},
	{
		id: 2591,
		name: "SEPETERI",
		lga_id: 434,
	},
	{
		id: 2592,
		name: "EKAJUK",
		lga_id: 310,
	},
	{
		id: 2593,
		name: "DISINA",
		lga_id: 460,
	},
	{
		id: 2594,
		name: "YASHI",
		lga_id: 232,
	},
	{
		id: 2595,
		name: "IJAGUN ODOGBOLU",
		lga_id: 159,
	},
	{
		id: 2596,
		name: "UDUEME",
		lga_id: 760,
	},
	{
		id: 2597,
		name: "AKUNNUI SOWOPO",
		lga_id: 64,
	},
	{
		id: 2598,
		name: "ENWHE",
		lga_id: 494,
	},
	{
		id: 2599,
		name: "UMUOLIRI UMUKORO",
		lga_id: 676,
	},
	{
		id: 2600,
		name: "OLORU",
		lga_id: 36,
	},
	{
		id: 2601,
		name: "TUNGAN MAGAJI",
		lga_id: 235,
	},
	{
		id: 2602,
		name: "OKO ESE",
		lga_id: 720,
	},
	{
		id: 2603,
		name: "GIWA",
		lga_id: 198,
	},
	{
		id: 2604,
		name: "ANGUWAN ROMI KADUNA SOUTH",
		lga_id: 757,
	},
	{
		id: 2605,
		name: "KATSINA EMMIRATE PALACE",
		lga_id: 399,
	},
	{
		id: 2606,
		name: "ETITA-EBOBE",
		lga_id: 541,
	},
	{
		id: 2607,
		name: "OLUNLOYO",
		lga_id: 246,
	},
	{
		id: 2608,
		name: "NROBO",
		lga_id: 684,
	},
	{
		id: 2609,
		name: "YELWA, JALINGO",
		lga_id: 222,
	},
	{
		id: 2610,
		name: "CBN, JOS",
		lga_id: 732,
	},
	{
		id: 2611,
		name: "SABON LAYI",
		lga_id: 122,
	},
	{
		id: 2612,
		name: "AFOR IGWE",
		lga_id: 554,
	},
	{
		id: 2613,
		name: "BEBEJI",
		lga_id: 73,
	},
	{
		id: 2614,
		name: "ASOKORO EXTENSION",
		lga_id: 476,
	},
	{
		id: 2615,
		name: "OFUGO",
		lga_id: 93,
	},
	{
		id: 2616,
		name: "OBA AKRAN",
		lga_id: 16,
	},
	{
		id: 2617,
		name: "AGULERI",
		lga_id: 499,
	},
	{
		id: 2618,
		name: "LITAYE",
		lga_id: 285,
	},
	{
		id: 2619,
		name: "MASHAMARI",
		lga_id: 671,
	},
	{
		id: 2620,
		name: "ALI AKILU GRA",
		lga_id: 450,
	},
	{
		id: 2621,
		name: "ATONKON",
		lga_id: 506,
	},
	{
		id: 2622,
		name: "MARARABA",
		lga_id: 317,
	},
	{
		id: 2623,
		name: "ADEKUNLE",
		lga_id: 442,
	},
	{
		id: 2624,
		name: "GUDU, MUNICIPAL AREA COUNCIL",
		lga_id: 476,
	},
	{
		id: 2625,
		name: "CABLE POINT",
		lga_id: 574,
	},
	{
		id: 2626,
		name: "UNIJOS",
		lga_id: 732,
	},
	{
		id: 2627,
		name: "NGURU, NGOR-OKPALA",
		lga_id: 676,
	},
	{
		id: 2628,
		name: "OKINNI",
		lga_id: 711,
	},
	{
		id: 2629,
		name: "ORA, OWAN WEST",
		lga_id: 254,
	},
	{
		id: 2630,
		name: "GBOKO CENTRAL BANK ROAD",
		lga_id: 351,
	},
	{
		id: 2631,
		name: "NGINE",
		lga_id: 330,
	},
	{
		id: 2632,
		name: "AVIARA",
		lga_id: 494,
	},
	{
		id: 2633,
		name: "IGBAKWU",
		lga_id: 229,
	},
	{
		id: 2634,
		name: "LEKKI COUNTY HOME",
		lga_id: 17,
	},
	{
		id: 2635,
		name: "TUDUN WADA GOMBE",
		lga_id: 347,
	},
	{
		id: 2636,
		name: "NITT, KADUNA",
		lga_id: 616,
	},
	{
		id: 2637,
		name: "IZZI UNUHU",
		lga_id: 68,
	},
	{
		id: 2638,
		name: "GIDAN WAYA",
		lga_id: 428,
	},
	{
		id: 2639,
		name: "UJEVWU",
		lga_id: 331,
	},
	{
		id: 2640,
		name: "OKO",
		lga_id: 747,
	},
	{
		id: 2641,
		name: "JALAM",
		lga_id: 326,
	},
	{
		id: 2642,
		name: "INTERNET AREA DUTSE",
		lga_id: 464,
	},
	{
		id: 2643,
		name: "TAGAGI AGAIE",
		lga_id: 307,
	},
	{
		id: 2644,
		name: "GRA AZARE",
		lga_id: 39,
	},
	{
		id: 2645,
		name: "IGBARA-OKE",
		lga_id: 239,
	},
	{
		id: 2646,
		name: "UMU AGWO OHAJI",
		lga_id: 244,
	},
	{
		id: 2647,
		name: "FEDERAL POLYTECHNIC IDAH",
		lga_id: 223,
	},
	{
		id: 2648,
		name: "WASANGERE OJA",
		lga_id: 615,
	},
	{
		id: 2649,
		name: "KUKA, MASHI",
		lga_id: 74,
	},
	{
		id: 2650,
		name: "HARDAWA",
		lga_id: 640,
	},
	{
		id: 2651,
		name: "JAGUNA",
		lga_id: 541,
	},
	{
		id: 2652,
		name: "ANDODOFI",
		lga_id: 140,
	},
	{
		id: 2653,
		name: "IDODENGE",
		lga_id: 421,
	},
	{
		id: 2654,
		name: "OFOMBONGHA",
		lga_id: 48,
	},
	{
		id: 2655,
		name: "UNIZIK PRESCIENCE SCHOOL",
		lga_id: 358,
	},
	{
		id: 2656,
		name: "ANGWAR FADA",
		lga_id: 575,
	},
	{
		id: 2657,
		name: "ODO",
		lga_id: 212,
	},
	{
		id: 2658,
		name: "ISUOCHI",
		lga_id: 633,
	},
	{
		id: 2659,
		name: "BATAL",
		lga_id: 512,
	},
	{
		id: 2660,
		name: "BORI KIRI",
		lga_id: 594,
	},
	{
		id: 2661,
		name: "LOKOGOMA",
		lga_id: 476,
	},
	{
		id: 2662,
		name: "AKPOGBA IDAH TOWN",
		lga_id: 223,
	},
	{
		id: 2663,
		name: "TOWNSHIP STADIUM",
		lga_id: 732,
	},
	{
		id: 2664,
		name: "1004",
		lga_id: 17,
	},
	{
		id: 2665,
		name: "IRRUA",
		lga_id: 621,
	},
	{
		id: 2666,
		name: "BANYE",
		lga_id: 123,
	},
	{
		id: 2667,
		name: "BANNI",
		lga_id: 24,
	},
	{
		id: 2668,
		name: "AKOTOGBO",
		lga_id: 366,
	},
	{
		id: 2669,
		name: "SOUTHERN IMAN",
		lga_id: 617,
	},
	{
		id: 2670,
		name: "IREE BORIPE",
		lga_id: 515,
	},
	{
		id: 2671,
		name: "NZAM",
		lga_id: 538,
	},
	{
		id: 2672,
		name: "GALADIMA GWARIPA",
		lga_id: 476,
	},
	{
		id: 2673,
		name: "CALABAR ITU",
		lga_id: 237,
	},
	{
		id: 2674,
		name: "DURUMI",
		lga_id: 476,
	},
	{
		id: 2675,
		name: "RAKA",
		lga_id: 47,
	},
	{
		id: 2676,
		name: "DANCHITAGI",
		lga_id: 374,
	},
	{
		id: 2677,
		name: "BIRNIN KUDU",
		lga_id: 390,
	},
	{
		id: 2678,
		name: "OYEDE",
		lga_id: 691,
	},
	{
		id: 2679,
		name: "MAGAJIN GARI, KADUNA NORTH",
		lga_id: 284,
	},
	{
		id: 2680,
		name: "ILARA, ONDO",
		lga_id: 239,
	},
	{
		id: 2681,
		name: "EZE OKPARA",
		lga_id: 71,
	},
	{
		id: 2682,
		name: "SHELL RA",
		lga_id: 78,
	},
	{
		id: 2683,
		name: "UMUOJIMA",
		lga_id: 765,
	},
	{
		id: 2684,
		name: "FEMI OKUNNU ESTATE",
		lga_id: 17,
	},
	{
		id: 2685,
		name: "DANKAMA",
		lga_id: 497,
	},
	{
		id: 2686,
		name: "EGBEDI",
		lga_id: 711,
	},
	{
		id: 2687,
		name: "LEGISLATURES QUARTERS KATSINA",
		lga_id: 399,
	},
	{
		id: 2688,
		name: "KUSHERI",
		lga_id: 80,
	},
	{
		id: 2689,
		name: "TURETA",
		lga_id: 566,
	},
	{
		id: 2690,
		name: "IFON, ONDO",
		lga_id: 413,
	},
	{
		id: 2691,
		name: "AGBEDE OYO",
		lga_id: 86,
	},
	{
		id: 2692,
		name: "OGBEKE OBIBI",
		lga_id: 741,
	},
	{
		id: 2693,
		name: "MAYA, MORO",
		lga_id: 36,
	},
	{
		id: 2694,
		name: "OLOMORE, ABEOKUTA SOUTH",
		lga_id: 118,
	},
	{
		id: 2695,
		name: "WUKARI RURAL",
		lga_id: 140,
	},
	{
		id: 2696,
		name: "OMOKU",
		lga_id: 535,
	},
	{
		id: 2697,
		name: "EKEREMOR",
		lga_id: 168,
	},
	{
		id: 2698,
		name: "EKENWA ARMY BARRACKS",
		lga_id: 393,
	},
	{
		id: 2699,
		name: "IDUMAGBO",
		lga_id: 766,
	},
	{
		id: 2700,
		name: "AJA IJIMU",
		lga_id: 18,
	},
	{
		id: 2701,
		name: "KEFFI",
		lga_id: 444,
	},
	{
		id: 2702,
		name: "ERUWA, OYO",
		lga_id: 740,
	},
	{
		id: 2703,
		name: "UWELU",
		lga_id: 23,
	},
	{
		id: 2704,
		name: "RIVER STATE COLLEGE OF EDUCATION",
		lga_id: 594,
	},
	{
		id: 2705,
		name: "DAFFO",
		lga_id: 339,
	},
	{
		id: 2706,
		name: "DAURA LAYOUT",
		lga_id: 181,
	},
	{
		id: 2707,
		name: "MBANO CAMP",
		lga_id: 137,
	},
	{
		id: 2708,
		name: "OKAKA",
		lga_id: 167,
	},
	{
		id: 2709,
		name: "IJAGUN IJEBU ODE",
		lga_id: 619,
	},
	{
		id: 2710,
		name: "BIU EAST",
		lga_id: 169,
	},
	{
		id: 2711,
		name: "OKUOKOKO",
		lga_id: 532,
	},
	{
		id: 2712,
		name: "OKABIA ELU",
		lga_id: 298,
	},
	{
		id: 2713,
		name: "OHIZI",
		lga_id: 187,
	},
	{
		id: 2714,
		name: "ILERIN IJESHA",
		lga_id: 470,
	},
	{
		id: 2715,
		name: "IGWE IZOR",
		lga_id: 329,
	},
	{
		id: 2716,
		name: "OKE OGUN",
		lga_id: 99,
	},
	{
		id: 2717,
		name: "IBEREKO",
		lga_id: 595,
	},
	{
		id: 2718,
		name: "BAGANA",
		lga_id: 113,
	},
	{
		id: 2719,
		name: "COLLEGE OF AGRICULTURE KABBA",
		lga_id: 56,
	},
	{
		id: 2720,
		name: "IJAIYE",
		lga_id: 118,
	},
	{
		id: 2721,
		name: "IKOSI",
		lga_id: 465,
	},
	{
		id: 2722,
		name: "SIMON ODUEYE ESTATE",
		lga_id: 97,
	},
	{
		id: 2723,
		name: "PINDIGA",
		lga_id: 103,
	},
	{
		id: 2724,
		name: "LEKKI FREE TRADE ZONE",
		lga_id: 541,
	},
	{
		id: 2725,
		name: "ENEKA",
		lga_id: 78,
	},
	{
		id: 2726,
		name: "IGBODIGO",
		lga_id: 248,
	},
	{
		id: 2727,
		name: "GARKIDA",
		lga_id: 776,
	},
	{
		id: 2728,
		name: "IPAJA",
		lga_id: 577,
	},
	{
		id: 2729,
		name: "IULEHA",
		lga_id: 254,
	},
	{
		id: 2730,
		name: "EDDA",
		lga_id: 68,
	},
	{
		id: 2731,
		name: "NGURU, NGURU",
		lga_id: 365,
	},
	{
		id: 2732,
		name: "SAGBAMA",
		lga_id: 391,
	},
	{
		id: 2733,
		name: "PANYAN",
		lga_id: 206,
	},
	{
		id: 2734,
		name: "OTAN AYEGBAJU",
		lga_id: 302,
	},
	{
		id: 2735,
		name: "OBOHIA",
		lga_id: 471,
	},
	{
		id: 2736,
		name: "AGBENI",
		lga_id: 648,
	},
	{
		id: 2737,
		name: "KUNKELE",
		lga_id: 106,
	},
	{
		id: 2738,
		name: "OLOJE EAST",
		lga_id: 129,
	},
	{
		id: 2739,
		name: "AYEGBAMI",
		lga_id: 378,
	},
	{
		id: 2740,
		name: "CHARANCHI",
		lga_id: 123,
	},
	{
		id: 2741,
		name: "NARAYI VILLAGE",
		lga_id: 480,
	},
	{
		id: 2742,
		name: "ALAPADI",
		lga_id: 212,
	},
	{
		id: 2743,
		name: "AKILO",
		lga_id: 240,
	},
	{
		id: 2744,
		name: "RUMUIBEKWE",
		lga_id: 78,
	},
	{
		id: 2745,
		name: "ORLU TOWNSHIP STADIUM",
		lga_id: 542,
	},
	{
		id: 2746,
		name: "OLUSOSUN",
		lga_id: 16,
	},
	{
		id: 2747,
		name: "UKWORJI",
		lga_id: 37,
	},
	{
		id: 2748,
		name: "IWERE ILE",
		lga_id: 565,
	},
	{
		id: 2749,
		name: "EPINMI-AKOKO",
		lga_id: 466,
	},
	{
		id: 2750,
		name: "IDEMILI TOWN",
		lga_id: 353,
	},
	{
		id: 2751,
		name: "IMO STATE SECRETARIAT",
		lga_id: 318,
	},
	{
		id: 2752,
		name: "MAMMY MARKET, WARRI",
		lga_id: 532,
	},
	{
		id: 2753,
		name: "OGBETTE",
		lga_id: 197,
	},
	{
		id: 2754,
		name: "TUDUN AMBA",
		lga_id: 452,
	},
	{
		id: 2755,
		name: "MAGONGO",
		lga_id: 145,
	},
	{
		id: 2756,
		name: "BOMODEKU",
		lga_id: 166,
	},
	{
		id: 2757,
		name: "OMIGADE",
		lga_id: 268,
	},
	{
		id: 2758,
		name: "MOWO IKORODU",
		lga_id: 18,
	},
	{
		id: 2759,
		name: "KAKURI SOUTH",
		lga_id: 757,
	},
	{
		id: 2760,
		name: "ISUMPE",
		lga_id: 441,
	},
	{
		id: 2761,
		name: "OSUN STATE SECRETARIAT",
		lga_id: 268,
	},
	{
		id: 2762,
		name: "MONATAN",
		lga_id: 372,
	},
	{
		id: 2763,
		name: "AINYADE",
		lga_id: 392,
	},
	{
		id: 2764,
		name: "KUGBO",
		lga_id: 476,
	},
	{
		id: 2765,
		name: "IGWURUTA AXIS",
		lga_id: 611,
	},
	{
		id: 2766,
		name: "UMUMBO",
		lga_id: 229,
	},
	{
		id: 2767,
		name: "OBINETTI",
		lga_id: 147,
	},
	{
		id: 2768,
		name: "PANTAMI",
		lga_id: 347,
	},
	{
		id: 2769,
		name: "KELEBE",
		lga_id: 394,
	},
	{
		id: 2770,
		name: "OJODU BERGER, OGUN",
		lga_id: 549,
	},
	{
		id: 2771,
		name: "UBA KALA, UMUAHIA SOUTH",
		lga_id: 26,
	},
	{
		id: 2772,
		name: "GUMAU",
		lga_id: 489,
	},
	{
		id: 2773,
		name: "OSO OKWA",
		lga_id: 765,
	},
	{
		id: 2774,
		name: "ISHIEKPE QUARTERS",
		lga_id: 370,
	},
	{
		id: 2775,
		name: "UMAGBAE NORTH",
		lga_id: 279,
	},
	{
		id: 2776,
		name: "HADEJIA NEW GRA",
		lga_id: 290,
	},
	{
		id: 2777,
		name: "ORIA-ABRAKA",
		lga_id: 411,
	},
	{
		id: 2778,
		name: "AWE, NASARAWA",
		lga_id: 186,
	},
	{
		id: 2779,
		name: "ABUJA ROAD, YOLA",
		lga_id: 553,
	},
	{
		id: 2780,
		name: "NEKEDE GARDEN ESTATE",
		lga_id: 318,
	},
	{
		id: 2781,
		name: "KIYAWA",
		lga_id: 763,
	},
	{
		id: 2782,
		name: "RAFIN KADA",
		lga_id: 140,
	},
	{
		id: 2783,
		name: "KURAYE",
		lga_id: 123,
	},
	{
		id: 2784,
		name: "BONNY ISLAND",
		lga_id: 165,
	},
	{
		id: 2785,
		name: "KOFARE",
		lga_id: 362,
	},
	{
		id: 2786,
		name: "IGBONNA",
		lga_id: 99,
	},
	{
		id: 2787,
		name: "PAPA ADEYEMO",
		lga_id: 529,
	},
	{
		id: 2788,
		name: "PPL",
		lga_id: 228,
	},
	{
		id: 2789,
		name: "GAWU",
		lga_id: 525,
	},
	{
		id: 2790,
		name: "MAFONI",
		lga_id: 693,
	},
	{
		id: 2791,
		name: "ETEGWE",
		lga_id: 167,
	},
	{
		id: 2792,
		name: "YELWA YAURI",
		lga_id: 407,
	},
	{
		id: 2793,
		name: "ISALE ILOGBO",
		lga_id: 529,
	},
	{
		id: 2794,
		name: "ASHIGBA",
		lga_id: 452,
	},
	{
		id: 2795,
		name: "IKA AJEIGO",
		lga_id: 93,
	},
	{
		id: 2796,
		name: "ELUJU",
		lga_id: 751,
	},
	{
		id: 2797,
		name: "BINDAWA",
		lga_id: 415,
	},
	{
		id: 2798,
		name: "ONYANGEDE",
		lga_id: 445,
	},
	{
		id: 2799,
		name: "KAUGAMA",
		lga_id: 498,
	},
	{
		id: 2800,
		name: "JIBIA",
		lga_id: 138,
	},
	{
		id: 2801,
		name: "GOVERNMENT HOUSE, UYO",
		lga_id: 162,
	},
	{
		id: 2802,
		name: "IWO ROAD",
		lga_id: 183,
	},
	{
		id: 2803,
		name: "SHINKAFI",
		lga_id: 734,
	},
	{
		id: 2804,
		name: "IFO",
		lga_id: 549,
	},
	{
		id: 2805,
		name: "KAKAKPANGI",
		lga_id: 517,
	},
	{
		id: 2806,
		name: "OWERRE OLUBOR",
		lga_id: 125,
	},
	{
		id: 2807,
		name: "ILE AGBO",
		lga_id: 155,
	},
	{
		id: 2808,
		name: "BAYERO UNIVERSITY",
		lga_id: 703,
	},
	{
		id: 2809,
		name: "MANGORO IKEJA",
		lga_id: 16,
	},
	{
		id: 2810,
		name: "AMALA",
		lga_id: 676,
	},
	{
		id: 2811,
		name: "MANDO KADUNA NORTH",
		lga_id: 284,
	},
	{
		id: 2812,
		name: "IDOGUN",
		lga_id: 413,
	},
	{
		id: 2813,
		name: "KARU",
		lga_id: 476,
	},
	{
		id: 2814,
		name: "IJORA OLOPA",
		lga_id: 13,
	},
	{
		id: 2815,
		name: "KUCHI KUCHI",
		lga_id: 175,
	},
	{
		id: 2816,
		name: "MBUBE",
		lga_id: 310,
	},
	{
		id: 2817,
		name: "WAMBA",
		lga_id: 32,
	},
	{
		id: 2818,
		name: "NKPOR",
		lga_id: 669,
	},
	{
		id: 2819,
		name: "OSOGBO STATE CID",
		lga_id: 394,
	},
	{
		id: 2820,
		name: "EZI-ORSU",
		lga_id: 288,
	},
	{
		id: 2821,
		name: "IWARA",
		lga_id: 386,
	},
	{
		id: 2822,
		name: "UREJE",
		lga_id: 573,
	},
	{
		id: 2823,
		name: "NACHI",
		lga_id: 760,
	},
	{
		id: 2824,
		name: "ILEBA",
		lga_id: 62,
	},
	{
		id: 2825,
		name: "EMIRS PALACE, LAFIA",
		lga_id: 452,
	},
	{
		id: 2826,
		name: "IGBALA",
		lga_id: 18,
	},
	{
		id: 2827,
		name: "ETITA-EBOBE",
		lga_id: 15,
	},
	{
		id: 2828,
		name: "OLUSOSUN",
		lga_id: 584,
	},
	{
		id: 2829,
		name: "BRIDGE HEAD MARKET",
		lga_id: 343,
	},
	{
		id: 2830,
		name: "ONNEH",
		lga_id: 320,
	},
	{
		id: 2831,
		name: "OJE OWODE",
		lga_id: 434,
	},
	{
		id: 2832,
		name: "CRAWFORD UNIVERSITY OTA",
		lga_id: 629,
	},
	{
		id: 2833,
		name: "ORERE OWU",
		lga_id: 573,
	},
	{
		id: 2834,
		name: "PAPA AJAO",
		lga_id: 115,
	},
	{
		id: 2835,
		name: "ODO-OBA, IBADAN",
		lga_id: 126,
	},
	{
		id: 2836,
		name: "GWIWA, SOKOTO SOUTH",
		lga_id: 450,
	},
	{
		id: 2837,
		name: "ODOMOLA",
		lga_id: 15,
	},
	{
		id: 2838,
		name: "ERIN OKE",
		lga_id: 57,
	},
	{
		id: 2839,
		name: "KIRI, GUYUK",
		lga_id: 194,
	},
	{
		id: 2840,
		name: "NIST SAMONDA",
		lga_id: 524,
	},
	{
		id: 2841,
		name: "LAFIAGI, EDU",
		lga_id: 272,
	},
	{
		id: 2842,
		name: "NEW GRA, MAIDUGURI",
		lga_id: 693,
	},
	{
		id: 2843,
		name: "DOSA",
		lga_id: 284,
	},
	{
		id: 2844,
		name: "AGBONYIN",
		lga_id: 641,
	},
	{
		id: 2845,
		name: "OWERRE EZUKALA",
		lga_id: 320,
	},
	{
		id: 2846,
		name: "RUMUOMASI",
		lga_id: 78,
	},
	{
		id: 2847,
		name: "ANCHAU",
		lga_id: 204,
	},
	{
		id: 2848,
		name: "DUKKU",
		lga_id: 724,
	},
	{
		id: 2849,
		name: "GIDAN BOYI",
		lga_id: 476,
	},
	{
		id: 2850,
		name: "ELUOMA UZUAKOLI",
		lga_id: 344,
	},
	{
		id: 2851,
		name: "OBOWO, OGBARU",
		lga_id: 353,
	},
	{
		id: 2852,
		name: "ARIGIDI",
		lga_id: 258,
	},
	{
		id: 2853,
		name: "ISAN",
		lga_id: 328,
	},
	{
		id: 2854,
		name: "ARMY BARRACKS, KANO",
		lga_id: 718,
	},
	{
		id: 2855,
		name: "IFITE DUNU",
		lga_id: 772,
	},
	{
		id: 2856,
		name: "RUMUOLA",
		lga_id: 78,
	},
	{
		id: 2857,
		name: "ITIRE",
		lga_id: 641,
	},
	{
		id: 2858,
		name: "GYADI GYADI",
		lga_id: 236,
	},
	{
		id: 2859,
		name: "BURUKU CHIKUN",
		lga_id: 480,
	},
	{
		id: 2860,
		name: "NKWESI OGUTA",
		lga_id: 288,
	},
	{
		id: 2861,
		name: "ATIMBO WEST",
		lga_id: 33,
	},
	{
		id: 2862,
		name: "ROGO",
		lga_id: 226,
	},
	{
		id: 2863,
		name: "KWALLA",
		lga_id: 513,
	},
	{
		id: 2864,
		name: "FADEYI",
		lga_id: 115,
	},
	{
		id: 2865,
		name: "UMUOMAKU",
		lga_id: 320,
	},
	{
		id: 2866,
		name: "TAFAWA BALEWA WAY",
		lga_id: 284,
	},
	{
		id: 2867,
		name: "AJABATA",
		lga_id: 690,
	},
	{
		id: 2868,
		name: "MAYIR",
		lga_id: 117,
	},
	{
		id: 2869,
		name: "UMORU UMUODE",
		lga_id: 765,
	},
	{
		id: 2870,
		name: "KWANAR MADOBI",
		lga_id: 425,
	},
	{
		id: 2871,
		name: "COLLEGE OF EDUCATION, AGBOR",
		lga_id: 441,
	},
	{
		id: 2872,
		name: "IKOM RURAL",
		lga_id: 250,
	},
	{
		id: 2873,
		name: "OKIJA",
		lga_id: 207,
	},
	{
		id: 2874,
		name: "UMULOKPA",
		lga_id: 684,
	},
	{
		id: 2875,
		name: "OPEREMOR",
		lga_id: 743,
	},
	{
		id: 2876,
		name: "KPANSIA",
		lga_id: 167,
	},
	{
		id: 2877,
		name: "SULE TANKARKAR",
		lga_id: 710,
	},
	{
		id: 2878,
		name: "OHIAMINI",
		lga_id: 594,
	},
	{
		id: 2879,
		name: "KIMBA",
		lga_id: 472,
	},
	{
		id: 2880,
		name: "ASUTAN EPE",
		lga_id: 231,
	},
	{
		id: 2881,
		name: "IWADE AREA",
		lga_id: 619,
	},
	{
		id: 2882,
		name: "ALAGOMEJI",
		lga_id: 442,
	},
	{
		id: 2883,
		name: "AKWEBULU",
		lga_id: 574,
	},
	{
		id: 2884,
		name: "EMENE HOUSING ESTATE",
		lga_id: 197,
	},
	{
		id: 2885,
		name: "LAGOS POLYTECHNIC, IKORODU",
		lga_id: 18,
	},
	{
		id: 2886,
		name: "IKWEN",
		lga_id: 376,
	},
	{
		id: 2887,
		name: "GABASAWA",
		lga_id: 388,
	},
	{
		id: 2888,
		name: "ISIOKWE STREET",
		lga_id: 500,
	},
	{
		id: 2889,
		name: "NZEREM",
		lga_id: 536,
	},
	{
		id: 2890,
		name: "NTO EDINO",
		lga_id: 376,
	},
	{
		id: 2891,
		name: "AGAMA",
		lga_id: 681,
	},
	{
		id: 2892,
		name: "NUNG UKIM",
		lga_id: 373,
	},
	{
		id: 2893,
		name: "MILITARY POLICE SCHOOL, KADUNA",
		lga_id: 616,
	},
	{
		id: 2894,
		name: "MAGBORO",
		lga_id: 690,
	},
	{
		id: 2895,
		name: "ETETE",
		lga_id: 508,
	},
	{
		id: 2896,
		name: "OREGUN",
		lga_id: 16,
	},
	{
		id: 2897,
		name: "UPPER AND LOWER EREJUWA",
		lga_id: 417,
	},
	{
		id: 2898,
		name: "AFOWO DOFORO",
		lga_id: 595,
	},
	{
		id: 2899,
		name: "UNIVERSITY OF UYO",
		lga_id: 162,
	},
	{
		id: 2900,
		name: "BILLIRI",
		lga_id: 107,
	},
	{
		id: 2901,
		name: "OLEYO",
		lga_id: 85,
	},
	{
		id: 2902,
		name: "ORETA",
		lga_id: 18,
	},
	{
		id: 2903,
		name: "ITAKPE",
		lga_id: 421,
	},
	{
		id: 2904,
		name: "AGO AMODU",
		lga_id: 434,
	},
	{
		id: 2905,
		name: "IPONRIN",
		lga_id: 129,
	},
	{
		id: 2906,
		name: "KAJOLA, ADO EKITI",
		lga_id: 573,
	},
	{
		id: 2907,
		name: "ABOH",
		lga_id: 591,
	},
	{
		id: 2908,
		name: "LANLATE",
		lga_id: 740,
	},
	{
		id: 2909,
		name: "IKIRUN",
		lga_id: 468,
	},
	{
		id: 2910,
		name: "ISAWO, IFO",
		lga_id: 378,
	},
	{
		id: 2911,
		name: "ALAIYABIAGBA",
		lga_id: 267,
	},
	{
		id: 2912,
		name: "IBERE, IKWUANO",
		lga_id: 650,
	},
	{
		id: 2913,
		name: "DEMSA",
		lga_id: 217,
	},
	{
		id: 2914,
		name: "JERICHO",
		lga_id: 648,
	},
	{
		id: 2915,
		name: "IFITE AWKA",
		lga_id: 358,
	},
	{
		id: 2916,
		name: "POLCHIN KANAWA",
		lga_id: 489,
	},
	{
		id: 2917,
		name: "NASARAWA QUARTERS, DAURA",
		lga_id: 181,
	},
	{
		id: 2918,
		name: "KADUNA STATE UNIVERSITY",
		lga_id: 284,
	},
	{
		id: 2919,
		name: "MADAKA",
		lga_id: 429,
	},
	{
		id: 2920,
		name: "ONOPA",
		lga_id: 167,
	},
	{
		id: 2921,
		name: "IGBOKIN",
		lga_id: 212,
	},
	{
		id: 2922,
		name: "SAUKA KAHUTA",
		lga_id: 19,
	},
	{
		id: 2923,
		name: "AROCHUKWU",
		lga_id: 87,
	},
	{
		id: 2924,
		name: "BUKAR ABBA IBRAHIM ESTATE",
		lga_id: 259,
	},
	{
		id: 2925,
		name: "KUNCHI",
		lga_id: 601,
	},
	{
		id: 2926,
		name: "FCE GOMBE",
		lga_id: 347,
	},
	{
		id: 2927,
		name: "AMAKWU UKAMA",
		lga_id: 676,
	},
	{
		id: 2928,
		name: "BASSA, KOGI STATE",
		lga_id: 266,
	},
	{
		id: 2929,
		name: "GUDI",
		lga_id: 98,
	},
	{
		id: 2930,
		name: "MBIERI",
		lga_id: 37,
	},
	{
		id: 2931,
		name: "IRESE",
		lga_id: 212,
	},
	{
		id: 2932,
		name: "OYIGBO",
		lga_id: 137,
	},
	{
		id: 2933,
		name: "UMUOGUDU OSHIA",
		lga_id: 642,
	},
	{
		id: 2934,
		name: "NEKE",
		lga_id: 613,
	},
	{
		id: 2935,
		name: "OBAN",
		lga_id: 177,
	},
	{
		id: 2936,
		name: "ILISAN",
		lga_id: 151,
	},
	{
		id: 2937,
		name: "AYETE",
		lga_id: 733,
	},
	{
		id: 2938,
		name: "LEKKI PHASE 1",
		lga_id: 17,
	},
	{
		id: 2939,
		name: "UNGWAN MUSA",
		lga_id: 480,
	},
	{
		id: 2940,
		name: "GBONGAN",
		lga_id: 38,
	},
	{
		id: 2941,
		name: "IITA",
		lga_id: 524,
	},
	{
		id: 2942,
		name: "OKWE, IMO",
		lga_id: 657,
	},
	{
		id: 2943,
		name: "EGAN",
		lga_id: 577,
	},
	{
		id: 2944,
		name: "URUK",
		lga_id: 156,
	},
	{
		id: 2945,
		name: "ALAKA, ANKPA",
		lga_id: 93,
	},
	{
		id: 2946,
		name: "AMAOHURU",
		lga_id: 71,
	},
	{
		id: 2947,
		name: "NSUKARA ATAI",
		lga_id: 162,
	},
	{
		id: 2948,
		name: "JAJERI",
		lga_id: 693,
	},
	{
		id: 2949,
		name: "GALADIMA, ARGUNGU",
		lga_id: 172,
	},
	{
		id: 2950,
		name: "FUNE",
		lga_id: 774,
	},
	{
		id: 2951,
		name: "ILASHE, AMUWO ODOFIN",
		lga_id: 694,
	},
	{
		id: 2952,
		name: "COLLEGE OF EDUCATION ILESA EAST",
		lga_id: 470,
	},
	{
		id: 2953,
		name: "OKADA",
		lga_id: 393,
	},
	{
		id: 2954,
		name: "EMUHU",
		lga_id: 441,
	},
	{
		id: 2955,
		name: "AYARAGU",
		lga_id: 408,
	},
	{
		id: 2956,
		name: "EZINIH",
		lga_id: 71,
	},
	{
		id: 2957,
		name: "ILAYE",
		lga_id: 378,
	},
	{
		id: 2958,
		name: "AGBARO TOWN AREA",
		lga_id: 411,
	},
	{
		id: 2959,
		name: "EDIENE",
		lga_id: 540,
	},
	{
		id: 2960,
		name: "UNIVERSITY OF NIGERIA",
		lga_id: 557,
	},
	{
		id: 2961,
		name: "HOTORO GRA",
		lga_id: 236,
	},
	{
		id: 2962,
		name: "ZANGO, ZANGO",
		lga_id: 662,
	},
	{
		id: 2963,
		name: "AGRIC, IKORODU",
		lga_id: 18,
	},
	{
		id: 2964,
		name: "KUJAMA",
		lga_id: 480,
	},
	{
		id: 2965,
		name: "OKPAI",
		lga_id: 591,
	},
	{
		id: 2966,
		name: "NDIYA",
		lga_id: 635,
	},
	{
		id: 2967,
		name: "KOKO PARK",
		lga_id: 754,
	},
	{
		id: 2968,
		name: "MOLAI GRA",
		lga_id: 693,
	},
	{
		id: 2969,
		name: "GHENGUGI",
		lga_id: 106,
	},
	{
		id: 2970,
		name: "OKENE",
		lga_id: 762,
	},
	{
		id: 2971,
		name: "IDAH IGALAMELA ODOLU",
		lga_id: 223,
	},
	{
		id: 2972,
		name: "OKE ODE",
		lga_id: 309,
	},
	{
		id: 2973,
		name: "KOMI",
		lga_id: 615,
	},
	{
		id: 2974,
		name: "EGBEDE",
		lga_id: 279,
	},
	{
		id: 2975,
		name: "IYATAWA TOWN",
		lga_id: 443,
	},
	{
		id: 2976,
		name: "OVOM 2",
		lga_id: 167,
	},
	{
		id: 2977,
		name: "AKPAJU",
		lga_id: 251,
	},
	{
		id: 2978,
		name: "APO",
		lga_id: 476,
	},
	{
		id: 2979,
		name: "EZECHIME",
		lga_id: 370,
	},
	{
		id: 2980,
		name: "GIDAN IGWAI",
		lga_id: 81,
	},
	{
		id: 2981,
		name: "NIWA QUARTERS LOKOJA",
		lga_id: 187,
	},
	{
		id: 2982,
		name: "OGUNGBE",
		lga_id: 595,
	},
	{
		id: 2983,
		name: "AGBOYI",
		lga_id: 465,
	},
	{
		id: 2984,
		name: "SOKOTO POLY",
		lga_id: 450,
	},
	{
		id: 2985,
		name: "GERIE",
		lga_id: 60,
	},
	{
		id: 2986,
		name: "EKENWA",
		lga_id: 508,
	},
	{
		id: 2987,
		name: "ODA",
		lga_id: 199,
	},
	{
		id: 2988,
		name: "UMAGBAE SOUTH",
		lga_id: 279,
	},
	{
		id: 2989,
		name: "OFADA",
		lga_id: 690,
	},
	{
		id: 2990,
		name: "URUMEDJI ESTATE",
		lga_id: 532,
	},
	{
		id: 2991,
		name: "UBIAJA",
		lga_id: 55,
	},
	{
		id: 2992,
		name: "UMUEHE",
		lga_id: 463,
	},
	{
		id: 2993,
		name: "OGBA",
		lga_id: 584,
	},
	{
		id: 2994,
		name: "OMISANJANA",
		lga_id: 573,
	},
	{
		id: 2995,
		name: "MANGORO IKEJA",
		lga_id: 584,
	},
	{
		id: 2996,
		name: "KAJOLA, OGUN",
		lga_id: 690,
	},
	{
		id: 2997,
		name: "CHIMBI",
		lga_id: 750,
	},
	{
		id: 2998,
		name: "UCHENNA UMERIE",
		lga_id: 554,
	},
	{
		id: 2999,
		name: "ABA CENTRAL",
		lga_id: 100,
	},
	{
		id: 3000,
		name: "UROMI",
		lga_id: 550,
	},
	{
		id: 3001,
		name: "IPELE",
		lga_id: 593,
	},
	{
		id: 3002,
		name: "ALE",
		lga_id: 10,
	},
	{
		id: 3003,
		name: "KOFAR AREWA",
		lga_id: 20,
	},
	{
		id: 3004,
		name: "UGBOWO",
		lga_id: 23,
	},
	{
		id: 3005,
		name: "BAZZA",
		lga_id: 92,
	},
	{
		id: 3006,
		name: "IKATE ELEGUSHI",
		lga_id: 17,
	},
	{
		id: 3007,
		name: "ASON",
		lga_id: 18,
	},
	{
		id: 3008,
		name: "CHIYODA",
		lga_id: 480,
	},
	{
		id: 3009,
		name: "ADETOKUNBO ADEMOLA",
		lga_id: 17,
	},
	{
		id: 3010,
		name: "OVIRE",
		lga_id: 438,
	},
	{
		id: 3011,
		name: "OGBA",
		lga_id: 16,
	},
	{
		id: 3012,
		name: "ESSI LAYOUT",
		lga_id: 417,
	},
	{
		id: 3013,
		name: "RINGIM",
		lga_id: 321,
	},
	{
		id: 3014,
		name: "IHOGBE",
		lga_id: 508,
	},
	{
		id: 3015,
		name: "IKOT EFFA",
		lga_id: 559,
	},
	{
		id: 3016,
		name: "UMUEHILEGBU ABA",
		lga_id: 765,
	},
	{
		id: 3017,
		name: "MINNA GRA",
		lga_id: 97,
	},
	{
		id: 3018,
		name: "OKEPADI",
		lga_id: 285,
	},
	{
		id: 3019,
		name: "ILARA, LAGOS",
		lga_id: 541,
	},
	{
		id: 3020,
		name: "PALM SPRING ESTATE",
		lga_id: 410,
	},
	{
		id: 3021,
		name: "OLORUNDA, IBADAN",
		lga_id: 524,
	},
	{
		id: 3022,
		name: "OGBE, OREDO",
		lga_id: 508,
	},
	{
		id: 3023,
		name: "IJORA",
		lga_id: 13,
	},
	{
		id: 3024,
		name: "KAGARKO",
		lga_id: 225,
	},
	{
		id: 3025,
		name: "GIZA",
		lga_id: 516,
	},
	{
		id: 3026,
		name: "GWANGE, DAMATURU",
		lga_id: 259,
	},
	{
		id: 3027,
		name: "EGBEDA, OYO",
		lga_id: 607,
	},
	{
		id: 3028,
		name: "OTUN QUARTERS",
		lga_id: 629,
	},
	{
		id: 3029,
		name: "OGBAKU, ANINRI",
		lga_id: 422,
	},
	{
		id: 3030,
		name: "ASEYIN",
		lga_id: 748,
	},
	{
		id: 3031,
		name: "ALAKOTO",
		lga_id: 267,
	},
	{
		id: 3032,
		name: "SEKONA",
		lga_id: 546,
	},
	{
		id: 3033,
		name: "ISIUWA",
		lga_id: 393,
	},
	{
		id: 3034,
		name: "IJORA OLOPA",
		lga_id: 526,
	},
	{
		id: 3035,
		name: "ABIJOH VILLAGE",
		lga_id: 17,
	},
	{
		id: 3036,
		name: "AGBAMA HOUSING ESTATE",
		lga_id: 26,
	},
	{
		id: 3037,
		name: "ELELENWO",
		lga_id: 78,
	},
	{
		id: 3038,
		name: "SOUTHERN URUAN",
		lga_id: 367,
	},
	{
		id: 3039,
		name: "OKO-EKO",
		lga_id: 212,
	},
	{
		id: 3040,
		name: "AMAKOHIA UBI",
		lga_id: 189,
	},
	{
		id: 3041,
		name: "NGWAIYIEKWE",
		lga_id: 658,
	},
	{
		id: 3042,
		name: "IGBOTAKO",
		lga_id: 248,
	},
	{
		id: 3043,
		name: "ADANI",
		lga_id: 684,
	},
	{
		id: 3044,
		name: "SABO, KADUNA",
		lga_id: 480,
	},
	{
		id: 3045,
		name: "MAIHA",
		lga_id: 324,
	},
	{
		id: 3046,
		name: "TRANS AMADI",
		lga_id: 594,
	},
	{
		id: 3047,
		name: "UMULERI",
		lga_id: 499,
	},
	{
		id: 3048,
		name: "IDISIN GRA IBADAN NORTH-WEST",
		lga_id: 648,
	},
	{
		id: 3049,
		name: "GYEL",
		lga_id: 683,
	},
	{
		id: 3050,
		name: "DAMATURU STATE HOTELS",
		lga_id: 259,
	},
	{
		id: 3051,
		name: "IKO EKPEREM",
		lga_id: 177,
	},
	{
		id: 3052,
		name: "LUGBE ZONE 1",
		lga_id: 476,
	},
	{
		id: 3053,
		name: "KABO",
		lga_id: 646,
	},
	{
		id: 3054,
		name: "GIDAN DAN ASABE",
		lga_id: 236,
	},
	{
		id: 3055,
		name: "OLD OYO NATIONAL PARK ATIBA",
		lga_id: 630,
	},
	{
		id: 3056,
		name: "MONIYA",
		lga_id: 524,
	},
	{
		id: 3057,
		name: "SARKIN PAWA",
		lga_id: 175,
	},
	{
		id: 3058,
		name: "NASSARAWA I BIRNIN KEBBI",
		lga_id: 706,
	},
	{
		id: 3059,
		name: "IGBOGILA",
		lga_id: 62,
	},
	{
		id: 3060,
		name: "EHA-AMUFU",
		lga_id: 708,
	},
	{
		id: 3061,
		name: "GORON DUTSE",
		lga_id: 182,
	},
	{
		id: 3062,
		name: "OYE NENI",
		lga_id: 387,
	},
	{
		id: 3063,
		name: "BIRNIN KEBBI RURAL 2",
		lga_id: 706,
	},
	{
		id: 3064,
		name: "UBURU",
		lga_id: 673,
	},
	{
		id: 3065,
		name: "OMI",
		lga_id: 366,
	},
	{
		id: 3066,
		name: "EKE AMIYI UHU",
		lga_id: 65,
	},
	{
		id: 3067,
		name: "ACHAN IKA",
		lga_id: 346,
	},
	{
		id: 3068,
		name: "IGBOBI",
		lga_id: 404,
	},
	{
		id: 3069,
		name: "IFON OSUN",
		lga_id: 436,
	},
	{
		id: 3070,
		name: "IGBEMO EKITI",
		lga_id: 43,
	},
	{
		id: 3071,
		name: "IKPER-EJERE",
		lga_id: 701,
	},
	{
		id: 3072,
		name: "OLOKUTA",
		lga_id: 680,
	},
	{
		id: 3073,
		name: "OFIN",
		lga_id: 18,
	},
	{
		id: 3074,
		name: "KUTA, SHIRORO",
		lga_id: 429,
	},
	{
		id: 3075,
		name: "OFFA IGBO",
		lga_id: 372,
	},
	{
		id: 3076,
		name: "KABWIR",
		lga_id: 238,
	},
	{
		id: 3077,
		name: "AKOMONIKEWU",
		lga_id: 468,
	},
	{
		id: 3078,
		name: "WUSE 1 ZONE 6",
		lga_id: 476,
	},
	{
		id: 3079,
		name: "IKOT ANNANG",
		lga_id: 346,
	},
	{
		id: 3080,
		name: "HASHIDU",
		lga_id: 724,
	},
	{
		id: 3081,
		name: "OBONG",
		lga_id: 156,
	},
	{
		id: 3082,
		name: "OLOJE WEST",
		lga_id: 108,
	},
	{
		id: 3083,
		name: "IJEBU ITELE",
		lga_id: 299,
	},
	{
		id: 3084,
		name: "OKE OPO GRA, ILESA WEST",
		lga_id: 720,
	},
	{
		id: 3085,
		name: "EMENE",
		lga_id: 276,
	},
	{
		id: 3086,
		name: "KIRIBO",
		lga_id: 127,
	},
	{
		id: 3087,
		name: "ELLU",
		lga_id: 691,
	},
	{
		id: 3088,
		name: "DU",
		lga_id: 683,
	},
	{
		id: 3089,
		name: "NOVENA COLLEGE AREA , AMAI",
		lga_id: 610,
	},
	{
		id: 3090,
		name: "MFAMOSING",
		lga_id: 177,
	},
	{
		id: 3091,
		name: "EKPANYA",
		lga_id: 162,
	},
	{
		id: 3092,
		name: "GARKAR JATUA",
		lga_id: 172,
	},
	{
		id: 3093,
		name: "SHINKAFI, KATSINA",
		lga_id: 399,
	},
	{
		id: 3094,
		name: "ITAOGBOLU",
		lga_id: 52,
	},
	{
		id: 3095,
		name: "MUMUYE",
		lga_id: 379,
	},
	{
		id: 3096,
		name: "UMUAKPARA",
		lga_id: 463,
	},
	{
		id: 3097,
		name: "OLOFUNA",
		lga_id: 18,
	},
	{
		id: 3098,
		name: "KPADA",
		lga_id: 260,
	},
	{
		id: 3099,
		name: "AYEDE, EGBEDA",
		lga_id: 607,
	},
	{
		id: 3100,
		name: "NORTHERN FORESHORE ESTATE",
		lga_id: 410,
	},
	{
		id: 3101,
		name: "UMU OKAHIA",
		lga_id: 463,
	},
	{
		id: 3102,
		name: "TARMUWA",
		lga_id: 83,
	},
	{
		id: 3103,
		name: "JIKOYI",
		lga_id: 476,
	},
	{
		id: 3104,
		name: "OBA AKRAN",
		lga_id: 584,
	},
	{
		id: 3105,
		name: "CHEVY ESTATE",
		lga_id: 17,
	},
	{
		id: 3106,
		name: "MAKUSIDI",
		lga_id: 427,
	},
	{
		id: 3107,
		name: "EKET AIRPORT",
		lga_id: 51,
	},
	{
		id: 3108,
		name: "UMU UHIE",
		lga_id: 474,
	},
	{
		id: 3109,
		name: "YACHE",
		lga_id: 761,
	},
	{
		id: 3110,
		name: "EZIAMA",
		lga_id: 633,
	},
	{
		id: 3111,
		name: "KUTA, AIYEDIRE",
		lga_id: 505,
	},
	{
		id: 3112,
		name: "RUMUKPAKOLOSI",
		lga_id: 78,
	},
	{
		id: 3113,
		name: "UMUELEBA OBOKWE",
		lga_id: 676,
	},
	{
		id: 3114,
		name: "KAKPI",
		lga_id: 307,
	},
	{
		id: 3115,
		name: "OGBOGORO",
		lga_id: 78,
	},
	{
		id: 3116,
		name: "AJIBODE",
		lga_id: 524,
	},
	{
		id: 3117,
		name: "STATE SECRETARIAT BAUCHI",
		lga_id: 653,
	},
	{
		id: 3118,
		name: "ANGAM VILLAGE",
		lga_id: 98,
	},
	{
		id: 3119,
		name: "GWIWA, GWIWA",
		lga_id: 606,
	},
	{
		id: 3120,
		name: "LANZAI",
		lga_id: 586,
	},
	{
		id: 3121,
		name: "YELWA VILLAGE",
		lga_id: 653,
	},
	{
		id: 3122,
		name: "MATORI",
		lga_id: 115,
	},
	{
		id: 3123,
		name: "OBELE",
		lga_id: 10,
	},
	{
		id: 3124,
		name: "WORD OF FAITH RD",
		lga_id: 441,
	},
	{
		id: 3125,
		name: "NKPORO",
		lga_id: 522,
	},
	{
		id: 3126,
		name: "MBIABONG UYO",
		lga_id: 162,
	},
	{
		id: 3127,
		name: "HEIPANG",
		lga_id: 458,
	},
	{
		id: 3128,
		name: "ALLOMA",
		lga_id: 731,
	},
	{
		id: 3129,
		name: "JENTA",
		lga_id: 732,
	},
	{
		id: 3130,
		name: "IYIN EKITI",
		lga_id: 43,
	},
	{
		id: 3131,
		name: "MAZA MAZA",
		lga_id: 694,
	},
	{
		id: 3132,
		name: "ABIA STATE UNIVERSITY",
		lga_id: 65,
	},
	{
		id: 3133,
		name: "RADDA",
		lga_id: 123,
	},
	{
		id: 3134,
		name: "AMAZANO",
		lga_id: 77,
	},
	{
		id: 3135,
		name: "OMOLE",
		lga_id: 16,
	},
	{
		id: 3136,
		name: "IGANDO, ALIMOSHO",
		lga_id: 577,
	},
	{
		id: 3137,
		name: "KWARI",
		lga_id: 194,
	},
	{
		id: 3138,
		name: "MOHD MUSTAPHA WAY, YOLA",
		lga_id: 553,
	},
	{
		id: 3139,
		name: "ALHERI",
		lga_id: 732,
	},
	{
		id: 3140,
		name: "COVENANT UNIVERSITY OTA",
		lga_id: 629,
	},
	{
		id: 3141,
		name: "BANTAJE",
		lga_id: 140,
	},
	{
		id: 3142,
		name: "TRANS NKISI ESTATE",
		lga_id: 500,
	},
	{
		id: 3143,
		name: "MODAKEKE",
		lga_id: 713,
	},
	{
		id: 3144,
		name: "OGBORO",
		lga_id: 434,
	},
	{
		id: 3145,
		name: "NSUKKA",
		lga_id: 557,
	},
	{
		id: 3146,
		name: "OSAPA LONDON",
		lga_id: 17,
	},
	{
		id: 3147,
		name: "ONIKE",
		lga_id: 442,
	},
	{
		id: 3148,
		name: "ORIE UMULOGHO",
		lga_id: 688,
	},
	{
		id: 3149,
		name: "OKE UREJE",
		lga_id: 573,
	},
	{
		id: 3150,
		name: "AMARYAWA",
		lga_id: 301,
	},
	{
		id: 3151,
		name: "IKANI AJAKA",
		lga_id: 223,
	},
	{
		id: 3152,
		name: "ERUWA, OGUN",
		lga_id: 356,
	},
	{
		id: 3153,
		name: "SEJE",
		lga_id: 595,
	},
	{
		id: 3154,
		name: "KWABO",
		lga_id: 357,
	},
	{
		id: 3155,
		name: "KWARA STATE POLYTECNIC",
		lga_id: 36,
	},
	{
		id: 3156,
		name: "ALABA RAGO",
		lga_id: 228,
	},
	{
		id: 3157,
		name: "OGODO",
		lga_id: 93,
	},
	{
		id: 3158,
		name: "ITU MBONUSO",
		lga_id: 685,
	},
	{
		id: 3159,
		name: "DAN ARCHIBONG",
		lga_id: 559,
	},
	{
		id: 3160,
		name: "MACHERI",
		lga_id: 80,
	},
	{
		id: 3161,
		name: "GOMBI",
		lga_id: 776,
	},
	{
		id: 3162,
		name: "MBOKO",
		lga_id: 463,
	},
	{
		id: 3163,
		name: "ABIJOH GRA",
		lga_id: 751,
	},
	{
		id: 3164,
		name: "UMUNEKE NGOR",
		lga_id: 676,
	},
	{
		id: 3165,
		name: "IJOWA",
		lga_id: 283,
	},
	{
		id: 3166,
		name: "CEMENT ALIMOSHO, LAGOS",
		lga_id: 577,
	},
	{
		id: 3167,
		name: "ILESHA GARAGE, OSOGBO",
		lga_id: 219,
	},
	{
		id: 3168,
		name: "HOWAYI",
		lga_id: 379,
	},
	{
		id: 3169,
		name: "OSUN STATE POLYTECHNIC",
		lga_id: 515,
	},
	{
		id: 3170,
		name: "GIDAN BAURE",
		lga_id: 138,
	},
	{
		id: 3171,
		name: "FEDERAL HOUSING CALABAR",
		lga_id: 543,
	},
	{
		id: 3172,
		name: "EWOSSA",
		lga_id: 461,
	},
	{
		id: 3173,
		name: "OSONGAMA ESTATE",
		lga_id: 162,
	},
	{
		id: 3174,
		name: "NTIBUKA ESTATE",
		lga_id: 741,
	},
	{
		id: 3175,
		name: "LEMU",
		lga_id: 106,
	},
	{
		id: 3176,
		name: "OBOT AKARA",
		lga_id: 376,
	},
	{
		id: 3177,
		name: "TINAPA RESORT",
		lga_id: 543,
	},
	{
		id: 3178,
		name: "IDUMOTA",
		lga_id: 766,
	},
	{
		id: 3179,
		name: "IRE EKITI OYE",
		lga_id: 328,
	},
	{
		id: 3180,
		name: "IKOYI-APOMU",
		lga_id: 469,
	},
	{
		id: 3181,
		name: "OBELE",
		lga_id: 595,
	},
	{
		id: 3182,
		name: "AWKA MARKET",
		lga_id: 358,
	},
	{
		id: 3183,
		name: "KOKOROO OJIA",
		lga_id: 333,
	},
	{
		id: 3184,
		name: "IRAGON",
		lga_id: 10,
	},
	{
		id: 3185,
		name: "RUWAN BORE",
		lga_id: 247,
	},
	{
		id: 3186,
		name: "ADETOKUNBO ADEMOLA",
		lga_id: 410,
	},
	{
		id: 3187,
		name: "UGBEYIYI ROAD",
		lga_id: 754,
	},
	{
		id: 3188,
		name: "DAWAKIN TOFA",
		lga_id: 131,
	},
	{
		id: 3189,
		name: "KANFANIN BOBI",
		lga_id: 361,
	},
	{
		id: 3190,
		name: "GBUGBU",
		lga_id: 272,
	},
	{
		id: 3191,
		name: "UBOWALLA",
		lga_id: 741,
	},
	{
		id: 3192,
		name: "TUDUN WADA GUSAU",
		lga_id: 34,
	},
	{
		id: 3193,
		name: "GANI FAWEHINMI",
		lga_id: 285,
	},
	{
		id: 3194,
		name: "GANJUWA",
		lga_id: 729,
	},
	{
		id: 3195,
		name: "IDI IGBA",
		lga_id: 282,
	},
	{
		id: 3196,
		name: "APINITE",
		lga_id: 615,
	},
	{
		id: 3197,
		name: "MAKARA",
		lga_id: 757,
	},
	{
		id: 3198,
		name: "PRESCO",
		lga_id: 68,
	},
	{
		id: 3199,
		name: "IJEDODO",
		lga_id: 577,
	},
	{
		id: 3200,
		name: "IJEBU IFE",
		lga_id: 299,
	},
	{
		id: 3201,
		name: "FELELE",
		lga_id: 126,
	},
	{
		id: 3202,
		name: "IKOT EKPENE",
		lga_id: 278,
	},
	{
		id: 3203,
		name: "OMOR",
		lga_id: 229,
	},
	{
		id: 3204,
		name: "EKPON",
		lga_id: 461,
	},
	{
		id: 3205,
		name: "ODE-OMI",
		lga_id: 541,
	},
	{
		id: 3206,
		name: "AMARATA EPIE",
		lga_id: 167,
	},
	{
		id: 3207,
		name: "IHO",
		lga_id: 63,
	},
	{
		id: 3208,
		name: "DOLPHIN ESTATE",
		lga_id: 17,
	},
	{
		id: 3209,
		name: "GOMBE ABBA",
		lga_id: 724,
	},
	{
		id: 3210,
		name: "AGUNGI",
		lga_id: 17,
	},
	{
		id: 3211,
		name: "ITA EFUN",
		lga_id: 394,
	},
	{
		id: 3212,
		name: "IGBOGUN",
		lga_id: 15,
	},
	{
		id: 3213,
		name: "SHAGARI VILLAGE",
		lga_id: 199,
	},
	{
		id: 3214,
		name: "ISA, SOKOTO",
		lga_id: 582,
	},
	{
		id: 3215,
		name: "AKOBO",
		lga_id: 372,
	},
	{
		id: 3216,
		name: "IWORO",
		lga_id: 595,
	},
	{
		id: 3217,
		name: "SHAGARI LOW COST",
		lga_id: 693,
	},
	{
		id: 3218,
		name: "MISAU",
		lga_id: 640,
	},
	{
		id: 3219,
		name: "UKPOR",
		lga_id: 170,
	},
	{
		id: 3220,
		name: "ORAUKWU",
		lga_id: 387,
	},
	{
		id: 3221,
		name: "NUNKAI",
		lga_id: 222,
	},
	{
		id: 3222,
		name: "KAJOLA IJESHA",
		lga_id: 203,
	},
	{
		id: 3223,
		name: "AGULUZIGBO, ANAOCHA",
		lga_id: 387,
	},
	{
		id: 3224,
		name: "NJIAKATA AMANI",
		lga_id: 463,
	},
	{
		id: 3225,
		name: "AZIKWE RD",
		lga_id: 100,
	},
	{
		id: 3226,
		name: "HERWA GANA",
		lga_id: 347,
	},
	{
		id: 3227,
		name: "FUGAR AVIANWU",
		lga_id: 431,
	},
	{
		id: 3228,
		name: "IYAGANKU IBADAN NORTH-WEST",
		lga_id: 648,
	},
	{
		id: 3229,
		name: "ABAYI, ISIALA NGWA SOUTH",
		lga_id: 119,
	},
	{
		id: 3230,
		name: "BADE",
		lga_id: 580,
	},
	{
		id: 3231,
		name: "AJOSE ADEOGUN",
		lga_id: 17,
	},
	{
		id: 3232,
		name: "AHMADU BELLO UNIVERSITY",
		lga_id: 616,
	},
	{
		id: 3233,
		name: "ETA AGBOR",
		lga_id: 559,
	},
	{
		id: 3234,
		name: "GARUN MALAM",
		lga_id: 457,
	},
	{
		id: 3235,
		name: "AGAIE",
		lga_id: 307,
	},
	{
		id: 3236,
		name: "FUT MINNA",
		lga_id: 97,
	},
	{
		id: 3237,
		name: "UBANI",
		lga_id: 598,
	},
	{
		id: 3238,
		name: "EDE NYSC SECRETARIAT",
		lga_id: 546,
	},
	{
		id: 3239,
		name: "OLOA OSSISSA KWAL",
		lga_id: 591,
	},
	{
		id: 3240,
		name: "HOUSE CORPORATION BATA",
		lga_id: 349,
	},
	{
		id: 3241,
		name: "BATSARI",
		lga_id: 568,
	},
	{
		id: 3242,
		name: "KAJOLA, LAGOS",
		lga_id: 751,
	},
	{
		id: 3243,
		name: "JALINGO RURAL",
		lga_id: 222,
	},
	{
		id: 3244,
		name: "NASARAWA, KADUNA",
		lga_id: 757,
	},
	{
		id: 3245,
		name: "KURUMDUMA",
		lga_id: 476,
	},
	{
		id: 3246,
		name: "GASHALA",
		lga_id: 53,
	},
	{
		id: 3247,
		name: "KAROFI, FUNTUA",
		lga_id: 122,
	},
	{
		id: 3248,
		name: "UMUNYA",
		lga_id: 717,
	},
	{
		id: 3249,
		name: "IRAGBIJI",
		lga_id: 515,
	},
	{
		id: 3250,
		name: "FGGC MAIDUGURI",
		lga_id: 693,
	},
	{
		id: 3251,
		name: "OLD GRA, PH",
		lga_id: 594,
	},
	{
		id: 3252,
		name: "OGUI",
		lga_id: 197,
	},
	{
		id: 3253,
		name: "IPANMI",
		lga_id: 212,
	},
	{
		id: 3254,
		name: "BAKUWAI",
		lga_id: 705,
	},
	{
		id: 3255,
		name: "KWANJI",
		lga_id: 144,
	},
	{
		id: 3256,
		name: "SODEKE IWO",
		lga_id: 155,
	},
	{
		id: 3257,
		name: "DEMSAWO",
		lga_id: 362,
	},
	{
		id: 3258,
		name: "LANGTANG",
		lga_id: 551,
	},
	{
		id: 3259,
		name: "RABAH",
		lga_id: 363,
	},
	{
		id: 3260,
		name: "KOFAR SAURI",
		lga_id: 399,
	},
	{
		id: 3261,
		name: "DUTSIN KURA",
		lga_id: 97,
	},
	{
		id: 3262,
		name: "RIDO",
		lga_id: 480,
	},
	{
		id: 3263,
		name: "TAKUM",
		lga_id: 709,
	},
	{
		id: 3264,
		name: "UMUOCHIMA",
		lga_id: 598,
	},
	{
		id: 3265,
		name: "BIRNIN KEBBI RURAL 1",
		lga_id: 706,
	},
	{
		id: 3266,
		name: "LEKKI SCHEME 2",
		lga_id: 410,
	},
	{
		id: 3267,
		name: "IGBOGUN",
		lga_id: 541,
	},
	{
		id: 3268,
		name: "UMUEME, ORU EAST",
		lga_id: 77,
	},
	{
		id: 3269,
		name: "MANGA USHE",
		lga_id: 655,
	},
	{
		id: 3270,
		name: "BOURDILLON",
		lga_id: 17,
	},
	{
		id: 3271,
		name: "OTOKITI",
		lga_id: 421,
	},
	{
		id: 3272,
		name: "IFE ODAN",
		lga_id: 545,
	},
	{
		id: 3273,
		name: "OBUKPA",
		lga_id: 557,
	},
	{
		id: 3274,
		name: "BAWO VILLAGE",
		lga_id: 368,
	},
	{
		id: 3275,
		name: "YAKILA",
		lga_id: 80,
	},
	{
		id: 3276,
		name: "TOPO",
		lga_id: 595,
	},
	{
		id: 3277,
		name: "ELELE",
		lga_id: 611,
	},
	{
		id: 3278,
		name: "UGBOLOKE",
		lga_id: 68,
	},
	{
		id: 3279,
		name: "WEPPA WANO",
		lga_id: 585,
	},
	{
		id: 3280,
		name: "OKITIPUPA",
		lga_id: 248,
	},
	{
		id: 3281,
		name: "UNGUMAR DAWAKI",
		lga_id: 247,
	},
	{
		id: 3282,
		name: "NKWEOGWU",
		lga_id: 598,
	},
	{
		id: 3283,
		name: "UMUMA",
		lga_id: 382,
	},
	{
		id: 3284,
		name: "NGASKI",
		lga_id: 157,
	},
	{
		id: 3285,
		name: "OLA EJIGBO",
		lga_id: 545,
	},
	{
		id: 3286,
		name: "BUNKURE",
		lga_id: 435,
	},
	{
		id: 3287,
		name: "BADIKO",
		lga_id: 757,
	},
	{
		id: 3288,
		name: "AHMADU BELLO WAY, KADUNA",
		lga_id: 284,
	},
	{
		id: 3289,
		name: "KUBACHA",
		lga_id: 225,
	},
	{
		id: 3290,
		name: "UMUHU",
		lga_id: 598,
	},
	{
		id: 3291,
		name: "OGBUNABALI",
		lga_id: 594,
	},
	{
		id: 3292,
		name: "UMUEZU AWOBU",
		lga_id: 772,
	},
	{
		id: 3293,
		name: "OGORI",
		lga_id: 145,
	},
	{
		id: 3294,
		name: "BARANAWA PHASE 1",
		lga_id: 480,
	},
	{
		id: 3295,
		name: "ABIJOH VILLAGE",
		lga_id: 410,
	},
	{
		id: 3296,
		name: "AHMADU BELLO, VI",
		lga_id: 410,
	},
	{
		id: 3297,
		name: "IKOT OMIN",
		lga_id: 559,
	},
	{
		id: 3298,
		name: "AJA IJIMU",
		lga_id: 212,
	},
	{
		id: 3299,
		name: "KAURU",
		lga_id: 54,
	},
	{
		id: 3300,
		name: "BAYELSA GOVERNMENT HOUSE",
		lga_id: 167,
	},
	{
		id: 3301,
		name: "UMUOCHAM ROAD",
		lga_id: 590,
	},
	{
		id: 3302,
		name: "KAMAZO",
		lga_id: 480,
	},
	{
		id: 3303,
		name: "BENDEL ESTATE, WARRI",
		lga_id: 532,
	},
	{
		id: 3304,
		name: "AFAHA OKPO",
		lga_id: 325,
	},
	{
		id: 3305,
		name: "OYO LOW COST HOUSING ESTATE",
		lga_id: 282,
	},
	{
		id: 3306,
		name: "OKPARA ISLAND",
		lga_id: 438,
	},
	{
		id: 3307,
		name: "OLUYOLE EXTENSION IBADAN SW",
		lga_id: 126,
	},
	{
		id: 3308,
		name: "BRASS",
		lga_id: 300,
	},
	{
		id: 3309,
		name: "BENIN CITY GRA",
		lga_id: 508,
	},
	{
		id: 3310,
		name: "IKATE ELEGUSHI",
		lga_id: 410,
	},
	{
		id: 3311,
		name: "MERAN",
		lga_id: 577,
	},
	{
		id: 3312,
		name: "EHOM",
		lga_id: 30,
	},
	{
		id: 3313,
		name: "EMEVOR",
		lga_id: 691,
	},
	{
		id: 3314,
		name: "OLOMORO",
		lga_id: 494,
	},
	{
		id: 3315,
		name: "FAKAI",
		lga_id: 454,
	},
	{
		id: 3316,
		name: "ABULOMA VILLAGE",
		lga_id: 594,
	},
	{
		id: 3317,
		name: "ISIN",
		lga_id: 312,
	},
	{
		id: 3318,
		name: "OJODU",
		lga_id: 731,
	},
	{
		id: 3319,
		name: "OLD IFE ROAD, IBADAN",
		lga_id: 607,
	},
	{
		id: 3320,
		name: "EDOZHIGI",
		lga_id: 106,
	},
	{
		id: 3321,
		name: "OBETERU",
		lga_id: 463,
	},
	{
		id: 3322,
		name: "OLUTI",
		lga_id: 694,
	},
	{
		id: 3323,
		name: "JAKANDE ESTATE, LEKKI",
		lga_id: 17,
	},
	{
		id: 3324,
		name: "ENUGUABO",
		lga_id: 257,
	},
	{
		id: 3325,
		name: "AJILETE ESTATE",
		lga_id: 529,
	},
	{
		id: 3326,
		name: "IBARA GRA",
		lga_id: 118,
	},
	{
		id: 3327,
		name: "OSI",
		lga_id: 313,
	},
	{
		id: 3328,
		name: "AGALEGU",
		lga_id: 68,
	},
	{
		id: 3329,
		name: "IGBOORA",
		lga_id: 639,
	},
	{
		id: 3330,
		name: "UMUDIM",
		lga_id: 327,
	},
	{
		id: 3331,
		name: "IBEME",
		lga_id: 463,
	},
	{
		id: 3332,
		name: "ABONNEMA",
		lga_id: 467,
	},
	{
		id: 3333,
		name: "TAKWA BAY",
		lga_id: 13,
	},
	{
		id: 3334,
		name: "OSIA UMU MGBEDE",
		lga_id: 765,
	},
	{
		id: 3335,
		name: "WARRI AIRPORT OKPE",
		lga_id: 354,
	},
	{
		id: 3336,
		name: "INYI, ENUGU",
		lga_id: 209,
	},
	{
		id: 3337,
		name: "MOKOLA",
		lga_id: 183,
	},
	{
		id: 3338,
		name: "ANGLO JOS",
		lga_id: 732,
	},
	{
		id: 3339,
		name: "SANTOLO",
		lga_id: 72,
	},
	{
		id: 3340,
		name: "ORE RURAL",
		lga_id: 25,
	},
	{
		id: 3341,
		name: "RECLAMATION ROAD",
		lga_id: 754,
	},
	{
		id: 3342,
		name: "ASA UMUNKA",
		lga_id: 528,
	},
	{
		id: 3343,
		name: "UVURU",
		lga_id: 684,
	},
	{
		id: 3344,
		name: "UMUOHIAGU",
		lga_id: 676,
	},
	{
		id: 3345,
		name: "YAGBA",
		lga_id: 530,
	},
	{
		id: 3346,
		name: "KAGA",
		lga_id: 715,
	},
	{
		id: 3347,
		name: "YANKARI GAME RESERVE",
		lga_id: 232,
	},
	{
		id: 3348,
		name: "MASHI",
		lga_id: 74,
	},
	{
		id: 3349,
		name: "DAURAMA",
		lga_id: 59,
	},
	{
		id: 3350,
		name: "TUNDUN WADA, SOKOTO",
		lga_id: 81,
	},
	{
		id: 3351,
		name: "AMAFOR AKOLI IMENYI",
		lga_id: 344,
	},
	{
		id: 3352,
		name: "ORIOKE OLORUNTOSIN",
		lga_id: 268,
	},
	{
		id: 3353,
		name: "NKPOR IDEMILI NORTH",
		lga_id: 669,
	},
	{
		id: 3354,
		name: "UMUHU OKWATO",
		lga_id: 71,
	},
	{
		id: 3355,
		name: "OLUYOLE",
		lga_id: 126,
	},
	{
		id: 3356,
		name: "SALKA",
		lga_id: 686,
	},
	{
		id: 3357,
		name: "AMAIGBO NWANGELE",
		lga_id: 120,
	},
	{
		id: 3358,
		name: "MAGAJIN GARI, SOKOTO NORTH",
		lga_id: 81,
	},
	{
		id: 3359,
		name: "AMUDA",
		lga_id: 68,
	},
	{
		id: 3360,
		name: "ASABA INTERNATIONAL AIRPORT",
		lga_id: 626,
	},
	{
		id: 3361,
		name: "OKE ORI OMI",
		lga_id: 573,
	},
	{
		id: 3362,
		name: "OZORO",
		lga_id: 691,
	},
	{
		id: 3363,
		name: "TUDUN WADA",
		lga_id: 201,
	},
	{
		id: 3364,
		name: "ARA",
		lga_id: 711,
	},
	{
		id: 3365,
		name: "NEW GARAGE EXTENSION IDO",
		lga_id: 85,
	},
	{
		id: 3366,
		name: "EKEKI HOUSING ESTATE",
		lga_id: 167,
	},
	{
		id: 3367,
		name: "ILEMONA",
		lga_id: 99,
	},
	{
		id: 3368,
		name: "EWURU",
		lga_id: 441,
	},
	{
		id: 3369,
		name: "MINISTRY OF WORKS",
		lga_id: 706,
	},
	{
		id: 3370,
		name: "UMUNNEKWU",
		lga_id: 65,
	},
	{
		id: 3371,
		name: "POST OFFICE OGBOMOSHO NORTH",
		lga_id: 529,
	},
	{
		id: 3372,
		name: "ADUNU",
		lga_id: 750,
	},
	{
		id: 3373,
		name: "JENGRE",
		lga_id: 329,
	},
	{
		id: 3374,
		name: "IKOT IBEKWE",
		lga_id: 432,
	},
	{
		id: 3375,
		name: "UMUNELU",
		lga_id: 611,
	},
	{
		id: 3376,
		name: "GANMASO",
		lga_id: 512,
	},
	{
		id: 3377,
		name: "NKWERE",
		lga_id: 192,
	},
	{
		id: 3378,
		name: "NDUFU ACHARA",
		lga_id: 627,
	},
	{
		id: 3379,
		name: "ORILE IGANMU",
		lga_id: 641,
	},
	{
		id: 3380,
		name: "OJOKODO, OGUN",
		lga_id: 118,
	},
	{
		id: 3381,
		name: "OKEBODE",
		lga_id: 386,
	},
	{
		id: 3382,
		name: "PAI, KUJE",
		lga_id: 191,
	},
	{
		id: 3383,
		name: "OKE IRA, IFAKO-IJAIYE",
		lga_id: 552,
	},
	{
		id: 3384,
		name: "ODOJOMU",
		lga_id: 285,
	},
	{
		id: 3385,
		name: "IKOT OFFIONG",
		lga_id: 685,
	},
	{
		id: 3386,
		name: "OTI ORON",
		lga_id: 587,
	},
	{
		id: 3387,
		name: "JAMA'ARE",
		lga_id: 645,
	},
	{
		id: 3388,
		name: "ABORU",
		lga_id: 577,
	},
	{
		id: 3389,
		name: "ILOGBO ARAROMI",
		lga_id: 10,
	},
	{
		id: 3390,
		name: "GANDU",
		lga_id: 453,
	},
	{
		id: 3391,
		name: "JAKANDE ESTATE, LEKKI",
		lga_id: 410,
	},
	{
		id: 3392,
		name: "OGOOLUWA",
		lga_id: 268,
	},
	{
		id: 3393,
		name: "TAMKUMPARA",
		lga_id: 750,
	},
	{
		id: 3394,
		name: "FEDERAL LOW COST HOUSING, JOS NORTH",
		lga_id: 732,
	},
	{
		id: 3395,
		name: "AHOADA",
		lga_id: 492,
	},
	{
		id: 3396,
		name: "IDADO",
		lga_id: 378,
	},
	{
		id: 3397,
		name: "ERUNWON",
		lga_id: 619,
	},
	{
		id: 3398,
		name: "WAWA, BORGU",
		lga_id: 608,
	},
	{
		id: 3399,
		name: "TAYI VILLA",
		lga_id: 97,
	},
	{
		id: 3400,
		name: "MANGU",
		lga_id: 649,
	},
	{
		id: 3401,
		name: "ADONTE",
		lga_id: 744,
	},
	{
		id: 3402,
		name: "NKEREFFI",
		lga_id: 704,
	},
	{
		id: 3403,
		name: "KUSKIE",
		lga_id: 489,
	},
	{
		id: 3404,
		name: "OPORAZA",
		lga_id: 139,
	},
	{
		id: 3405,
		name: "EYOTONG",
		lga_id: 325,
	},
	{
		id: 3406,
		name: "MBANO, IHITTE/UBOMA",
		lga_id: 701,
	},
	{
		id: 3407,
		name: "UMUECHEBU",
		lga_id: 189,
	},
	{
		id: 3408,
		name: "GADAU",
		lga_id: 295,
	},
	{
		id: 3409,
		name: "UMUEZIKE",
		lga_id: 542,
	},
	{
		id: 3410,
		name: "BARRACKS SURULERE",
		lga_id: 641,
	},
	{
		id: 3411,
		name: "ILARA, LAGOS",
		lga_id: 15,
	},
	{
		id: 3412,
		name: "SAS UYO",
		lga_id: 162,
	},
	{
		id: 3413,
		name: "FEDERAL HOUSING ESTATE, MAKURDI",
		lga_id: 357,
	},
	{
		id: 3414,
		name: "LOW COST (GWIWA)",
		lga_id: 450,
	},
	{
		id: 3415,
		name: "ILO AWELA",
		lga_id: 629,
	},
	{
		id: 3416,
		name: "TREND, KANO",
		lga_id: 425,
	},
	{
		id: 3417,
		name: "TIN CAN ISLAND",
		lga_id: 13,
	},
	{
		id: 3418,
		name: "ISUADA",
		lga_id: 593,
	},
	{
		id: 3419,
		name: "DARANGI",
		lga_id: 235,
	},
	{
		id: 3420,
		name: "CORE AREA, ASABA",
		lga_id: 626,
	},
	{
		id: 3421,
		name: "NYANYAN FCT",
		lga_id: 476,
	},
	{
		id: 3422,
		name: "NAZE",
		lga_id: 741,
	},
	{
		id: 3423,
		name: "EPZ CALABAR",
		lga_id: 559,
	},
	{
		id: 3424,
		name: "ABAYI, OSISIOMA NGWA",
		lga_id: 765,
	},
	{
		id: 3425,
		name: "KWAYA BURA",
		lga_id: 562,
	},
	{
		id: 3426,
		name: "DABAI",
		lga_id: 655,
	},
	{
		id: 3427,
		name: "BODE-OSI",
		lga_id: 576,
	},
	{
		id: 3428,
		name: "SHANONO",
		lga_id: 534,
	},
	{
		id: 3429,
		name: "OFUKOLO IDAH",
		lga_id: 384,
	},
	{
		id: 3430,
		name: "YAMALTU",
		lga_id: 712,
	},
	{
		id: 3431,
		name: "EWET HOUSING ESTATE",
		lga_id: 162,
	},
	{
		id: 3432,
		name: "OBEHIRA",
		lga_id: 762,
	},
	{
		id: 3433,
		name: "MBUTU MBAISE",
		lga_id: 71,
	},
	{
		id: 3434,
		name: "MISSION QUARTERS",
		lga_id: 140,
	},
	{
		id: 3435,
		name: "OTOLOKPO",
		lga_id: 125,
	},
	{
		id: 3436,
		name: "TEDE",
		lga_id: 132,
	},
	{
		id: 3437,
		name: "BISHINI",
		lga_id: 647,
	},
	{
		id: 3438,
		name: "OROGUN TOWN, UGHELLI NORTH",
		lga_id: 411,
	},
	{
		id: 3439,
		name: "ZING",
		lga_id: 111,
	},
	{
		id: 3440,
		name: "YANKWASHI",
		lga_id: 402,
	},
	{
		id: 3441,
		name: "KAOJE",
		lga_id: 255,
	},
	{
		id: 3442,
		name: "OSAPA LONDON",
		lga_id: 410,
	},
	{
		id: 3443,
		name: "BIRDAWA, GADA",
		lga_id: 196,
	},
	{
		id: 3444,
		name: "IJORA BADIA",
		lga_id: 13,
	},
	{
		id: 3445,
		name: "OTOBI",
		lga_id: 333,
	},
	{
		id: 3446,
		name: "MANDAWARI",
		lga_id: 182,
	},
	{
		id: 3447,
		name: "TUDUN WADA, KADUNA NORTH",
		lga_id: 284,
	},
	{
		id: 3448,
		name: "UGA AKOKWA",
		lga_id: 747,
	},
	{
		id: 3449,
		name: "UNGUWAN GADI",
		lga_id: 222,
	},
	{
		id: 3450,
		name: "MBANO, ISIALA MBANO",
		lga_id: 27,
	},
	{
		id: 3451,
		name: "BANANA ISLAND",
		lga_id: 17,
	},
	{
		id: 3452,
		name: "IDISIN GRA IDO",
		lga_id: 85,
	},
	{
		id: 3453,
		name: "OKE ADO, IBADAN",
		lga_id: 126,
	},
	{
		id: 3454,
		name: "MAMBILLA",
		lga_id: 135,
	},
	{
		id: 3455,
		name: "ARAROMI-OWU",
		lga_id: 38,
	},
	{
		id: 3456,
		name: "FARASIME",
		lga_id: 10,
	},
	{
		id: 3457,
		name: "TEKUNLE",
		lga_id: 166,
	},
	{
		id: 3458,
		name: "AHMADU BELLO, VI",
		lga_id: 17,
	},
	{
		id: 3459,
		name: "OGBO OSISI",
		lga_id: 100,
	},
	{
		id: 3460,
		name: "IJARE IRESE ROAD",
		lga_id: 199,
	},
	{
		id: 3461,
		name: "IHI",
		lga_id: 298,
	},
	{
		id: 3462,
		name: "ISOKUN, ILESA WEST",
		lga_id: 720,
	},
	{
		id: 3463,
		name: "OKE ATA HOUSING ESTATE",
		lga_id: 719,
	},
	{
		id: 3464,
		name: "KUDENDA",
		lga_id: 757,
	},
	{
		id: 3465,
		name: "AJAGANABE",
		lga_id: 15,
	},
	{
		id: 3466,
		name: "RUMUDARA",
		lga_id: 78,
	},
	{
		id: 3467,
		name: "TRADERS MARKET, IMO",
		lga_id: 741,
	},
	{
		id: 3468,
		name: "OGBOMOSHO RURAL NORTH",
		lga_id: 529,
	},
	{
		id: 3469,
		name: "ODO EKU",
		lga_id: 312,
	},
	{
		id: 3470,
		name: "IDI IROKO",
		lga_id: 584,
	},
	{
		id: 3471,
		name: "AFARA, MBAITOLI",
		lga_id: 37,
	},
	{
		id: 3472,
		name: "IGBESA",
		lga_id: 629,
	},
	{
		id: 3473,
		name: "IDODE-OYO",
		lga_id: 670,
	},
	{
		id: 3474,
		name: "CHONKU",
		lga_id: 140,
	},
	{
		id: 3475,
		name: "RIGASA VILLAGE",
		lga_id: 130,
	},
	{
		id: 3476,
		name: "IGBIDE",
		lga_id: 494,
	},
	{
		id: 3477,
		name: "NEMBE",
		lga_id: 49,
	},
	{
		id: 3478,
		name: "KAMACHE",
		lga_id: 175,
	},
	{
		id: 3479,
		name: "OGOH",
		lga_id: 357,
	},
	{
		id: 3480,
		name: "OSADEP IWO",
		lga_id: 155,
	},
	{
		id: 3481,
		name: "IGANGAN, IBARAPA NORTH",
		lga_id: 733,
	},
	{
		id: 3482,
		name: "UMUNEDE",
		lga_id: 125,
	},
	{
		id: 3483,
		name: "JANRUWA",
		lga_id: 686,
	},
	{
		id: 3484,
		name: "SEPLAT COMPANY",
		lga_id: 754,
	},
	{
		id: 3485,
		name: "OZALLA, NKANU WEST",
		lga_id: 330,
	},
	{
		id: 3486,
		name: "IKABIGBO",
		lga_id: 697,
	},
	{
		id: 3487,
		name: "UCTH",
		lga_id: 559,
	},
	{
		id: 3488,
		name: "OMUPO",
		lga_id: 309,
	},
	{
		id: 3489,
		name: "ERIKORODU",
		lga_id: 18,
	},
	{
		id: 3490,
		name: "OBA, OGUN",
		lga_id: 690,
	},
	{
		id: 3491,
		name: "NGANZAI",
		lga_id: 665,
	},
	{
		id: 3492,
		name: "KULENDE ESTATE",
		lga_id: 129,
	},
	{
		id: 3493,
		name: "ACHARA LAYOUT",
		lga_id: 128,
	},
	{
		id: 3494,
		name: "IKARA",
		lga_id: 200,
	},
	{
		id: 3495,
		name: "ANKPA, ANKPA",
		lga_id: 93,
	},
	{
		id: 3496,
		name: "GEZAWA",
		lga_id: 727,
	},
	{
		id: 3497,
		name: "AJOSE ADEOGUN",
		lga_id: 410,
	},
	{
		id: 3498,
		name: "PALMGROVE",
		lga_id: 115,
	},
	{
		id: 3499,
		name: "OLOGUNERU",
		lga_id: 85,
	},
	{
		id: 3500,
		name: "CHEVRON, LAGOS",
		lga_id: 410,
	},
	{
		id: 3501,
		name: "REDEMPTION CAMP",
		lga_id: 690,
	},
	{
		id: 3502,
		name: "ALAKIJA",
		lga_id: 694,
	},
	{
		id: 3503,
		name: "WUSE 1 ZONE 2",
		lga_id: 476,
	},
	{
		id: 3504,
		name: "ADIGBE",
		lga_id: 118,
	},
	{
		id: 3505,
		name: "UGBORIKOKO",
		lga_id: 532,
	},
	{
		id: 3506,
		name: "ALEGBO",
		lga_id: 532,
	},
	{
		id: 3507,
		name: "JANKASA",
		lga_id: 539,
	},
	{
		id: 3508,
		name: "TUDUN WADA , SABON GARI",
		lga_id: 616,
	},
	{
		id: 3509,
		name: "ANGWAN BAKIN KASUWA",
		lga_id: 39,
	},
	{
		id: 3510,
		name: "UNGUWAR RIMI",
		lga_id: 560,
	},
	{
		id: 3511,
		name: "AFON",
		lga_id: 193,
	},
	{
		id: 3512,
		name: "AKWUKE",
		lga_id: 128,
	},
	{
		id: 3513,
		name: "OZO OBI PAUL",
		lga_id: 747,
	},
	{
		id: 3514,
		name: "WANKE",
		lga_id: 247,
	},
	{
		id: 3515,
		name: "KOZA",
		lga_id: 59,
	},
	{
		id: 3516,
		name: "AKWAEZE",
		lga_id: 387,
	},
	{
		id: 3517,
		name: "NTEJE",
		lga_id: 717,
	},
	{
		id: 3518,
		name: "NNEWI MARKET",
		lga_id: 327,
	},
	{
		id: 3519,
		name: "BAIYEKU",
		lga_id: 212,
	},
	{
		id: 3520,
		name: "OKE OSUN ALABATA",
		lga_id: 505,
	},
	{
		id: 3521,
		name: "ADO",
		lga_id: 212,
	},
	{
		id: 3522,
		name: "KAKURI CHIKUN",
		lga_id: 480,
	},
	{
		id: 3523,
		name: "ODO OWA",
		lga_id: 678,
	},
	{
		id: 3524,
		name: "OGBE, AHIAZU-MBAISE",
		lga_id: 264,
	},
	{
		id: 3525,
		name: "UMARARI",
		lga_id: 693,
	},
	{
		id: 3526,
		name: "IJEBU MUSHIN",
		lga_id: 299,
	},
	{
		id: 3527,
		name: "TODI",
		lga_id: 107,
	},
	{
		id: 3528,
		name: "LAWANSON MUSHIN",
		lga_id: 115,
	},
	{
		id: 3529,
		name: "GRACELAND ESTATE",
		lga_id: 17,
	},
	{
		id: 3530,
		name: "SABON GARI RURAL, KANO",
		lga_id: 718,
	},
	{
		id: 3531,
		name: "UMUEDE",
		lga_id: 348,
	},
	{
		id: 3532,
		name: "ONILADO AJADI",
		lga_id: 639,
	},
	{
		id: 3533,
		name: "NORTH AKOKO",
		lga_id: 479,
	},
	{
		id: 3534,
		name: "INISA",
		lga_id: 771,
	},
	{
		id: 3535,
		name: "EBEDEI",
		lga_id: 503,
	},
	{
		id: 3536,
		name: "KWATARKWASHI",
		lga_id: 34,
	},
	{
		id: 3537,
		name: "JARA",
		lga_id: 712,
	},
	{
		id: 3538,
		name: "DAKARAKO",
		lga_id: 106,
	},
	{
		id: 3539,
		name: "ALABA LAYOUT",
		lga_id: 199,
	},
	{
		id: 3540,
		name: "KOFAR MARUSA",
		lga_id: 560,
	},
	{
		id: 3541,
		name: "IGBERE",
		lga_id: 344,
	},
	{
		id: 3542,
		name: "MANGWARO",
		lga_id: 620,
	},
	{
		id: 3543,
		name: "ADALEMO",
		lga_id: 629,
	},
	{
		id: 3544,
		name: "SABONGARI, SOKOTO",
		lga_id: 699,
	},
	{
		id: 3545,
		name: "YUSUFARI",
		lga_id: 242,
	},
	{
		id: 3546,
		name: "ODE AYE",
		lga_id: 248,
	},
	{
		id: 3547,
		name: "MUSA WADA AREA",
		lga_id: 446,
	},
	{
		id: 3548,
		name: "EBENEBE",
		lga_id: 730,
	},
	{
		id: 3549,
		name: "GARKI AREA 8",
		lga_id: 476,
	},
	{
		id: 3550,
		name: "ALEGONGO AKOBO",
		lga_id: 524,
	},
	{
		id: 3551,
		name: "SAM MBAKWE AIRPORT",
		lga_id: 676,
	},
	{
		id: 3552,
		name: "WORKS LAYOUT, IMO",
		lga_id: 318,
	},
	{
		id: 3553,
		name: "YELWAN GOJE",
		lga_id: 477,
	},
	{
		id: 3554,
		name: "IJOKU",
		lga_id: 378,
	},
	{
		id: 3555,
		name: "EKPAN",
		lga_id: 532,
	},
	{
		id: 3556,
		name: "ACADEMY",
		lga_id: 625,
	},
	{
		id: 3557,
		name: "OYE EKITI",
		lga_id: 328,
	},
	{
		id: 3558,
		name: "ABULE-IJESHA",
		lga_id: 404,
	},
	{
		id: 3559,
		name: "SHABU",
		lga_id: 452,
	},
	{
		id: 3560,
		name: "DOGUWA",
		lga_id: 29,
	},
	{
		id: 3561,
		name: "ISAKAITA",
		lga_id: 284,
	},
	{
		id: 3562,
		name: "UWHERU",
		lga_id: 411,
	},
	{
		id: 3563,
		name: "DUTSEN REME 2",
		lga_id: 122,
	},
	{
		id: 3564,
		name: "KUSUV",
		lga_id: 459,
	},
	{
		id: 3565,
		name: "KURA",
		lga_id: 488,
	},
	{
		id: 3566,
		name: "UGBOR UVURU CENTRAL",
		lga_id: 71,
	},
	{
		id: 3567,
		name: "ILAJE, LAGOS",
		lga_id: 404,
	},
	{
		id: 3568,
		name: "ADA GEORGE ROAD AXIS",
		lga_id: 594,
	},
	{
		id: 3569,
		name: "ISU UBURU",
		lga_id: 742,
	},
	{
		id: 3570,
		name: "GAMAWA",
		lga_id: 759,
	},
	{
		id: 3571,
		name: "OKUTUKUTU",
		lga_id: 167,
	},
	{
		id: 3572,
		name: "SILAME",
		lga_id: 110,
	},
	{
		id: 3573,
		name: "ADEWOLE ESTATE LOW COST",
		lga_id: 108,
	},
	{
		id: 3574,
		name: "EKOSODIN",
		lga_id: 393,
	},
	{
		id: 3575,
		name: "AWO OMAMMA",
		lga_id: 77,
	},
	{
		id: 3576,
		name: "DIOBU MILE 4",
		lga_id: 594,
	},
	{
		id: 3577,
		name: "BULBULI",
		lga_id: 418,
	},
	{
		id: 3578,
		name: "ALAPADI",
		lga_id: 18,
	},
	{
		id: 3579,
		name: "OTUEGILA",
		lga_id: 256,
	},
	{
		id: 3580,
		name: "HAMMA ALI",
		lga_id: 364,
	},
	{
		id: 3581,
		name: "ANKUNG",
		lga_id: 21,
	},
	{
		id: 3582,
		name: "OLORUNSOGO, OYO",
		lga_id: 173,
	},
	{
		id: 3583,
		name: "GADA BUKE",
		lga_id: 41,
	},
	{
		id: 3584,
		name: "SALAME",
		lga_id: 47,
	},
	{
		id: 3585,
		name: "FADAN NINZO",
		lga_id: 117,
	},
	{
		id: 3586,
		name: "ASHI",
		lga_id: 524,
	},
	{
		id: 3587,
		name: "WURUKUM",
		lga_id: 357,
	},
	{
		id: 3588,
		name: "SOUTH IBIE",
		lga_id: 697,
	},
	{
		id: 3589,
		name: "UKWA",
		lga_id: 543,
	},
	{
		id: 3590,
		name: "NDOKI",
		lga_id: 137,
	},
	{
		id: 3591,
		name: "NNOKWA",
		lga_id: 679,
	},
	{
		id: 3592,
		name: "SAKA TINUBU",
		lga_id: 410,
	},
	{
		id: 3593,
		name: "EPEILO",
		lga_id: 564,
	},
	{
		id: 3594,
		name: "IDDO, OYO",
		lga_id: 85,
	},
	{
		id: 3595,
		name: "KUDAI",
		lga_id: 464,
	},
	{
		id: 3596,
		name: "SABO SAGAMU",
		lga_id: 378,
	},
	{
		id: 3597,
		name: "OSONWA, AWKA",
		lga_id: 358,
	},
	{
		id: 3598,
		name: "IWESI VILLAGE",
		lga_id: 619,
	},
	{
		id: 3599,
		name: "GOLOLO",
		lga_id: 759,
	},
	{
		id: 3600,
		name: "EREKESAN",
		lga_id: 176,
	},
	{
		id: 3601,
		name: "MASAKA, KARU",
		lga_id: 317,
	},
	{
		id: 3602,
		name: "CORNER OWO",
		lga_id: 132,
	},
	{
		id: 3603,
		name: "POST OFFICE HADEJIA",
		lga_id: 290,
	},
	{
		id: 3604,
		name: "UNGWAR DANAJE",
		lga_id: 575,
	},
	{
		id: 3605,
		name: "OBOTO",
		lga_id: 166,
	},
	{
		id: 3606,
		name: "UNITY ESTATE, OSOGBO",
		lga_id: 711,
	},
	{
		id: 3607,
		name: "KOKORI",
		lga_id: 438,
	},
	{
		id: 3608,
		name: "ILORO EKITI",
		lga_id: 678,
	},
	{
		id: 3609,
		name: "ONOWA BONO",
		lga_id: 219,
	},
	{
		id: 3610,
		name: "AKESAN",
		lga_id: 577,
	},
	{
		id: 3611,
		name: "NIGERIA BREWERIES",
		lga_id: 607,
	},
	{
		id: 3612,
		name: "ORIE OBIBI",
		lga_id: 741,
	},
	{
		id: 3613,
		name: "ADEKAA ROAD",
		lga_id: 351,
	},
	{
		id: 3614,
		name: "HARBOUR INDUSTRIAL AREA",
		lga_id: 343,
	},
	{
		id: 3615,
		name: "ALAUSA",
		lga_id: 16,
	},
	{
		id: 3616,
		name: "SABO ILARO",
		lga_id: 680,
	},
	{
		id: 3617,
		name: "AJAKURAMA",
		lga_id: 654,
	},
	{
		id: 3618,
		name: "JABI",
		lga_id: 476,
	},
	{
		id: 3619,
		name: "IHIALA",
		lga_id: 207,
	},
	{
		id: 3620,
		name: "OBINTO IHECHIOWA",
		lga_id: 87,
	},
	{
		id: 3621,
		name: "UBEJI",
		lga_id: 417,
	},
	{
		id: 3622,
		name: "JIGAWA KUKA",
		lga_id: 483,
	},
	{
		id: 3623,
		name: "AGIP OBAMA",
		lga_id: 49,
	},
	{
		id: 3624,
		name: "AWKA ETITI",
		lga_id: 679,
	},
	{
		id: 3625,
		name: "OZUOABA",
		lga_id: 78,
	},
	{
		id: 3626,
		name: "WUDIL",
		lga_id: 306,
	},
	{
		id: 3627,
		name: "NAHUCE KAKU",
		lga_id: 34,
	},
	{
		id: 3628,
		name: "OKELELE EAST",
		lga_id: 129,
	},
	{
		id: 3629,
		name: "OJO, LAGOS",
		lga_id: 228,
	},
	{
		id: 3630,
		name: "GARKI AREA 11",
		lga_id: 476,
	},
	{
		id: 3631,
		name: "KWAJI",
		lga_id: 379,
	},
	{
		id: 3632,
		name: "CHEVRON, LAGOS",
		lga_id: 17,
	},
	{
		id: 3633,
		name: "OBUZOR NGWA",
		lga_id: 528,
	},
	{
		id: 3634,
		name: "KOLUAMA",
		lga_id: 230,
	},
	{
		id: 3635,
		name: "ANGUWAN RIMI KADUNA NORTH",
		lga_id: 284,
	},
	{
		id: 3636,
		name: "UDUNG UKO",
		lga_id: 224,
	},
	{
		id: 3637,
		name: "IRUN",
		lga_id: 258,
	},
	{
		id: 3638,
		name: "JIBERU",
		lga_id: 60,
	},
	{
		id: 3639,
		name: "MOKWA",
		lga_id: 400,
	},
	{
		id: 3640,
		name: "SARKIN-POWA",
		lga_id: 175,
	},
	{
		id: 3641,
		name: "OTUEKE",
		lga_id: 256,
	},
	{
		id: 3642,
		name: "AGBELEKALE",
		lga_id: 577,
	},
	{
		id: 3643,
		name: "JOS WILDLIFE PARK",
		lga_id: 732,
	},
	{
		id: 3644,
		name: "NINGI",
		lga_id: 487,
	},
	{
		id: 3645,
		name: "ANGUWAN AJIKUKU",
		lga_id: 140,
	},
	{
		id: 3646,
		name: "CHEVY ESTATE",
		lga_id: 410,
	},
	{
		id: 3647,
		name: "AGATU, AGATU",
		lga_id: 270,
	},
	{
		id: 3648,
		name: "AYEGUNLE EKITI",
		lga_id: 678,
	},
	{
		id: 3649,
		name: "IMORU, ONDO",
		lga_id: 413,
	},
	{
		id: 3650,
		name: "YAN NAYAKI",
		lga_id: 123,
	},
	{
		id: 3651,
		name: "NGWO",
		lga_id: 760,
	},
	{
		id: 3652,
		name: "OPEBI",
		lga_id: 584,
	},
	{
		id: 3653,
		name: "OBEMBE",
		lga_id: 720,
	},
	{
		id: 3654,
		name: "SOMOLU",
		lga_id: 404,
	},
	{
		id: 3655,
		name: "OVOM",
		lga_id: 167,
	},
	{
		id: 3656,
		name: "GWARAM, ALKALERI",
		lga_id: 232,
	},
	{
		id: 3657,
		name: "IGBO ALU",
		lga_id: 99,
	},
	{
		id: 3658,
		name: "AMAETITI",
		lga_id: 257,
	},
	{
		id: 3659,
		name: "CHIWAI",
		lga_id: 54,
	},
	{
		id: 3660,
		name: "NEW AND OLD EKE AMICHI",
		lga_id: 170,
	},
	{
		id: 3661,
		name: "OGINIGBO",
		lga_id: 210,
	},
	{
		id: 3662,
		name: "OLAMABORO",
		lga_id: 702,
	},
	{
		id: 3663,
		name: "JOBELE QUARTERS",
		lga_id: 735,
	},
	{
		id: 3664,
		name: "SABONGARI WUKARI",
		lga_id: 140,
	},
	{
		id: 3665,
		name: "RIMIN GUZA",
		lga_id: 40,
	},
	{
		id: 3666,
		name: "IJOKO ADO ODO-OTA",
		lga_id: 629,
	},
	{
		id: 3667,
		name: "NTA EKU",
		lga_id: 438,
	},
	{
		id: 3668,
		name: "NPF STATE HEADQUARTERS",
		lga_id: 358,
	},
	{
		id: 3669,
		name: "URUE ITA",
		lga_id: 587,
	},
	{
		id: 3670,
		name: "CREEK RD, APAPA",
		lga_id: 13,
	},
	{
		id: 3671,
		name: "ALAPINNI",
		lga_id: 282,
	},
	{
		id: 3672,
		name: "ZUNGERU",
		lga_id: 427,
	},
	{
		id: 3673,
		name: "KIRU",
		lga_id: 558,
	},
	{
		id: 3674,
		name: "COLLEGE OF AGRICULTURE GANYE",
		lga_id: 512,
	},
	{
		id: 3675,
		name: "EDUMOGA",
		lga_id: 475,
	},
	{
		id: 3676,
		name: "OLOMORE, ABEOKUTA NORTH",
		lga_id: 719,
	},
	{
		id: 3677,
		name: "URHONIGBE",
		lga_id: 371,
	},
	{
		id: 3678,
		name: "OROWA",
		lga_id: 55,
	},
	{
		id: 3679,
		name: "AGBOWA EPE",
		lga_id: 15,
	},
	{
		id: 3680,
		name: "KAMBA",
		lga_id: 360,
	},
	{
		id: 3681,
		name: "SHANI",
		lga_id: 124,
	},
	{
		id: 3682,
		name: "AJILOSUN",
		lga_id: 573,
	},
	{
		id: 3683,
		name: "URUA EDETOBO",
		lga_id: 376,
	},
	{
		id: 3684,
		name: "MOSALASI",
		lga_id: 766,
	},
	{
		id: 3685,
		name: "GULMA",
		lga_id: 172,
	},
	{
		id: 3686,
		name: "EAST ITAM",
		lga_id: 237,
	},
	{
		id: 3687,
		name: "IHIAGWA",
		lga_id: 189,
	},
	{
		id: 3688,
		name: "AKENFA EPIE",
		lga_id: 167,
	},
	{
		id: 3689,
		name: "IGBOYI",
		lga_id: 529,
	},
	{
		id: 3690,
		name: "KARIM LAMIDO",
		lga_id: 677,
	},
	{
		id: 3691,
		name: "OLUKWU",
		lga_id: 393,
	},
	{
		id: 3692,
		name: "SABON GARI, JALINGO",
		lga_id: 222,
	},
	{
		id: 3693,
		name: "NEW INDUSTRIAL LAYOUT",
		lga_id: 318,
	},
	{
		id: 3694,
		name: "CEMENT, LAGOS",
		lga_id: 584,
	},
	{
		id: 3695,
		name: "ABAWYUMNNAM",
		lga_id: 250,
	},
	{
		id: 3696,
		name: "IYALLA",
		lga_id: 48,
	},
	{
		id: 3697,
		name: "MUMUNA",
		lga_id: 700,
	},
	{
		id: 3698,
		name: "KWAIKWAI",
		lga_id: 34,
	},
	{
		id: 3699,
		name: "ILOBU",
		lga_id: 109,
	},
	{
		id: 3700,
		name: "GASHISH",
		lga_id: 458,
	},
	{
		id: 3701,
		name: "OTTO",
		lga_id: 442,
	},
	{
		id: 3702,
		name: "RUBOCHI",
		lga_id: 191,
	},
	{
		id: 3703,
		name: "KUTIGI",
		lga_id: 105,
	},
	{
		id: 3704,
		name: "RUMUROLU",
		lga_id: 78,
	},
	{
		id: 3705,
		name: "GWANGE, MAIDUGURI",
		lga_id: 693,
	},
	{
		id: 3706,
		name: "OTUKPA",
		lga_id: 564,
	},
	{
		id: 3707,
		name: "APETE AKINYELE",
		lga_id: 524,
	},
	{
		id: 3708,
		name: "AWE",
		lga_id: 735,
	},
	{
		id: 3709,
		name: "ODOMOLA",
		lga_id: 541,
	},
	{
		id: 3710,
		name: "ARMY BARRACKS, EDE",
		lga_id: 268,
	},
	{
		id: 3711,
		name: "IFITE OGWARI",
		lga_id: 229,
	},
	{
		id: 3712,
		name: "EGBENWU NIMO",
		lga_id: 772,
	},
	{
		id: 3713,
		name: "AJAGBA, ONDO",
		lga_id: 366,
	},
	{
		id: 3714,
		name: "OJUELEGBA",
		lga_id: 641,
	},
	{
		id: 3715,
		name: "EGBUDU AKA",
		lga_id: 744,
	},
	{
		id: 3716,
		name: "BARNAWA GRA KADUNA SOUTH",
		lga_id: 757,
	},
	{
		id: 3717,
		name: "HAUWAN KIGBO",
		lga_id: 622,
	},
	{
		id: 3718,
		name: "AYETEJU",
		lga_id: 751,
	},
	{
		id: 3719,
		name: "EKWULOBIA",
		lga_id: 747,
	},
	{
		id: 3720,
		name: "IPAPO",
		lga_id: 86,
	},
	{
		id: 3721,
		name: "OLODO, OYO",
		lga_id: 372,
	},
	{
		id: 3722,
		name: "GOVT HOUSE, SOKOTO",
		lga_id: 450,
	},
	{
		id: 3723,
		name: "POST OFFICE ABAKALIKI",
		lga_id: 520,
	},
	{
		id: 3724,
		name: "GADA, GADA",
		lga_id: 196,
	},
	{
		id: 3725,
		name: "BANKOGI",
		lga_id: 427,
	},
	{
		id: 3726,
		name: "IJEGUN",
		lga_id: 577,
	},
	{
		id: 3727,
		name: "AVUVU",
		lga_id: 63,
	},
	{
		id: 3728,
		name: "OPETE, DELTA",
		lga_id: 331,
	},
	{
		id: 3729,
		name: "IRHUE",
		lga_id: 279,
	},
	{
		id: 3730,
		name: "ERINMOPE",
		lga_id: 768,
	},
	{
		id: 3731,
		name: "AGUNU",
		lga_id: 647,
	},
	{
		id: 3732,
		name: "EBIKORO AMAEZE",
		lga_id: 741,
	},
	{
		id: 3733,
		name: "NEW MARKET LAYOUT",
		lga_id: 318,
	},
	{
		id: 3734,
		name: "NIPOST DAMATURU",
		lga_id: 259,
	},
	{
		id: 3735,
		name: "NORTHERN IMAN",
		lga_id: 617,
	},
	{
		id: 3736,
		name: "ELIOZU ROAD",
		lga_id: 78,
	},
	{
		id: 3737,
		name: "KAKAU VILLAGE",
		lga_id: 480,
	},
	{
		id: 3738,
		name: "ABTI UNIVERSITY YOLA",
		lga_id: 553,
	},
	{
		id: 3739,
		name: "BERGER CEMENT",
		lga_id: 694,
	},
	{
		id: 3740,
		name: "RUWA WURI",
		lga_id: 293,
	},
	{
		id: 3741,
		name: "AJAOKUTA",
		lga_id: 775,
	},
	{
		id: 3742,
		name: "ABE OBI",
		lga_id: 219,
	},
	{
		id: 3743,
		name: "FADAN KARSHI",
		lga_id: 117,
	},
	{
		id: 3744,
		name: "IKOT ESSIET",
		lga_id: 426,
	},
	{
		id: 3745,
		name: "DANGE",
		lga_id: 660,
	},
	{
		id: 3746,
		name: "ITUKU",
		lga_id: 547,
	},
	{
		id: 3747,
		name: "ISEYIN ISEYIN",
		lga_id: 748,
	},
	{
		id: 3748,
		name: "KATAGUM ZAKI",
		lga_id: 179,
	},
	{
		id: 3749,
		name: "ORILE IJAIYE",
		lga_id: 524,
	},
	{
		id: 3750,
		name: "AGBONLE",
		lga_id: 434,
	},
	{
		id: 3751,
		name: "AFOR AMAUZARI",
		lga_id: 27,
	},
	{
		id: 3752,
		name: "AGUMU KOSHI",
		lga_id: 608,
	},
	{
		id: 3753,
		name: "IGBOTU",
		lga_id: 389,
	},
	{
		id: 3754,
		name: "ISU AMAWU",
		lga_id: 65,
	},
	{
		id: 3755,
		name: "IJAMIDO",
		lga_id: 629,
	},
	{
		id: 3756,
		name: "AJARA TOPA",
		lga_id: 10,
	},
	{
		id: 3757,
		name: "ISI SOUTH",
		lga_id: 279,
	},
	{
		id: 3758,
		name: "KETA",
		lga_id: 527,
	},
	{
		id: 3759,
		name: "AMANSI ODO",
		lga_id: 613,
	},
	{
		id: 3760,
		name: "ANGWAN HAUSA, LOKOJA",
		lga_id: 187,
	},
	{
		id: 3761,
		name: "UMUNANKWO",
		lga_id: 353,
	},
	{
		id: 3762,
		name: "ILOKO IJESHA",
		lga_id: 57,
	},
	{
		id: 3763,
		name: "RAGA",
		lga_id: 759,
	},
	{
		id: 3764,
		name: "ANICHIE",
		lga_id: 657,
	},
	{
		id: 3765,
		name: "OREGUN",
		lga_id: 584,
	},
	{
		id: 3766,
		name: "OJOO",
		lga_id: 524,
	},
	{
		id: 3767,
		name: "AWGBU AGULU",
		lga_id: 257,
	},
	{
		id: 3768,
		name: "MALAMAWA",
		lga_id: 464,
	},
	{
		id: 3769,
		name: "OBUDU RURAL",
		lga_id: 398,
	},
	{
		id: 3770,
		name: "ABUJA AIRPORT RD",
		lga_id: 476,
	},
	{
		id: 3771,
		name: "IKEJI ILE",
		lga_id: 57,
	},
	{
		id: 3772,
		name: "OZALLA, OWAN WEST",
		lga_id: 254,
	},
	{
		id: 3773,
		name: "DINGIYADI",
		lga_id: 216,
	},
	{
		id: 3774,
		name: "OJOTA",
		lga_id: 465,
	},
	{
		id: 3775,
		name: "ATIMBO EAST",
		lga_id: 33,
	},
	{
		id: 3776,
		name: "WURO GUDE",
		lga_id: 477,
	},
	{
		id: 3777,
		name: "AKPEKPE",
		lga_id: 697,
	},
	{
		id: 3778,
		name: "JAKANDE ESTATE",
		lga_id: 509,
	},
	{
		id: 3779,
		name: "MAGAMI ZAMFARA",
		lga_id: 247,
	},
	{
		id: 3780,
		name: "YAR AKWA",
		lga_id: 425,
	},
	{
		id: 3781,
		name: "GARKI 2",
		lga_id: 476,
	},
	{
		id: 3782,
		name: "AKATA AWOM",
		lga_id: 463,
	},
	{
		id: 3783,
		name: "YENAGOA RURAL",
		lga_id: 167,
	},
	{
		id: 3784,
		name: "ENJEMA",
		lga_id: 93,
	},
	{
		id: 3785,
		name: "AKANU IBIAM INTERNATIONAL AIRPORT",
		lga_id: 276,
	},
	{
		id: 3786,
		name: "GWARZO",
		lga_id: 146,
	},
	{
		id: 3787,
		name: "BACIRAWA",
		lga_id: 142,
	},
	{
		id: 3788,
		name: "ABRAHAM ADESANYA",
		lga_id: 17,
	},
	{
		id: 3789,
		name: "UDEGE",
		lga_id: 335,
	},
	{
		id: 3790,
		name: "ANKWA",
		lga_id: 647,
	},
	{
		id: 3791,
		name: "KOKO, KOKO/BESSE",
		lga_id: 656,
	},
	{
		id: 3792,
		name: "EASTERN NSIT",
		lga_id: 66,
	},
	{
		id: 3793,
		name: "AJASE IPO",
		lga_id: 164,
	},
	{
		id: 3794,
		name: "OKUROKWU",
		lga_id: 298,
	},
	{
		id: 3795,
		name: "EXPRESS JUNCTION, OYIGBO",
		lga_id: 137,
	},
	{
		id: 3796,
		name: "ENU OJI",
		lga_id: 772,
	},
	{
		id: 3797,
		name: "OLD AIRPORT KADUNA",
		lga_id: 130,
	},
	{
		id: 3798,
		name: "CALABAR STADIUM",
		lga_id: 559,
	},
	{
		id: 3799,
		name: "ISABO",
		lga_id: 118,
	},
	{
		id: 3800,
		name: "SHEDA GALADIMA",
		lga_id: 643,
	},
	{
		id: 3801,
		name: "KUBELI",
		lga_id: 357,
	},
	{
		id: 3802,
		name: "ABIGI",
		lga_id: 350,
	},
	{
		id: 3803,
		name: "OBIO AKPA",
		lga_id: 511,
	},
	{
		id: 3804,
		name: "OSELEGBE",
		lga_id: 212,
	},
	{
		id: 3805,
		name: "AYETORO, LAGOS",
		lga_id: 267,
	},
	{
		id: 3806,
		name: "ERE",
		lga_id: 15,
	},
	{
		id: 3807,
		name: "AMANYAM",
		lga_id: 278,
	},
	{
		id: 3808,
		name: "IKOKU",
		lga_id: 594,
	},
	{
		id: 3809,
		name: "JARE EMILY ESTATE",
		lga_id: 282,
	},
	{
		id: 3810,
		name: "DOMA CENTRAL",
		lga_id: 180,
	},
	{
		id: 3811,
		name: "OBOWO, AHIAZU-MBAISE",
		lga_id: 264,
	},
	{
		id: 3812,
		name: "ONYUWEI AKPA",
		lga_id: 333,
	},
	{
		id: 3813,
		name: "IHITE",
		lga_id: 320,
	},
	{
		id: 3814,
		name: "OKE IRA NLA",
		lga_id: 17,
	},
	{
		id: 3815,
		name: "ISHERI OSHUN",
		lga_id: 577,
	},
	{
		id: 3816,
		name: "AFOR UMUNDURU",
		lga_id: 687,
	},
	{
		id: 3817,
		name: "DOLPHIN ESTATE",
		lga_id: 410,
	},
	{
		id: 3818,
		name: "OSUSU AKU UGWUNAGBO",
		lga_id: 528,
	},
	{
		id: 3819,
		name: "IGBAJO",
		lga_id: 302,
	},
	{
		id: 3820,
		name: "OBA PALACE",
		lga_id: 545,
	},
	{
		id: 3821,
		name: "AYEGBAJU",
		lga_id: 328,
	},
	{
		id: 3822,
		name: "KWAKWAAH",
		lga_id: 53,
	},
	{
		id: 3823,
		name: "NEW BODIJA",
		lga_id: 183,
	},
	{
		id: 3824,
		name: "SURULERE, LAGOS STATE",
		lga_id: 641,
	},
	{
		id: 3825,
		name: "EKPENE EDIENE",
		lga_id: 373,
	},
	{
		id: 3826,
		name: "OAU TEACHING HOSPITAL",
		lga_id: 152,
	},
	{
		id: 3827,
		name: "ABRAKA",
		lga_id: 438,
	},
	{
		id: 3828,
		name: "DAGGAN HILL",
		lga_id: 19,
	},
	{
		id: 3829,
		name: "SPIBAT ROAD",
		lga_id: 741,
	},
	{
		id: 3830,
		name: "AWOLOWO RD",
		lga_id: 17,
	},
	{
		id: 3831,
		name: "OHAFIA",
		lga_id: 241,
	},
	{
		id: 3832,
		name: "UTE GBEJI",
		lga_id: 125,
	},
	{
		id: 3833,
		name: "ZUNGUR",
		lga_id: 653,
	},
	{
		id: 3834,
		name: "MOORE PLANTATION",
		lga_id: 85,
	},
	{
		id: 3835,
		name: "BARAKIN GURUM",
		lga_id: 329,
	},
	{
		id: 3836,
		name: "TURAN",
		lga_id: 136,
	},
	{
		id: 3837,
		name: "NOMEH",
		lga_id: 704,
	},
	{
		id: 3838,
		name: "KATSINA SECRETARIAT",
		lga_id: 560,
	},
	{
		id: 3839,
		name: "MAYA, IBARAPA EAST",
		lga_id: 740,
	},
	{
		id: 3840,
		name: "SAWMILL ISEYIN",
		lga_id: 748,
	},
	{
		id: 3841,
		name: "AMAKA",
		lga_id: 223,
	},
	{
		id: 3842,
		name: "MODO",
		lga_id: 651,
	},
	{
		id: 3843,
		name: "UMUAKWU",
		lga_id: 119,
	},
	{
		id: 3844,
		name: "KIRORO",
		lga_id: 750,
	},
	{
		id: 3845,
		name: "NEW LAYOUT , LOKOJA",
		lga_id: 187,
	},
	{
		id: 3846,
		name: "LIMCA ROAD,ONITSHA",
		lga_id: 669,
	},
	{
		id: 3847,
		name: "WUSE 1 ZONE 4",
		lga_id: 476,
	},
	{
		id: 3848,
		name: "ONPETU",
		lga_id: 529,
	},
	{
		id: 3849,
		name: "OREMERIN, OGBOMOSHO NORTH",
		lga_id: 529,
	},
	{
		id: 3850,
		name: "GAGARAWA",
		lga_id: 31,
	},
	{
		id: 3851,
		name: "BAGUDO",
		lga_id: 255,
	},
	{
		id: 3852,
		name: "UMUNA",
		lga_id: 657,
	},
	{
		id: 3853,
		name: "ORJI",
		lga_id: 741,
	},
	{
		id: 3854,
		name: "SANGOTEDO",
		lga_id: 17,
	},
	{
		id: 3855,
		name: "OTUASEGA",
		lga_id: 256,
	},
	{
		id: 3856,
		name: "IDOANI",
		lga_id: 413,
	},
	{
		id: 3857,
		name: "ODOGWU",
		lga_id: 446,
	},
	{
		id: 3858,
		name: "BARRACKS ROAD, UYO",
		lga_id: 162,
	},
	{
		id: 3859,
		name: "DANGUSAU",
		lga_id: 347,
	},
	{
		id: 3860,
		name: "IGBODO, NGOR OKPALA",
		lga_id: 676,
	},
	{
		id: 3861,
		name: "OKEMESI",
		lga_id: 419,
	},
	{
		id: 3862,
		name: "ABDUL AZEEZ EAST",
		lga_id: 129,
	},
	{
		id: 3863,
		name: "KUNKA",
		lga_id: 575,
	},
	{
		id: 3864,
		name: "BODE THOMAS",
		lga_id: 641,
	},
	{
		id: 3865,
		name: "NASARAWA TARAUNI",
		lga_id: 578,
	},
	{
		id: 3866,
		name: "TUNDUN WADA,ARGUNGU",
		lga_id: 172,
	},
	{
		id: 3867,
		name: "AGBAISI LAYOUT",
		lga_id: 139,
	},
	{
		id: 3868,
		name: "GBAGI",
		lga_id: 607,
	},
	{
		id: 3869,
		name: "UNGUWAN GANGAREN RAFIN KASA",
		lga_id: 122,
	},
	{
		id: 3870,
		name: "UGBOGUE",
		lga_id: 654,
	},
	{
		id: 3871,
		name: "OMOLE",
		lga_id: 584,
	},
	{
		id: 3872,
		name: "MPAPE",
		lga_id: 476,
	},
	{
		id: 3873,
		name: "AJIDO",
		lga_id: 10,
	},
	{
		id: 3874,
		name: "LAMBE AKUTE",
		lga_id: 549,
	},
	{
		id: 3875,
		name: "TUNDUN IYA",
		lga_id: 122,
	},
	{
		id: 3876,
		name: "TOUMO",
		lga_id: 743,
	},
	{
		id: 3877,
		name: "MARKET SQUARE, AWE",
		lga_id: 670,
	},
	{
		id: 3878,
		name: "ILORIN GRA",
		lga_id: 129,
	},
	{
		id: 3879,
		name: "UMULE ROAD",
		lga_id: 590,
	},
	{
		id: 3880,
		name: "IBUSA",
		lga_id: 626,
	},
	{
		id: 3881,
		name: "ISI MKPOROBE",
		lga_id: 471,
	},
	{
		id: 3882,
		name: "IPETUMODU",
		lga_id: 713,
	},
	{
		id: 3883,
		name: "ONITSHA GRA",
		lga_id: 500,
	},
	{
		id: 3884,
		name: "EGADABA",
		lga_id: 223,
	},
	{
		id: 3885,
		name: "DEDERE",
		lga_id: 428,
	},
	{
		id: 3886,
		name: "UMUDIKE",
		lga_id: 687,
	},
	{
		id: 3887,
		name: "GIRO",
		lga_id: 705,
	},
	{
		id: 3888,
		name: "SABONFEGI SANDAMU",
		lga_id: 663,
	},
	{
		id: 3889,
		name: "UMUGUMA HOUSING ESTATE",
		lga_id: 318,
	},
	{
		id: 3890,
		name: "EGBIN",
		lga_id: 212,
	},
	{
		id: 3891,
		name: "EKPARAKWA",
		lga_id: 511,
	},
	{
		id: 3892,
		name: "AMAOZU NWANGELE",
		lga_id: 120,
	},
	{
		id: 3893,
		name: "IMORU, OGUN",
		lga_id: 159,
	},
	{
		id: 3894,
		name: "SABUWAR UNGUWA",
		lga_id: 399,
	},
	{
		id: 3895,
		name: "AWOLOWO RD",
		lga_id: 410,
	},
	{
		id: 3896,
		name: "OJUTALAYO AREA",
		lga_id: 152,
	},
	{
		id: 3897,
		name: "OFUTOP",
		lga_id: 250,
	},
	{
		id: 3898,
		name: "JOJU",
		lga_id: 629,
	},
	{
		id: 3899,
		name: "EVUTAGI",
		lga_id: 307,
	},
	{
		id: 3900,
		name: "OJA-OBA",
		lga_id: 240,
	},
	{
		id: 3901,
		name: "UMUMOCHE OKOLOCHI",
		lga_id: 741,
	},
	{
		id: 3902,
		name: "ISANLU MAKUTU",
		lga_id: 206,
	},
	{
		id: 3903,
		name: "ILUPEJU IKEJA",
		lga_id: 16,
	},
	{
		id: 3904,
		name: "COCA-COLA IBADAN NORTH",
		lga_id: 183,
	},
	{
		id: 3905,
		name: "APATA",
		lga_id: 85,
	},
	{
		id: 3906,
		name: "ATBU CAMPUS",
		lga_id: 653,
	},
	{
		id: 3907,
		name: "DUTSI",
		lga_id: 745,
	},
	{
		id: 3908,
		name: "AWARA OHAJI",
		lga_id: 244,
	},
	{
		id: 3909,
		name: "EMEDE",
		lga_id: 494,
	},
	{
		id: 3910,
		name: "POTISKUM",
		lga_id: 448,
	},
	{
		id: 3911,
		name: "WAWA, FUNAKAYE",
		lga_id: 716,
	},
	{
		id: 3912,
		name: "IBOWON",
		lga_id: 15,
	},
	{
		id: 3913,
		name: "ADUGI",
		lga_id: 90,
	},
	{
		id: 3914,
		name: "ODOAKPU ONITSHA NORTH",
		lga_id: 500,
	},
	{
		id: 3915,
		name: "ABAKPA NIKE",
		lga_id: 276,
	},
	{
		id: 3916,
		name: "SHOLUYI",
		lga_id: 465,
	},
	{
		id: 3917,
		name: "ABAGANA TOWN",
		lga_id: 772,
	},
	{
		id: 3918,
		name: "AKUMA IMO",
		lga_id: 463,
	},
	{
		id: 3919,
		name: "CHIKUN",
		lga_id: 480,
	},
	{
		id: 3920,
		name: "NEW JERUSALEM",
		lga_id: 259,
	},
	{
		id: 3921,
		name: "IFALAKA",
		lga_id: 37,
	},
	{
		id: 3922,
		name: "ANFANI",
		lga_id: 126,
	},
	{
		id: 3923,
		name: "DAKKA",
		lga_id: 707,
	},
	{
		id: 3924,
		name: "FASKARI",
		lga_id: 631,
	},
	{
		id: 3925,
		name: "YANGOJI",
		lga_id: 643,
	},
	{
		id: 3926,
		name: "AKANI",
		lga_id: 392,
	},
	{
		id: 3927,
		name: "MAIGANA",
		lga_id: 675,
	},
	{
		id: 3928,
		name: "ZANGO, ETSAKO WEST",
		lga_id: 697,
	},
	{
		id: 3929,
		name: "ADANA",
		lga_id: 187,
	},
	{
		id: 3930,
		name: "KIBIYA",
		lga_id: 689,
	},
	{
		id: 3931,
		name: "KOFA",
		lga_id: 73,
	},
	{
		id: 3932,
		name: "EREI",
		lga_id: 30,
	},
	{
		id: 3933,
		name: "OFOROCHI",
		lga_id: 223,
	},
	{
		id: 3934,
		name: "FALANA",
		lga_id: 212,
	},
	{
		id: 3935,
		name: "TILDEN FULANI",
		lga_id: 489,
	},
	{
		id: 3936,
		name: "OWODE IDO",
		lga_id: 85,
	},
	{
		id: 3937,
		name: "OWODE",
		lga_id: 193,
	},
	{
		id: 3938,
		name: "IJEBU IBEFUN",
		lga_id: 159,
	},
	{
		id: 3939,
		name: "OLOMI",
		lga_id: 625,
	},
	{
		id: 3940,
		name: "AJINGI",
		lga_id: 723,
	},
	{
		id: 3941,
		name: "PANKO",
		lga_id: 595,
	},
	{
		id: 3942,
		name: "SIR KASHIM IBRAHIM WAY, YOLA",
		lga_id: 362,
	},
	{
		id: 3943,
		name: "ODO-ARO",
		lga_id: 282,
	},
	{
		id: 3944,
		name: "YASHIKIRA",
		lga_id: 205,
	},
	{
		id: 3945,
		name: "NUNG ITA",
		lga_id: 511,
	},
	{
		id: 3946,
		name: "IBERE, IKORODU",
		lga_id: 212,
	},
	{
		id: 3947,
		name: "AKINOGUN",
		lga_id: 577,
	},
	{
		id: 3948,
		name: "ADA",
		lga_id: 357,
	},
	{
		id: 3949,
		name: "UBULU UKU",
		lga_id: 744,
	},
	{
		id: 3950,
		name: "MOJODA",
		lga_id: 541,
	},
	{
		id: 3951,
		name: "AKINMORIN",
		lga_id: 735,
	},
	{
		id: 3952,
		name: "WAHU",
		lga_id: 586,
	},
	{
		id: 3953,
		name: "OVADE",
		lga_id: 438,
	},
	{
		id: 3954,
		name: "NIPSS KURU JOS",
		lga_id: 683,
	},
	{
		id: 3955,
		name: "OGUN FREE TRADE ZONE",
		lga_id: 629,
	},
	{
		id: 3956,
		name: "IFE OLUKOTUN",
		lga_id: 206,
	},
	{
		id: 3957,
		name: "TULA",
		lga_id: 245,
	},
	{
		id: 3958,
		name: "ORIE OGWU",
		lga_id: 26,
	},
	{
		id: 3959,
		name: "OWODE OYO WEST",
		lga_id: 282,
	},
	{
		id: 3960,
		name: "ANIFOWOSHE",
		lga_id: 16,
	},
	{
		id: 3961,
		name: "LAGOS TRADE FAIR",
		lga_id: 694,
	},
	{
		id: 3962,
		name: "BAKORI",
		lga_id: 749,
	},
	{
		id: 3963,
		name: "CHAKWAMA",
		lga_id: 225,
	},
	{
		id: 3964,
		name: "KABUSA",
		lga_id: 476,
	},
	{
		id: 3965,
		name: "INUWA DUTSE HOUSING ESTATE",
		lga_id: 464,
	},
	{
		id: 3966,
		name: "LAMURDE",
		lga_id: 405,
	},
	{
		id: 3967,
		name: "AGBOWA EPE",
		lga_id: 541,
	},
	{
		id: 3968,
		name: "IPERIN IJESHA",
		lga_id: 227,
	},
	{
		id: 3969,
		name: "OJUWOCHA",
		lga_id: 731,
	},
	{
		id: 3970,
		name: "MOWO",
		lga_id: 595,
	},
	{
		id: 3971,
		name: "OGHARA, ETHIOPE WEST",
		lga_id: 161,
	},
	{
		id: 3972,
		name: "ELEBELE",
		lga_id: 256,
	},
	{
		id: 3973,
		name: "AGRIC, IKORODU",
		lga_id: 212,
	},
	{
		id: 3974,
		name: "KABUKAWA",
		lga_id: 399,
	},
	{
		id: 3975,
		name: "STADIUM AREA, OLORUNDA",
		lga_id: 394,
	},
	{
		id: 3976,
		name: "DAWARE",
		lga_id: 755,
	},
	{
		id: 3977,
		name: "KALGO",
		lga_id: 424,
	},
	{
		id: 3978,
		name: "NIRESA OYO WEST",
		lga_id: 282,
	},
	{
		id: 3979,
		name: "OGUM",
		lga_id: 266,
	},
	{
		id: 3980,
		name: "ELEPUTU",
		lga_id: 751,
	},
	{
		id: 3981,
		name: "ANAMBRA STATE SECRETARIAT",
		lga_id: 358,
	},
	{
		id: 3982,
		name: "LATOGUN ODOGBOLU",
		lga_id: 159,
	},
	{
		id: 3983,
		name: "ITOKIN EPE",
		lga_id: 15,
	},
	{
		id: 3984,
		name: "NSU",
		lga_id: 536,
	},
	{
		id: 3985,
		name: "ATIMBO",
		lga_id: 559,
	},
	{
		id: 3986,
		name: "ANGUWAN ROMI CHIKUN",
		lga_id: 480,
	},
	{
		id: 3987,
		name: "MBAKA ANGE",
		lga_id: 770,
	},
	{
		id: 3988,
		name: "BULANGU",
		lga_id: 174,
	},
	{
		id: 3989,
		name: "ODOSIDA",
		lga_id: 285,
	},
	{
		id: 3990,
		name: "DUKUGI",
		lga_id: 374,
	},
	{
		id: 3991,
		name: "TSER WASE",
		lga_id: 357,
	},
	{
		id: 3992,
		name: "IPETU IJESA",
		lga_id: 57,
	},
	{
		id: 3993,
		name: "IKENNE",
		lga_id: 151,
	},
	{
		id: 3994,
		name: "UMUABI",
		lga_id: 760,
	},
	{
		id: 3995,
		name: "LIGHT HOUSE ESTATE",
		lga_id: 410,
	},
	{
		id: 3996,
		name: "ILLAH",
		lga_id: 626,
	},
	{
		id: 3997,
		name: "PILEFU",
		lga_id: 53,
	},
	{
		id: 3998,
		name: "UMUAHIA URBAN 2",
		lga_id: 598,
	},
	{
		id: 3999,
		name: "IGBEKEBO",
		lga_id: 127,
	},
	{
		id: 4000,
		name: "MATANDI",
		lga_id: 686,
	},
	{
		id: 4001,
		name: "GINDIRI",
		lga_id: 649,
	},
	{
		id: 4002,
		name: "OGBOR HILL",
		lga_id: 590,
	},
	{
		id: 4003,
		name: "ODO OJA",
		lga_id: 203,
	},
	{
		id: 4004,
		name: "ISINBODE EKITI",
		lga_id: 75,
	},
	{
		id: 4005,
		name: "GOMBE STATE UNIVERSITY",
		lga_id: 347,
	},
	{
		id: 4006,
		name: "TAMA",
		lga_id: 415,
	},
	{
		id: 4007,
		name: "KOFAR GABAS",
		lga_id: 290,
	},
	{
		id: 4008,
		name: "ZARIA AIRPORT",
		lga_id: 616,
	},
	{
		id: 4009,
		name: "KAGUNI",
		lga_id: 225,
	},
	{
		id: 4010,
		name: "RONI",
		lga_id: 301,
	},
	{
		id: 4011,
		name: "IJANIKIN",
		lga_id: 228,
	},
	{
		id: 4012,
		name: "GERI ALIMI",
		lga_id: 108,
	},
	{
		id: 4013,
		name: "NDOT",
		lga_id: 511,
	},
	{
		id: 4014,
		name: "MALUMFASHI",
		lga_id: 153,
	},
	{
		id: 4015,
		name: "KUMAPAYI",
		lga_id: 607,
	},
	{
		id: 4016,
		name: "UNIVERSITY OF AGRICULTURE ABEOKUTA",
		lga_id: 356,
	},
	{
		id: 4017,
		name: "KURUDU",
		lga_id: 335,
	},
	{
		id: 4018,
		name: "ODINJO",
		lga_id: 173,
	},
	{
		id: 4019,
		name: "EHA ALUMONA",
		lga_id: 704,
	},
	{
		id: 4020,
		name: "OTUO",
		lga_id: 667,
	},
	{
		id: 4021,
		name: "AFOR AMESI",
		lga_id: 747,
	},
	{
		id: 4022,
		name: "RAHAMA",
		lga_id: 489,
	},
	{
		id: 4023,
		name: "UKE",
		lga_id: 669,
	},
	{
		id: 4024,
		name: "ONIGBONGBO",
		lga_id: 16,
	},
	{
		id: 4025,
		name: "ORILE-OWU",
		lga_id: 38,
	},
	{
		id: 4026,
		name: "AMACHARA",
		lga_id: 337,
	},
	{
		id: 4027,
		name: "KETEREN GWARI",
		lga_id: 97,
	},
	{
		id: 4028,
		name: "OGBON OBA",
		lga_id: 573,
	},
	{
		id: 4029,
		name: "PALMS SHOPPING MALL",
		lga_id: 17,
	},
	{
		id: 4030,
		name: "DAMPAR",
		lga_id: 133,
	},
	{
		id: 4031,
		name: "UMUNUMO",
		lga_id: 536,
	},
	{
		id: 4032,
		name: "FEDERAL POLY ILARO",
		lga_id: 680,
	},
	{
		id: 4033,
		name: "KEANA",
		lga_id: 516,
	},
	{
		id: 4034,
		name: "APATA, JOS",
		lga_id: 732,
	},
	{
		id: 4035,
		name: "UNIVERSITY OF JOS PERMANENT SITE",
		lga_id: 732,
	},
	{
		id: 4036,
		name: "OBELEAGU MARKET",
		lga_id: 500,
	},
	{
		id: 4037,
		name: "OGUNU",
		lga_id: 417,
	},
	{
		id: 4038,
		name: "AWGU",
		lga_id: 547,
	},
	{
		id: 4039,
		name: "FASHOLA",
		lga_id: 282,
	},
	{
		id: 4040,
		name: "MBAMBA",
		lga_id: 553,
	},
	{
		id: 4041,
		name: "AGUNGI",
		lga_id: 410,
	},
	{
		id: 4042,
		name: "IMO HILL",
		lga_id: 470,
	},
	{
		id: 4043,
		name: "OZUBULU EKWUSIGO",
		lga_id: 773,
	},
	{
		id: 4044,
		name: "IMEKE",
		lga_id: 595,
	},
	{
		id: 4045,
		name: "NICON ESTATE",
		lga_id: 17,
	},
	{
		id: 4046,
		name: "IJERO",
		lga_id: 641,
	},
	{
		id: 4047,
		name: "UMULUMGBE",
		lga_id: 637,
	},
	{
		id: 4048,
		name: "AREAGBO",
		lga_id: 529,
	},
	{
		id: 4049,
		name: "KANO STATE UNIVERSITY",
		lga_id: 306,
	},
	{
		id: 4050,
		name: "MGBUKA MARKET CENTRE",
		lga_id: 669,
	},
	{
		id: 4051,
		name: "IGBO ELERIN",
		lga_id: 228,
	},
	{
		id: 4052,
		name: "EHEKELEM OWERRI",
		lga_id: 676,
	},
	{
		id: 4053,
		name: "BOMADI",
		lga_id: 614,
	},
	{
		id: 4054,
		name: "GASANGA AFCOT",
		lga_id: 217,
	},
	{
		id: 4055,
		name: "EJIRAN",
		lga_id: 15,
	},
	{
		id: 4056,
		name: "EJIGBO, LAGOS",
		lga_id: 509,
	},
	{
		id: 4057,
		name: "AWAIN",
		lga_id: 697,
	},
	{
		id: 4058,
		name: "MILITARY BARRACKS IDEMILI NORTH",
		lga_id: 669,
	},
	{
		id: 4059,
		name: "IGBALA",
		lga_id: 212,
	},
	{
		id: 4060,
		name: "YANA",
		lga_id: 460,
	},
	{
		id: 4061,
		name: "UDOMI",
		lga_id: 731,
	},
	{
		id: 4062,
		name: "IKONO UYO",
		lga_id: 162,
	},
	{
		id: 4063,
		name: "PIPELINE, ALIMOSHO",
		lga_id: 577,
	},
	{
		id: 4064,
		name: "OJA ODAN EGBADO NORTH",
		lga_id: 62,
	},
	{
		id: 4065,
		name: "COKER AMUWO",
		lga_id: 694,
	},
	{
		id: 4066,
		name: "ANDONI",
		lga_id: 652,
	},
	{
		id: 4067,
		name: "KAJURU",
		lga_id: 273,
	},
	{
		id: 4068,
		name: "BADARA",
		lga_id: 69,
	},
	{
		id: 4069,
		name: "WANKA",
		lga_id: 69,
	},
	{
		id: 4070,
		name: "ASEJIRE",
		lga_id: 607,
	},
	{
		id: 4071,
		name: "BOGORO",
		lga_id: 725,
	},
	{
		id: 4072,
		name: "UGBEGUN",
		lga_id: 621,
	},
	{
		id: 4073,
		name: "PLAZA DE ARUNA HOTEL, OYO",
		lga_id: 735,
	},
	{
		id: 4074,
		name: "AMATO OROGWE",
		lga_id: 189,
	},
	{
		id: 4075,
		name: "KURMIN MAZUGA",
		lga_id: 647,
	},
	{
		id: 4076,
		name: "UKODU",
		lga_id: 91,
	},
	{
		id: 4077,
		name: "GWADABAWA",
		lga_id: 47,
	},
	{
		id: 4078,
		name: "AJARA VETHO",
		lga_id: 595,
	},
	{
		id: 4079,
		name: "DERE",
		lga_id: 638,
	},
	{
		id: 4080,
		name: "GINDI",
		lga_id: 423,
	},
	{
		id: 4081,
		name: "SABON PEGI, LAFIA",
		lga_id: 452,
	},
	{
		id: 4082,
		name: "TAMBAWAL",
		lga_id: 423,
	},
	{
		id: 4083,
		name: "NEW LAYOUT ABAKALIKI",
		lga_id: 520,
	},
	{
		id: 4084,
		name: "IKPA NUNG ASANG",
		lga_id: 432,
	},
	{
		id: 4085,
		name: "ISAAC BORO ESTATE",
		lga_id: 167,
	},
	{
		id: 4086,
		name: "OGBONO",
		lga_id: 421,
	},
	{
		id: 4087,
		name: "TUDUN WADA, JOS NORTH",
		lga_id: 732,
	},
	{
		id: 4088,
		name: "GWADE",
		lga_id: 374,
	},
	{
		id: 4089,
		name: "EZIMO",
		lga_id: 753,
	},
	{
		id: 4090,
		name: "ILESHA BARUBA",
		lga_id: 205,
	},
	{
		id: 4091,
		name: "ITU",
		lga_id: 237,
	},
	{
		id: 4092,
		name: "IFOYINTEDO",
		lga_id: 141,
	},
	{
		id: 4093,
		name: "GWADA",
		lga_id: 429,
	},
	{
		id: 4094,
		name: "UGBAWKA",
		lga_id: 704,
	},
	{
		id: 4095,
		name: "ANDODEKA",
		lga_id: 140,
	},
	{
		id: 4096,
		name: "IMEKO",
		lga_id: 195,
	},
	{
		id: 4097,
		name: "OKUN OWA",
		lga_id: 159,
	},
	{
		id: 4098,
		name: "EMIRS PALACE KANO",
		lga_id: 703,
	},
	{
		id: 4099,
		name: "MENDE",
		lga_id: 465,
	},
	{
		id: 4100,
		name: "UMUAMALA",
		lga_id: 100,
	},
	{
		id: 4101,
		name: "AGUNBELEWO ESTATE",
		lga_id: 394,
	},
	{
		id: 4102,
		name: "GRA AREA, KADUNA",
		lga_id: 284,
	},
	{
		id: 4103,
		name: "ESUGALE",
		lga_id: 517,
	},
	{
		id: 4104,
		name: "SQUADROM 13",
		lga_id: 357,
	},
	{
		id: 4105,
		name: "IBENO CLAN",
		lga_id: 571,
	},
	{
		id: 4106,
		name: "UMURIAM NAWFIA",
		lga_id: 772,
	},
	{
		id: 4107,
		name: "OKELAJE",
		lga_id: 285,
	},
	{
		id: 4108,
		name: "UNGUAR BIJEY",
		lga_id: 480,
	},
	{
		id: 4109,
		name: "PELA",
		lga_id: 53,
	},
	{
		id: 4110,
		name: "AYIGBA",
		lga_id: 202,
	},
	{
		id: 4111,
		name: "KWALE",
		lga_id: 610,
	},
	{
		id: 4112,
		name: "OWU",
		lga_id: 118,
	},
	{
		id: 4113,
		name: "KWEME",
		lga_id: 10,
	},
	{
		id: 4114,
		name: "IKERE EKITI",
		lga_id: 681,
	},
	{
		id: 4115,
		name: "SHUWA",
		lga_id: 518,
	},
	{
		id: 4116,
		name: "ATAOJA ESTATE",
		lga_id: 268,
	},
	{
		id: 4117,
		name: "IKOT ABASI",
		lga_id: 432,
	},
	{
		id: 4118,
		name: "LAGO",
		lga_id: 586,
	},
	{
		id: 4119,
		name: "YABA, LAGOS",
		lga_id: 442,
	},
	{
		id: 4120,
		name: "OSBORNE",
		lga_id: 410,
	},
	{
		id: 4121,
		name: "IMEKE",
		lga_id: 10,
	},
	{
		id: 4122,
		name: "ANTHONY",
		lga_id: 465,
	},
	{
		id: 4123,
		name: "OBUOFIA IFITE NIBO",
		lga_id: 358,
	},
	{
		id: 4124,
		name: "IKEJE IGAH",
		lga_id: 702,
	},
	{
		id: 4125,
		name: "APAPA WHARF",
		lga_id: 526,
	},
	{
		id: 4126,
		name: "PEDRO",
		lga_id: 404,
	},
	{
		id: 4127,
		name: "WUSE 2A",
		lga_id: 476,
	},
	{
		id: 4128,
		name: "ALABA INTERNATIONAL MARKET",
		lga_id: 228,
	},
	{
		id: 4129,
		name: "UMUEME, OBI NGWA",
		lga_id: 463,
	},
	{
		id: 4130,
		name: "ISASA POROGUN AREA",
		lga_id: 619,
	},
	{
		id: 4131,
		name: "MAYERE ROAD",
		lga_id: 757,
	},
	{
		id: 4132,
		name: "GBANGBAN",
		lga_id: 106,
	},
	{
		id: 4133,
		name: "SABO",
		lga_id: 697,
	},
	{
		id: 4134,
		name: "APA",
		lga_id: 10,
	},
	{
		id: 4135,
		name: "KURMI,WASE",
		lga_id: 644,
	},
	{
		id: 4136,
		name: "IJABE ILA ODO",
		lga_id: 771,
	},
	{
		id: 4137,
		name: "ASON",
		lga_id: 212,
	},
	{
		id: 4138,
		name: "UBULUBU",
		lga_id: 370,
	},
	{
		id: 4139,
		name: "OJOKORO IFO",
		lga_id: 549,
	},
	{
		id: 4140,
		name: "IKARE AKOKO",
		lga_id: 297,
	},
	{
		id: 4141,
		name: "ABERU AGBA",
		lga_id: 690,
	},
	{
		id: 4142,
		name: "AKOKWA",
		lga_id: 687,
	},
	{
		id: 4143,
		name: "KARASUWA",
		lga_id: 45,
	},
	{
		id: 4144,
		name: "REDEEMER UNIVERSITY, EDE",
		lga_id: 546,
	},
	{
		id: 4145,
		name: "IDOFIN, IFELODUN, KWARA STATE",
		lga_id: 309,
	},
	{
		id: 4146,
		name: "MUSAWA",
		lga_id: 380,
	},
	{
		id: 4147,
		name: "OKORO AREA IBADAN",
		lga_id: 183,
	},
	{
		id: 4148,
		name: "IWAJOWA",
		lga_id: 629,
	},
	{
		id: 4149,
		name: "UMUABALI",
		lga_id: 26,
	},
	{
		id: 4150,
		name: "UMUCHU TOWN",
		lga_id: 747,
	},
	{
		id: 4151,
		name: "BUSKURI",
		lga_id: 39,
	},
	{
		id: 4152,
		name: "OKE ODO",
		lga_id: 316,
	},
	{
		id: 4153,
		name: "FEDERE",
		lga_id: 148,
	},
	{
		id: 4154,
		name: "NIGER DOCK",
		lga_id: 694,
	},
	{
		id: 4155,
		name: "AMAGU",
		lga_id: 68,
	},
	{
		id: 4156,
		name: "TUDUN MUWAILA",
		lga_id: 47,
	},
	{
		id: 4157,
		name: "DOUGLAS ROAD",
		lga_id: 318,
	},
	{
		id: 4158,
		name: "FUNTUA",
		lga_id: 122,
	},
	{
		id: 4159,
		name: "ANKA",
		lga_id: 58,
	},
	{
		id: 4160,
		name: "OLD YIDI",
		lga_id: 129,
	},
	{
		id: 4161,
		name: "GANUWA",
		lga_id: 123,
	},
	{
		id: 4162,
		name: "JIMETA",
		lga_id: 362,
	},
	{
		id: 4163,
		name: "FEMI OKUNNU ESTATE",
		lga_id: 410,
	},
	{
		id: 4164,
		name: "DARAZO",
		lga_id: 586,
	},
	{
		id: 4165,
		name: "OSI OTA",
		lga_id: 629,
	},
	{
		id: 4166,
		name: "ARAROMI, ILESA",
		lga_id: 203,
	},
	{
		id: 4167,
		name: "OBALENDE",
		lga_id: 766,
	},
	{
		id: 4168,
		name: "EGBERDE",
		lga_id: 591,
	},
	{
		id: 4169,
		name: "UMUELEMAI",
		lga_id: 27,
	},
	{
		id: 4170,
		name: "KWAMBA",
		lga_id: 634,
	},
	{
		id: 4171,
		name: "OKE EGAN",
		lga_id: 268,
	},
	{
		id: 4172,
		name: "MABUSHI",
		lga_id: 476,
	},
	{
		id: 4173,
		name: "UNIMAID",
		lga_id: 693,
	},
	{
		id: 4174,
		name: "GIADE",
		lga_id: 101,
	},
	{
		id: 4175,
		name: "ALOHIA ISULO",
		lga_id: 320,
	},
	{
		id: 4176,
		name: "MOLIPA",
		lga_id: 619,
	},
	{
		id: 4177,
		name: "AGBAMU",
		lga_id: 164,
	},
	{
		id: 4178,
		name: "FESTAC",
		lga_id: 694,
	},
	{
		id: 4179,
		name: "MARA",
		lga_id: 489,
	},
	{
		id: 4180,
		name: "IRA",
		lga_id: 99,
	},
	{
		id: 4181,
		name: "GUMEL",
		lga_id: 482,
	},
	{
		id: 4182,
		name: "IVBI MION",
		lga_id: 667,
	},
	{
		id: 4183,
		name: "SHANGA",
		lga_id: 144,
	},
	{
		id: 4184,
		name: "YUNUSARI",
		lga_id: 486,
	},
	{
		id: 4185,
		name: "ODUNMARA ORODO",
		lga_id: 37,
	},
	{
		id: 4186,
		name: "UTUGWANG",
		lga_id: 398,
	},
	{
		id: 4187,
		name: "AYERE",
		lga_id: 178,
	},
	{
		id: 4188,
		name: "IBAFO",
		lga_id: 690,
	},
	{
		id: 4189,
		name: "IDISEKE",
		lga_id: 219,
	},
	{
		id: 4190,
		name: "OBINKITA TOWN",
		lga_id: 87,
	},
	{
		id: 4191,
		name: "JESUS SAVES",
		lga_id: 574,
	},
	{
		id: 4192,
		name: "UMUNEKE",
		lga_id: 27,
	},
	{
		id: 4193,
		name: "KATEAREGI",
		lga_id: 517,
	},
	{
		id: 4194,
		name: "USEN",
		lga_id: 654,
	},
	{
		id: 4195,
		name: "JANGO",
		lga_id: 529,
	},
	{
		id: 4196,
		name: "INGOHOV",
		lga_id: 495,
	},
	{
		id: 4197,
		name: "AWADA IDEMILI NORTH",
		lga_id: 669,
	},
	{
		id: 4198,
		name: "DUMA",
		lga_id: 556,
	},
	{
		id: 4199,
		name: "SUBERU OJE",
		lga_id: 577,
	},
	{
		id: 4200,
		name: "OWODE ONIRIN",
		lga_id: 465,
	},
	{
		id: 4201,
		name: "UTANGA",
		lga_id: 385,
	},
	{
		id: 4202,
		name: "IKOT NAKANDA,AKPABUYO",
		lga_id: 33,
	},
	{
		id: 4203,
		name: "NDIKPA EZINATO",
		lga_id: 358,
	},
	{
		id: 4204,
		name: "ABUOCHICHE",
		lga_id: 440,
	},
	{
		id: 4205,
		name: "WUSE 1 ZONE 5",
		lga_id: 476,
	},
	{
		id: 4206,
		name: "DANJUMA",
		lga_id: 199,
	},
	{
		id: 4207,
		name: "MAROKO",
		lga_id: 17,
	},
	{
		id: 4208,
		name: "SHAGARI ESTATE",
		lga_id: 577,
	},
	{
		id: 4209,
		name: "LAUTECH",
		lga_id: 529,
	},
	{
		id: 4210,
		name: "LOCATION ROAD, OYIGBO",
		lga_id: 137,
	},
	{
		id: 4211,
		name: "IGBONLA",
		lga_id: 164,
	},
	{
		id: 4212,
		name: "IDIARABA",
		lga_id: 115,
	},
	{
		id: 4213,
		name: "EGEBURU",
		lga_id: 337,
	},
	{
		id: 4214,
		name: "BAGANAKWO",
		lga_id: 19,
	},
	{
		id: 4215,
		name: "ISHERI OLOFIN",
		lga_id: 577,
	},
	{
		id: 4216,
		name: "UMUAHII",
		lga_id: 37,
	},
	{
		id: 4217,
		name: "DUTSE RURAL",
		lga_id: 464,
	},
	{
		id: 4218,
		name: "AMALLA",
		lga_id: 753,
	},
	{
		id: 4219,
		name: "SAKA TINUBU",
		lga_id: 17,
	},
	{
		id: 4220,
		name: "BANANA ISLAND",
		lga_id: 410,
	},
	{
		id: 4221,
		name: "JUJI",
		lga_id: 480,
	},
	{
		id: 4222,
		name: "NENWE",
		lga_id: 422,
	},
	{
		id: 4223,
		name: "IJAPO ESTATE",
		lga_id: 52,
	},
	{
		id: 4224,
		name: "ORO",
		lga_id: 164,
	},
	{
		id: 4225,
		name: "AGBADO IFO",
		lga_id: 549,
	},
	{
		id: 4226,
		name: "OKPEKPE",
		lga_id: 431,
	},
	{
		id: 4227,
		name: "OBIARUKU",
		lga_id: 503,
	},
	{
		id: 4228,
		name: "HADEJIA JAMA'ARE RIVER BASIN AREA",
		lga_id: 20,
	},
	{
		id: 4229,
		name: "FEDERAL LOW COST HOUSING, BAUCHI",
		lga_id: 653,
	},
	{
		id: 4230,
		name: "ARAROMI OKE-ADO",
		lga_id: 383,
	},
	{
		id: 4231,
		name: "ODE-OMI",
		lga_id: 15,
	},
	{
		id: 4232,
		name: "PALM SPRING ESTATE",
		lga_id: 17,
	},
	{
		id: 4233,
		name: "ERIKORODU",
		lga_id: 212,
	},
	{
		id: 4234,
		name: "BOLO",
		lga_id: 275,
	},
	{
		id: 4235,
		name: "FAGGE SECRETARIAT",
		lga_id: 718,
	},
	{
		id: 4236,
		name: "GBAGBA WEST",
		lga_id: 108,
	},
	{
		id: 4237,
		name: "NKWO ELELEM",
		lga_id: 676,
	},
	{
		id: 4238,
		name: "ASSIGA",
		lga_id: 221,
	},
	{
		id: 4239,
		name: "TSARAGI",
		lga_id: 272,
	},
	{
		id: 4240,
		name: "ADATAN",
		lga_id: 118,
	},
	{
		id: 4241,
		name: "AKUKU UNO",
		lga_id: 503,
	},
	{
		id: 4242,
		name: "OKE ALAPATA",
		lga_id: 529,
	},
	{
		id: 4243,
		name: "AIBIOKUNLA",
		lga_id: 371,
	},
	{
		id: 4244,
		name: "ODO ONA ELEWE IDO",
		lga_id: 85,
	},
	{
		id: 4245,
		name: "USO",
		lga_id: 593,
	},
	{
		id: 4246,
		name: "NKUM",
		lga_id: 310,
	},
	{
		id: 4247,
		name: "ARULOGUN AGORO",
		lga_id: 524,
	},
	{
		id: 4248,
		name: "UGBER HWER",
		lga_id: 218,
	},
	{
		id: 4249,
		name: "ALOGBA",
		lga_id: 18,
	},
	{
		id: 4250,
		name: "AREMO IBADAN NORTH",
		lga_id: 183,
	},
	{
		id: 4251,
		name: "IKOT OSUKPONG",
		lga_id: 426,
	},
	{
		id: 4252,
		name: "MIDIM",
		lga_id: 540,
	},
	{
		id: 4253,
		name: "KHANA",
		lga_id: 249,
	},
	{
		id: 4254,
		name: "IRAWO OWODE",
		lga_id: 132,
	},
	{
		id: 4255,
		name: "MAKERA GANDU",
		lga_id: 706,
	},
	{
		id: 4256,
		name: "AGBEDE LAGOS",
		lga_id: 18,
	},
	{
		id: 4257,
		name: "UGBIGHOKO",
		lga_id: 23,
	},
	{
		id: 4258,
		name: "AGBADUMA",
		lga_id: 702,
	},
	{
		id: 4259,
		name: "NASSARAWA GOMBE",
		lga_id: 103,
	},
	{
		id: 4260,
		name: "OIL AND GAS FREE TRADE ZONE",
		lga_id: 342,
	},
	{
		id: 4261,
		name: "LAYIN GADUJE",
		lga_id: 349,
	},
	{
		id: 4262,
		name: "IGANNA",
		lga_id: 565,
	},
	{
		id: 4263,
		name: "OBUZO",
		lga_id: 765,
	},
	{
		id: 4264,
		name: "RIGACHIKUN",
		lga_id: 130,
	},
	{
		id: 4265,
		name: "LAGOS POLYTECHNIC, IKORODU",
		lga_id: 212,
	},
	{
		id: 4266,
		name: "IDDAH",
		lga_id: 225,
	},
	{
		id: 4267,
		name: "KISHI",
		lga_id: 340,
	},
	{
		id: 4268,
		name: "MATAZU",
		lga_id: 437,
	},
	{
		id: 4269,
		name: "ILE IRE",
		lga_id: 309,
	},
	{
		id: 4270,
		name: "AGBADO EKITI",
		lga_id: 323,
	},
	{
		id: 4271,
		name: "IKOYA",
		lga_id: 248,
	},
	{
		id: 4272,
		name: "DIOBU MILE 2",
		lga_id: 594,
	},
	{
		id: 4273,
		name: "IGUOSHODIN",
		lga_id: 393,
	},
	{
		id: 4274,
		name: "TEJUOSHO",
		lga_id: 641,
	},
	{
		id: 4275,
		name: "DONGA",
		lga_id: 501,
	},
	{
		id: 4276,
		name: "KABANG",
		lga_id: 477,
	},
	{
		id: 4277,
		name: "GALLA",
		lga_id: 291,
	},
	{
		id: 4278,
		name: "ILLO, BAGUDO",
		lga_id: 255,
	},
	{
		id: 4279,
		name: "LAMINGA",
		lga_id: 335,
	},
	{
		id: 4280,
		name: "OJOWO",
		lga_id: 299,
	},
	{
		id: 4281,
		name: "OGLEWU",
		lga_id: 357,
	},
	{
		id: 4282,
		name: "UMUYEYE UMUAKPO",
		lga_id: 244,
	},
	{
		id: 4283,
		name: "SABON TASHA",
		lga_id: 480,
	},
	{
		id: 4284,
		name: "GESSE LOW COST",
		lga_id: 706,
	},
	{
		id: 4285,
		name: "ETCHE, UKWA WEST",
		lga_id: 658,
	},
	{
		id: 4286,
		name: "IREDE",
		lga_id: 694,
	},
	{
		id: 4287,
		name: "MARARRABA",
		lga_id: 649,
	},
	{
		id: 4288,
		name: "APA",
		lga_id: 595,
	},
	{
		id: 4289,
		name: "OPI",
		lga_id: 557,
	},
	{
		id: 4290,
		name: "OJUWO AJEBENE",
		lga_id: 202,
	},
	{
		id: 4291,
		name: "ODO ONA ELEWE IBADAN SW",
		lga_id: 126,
	},
	{
		id: 4292,
		name: "DUGBE",
		lga_id: 648,
	},
	{
		id: 4293,
		name: "OKO YONG SOUTH",
		lga_id: 543,
	},
	{
		id: 4294,
		name: "EJIBA",
		lga_id: 214,
	},
	{
		id: 4295,
		name: "FEDERAL LOW COST, ONDO",
		lga_id: 285,
	},
	{
		id: 4296,
		name: "ENERHEN",
		lga_id: 532,
	},
	{
		id: 4297,
		name: "OKO SURULERE, OYO",
		lga_id: 381,
	},
	{
		id: 4298,
		name: "NEW IFE RD",
		lga_id: 372,
	},
	{
		id: 4299,
		name: "UMUEZE KOHA",
		lga_id: 160,
	},
	{
		id: 4300,
		name: "KOLAPO ISHOLA GRA",
		lga_id: 372,
	},
	{
		id: 4301,
		name: "AHOKO",
		lga_id: 531,
	},
	{
		id: 4302,
		name: "GRA, ENUGU",
		lga_id: 197,
	},
	{
		id: 4303,
		name: "OJOKORO IFAKO",
		lga_id: 552,
	},
	{
		id: 4304,
		name: "UMUABAI",
		lga_id: 765,
	},
	{
		id: 4305,
		name: "MALLAM AMINU KANO AIRPORT EXTENSION",
		lga_id: 142,
	},
	{
		id: 4306,
		name: "UNIMAID JERE",
		lga_id: 671,
	},
	{
		id: 4307,
		name: "YAMADAWA QUATERS",
		lga_id: 425,
	},
	{
		id: 4308,
		name: "OBUDU CITY",
		lga_id: 398,
	},
	{
		id: 4309,
		name: "DAMATURU RURAL",
		lga_id: 259,
	},
	{
		id: 4310,
		name: "BURUKU IGABI",
		lga_id: 130,
	},
	{
		id: 4311,
		name: "KUKA, SHENDAM",
		lga_id: 341,
	},
	{
		id: 4312,
		name: "OKE GADA",
		lga_id: 268,
	},
	{
		id: 4313,
		name: "ZOOLOGICAL GARDEN",
		lga_id: 732,
	},
	{
		id: 4314,
		name: "LASSA",
		lga_id: 188,
	},
	{
		id: 4315,
		name: "RIMIN GADO",
		lga_id: 544,
	},
	{
		id: 4316,
		name: "THE POLYTECHNIC IBADAN SOUTH GATE",
		lga_id: 183,
	},
	{
		id: 4317,
		name: "CENTRAL MARKET, SOKOTO",
		lga_id: 81,
	},
	{
		id: 4318,
		name: "ISALE OGBA",
		lga_id: 748,
	},
	{
		id: 4319,
		name: "AMOFFIA MGBO",
		lga_id: 642,
	},
	{
		id: 4320,
		name: "IDO EKITI RURAL",
		lga_id: 377,
	},
	{
		id: 4321,
		name: "MUSHIN",
		lga_id: 115,
	},
	{
		id: 4322,
		name: "ARMY BARACKS NORTH BANK",
		lga_id: 357,
	},
	{
		id: 4323,
		name: "LOS",
		lga_id: 18,
	},
	{
		id: 4324,
		name: "SHERETTI",
		lga_id: 476,
	},
	{
		id: 4325,
		name: "OHEBE OHODO",
		lga_id: 637,
	},
	{
		id: 4326,
		name: "KUBWA",
		lga_id: 476,
	},
	{
		id: 4327,
		name: "ISIALA NGWA",
		lga_id: 119,
	},
	{
		id: 4328,
		name: "AKODO",
		lga_id: 751,
	},
	{
		id: 4329,
		name: "ABULE-OJA",
		lga_id: 442,
	},
	{
		id: 4330,
		name: "DIOBU MILE 3",
		lga_id: 594,
	},
	{
		id: 4331,
		name: "ORIN EKITI",
		lga_id: 377,
	},
	{
		id: 4332,
		name: "LOKPANTA",
		lga_id: 633,
	},
	{
		id: 4333,
		name: "ICHI",
		lga_id: 773,
	},
	{
		id: 4334,
		name: "GOVERNOR HOUSE, ASABA",
		lga_id: 574,
	},
	{
		id: 4335,
		name: "WESTERN YALA",
		lga_id: 761,
	},
	{
		id: 4336,
		name: "TRANS EKULU",
		lga_id: 276,
	},
	{
		id: 4337,
		name: "IGANDO, IBEJU-LEKKI",
		lga_id: 751,
	},
	{
		id: 4338,
		name: "ADANKOLO LAYOUT",
		lga_id: 187,
	},
	{
		id: 4339,
		name: "EBELLE",
		lga_id: 461,
	},
	{
		id: 4340,
		name: "KUNDILLA HOUSIG ESTATE",
		lga_id: 236,
	},
	{
		id: 4341,
		name: "ARANYIN",
		lga_id: 219,
	},
	{
		id: 4342,
		name: "AMINU KANO TEACHING HOSPITAL",
		lga_id: 236,
	},
	{
		id: 4343,
		name: "IGBOGBO",
		lga_id: 18,
	},
	{
		id: 4344,
		name: "OKE ADO, OGBOMOSHO",
		lga_id: 529,
	},
	{
		id: 4345,
		name: "EKUMENYI",
		lga_id: 68,
	},
	{
		id: 4346,
		name: "NORTHERN ETUNG",
		lga_id: 304,
	},
	{
		id: 4347,
		name: "IMOTA",
		lga_id: 18,
	},
	{
		id: 4348,
		name: "NTEZI ABBA",
		lga_id: 520,
	},
	{
		id: 4349,
		name: "ALFRED REWANE",
		lga_id: 17,
	},
	{
		id: 4350,
		name: "AGUDA",
		lga_id: 641,
	},
	{
		id: 4351,
		name: "DOPEMU",
		lga_id: 240,
	},
	{
		id: 4352,
		name: "KOFAR NUNKAI",
		lga_id: 222,
	},
	{
		id: 4353,
		name: "NABAYI",
		lga_id: 729,
	},
	{
		id: 4354,
		name: "EMOHUA",
		lga_id: 412,
	},
	{
		id: 4355,
		name: "OKE-ARO, IFO",
		lga_id: 549,
	},
	{
		id: 4356,
		name: "IKPOBA",
		lga_id: 114,
	},
	{
		id: 4357,
		name: "ANYAMA",
		lga_id: 167,
	},
	{
		id: 4358,
		name: "WABABI",
		lga_id: 660,
	},
	{
		id: 4359,
		name: "ALKALERI",
		lga_id: 232,
	},
	{
		id: 4360,
		name: "AKAEGBE-UGWU",
		lga_id: 330,
	},
	{
		id: 4361,
		name: "GRA ABAKALIKI",
		lga_id: 68,
	},
	{
		id: 4362,
		name: "TALATA MARAFA",
		lga_id: 597,
	},
	{
		id: 4363,
		name: "KATCHA",
		lga_id: 517,
	},
	{
		id: 4364,
		name: "KONDUGA",
		lga_id: 287,
	},
	{
		id: 4365,
		name: "IDI-ORO, OYO",
		lga_id: 529,
	},
	{
		id: 4366,
		name: "JJA ALAMU",
		lga_id: 129,
	},
	{
		id: 4367,
		name: "ENWANG",
		lga_id: 767,
	},
	{
		id: 4368,
		name: "SHIRORO",
		lga_id: 429,
	},
	{
		id: 4369,
		name: "KWIMO",
		lga_id: 493,
	},
	{
		id: 4370,
		name: "EMAI",
		lga_id: 667,
	},
	{
		id: 4371,
		name: "MASAKA",
		lga_id: 455,
	},
	{
		id: 4372,
		name: "AWOYAYA",
		lga_id: 751,
	},
	{
		id: 4373,
		name: "UHIERE",
		lga_id: 393,
	},
	{
		id: 4374,
		name: "FEDERAL UNIVERSITY, DUTSE",
		lga_id: 464,
	},
	{
		id: 4375,
		name: "TOFA",
		lga_id: 692,
	},
	{
		id: 4376,
		name: "OLEH",
		lga_id: 494,
	},
	{
		id: 4377,
		name: "UMUDURUNNA ABBA",
		lga_id: 120,
	},
	{
		id: 4378,
		name: "SABON GARI, KADUNA",
		lga_id: 757,
	},
	{
		id: 4379,
		name: "NDIOWU",
		lga_id: 257,
	},
	{
		id: 4380,
		name: "OYINGBO",
		lga_id: 442,
	},
	{
		id: 4381,
		name: "OKE OYI",
		lga_id: 129,
	},
	{
		id: 4382,
		name: "WURO CHEKKE",
		lga_id: 553,
	},
	{
		id: 4383,
		name: "SUPARE AKOKO",
		lga_id: 64,
	},
	{
		id: 4384,
		name: "OKA-AKOKO",
		lga_id: 64,
	},
	{
		id: 4385,
		name: "EMIR PALACE GUSAU",
		lga_id: 247,
	},
	{
		id: 4386,
		name: "EMEKUKU",
		lga_id: 741,
	},
	{
		id: 4387,
		name: "URUAGU",
		lga_id: 327,
	},
	{
		id: 4388,
		name: "OKWUOBA",
		lga_id: 337,
	},
	{
		id: 4389,
		name: "JAGINDI TASHA",
		lga_id: 514,
	},
	{
		id: 4390,
		name: "IKE",
		lga_id: 56,
	},
	{
		id: 4391,
		name: "KPEZHI",
		lga_id: 105,
	},
	{
		id: 4392,
		name: "LAKOWE",
		lga_id: 751,
	},
	{
		id: 4393,
		name: "AJAKA",
		lga_id: 720,
	},
	{
		id: 4394,
		name: "DORO, BINDAWA",
		lga_id: 415,
	},
	{
		id: 4395,
		name: "AGBOR POWER LINE",
		lga_id: 125,
	},
	{
		id: 4396,
		name: "OKE MOSAN OBAFEMI OWODE",
		lga_id: 690,
	},
	{
		id: 4397,
		name: "OGBERIN",
		lga_id: 268,
	},
	{
		id: 4398,
		name: "ALAKAHIA",
		lga_id: 78,
	},
	{
		id: 4399,
		name: "BOMPAI",
		lga_id: 578,
	},
	{
		id: 4400,
		name: "INDEPENDENCE LAYOUT, ENUGU SOUTH",
		lga_id: 128,
	},
	{
		id: 4401,
		name: "NIKE",
		lga_id: 276,
	},
	{
		id: 4402,
		name: "RAFIN ATIKU",
		lga_id: 706,
	},
	{
		id: 4403,
		name: "ZURU",
		lga_id: 655,
	},
	{
		id: 4404,
		name: "JAKUSKO",
		lga_id: 149,
	},
	{
		id: 4405,
		name: "NKWE",
		lga_id: 547,
	},
	{
		id: 4406,
		name: "IDUA CLAN",
		lga_id: 51,
	},
	{
		id: 4407,
		name: "AGUOWO",
		lga_id: 358,
	},
	{
		id: 4408,
		name: "AZIGBO",
		lga_id: 170,
	},
	{
		id: 4409,
		name: "TUNDUN WADA",
		lga_id: 122,
	},
	{
		id: 4410,
		name: "SEIMIBIRI",
		lga_id: 743,
	},
	{
		id: 4411,
		name: "IKIRE",
		lga_id: 252,
	},
	{
		id: 4412,
		name: "AJERE",
		lga_id: 221,
	},
	{
		id: 4413,
		name: "IJEDE",
		lga_id: 18,
	},
	{
		id: 4414,
		name: "STATE HOUSING ESTATE, EKITI",
		lga_id: 573,
	},
	{
		id: 4415,
		name: "ABULE-EGBA",
		lga_id: 577,
	},
	{
		id: 4416,
		name: "PESU WATERSIDE",
		lga_id: 417,
	},
	{
		id: 4417,
		name: "CATERPILLAR KANO",
		lga_id: 718,
	},
	{
		id: 4418,
		name: "OBOLLO EKE",
		lga_id: 557,
	},
	{
		id: 4419,
		name: "UYA ORO",
		lga_id: 325,
	},
	{
		id: 4420,
		name: "ISALU",
		lga_id: 595,
	},
	{
		id: 4421,
		name: "OLOFUNA",
		lga_id: 212,
	},
	{
		id: 4422,
		name: "GALADIMAWA",
		lga_id: 476,
	},
	{
		id: 4423,
		name: "FEDERAL UNIVERSITY OF TECHNOLOGY AKURE",
		lga_id: 199,
	},
	{
		id: 4424,
		name: "OKURUAMA",
		lga_id: 594,
	},
	{
		id: 4425,
		name: "GAAMBE YA",
		lga_id: 289,
	},
	{
		id: 4426,
		name: "NTEZI",
		lga_id: 241,
	},
	{
		id: 4427,
		name: "NAMTARA",
		lga_id: 553,
	},
	{
		id: 4428,
		name: "JANYAU",
		lga_id: 247,
	},
	{
		id: 4429,
		name: "ERUNWEN",
		lga_id: 212,
	},
	{
		id: 4430,
		name: "BABACOCK UNIVERSITY",
		lga_id: 151,
	},
	{
		id: 4431,
		name: "GODSWILL AKPABIO STADIUM",
		lga_id: 162,
	},
	{
		id: 4432,
		name: "UMUOWA",
		lga_id: 542,
	},
	{
		id: 4433,
		name: "VICTORY ESTATE",
		lga_id: 410,
	},
	{
		id: 4434,
		name: "ALA QUATERS",
		lga_id: 199,
	},
	{
		id: 4435,
		name: "NNPC STAFF SCHOOL, KADUNA",
		lga_id: 480,
	},
	{
		id: 4436,
		name: "MMA 1",
		lga_id: 584,
	},
	{
		id: 4437,
		name: "OKORO ETE",
		lga_id: 736,
	},
	{
		id: 4438,
		name: "IHUBE",
		lga_id: 147,
	},
	{
		id: 4439,
		name: "ASABARI BARRACKS",
		lga_id: 615,
	},
	{
		id: 4440,
		name: "LAGBEDA",
		lga_id: 485,
	},
	{
		id: 4441,
		name: "ABULE OSUN",
		lga_id: 694,
	},
	{
		id: 4442,
		name: "ABBI",
		lga_id: 610,
	},
	{
		id: 4443,
		name: "UMARUMA",
		lga_id: 364,
	},
	{
		id: 4444,
		name: "EDEMAYA",
		lga_id: 432,
	},
	{
		id: 4445,
		name: "NDA KADUNA",
		lga_id: 130,
	},
	{
		id: 4446,
		name: "ARMY CANTONMENT, MARYLAND",
		lga_id: 16,
	},
	{
		id: 4447,
		name: "AMAWBIA AWKA",
		lga_id: 358,
	},
	{
		id: 4448,
		name: "TUNGA MALLAM",
		lga_id: 750,
	},
	{
		id: 4449,
		name: "NANEV",
		lga_id: 136,
	},
	{
		id: 4450,
		name: "INTERNATIONAL MODEL, IMO",
		lga_id: 742,
	},
	{
		id: 4451,
		name: "GBUDU",
		lga_id: 282,
	},
	{
		id: 4452,
		name: "AKWUKWU IGBO",
		lga_id: 626,
	},
	{
		id: 4453,
		name: "OBODO",
		lga_id: 264,
	},
	{
		id: 4454,
		name: "AGBAJA, IZZI",
		lga_id: 220,
	},
	{
		id: 4455,
		name: "IWOGBAN",
		lga_id: 114,
	},
	{
		id: 4456,
		name: "BARKIN LADI",
		lga_id: 458,
	},
	{
		id: 4457,
		name: "IWAGBA LAYOUT",
		lga_id: 529,
	},
	{
		id: 4458,
		name: "COMPUTER VILLAGE, LAGOS",
		lga_id: 584,
	},
	{
		id: 4459,
		name: "ALARO VILLAGE",
		lga_id: 386,
	},
	{
		id: 4460,
		name: "GARIN MAKURDA",
		lga_id: 443,
	},
	{
		id: 4461,
		name: "ODEIRELE",
		lga_id: 366,
	},
	{
		id: 4462,
		name: "SAKWA, ZAKI",
		lga_id: 179,
	},
	{
		id: 4463,
		name: "TECHNICAL COLLEGE IFE",
		lga_id: 599,
	},
	{
		id: 4464,
		name: "HADEJIA CENTRAL MARKET",
		lga_id: 20,
	},
	{
		id: 4465,
		name: "IGBOLOMI",
		lga_id: 751,
	},
	{
		id: 4466,
		name: "KOFAR WASA",
		lga_id: 653,
	},
	{
		id: 4467,
		name: "KOTONKARIFI",
		lga_id: 531,
	},
	{
		id: 4468,
		name: "ANDAHA",
		lga_id: 98,
	},
	{
		id: 4469,
		name: "OBASANJO ESTATE, DAMATURU",
		lga_id: 259,
	},
	{
		id: 4470,
		name: "AVIELE",
		lga_id: 697,
	},
	{
		id: 4471,
		name: "AGBEDE LAGOS",
		lga_id: 212,
	},
	{
		id: 4472,
		name: "IPANMI",
		lga_id: 18,
	},
	{
		id: 4473,
		name: "OKU ITU",
		lga_id: 237,
	},
	{
		id: 4474,
		name: "BASHORUN IBADAN NORTH",
		lga_id: 183,
	},
	{
		id: 4475,
		name: "LOGO",
		lga_id: 592,
	},
	{
		id: 4476,
		name: "LANGBASA",
		lga_id: 410,
	},
	{
		id: 4477,
		name: "ZARANDA",
		lga_id: 489,
	},
	{
		id: 4478,
		name: "KORE, DAMBATTA",
		lga_id: 281,
	},
	{
		id: 4479,
		name: "ABARANJE",
		lga_id: 577,
	},
	{
		id: 4480,
		name: "EASTERN YALA",
		lga_id: 761,
	},
	{
		id: 4481,
		name: "SADUKO",
		lga_id: 307,
	},
	{
		id: 4482,
		name: "OKOYONG",
		lga_id: 543,
	},
	{
		id: 4483,
		name: "GUMBO",
		lga_id: 643,
	},
	{
		id: 4484,
		name: "OSELEGBE",
		lga_id: 18,
	},
	{
		id: 4485,
		name: "MOZUM",
		lga_id: 266,
	},
	{
		id: 4486,
		name: "SANI ABACHA RD, PH",
		lga_id: 594,
	},
	{
		id: 4487,
		name: "BOJIBOJI OWA",
		lga_id: 441,
	},
	{
		id: 4488,
		name: "NAFADA",
		lga_id: 483,
	},
	{
		id: 4489,
		name: "MAYO GWOI",
		lga_id: 222,
	},
	{
		id: 4490,
		name: "UMUORIAKU OGBODIUKWU",
		lga_id: 26,
	},
	{
		id: 4491,
		name: "OBA AKRAN AGEGE",
		lga_id: 240,
	},
	{
		id: 4492,
		name: "UGANG",
		lga_id: 398,
	},
	{
		id: 4493,
		name: "OBORO, IKWUANO",
		lga_id: 650,
	},
	{
		id: 4494,
		name: "MACHINA",
		lga_id: 764,
	},
	{
		id: 4495,
		name: "GWAMMAJA",
		lga_id: 718,
	},
	{
		id: 4496,
		name: "MIANGO",
		lga_id: 329,
	},
	{
		id: 4497,
		name: "NKWO ACHARA",
		lga_id: 598,
	},
	{
		id: 4498,
		name: "OKE ANU",
		lga_id: 470,
	},
	{
		id: 4499,
		name: "UBA, ASKIRA/UBA",
		lga_id: 188,
	},
	{
		id: 4500,
		name: "ASA NNETU",
		lga_id: 100,
	},
	{
		id: 4501,
		name: "EGWA",
		lga_id: 139,
	},
	{
		id: 4502,
		name: "MUBI HOSPITAL",
		lga_id: 477,
	},
	{
		id: 4503,
		name: "ODORO IKPE",
		lga_id: 685,
	},
	{
		id: 4504,
		name: "GDSS SHAGARI QTRS,YOLA NORTH",
		lga_id: 553,
	},
	{
		id: 4505,
		name: "OFFIN",
		lga_id: 378,
	},
	{
		id: 4506,
		name: "ENIYIOGUGU MBAISE",
		lga_id: 71,
	},
	{
		id: 4507,
		name: "FILELE, LOKOJA",
		lga_id: 187,
	},
	{
		id: 4508,
		name: "IROKO",
		lga_id: 735,
	},
	{
		id: 4509,
		name: "CARLTON GATE ESTATE",
		lga_id: 410,
	},
	{
		id: 4510,
		name: "POKA",
		lga_id: 541,
	},
	{
		id: 4511,
		name: "EKPOMA",
		lga_id: 756,
	},
	{
		id: 4512,
		name: "YAKASAI QUARTERS",
		lga_id: 182,
	},
	{
		id: 4513,
		name: "GARKI AREA 10",
		lga_id: 476,
	},
	{
		id: 4514,
		name: "INYIMA",
		lga_id: 221,
	},
	{
		id: 4515,
		name: "BAYA OJE",
		lga_id: 381,
	},
	{
		id: 4516,
		name: "UWANSE",
		lga_id: 737,
	},
	{
		id: 4517,
		name: "OSUNTEDO",
		lga_id: 545,
	},
	{
		id: 4518,
		name: "IMODI",
		lga_id: 159,
	},
	{
		id: 4519,
		name: "GRACELAND ESTATE",
		lga_id: 410,
	},
	{
		id: 4520,
		name: "IRAGBERI",
		lga_id: 711,
	},
	{
		id: 4521,
		name: "GARU RD",
		lga_id: 464,
	},
	{
		id: 4522,
		name: "COMMERCIAL AREA, GOMBE",
		lga_id: 347,
	},
	{
		id: 4523,
		name: "EKWUMILI",
		lga_id: 170,
	},
	{
		id: 4524,
		name: "ESUK UTAN",
		lga_id: 559,
	},
	{
		id: 4525,
		name: "ABAVO",
		lga_id: 441,
	},
	{
		id: 4526,
		name: "ABEERE",
		lga_id: 268,
	},
	{
		id: 4527,
		name: "MARINA",
		lga_id: 766,
	},
	{
		id: 4528,
		name: "LERE, KADUNA",
		lga_id: 89,
	},
	{
		id: 4529,
		name: "DAURA CITY",
		lga_id: 181,
	},
	{
		id: 4530,
		name: "WORKS AND HOUSING, ABUJA",
		lga_id: 476,
	},
	{
		id: 4531,
		name: "ANGUWAR BAKIN",
		lga_id: 755,
	},
	{
		id: 4532,
		name: "ZANGO DAJI",
		lga_id: 421,
	},
	{
		id: 4533,
		name: "BOLODE OKU",
		lga_id: 529,
	},
	{
		id: 4534,
		name: "IZOMBE MKT",
		lga_id: 288,
	},
	{
		id: 4535,
		name: "BIRNIN BOLAWA",
		lga_id: 483,
	},
	{
		id: 4536,
		name: "IGOSUN",
		lga_id: 99,
	},
	{
		id: 4537,
		name: "OSOKOM",
		lga_id: 250,
	},
	{
		id: 4538,
		name: "AJEGUNLE EDE",
		lga_id: 268,
	},
	{
		id: 4539,
		name: "MILITARY BARRACKS, OJOO",
		lga_id: 524,
	},
	{
		id: 4540,
		name: "OKE ILA",
		lga_id: 359,
	},
	{
		id: 4541,
		name: "YELWA, JOS",
		lga_id: 341,
	},
	{
		id: 4542,
		name: "UGBOSI-AFE",
		lga_id: 479,
	},
	{
		id: 4543,
		name: "EHOR",
		lga_id: 279,
	},
	{
		id: 4544,
		name: "MAKODA",
		lga_id: 769,
	},
	{
		id: 4545,
		name: "OGBA AGBA",
		lga_id: 576,
	},
	{
		id: 4546,
		name: "AMANO ANARA",
		lga_id: 27,
	},
	{
		id: 4547,
		name: "AGBARHA OTOR",
		lga_id: 411,
	},
	{
		id: 4548,
		name: "OGWA, MBAITOLI",
		lga_id: 37,
	},
	{
		id: 4549,
		name: "OKE OWA AREA",
		lga_id: 619,
	},
	{
		id: 4550,
		name: "ZARIA CITY",
		lga_id: 579,
	},
	{
		id: 4551,
		name: "UGWUEKE EZEUKWU",
		lga_id: 344,
	},
	{
		id: 4552,
		name: "URUALLA IHITENANSA",
		lga_id: 298,
	},
	{
		id: 4553,
		name: "BASHAR",
		lga_id: 644,
	},
	{
		id: 4554,
		name: "IKAO",
		lga_id: 667,
	},
	{
		id: 4555,
		name: "UMUAGBAI",
		lga_id: 100,
	},
	{
		id: 4556,
		name: "AFOR, AWKA SOUTH",
		lga_id: 358,
	},
	{
		id: 4557,
		name: "OSHIRI",
		lga_id: 76,
	},
	{
		id: 4558,
		name: "UMUODU",
		lga_id: 189,
	},
	{
		id: 4559,
		name: "TUNGAN KADE",
		lga_id: 493,
	},
	{
		id: 4560,
		name: "OKERE PLACE",
		lga_id: 615,
	},
	{
		id: 4561,
		name: "IRESI",
		lga_id: 302,
	},
	{
		id: 4562,
		name: "OLODI APAPA",
		lga_id: 267,
	},
	{
		id: 4563,
		name: "MARYLAND",
		lga_id: 16,
	},
	{
		id: 4564,
		name: "BIRIYEL",
		lga_id: 604,
	},
	{
		id: 4565,
		name: "OGBONISHA",
		lga_id: 731,
	},
	{
		id: 4566,
		name: "ORITA",
		lga_id: 680,
	},
	{
		id: 4567,
		name: "CBD PHASE 2, ABUJA",
		lga_id: 476,
	},
	{
		id: 4568,
		name: "EGBE EKITI",
		lga_id: 323,
	},
	{
		id: 4569,
		name: "OKABA",
		lga_id: 93,
	},
	{
		id: 4570,
		name: "NAVY TOWN",
		lga_id: 694,
	},
	{
		id: 4571,
		name: "ALBASU",
		lga_id: 403,
	},
	{
		id: 4572,
		name: "DSC COMPLEX",
		lga_id: 331,
	},
	{
		id: 4573,
		name: "MEREJE",
		lga_id: 354,
	},
	{
		id: 4574,
		name: "PORT HARCOURT STADIUM",
		lga_id: 78,
	},
	{
		id: 4575,
		name: "IDOMI",
		lga_id: 221,
	},
	{
		id: 4576,
		name: "UGWUAJI",
		lga_id: 128,
	},
	{
		id: 4577,
		name: "GBOROGBORO",
		lga_id: 383,
	},
	{
		id: 4578,
		name: "TELE MAIFULOTI",
		lga_id: 61,
	},
	{
		id: 4579,
		name: "AMADI VILLAGE",
		lga_id: 594,
	},
	{
		id: 4580,
		name: "KARMO",
		lga_id: 476,
	},
	{
		id: 4581,
		name: "TOMORI ESTATE",
		lga_id: 629,
	},
	{
		id: 4582,
		name: "OPEBI",
		lga_id: 16,
	},
	{
		id: 4583,
		name: "SHELL INDUSTRIAL AREA",
		lga_id: 78,
	},
	{
		id: 4584,
		name: "AYEKOKA",
		lga_id: 383,
	},
	{
		id: 4585,
		name: "TUNGAN MAKERI",
		lga_id: 80,
	},
	{
		id: 4586,
		name: "KEMBERI",
		lga_id: 228,
	},
	{
		id: 4587,
		name: "SHAISKAWA YANDOMA",
		lga_id: 455,
	},
	{
		id: 4588,
		name: "AMIYIOBINOHIA",
		lga_id: 65,
	},
	{
		id: 4589,
		name: "ABBARE",
		lga_id: 722,
	},
	{
		id: 4590,
		name: "EVBOGIDA",
		lga_id: 23,
	},
	{
		id: 4591,
		name: "KATAMBE",
		lga_id: 476,
	},
	{
		id: 4592,
		name: "GRA, ETSAKO WEST",
		lga_id: 697,
	},
	{
		id: 4593,
		name: "SABON GIDA",
		lga_id: 284,
	},
	{
		id: 4594,
		name: "BASSA, PLATEAU STATE",
		lga_id: 329,
	},
	{
		id: 4595,
		name: "ORLU",
		lga_id: 542,
	},
	{
		id: 4596,
		name: "HAUSARI MAIDUGURI",
		lga_id: 693,
	},
	{
		id: 4597,
		name: "ITAS GADAU",
		lga_id: 295,
	},
	{
		id: 4598,
		name: "SAFANA",
		lga_id: 575,
	},
	{
		id: 4599,
		name: "ALAPAKOONI",
		lga_id: 690,
	},
	{
		id: 4600,
		name: "EMIRS PALACE BIRNIN KEBBI",
		lga_id: 706,
	},
	{
		id: 4601,
		name: "UMUOF",
		lga_id: 474,
	},
	{
		id: 4602,
		name: "UNEME-OSU",
		lga_id: 479,
	},
	{
		id: 4603,
		name: "ODO",
		lga_id: 18,
	},
	{
		id: 4604,
		name: "IKANG CENTRAL",
		lga_id: 33,
	},
	{
		id: 4605,
		name: "AGUNJIN ORA",
		lga_id: 309,
	},
	{
		id: 4606,
		name: "OKUVWERI",
		lga_id: 754,
	},
	{
		id: 4607,
		name: "WUSE 2B",
		lga_id: 476,
	},
	{
		id: 4608,
		name: "MOGONO",
		lga_id: 293,
	},
	{
		id: 4609,
		name: "IKOYI ILE",
		lga_id: 305,
	},
	{
		id: 4610,
		name: "AUMUIMO",
		lga_id: 463,
	},
	{
		id: 4611,
		name: "RUMUOLUMENI",
		lga_id: 594,
	},
	{
		id: 4612,
		name: "GUMBI",
		lga_id: 521,
	},
	{
		id: 4613,
		name: "ALALUBOSA ESTATE",
		lga_id: 648,
	},
	{
		id: 4614,
		name: "SABON KALI",
		lga_id: 608,
	},
	{
		id: 4615,
		name: "KABA",
		lga_id: 476,
	},
	{
		id: 4616,
		name: "ADENIRAN LOW COST",
		lga_id: 529,
	},
	{
		id: 4617,
		name: "KURFI",
		lga_id: 659,
	},
	{
		id: 4618,
		name: "AMAKOHA",
		lga_id: 701,
	},
	{
		id: 4619,
		name: "ENWAN",
		lga_id: 479,
	},
	{
		id: 4620,
		name: "AMAORJI",
		lga_id: 765,
	},
	{
		id: 4621,
		name: "FEDERAL MEDICAL CENTRE KATSINA",
		lga_id: 399,
	},
	{
		id: 4622,
		name: "ATTA IKEDURU",
		lga_id: 63,
	},
	{
		id: 4623,
		name: "OKOMU",
		lga_id: 654,
	},
	{
		id: 4624,
		name: "ZALAU",
		lga_id: 489,
	},
	{
		id: 4625,
		name: "AKANRAN ONA ARA",
		lga_id: 246,
	},
	{
		id: 4626,
		name: "ABAJI",
		lga_id: 530,
	},
	{
		id: 4627,
		name: "FUKAFU",
		lga_id: 643,
	},
	{
		id: 4628,
		name: "UBUGHUBU",
		lga_id: 772,
	},
	{
		id: 4629,
		name: "OTUN EKITI",
		lga_id: 768,
	},
	{
		id: 4630,
		name: "OGBA, BENIN CITY",
		lga_id: 508,
	},
	{
		id: 4631,
		name: "IBI",
		lga_id: 133,
	},
	{
		id: 4632,
		name: "IPONRI",
		lga_id: 641,
	},
	{
		id: 4633,
		name: "IHIEUKWU",
		lga_id: 528,
	},
	{
		id: 4634,
		name: "IYERE",
		lga_id: 593,
	},
	{
		id: 4635,
		name: "BYE PASS ROAD",
		lga_id: 706,
	},
	{
		id: 4636,
		name: "ABA UNOR",
		lga_id: 744,
	},
	{
		id: 4637,
		name: "AGBARA",
		lga_id: 629,
	},
	{
		id: 4638,
		name: "GWADABE MARKET",
		lga_id: 525,
	},
	{
		id: 4639,
		name: "ANGUWAN TASHAN AMALI",
		lga_id: 80,
	},
	{
		id: 4640,
		name: "AWKWUZU",
		lga_id: 717,
	},
	{
		id: 4641,
		name: "HOUSE OF ASSEMBLY, JOS",
		lga_id: 732,
	},
	{
		id: 4642,
		name: "OSOSUN",
		lga_id: 18,
	},
	{
		id: 4643,
		name: "KAGARAWAL",
		lga_id: 103,
	},
	{
		id: 4644,
		name: "TAI",
		lga_id: 548,
	},
	{
		id: 4645,
		name: "GOLF COURSE KADUNA",
		lga_id: 284,
	},
	{
		id: 4646,
		name: "IGUMALE",
		lga_id: 506,
	},
	{
		id: 4647,
		name: "MAGBON",
		lga_id: 240,
	},
	{
		id: 4648,
		name: "EBUTE IKORODU",
		lga_id: 18,
	},
	{
		id: 4649,
		name: "AROKOJE",
		lga_id: 118,
	},
	{
		id: 4650,
		name: "MALLAM MADORI",
		lga_id: 661,
	},
	{
		id: 4651,
		name: "KATSINA TOWNSHIP",
		lga_id: 399,
	},
	{
		id: 4652,
		name: "ARTILLERY, PORT-HARCOURT",
		lga_id: 78,
	},
	{
		id: 4653,
		name: "OJUBA",
		lga_id: 446,
	},
];

const BillBoardCampaign = ({
	nextStep,
	selectedInfluencer,
	handleCheckedState,
	handleCampaignDuration,
}) => {
	const dispatch = useDispatch();

	const { allBillBoard, loading } = useSelector(
		(state) => state.allBillBoard || []
	);

	const [tempBillBoard, setTempBillBoard] = useState(null);
	const [selectedBillBoards, setSelectedBillBoards] = useState([]);
	const [selectedRate, setSelectedRate] = useState(null);

	const [closeModal, setCloseModal] = useState(false);
	const [billBoardId, setBillBoardId] = useState("");
	const [billboardFilter, setBillboardFilter] = useState("all");
	const [filterData, setFilterData] = useState({
		rawLga: undefined,
		rawArea: undefined,
		arrayState: undefined,
	});

	const options = statesData.map((state) => {
		return { value: state.id, label: state.name };
	});
	// console.log("🚀 ~ options ~ options:", options);

	const { rawLga, arrayState, rawArea } = filterData;

	const allLga =
		arrayState &&
		arrayState
			.map((value) => NaijaStates.lgas(value.value))
			.map((lga) => lga.lgas);

	const allArea =
		rawLga &&
		rawLga.length !== 0 &&
		rawLga
			.map((value) => NaijaStates.areas(value.label))
			.map((lga) => lga.areas);

	const mergedLga =
		allLga &&
		[].concat.apply([], allLga).map((lga) => {
			return { value: lga.name, label: lga.name };
		});

	const mergedArea =
		allArea &&
		[].concat.apply([], allArea).map((area) => {
			return { value: area.name, label: area.name };
		});

	const billBoardOptions = [
		{
			label: "All",
			value: "all",
		},
		{
			label: "LED Boards",
			value: "led",
		},
		{
			label: "Static Boards",
			value: "static",
		},
	];

	// const handleFilterChange = (e) => {
	// 	setBillboardFilter(e.value);
	// };

	const handleFilterChange = (e) => {
		const { value } = e.target;
		setBillboardFilter(value);
	};

	const setBillboardData = (array, filterOption) => {
		if (filterOption === "all") {
			return array;
		} else {
			return array.filter((billboard) => {
				if (filterOption === "led") {
					return billboard.illumination.toLowerCase().includes("led");
				} else if (filterOption === "static") {
					return billboard.illumination
						.toLowerCase()
						.includes("static");
				}

				// Add a default return statement here if needed
				return false; // Or return the original array, or any other default value
			});
		}
	};

	const Continue = (e) => {
		if (tempBillBoard === null || selectedBillBoards.length <= 0) {
			toast.warning("Choose at least a billboard & plan to continue");
		} else {
			nextStep();
		}
	};

	const toggleHandler = (item) => (e) => {
		setCloseModal(!closeModal);
		const isChecked = e.target.checked;
		let singleBillBoard = allBillBoard.find((el) => el.id === item.id);
		if (isChecked) {
			setBillBoardId(e.target.value);
			singleBillBoard.billboards = tempBillBoard
				? [...tempBillBoard.billboards]
				: [];
			setTempBillBoard(singleBillBoard);
			// setCloseModal(true);
		} else {
			const unchecked = selectedBillBoards.filter(
				(el) => parseInt(el.billboard_id) !== parseInt(e.target.value)
			);
			setSelectedBillBoards(unchecked);
			setSelectedRate(null);
			// setCloseModal(false);
		}
	};

	const handlePlatformOnChange = (item, idx) => (e) => {
		// console.log("item", item);
		setSelectedRate(item);
		handleCampaignDuration(item.name);
	};

	const setSelectedBillBoard = () => {
		if (!selectedRate) {
			toast.warning("Please choose a plan");
		} else {
			const billboardObject = {
				rateType: selectedRate.name,
				cost: selectedRate.cost,
				billboard_id: selectedRate.billboard_id,
				imageUrl: tempBillBoard.imageUrl,
				name: tempBillBoard.title,
				location: tempBillBoard.location,
				pixel_size: tempBillBoard.pixel_size,
				orientation: tempBillBoard.orientation,
				pixel_width: tempBillBoard.pixel_width,
				pixel_height: tempBillBoard.pixel_height,
			};
			setSelectedBillBoards([billboardObject]);
			// setSelectedRate(null);
			setCloseModal(false);
		}
	};

	const handleStateChange = (state) => {
		setFilterData({ ...filterData, arrayState: state });
	};

	const handleLgaChange = (lga) => {
		setFilterData({
			...filterData,
			arrayLga: lga.map((value) => value.value).join(","),
			rawLga: lga,
		});
	};

	const handleAreaChange = (area) => {
		setFilterData({
			...filterData,
			arrayArea: area.map((value) => value.value).join(","),
			rawArea: area,
		});
	};

	useEffect(() => {
		dispatch(getAllBillBoard());
	}, [dispatch]);

	useEffect(() => {
		if (selectedBillBoards.length > 0) {
			handleCheckedState(selectedBillBoards);
		}
	}, [handleCheckedState, selectedBillBoards]);

	return (
		<Fragment>
			{loading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"BillBoard Campaign"} />
					<div className="content-body">
						<div className="container pd-x-0">
							<div className="mg-b-20 mg-md-b-50">
								<div className="d-flex justify-content-between">
									<p className="tx-18 mb-0 tx-com tx-bold">
										60%
									</p>
									<p className="tx-18 mb-0 tx-com tx-bold">
										2 out of 3
									</p>
								</div>
								<div className="progress">
									<div
										className="progress-bar bg-primary progress-bar-striped progress-bar-animated wd-60p"
										role="progressbar"
										aria-valuenow={60}
										aria-valuemin={0}
										aria-valuemax={100}
									/>
								</div>
							</div>
							<div className="row justify-content-between z-index-999">
								<div className="col-md-5 col-12 mg-b-20">
									<p className="tx-20 mb-1">BillBoards</p>
									<p className="tx-blac">
										Select your choice billboard package to
										help promote your business
									</p>
								</div>
								<div className="col-md-5 col-12 mg-b-20">
									<div className="d-flex float-md-right justify-content-end">
										<div>
											<button
												className="btn btn-primary pd-x-50 mg-l-10"
												onClick={Continue}
												type="submit"
												variant="contained"
											>
												Proceed
											</button>
										</div>
									</div>
								</div>
								<div className="col-md-5 col-12 mg-b-20">
									<div className="d-flex align-items-center">
										<label className="mg-b-0 tx-bold tx-18 mg-r-10">
											Filter:
										</label>
										<select
											className="custom-select"
											// value="select channel"
											defaultValue={billboardFilter}
											onChange={handleFilterChange}
										>
											<option value="">
												Select Sender ID
											</option>
											{billBoardOptions.map(
												(filter, i) => (
													<option
														value={filter.value}
														key={i}
													>
														{filter.label}
													</option>
												)
											)}
										</select>
									</div>
								</div>
								{/* <div className="col-12">
									<div className="row mg-b-20">
										<div className="col-md-12 p-0">
											<div className="form-group col-md-4">
												<label className="mg-b-0 tx-bold tx-18 mg-r-10">
													Filter:
												</label>
												<Select
													defaultValue={
														billboardFilter
													}
													onChange={
														handleFilterChange
													}
													options={billBoardOptions}
													// isMulti
												/>
												<select
													className="custom-select"
													// value="select channel"
													defaultValue={
														billboardFilter
													}
													onChange={
														handleFilterChange
													}
												>
													<option value="">
														Select billboard type
													</option>
													{billBoardOptions.map(
														(filter, i) => (
															<option
																value={
																	filter.value
																}
																key={i}
															>
																{filter.label}
															</option>
														)
													)}
												</select>
											</div>
										</div>
										<div className="form-group col-md-4 col-lg-4">
											<label
												className="mb-1 tx-com d-flex align-items-center"
											>
												State
											</label>
											<Select
												defaultValue={arrayState}
												onChange={handleStateChange}
												options={options}
												isMulti
											/>
										</div>
										<div className="form-group col-md-4 col-lg-4">
											<label
												className="mb-1 tx-com d-flex align-items-center"
											>
												LGA
											</label>
											<Select
												defaultValue={rawLga}
												onChange={handleLgaChange}
												options={mergedLga}
												isMulti
											/>
										</div>
										<div className="form-group col-md-4 col-lg-4">
											<label className="mb-1 tx-com d-flex align-items-center">
												Area
											</label>
											<Select
												defaultValue={rawArea}
												onChange={handleAreaChange}
												options={mergedArea}
												isMulti
											/>
										</div>
									</div>
								</div> */}
							</div>
							<div className="row">
								{allBillBoard &&
									setBillboardData(
										allBillBoard,
										billboardFilter
									).map((billboard) => (
										<BillBoardCard
											key={billboard.id}
											billboard={billboard}
											toggleHandler={toggleHandler}
											show={selectedInfluencer}
										/>
									))}
							</div>
							<div className="col-md-5 col-xl-4 pd-x-0 mg-y-50">
								<div className="d-flex">
									<Link
										to="/app/campaign/create"
										className="btn btn-outline-primary w-100 mg-b-15"
									>
										Go Back
									</Link>
									<button
										className="btn btn-primary w-100 mg-b-15 mg-l-10"
										onClick={Continue}
										type="submit"
										variant="contained"
									>
										Proceed
									</button>
								</div>
							</div>
							<BillBoardModal
								title="My Modal"
								onClose={() => setCloseModal(false)}
								show={closeModal}
								billBoardId={billBoardId}
								checkedBillBoard={tempBillBoard}
								handlePlatformOnChange={handlePlatformOnChange}
								setSelectedBillBoard={setSelectedBillBoard}
								selectedRate={selectedRate}
							/>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default BillBoardCampaign;
