import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import MetaData from "../../../layout/MetaData";
import Loader from "../../../loader";
import StationCard from "./StationCard";

import {
	getAllRadioStations,
	clearErrors,
} from "../../../../actions/radioActions";

const RadioStations = ({
	nextStep,
	setSelectedStation,
	setJingleFee,
	setArconFee,
}) => {
	const dispatch = useDispatch();
	const { loading, radio, jingleFee, arconFee, error } = useSelector(
		(state) => state.radioCampaign || []
	);

	const Continue = (e) => {
		nextStep();
	};

	// const feesString = jingleFee?.fees?.fees || [];
	// const totalAmount = feesString
	// 	.filter((item) => item)
	// 	.reduce((total, item) => total + parseInt(item.amount), 0);

	const toggleHandler = (station) => (e) => {
		setSelectedStation(station);
		setJingleFee(jingleFee || 0);
		setArconFee(arconFee || 30000);
		Continue();
	};

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch(clearErrors());
		}
	}, [dispatch, error]);

	useEffect(() => {
		dispatch(getAllRadioStations());
	}, [dispatch]);

	return (
		<Fragment>
			<MetaData title={"Radio Ads Campaign"} />
			{loading ? (
				<Loader />
			) : (
				<div className="content-body">
					<div className="container pd-x-0">
						<div className="mg-b-20 mg-md-b-50">
							<div className="d-flex justify-content-between">
								<p className="tx-18 mb-0 tx-com tx-bold">50%</p>
								<p className="tx-18 mb-0 tx-com tx-bold">
									2 out of 4
								</p>
							</div>
							<div className="progress">
								<div
									className="progress-bar bg-primary progress-bar-striped progress-bar-animated wd-50p"
									role="progressbar"
									aria-valuenow={60}
									aria-valuemin={0}
									aria-valuemax={100}
								/>
							</div>
						</div>
						<div className="row justify-content-between">
							<div className="col-md-12 col-12 mg-b-20">
								<p className="tx-22 tx-bold mb-2">
									Select your preferred radio station
								</p>
								<p className="tx-blac tx-14">
									Select the goal that would make the campaign
									successful for you
								</p>
							</div>
						</div>
						<div className="row">
							{radio &&
								radio.map((station) => (
									<StationCard
										key={station.id}
										station={station}
										toggleHandler={toggleHandler}
									/>
								))}
						</div>
						<div className="col-md-2 col-xl-2 col-sm-12 pd-x-0 mg-y-50">
							<div className="d-flex">
								<Link
									to="/app/campaign/create"
									className="btn btn-outline-primary w-100 mg-b-15"
								>
									Go Back
								</Link>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default RadioStations;
