import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, getUser } from "../../../../actions/authActions";

import FeatherIcon from "feather-icons-react";

const DashboardHeader = () => {
	const ref = useRef();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const dispatch = useDispatch();

	const { userDetails } = useSelector((state) => state || []);

	const logoutHandler = () => {
		dispatch(logout());
	};

	useEffect(() => {
		dispatch(getUser());
		const checkIfClickedOutside = (e) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
				setIsMenuOpen(false);
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [dispatch, isMenuOpen]);

	return (
		<div className="content-header sticky-top shadow-dash bd-b-0">
			<div className="content-search">
				<FeatherIcon icon="search" />
				<input
					type="search"
					className="form-control"
					placeholder="Search..."
				/>
			</div>
			<nav className="nav justify-content-end col">
				<div
					className="dropdown dropdown-profile ml-md-4 ml-2"
					ref={ref}
				>
					<div
						className="dropdown-link tx-dark tx-13 tx-medium"
						onClick={() => setIsMenuOpen((oldState) => !oldState)}
					>
						{userDetails.user.firstName}
						<div className="avatar avatar-sm mg-l-10">
							<img
								src={
									userDetails.user
										? userDetails.user.imageUrl
										: "https://via.placeholder.com/500"
								}
								className="rounded-circle"
								alt="asset"
							/>
						</div>
					</div>
					{isMenuOpen && (
						<div className="dropdown-menu dropdown-menu-right dropdown-adjust tx-13 show">
							<div className="avatar avatar-lg mg-b-15">
								<img
									src={
										userDetails.user
											? userDetails.user.imageUrl
											: "https://via.placeholder.com/500"
									}
									className="rounded-circle"
									alt="asset"
								/>
							</div>
							<h6 className="tx-semibold mg-b-5">
								{userDetails.user.firstName +
									" " +
									(userDetails.user.lastName
										? userDetails.user.lastName
										: "")}
							</h6>
							<p className="mg-b-25 tx-12 tx-color-03">
								Administrator
							</p>
							<Link to="settings" className="dropdown-item">
								<FeatherIcon icon="settings" /> Account Settings
							</Link>
							<div className="dropdown-divider" />
							<Link to="#" className="dropdown-item disabled">
								<FeatherIcon icon="help-circle" /> Help Center
							</Link>
							<Link to="" className="dropdown-item disabled">
								<FeatherIcon icon="life-buoy" /> Forum
							</Link>
							<Link
								to="/"
								className="dropdown-item"
								onClick={logoutHandler}
							>
								<FeatherIcon icon="log-out" /> Sign Out
							</Link>
						</div>
					)}
				</div>
			</nav>
		</div>
	);
};

export default DashboardHeader;
