import { Fragment } from "react";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import { ProgressBar } from "react-bootstrap";

import MetaData from "../../../layout/MetaData";
import AudioPlayer from "../../../../_helpers/AudioPlayer";
import TextEditor from "../../../../_helpers/TextEditor";
import parse from "html-react-parser";

const RadioMedia = ({
	nextStep,
	prevStep,
	handleChange,
	startDate,
	hasJingle,
	selectedFileName,
	hasArcon,
	doNotRequireArcon,
	selectedArconFileName,
	uploadPercentage,
	uploadArconPercentage,
	campaignMessage,
	terms,
	arconTerms,
	arconType,
	price,
	jinglePrice,
	arconFee,
	handleFIleUpload,
	attachment,
	arconAttachment,
	handleDeleteAttachment,
	adType,
	announcementWordCount,
}) => {
	let date = new Date();
	let endingDate = new Date(startDate !== "" ? startDate : date);

	// add 2 day
	date.setDate(date.getDate() + 2);
	endingDate.setDate(date.getDate() + 1);

	const Continue = (e) => {
		e.preventDefault();
		if (startDate === "") {
			toast.error("Please select start date");
		} else if (
			adType !== "Announcement" &&
			hasJingle &&
			attachment === ""
		) {
			toast.error("Please upload jingle");
		} else if (!hasJingle && campaignMessage === "") {
			toast.error("Please provide jingle message");
		} else if (adType === "Announcement" && campaignMessage === "") {
			toast.error("Please provide announcement message");
		} else if (adType !== "Announcement" && !hasJingle && !terms) {
			toast.error("Please accept jingle creation terms");
		} else if (
			adType !== "Announcement" &&
			hasArcon &&
			arconAttachment === ""
		) {
			toast.error("Please upload ARCON Permit");
		} else if (!hasArcon && !arconType && !doNotRequireArcon) {
			toast.error("Please accept ARCON permit terms");
		} else {
			nextStep();
		}
	};

	return (
		<Fragment>
			<MetaData title={"Radio Ads Campaign - Media"} />
			<div className="card bd-0 content-body">
				<div className="card-body flex-none bg-white pd-30-f container pd-x-0">
					<div className="mg-b-20 mg-md-b-50">
						<div className="d-flex justify-content-between">
							<p className="tx-18 mb-0 tx-com tx-bold">100%</p>
							<p className="tx-18 mb-0 tx-com tx-bold">
								4 out of 4
							</p>
						</div>
						<div className="progress">
							<div
								className="progress-bar bg-primary progress-bar-striped progress-bar-animated wd-100p"
								role="progressbar"
								aria-valuenow={100}
								aria-valuemin={0}
								aria-valuemax={100}
							/>
						</div>
					</div>
					<div className="row justify-content-between align-items-center">
						<div className="col-md-6 col-12 mg-b-20">
							<p className="tx-22 tx-bold mb-2">
								Select your radio ads preference
							</p>
							<p className="tx-blac tx-14">
								Select the goal that would make the campaign
								successful for you
							</p>
						</div>
						<div className="col-md-6 col-12 mg-b-20">
							<div className="d-flex align-items-center justify-content-end">
								<h3 className="tx-primary tx-semi-bold tx-16 mg-0 mg-r-10">
									Campaign Cost:
								</h3>
								<NumberFormat
									className="tx-bold tx-22"
									value={price}
									displayType={"text"}
									thousandSeparator={true}
									prefix={"₦"}
								/>
							</div>
						</div>
					</div>
					<div className="align-items-start row justify-content-between">
						<div className="col-md-6 col-12 mg-t-20">
							<form>
								<div className="form-group">
									<label className="mb-2">
										Campaign Starts on
									</label>
									<input
										type="date"
										className="form-control"
										placeholder="Start Date"
										aria-label="startDate"
										aria-describedby="basic-addon1"
										defaultValue={startDate}
										onChange={handleChange("startDate")}
										min={date.toISOString().split("T")[0]}
									/>
								</div>
								{adType === "Jingle" && (
									<>
										<div className="form-group">
											<div className="custom-control custom-radio">
												<input
													type="radio"
													id="hasJingle"
													name="customRadio"
													className="custom-control-input"
													checked={hasJingle}
													value={true}
													onChange={handleChange(
														"hasJingle"
													)}
												/>
												<label
													className="custom-control-label"
													htmlFor="hasJingle"
												>
													I have a jingle
												</label>
											</div>
										</div>
										<div className="form-group">
											<div className="custom-control custom-radio">
												<input
													type="radio"
													id="hasJingle-false"
													name="customRadio"
													className="custom-control-input"
													checked={!hasJingle}
													value={false}
													onChange={handleChange(
														"hasJingle"
													)}
												/>
												<label
													className="custom-control-label"
													htmlFor="hasJingle-false"
												>
													Create Jingle for me
												</label>
											</div>
										</div>
									</>
								)}

								<div>
									{hasJingle && adType === "Jingle" ? (
										<>
											<div className="form-group">
												<label className="mb-2">
													Attachment
												</label>
												<div className="custom-file">
													<input
														type="file"
														name="jingle"
														accept="audio/mp3,audio/mpeg,audio/*"
														className="custom-file-input"
														id="customFile"
														onChange={
															handleFIleUpload
														}
														multiple
													/>
													<label
														className="custom-file-label"
														htmlFor="customFile"
													>
														{selectedFileName}
													</label>
													{uploadPercentage > 0 && (
														<span className="mt-2">
															<ProgressBar
																now={
																	uploadPercentage
																}
																label={`${uploadPercentage}%`}
															/>
														</span>
													)}
													<p className="mt-2 tx-danger tx-italic">
														Audio must not be more
														than 30mb in size and 30
														seconds in length.
													</p>
												</div>
											</div>
										</>
									) : (
										<>
											<div className="form-group mb-2">
												<label className="mb-2">
													{adType === "Jingle"
														? "Message"
														: "Campaign Message"}
													<span className="tx-italic tx-12 tx-gray">
														{" "}
														(
														{adType === "Jingle"
															? "Message will be voiced by an artist"
															: "Announcement would be voiced by Radio Presenter or OAP"}
														)
													</span>
												</label>
												<TextEditor
													content={campaignMessage}
													handleChange={handleChange(
														"campaignMessage"
													)}
													maxWordCount={
														announcementWordCount
													}
													hasCount={
														adType ===
														"Announcement"
													}
													placeholder={
														"Verse: My number don dey portable E mean say, I fit to carry am come to MTN easy, come enjoy the coverage wey no get part two ah ha, the number wey u dey use now and before, carry dey go dere.\nHook: I don port o"
													}
												/>
											</div>
											{adType === "Jingle" ? (
												<div className="custom-control custom-checkbox">
													<input
														type="checkbox"
														className="custom-control-input"
														value={terms}
														id={"terms"}
														onChange={handleChange(
															"terms"
														)}
														checked={terms}
													/>
													<label
														className="custom-control-label"
														htmlFor={"terms"}
													>
														I agree for jingles to
														be created for me (cost:{" "}
														<NumberFormat
															className="tx-danger"
															value={jinglePrice}
															displayType={"text"}
															thousandSeparator={
																true
															}
															prefix={"₦"}
														/>
														)
													</label>
												</div>
											) : (
												<div className="note">
													<p>
														<span className="tx-bold">
															Note:
														</span>{" "}
														Only 50words or 100words
														would be eligible. All
														other words after that
														would not be part of the
														announcement.
													</p>
												</div>
											)}
										</>
									)}
								</div>

								{adType === "Jingle" && (
									<div className="mg-t-40">
										<p className="tx-22 tx-bold mg-b-24 tx-com">
											ARCON
										</p>
										<div className="form-group">
											<div className="custom-control custom-radio">
												<input
													type="radio"
													id="hasArcon"
													name="hasArcon"
													className="custom-control-input"
													checked={
														hasArcon &&
														!doNotRequireArcon
													}
													value={true}
													onChange={handleChange(
														"hasArcon"
													)}
												/>
												<label
													className="custom-control-label"
													htmlFor="hasArcon"
												>
													I have ARCON Permit
												</label>
											</div>
										</div>
										<div className="form-group">
											<div className="custom-control custom-radio">
												<input
													type="radio"
													id="hasArcon-false"
													name="hasArcon"
													className="custom-control-input"
													checked={
														!hasArcon &&
														!doNotRequireArcon
													}
													value={false}
													onChange={handleChange(
														"hasArcon"
													)}
												/>
												<label
													className="custom-control-label"
													htmlFor="hasArcon-false"
												>
													I do not have ARCON Permit
												</label>
											</div>
										</div>
										<div className="form-group">
											<div className="custom-control custom-radio">
												<input
													type="radio"
													id="doNotRequireArcon"
													name="doNotRequireArcon"
													className="custom-control-input"
													checked={
														!hasArcon &&
														doNotRequireArcon
													}
													value={true}
													onChange={handleChange(
														"doNotRequireArcon"
													)}
												/>
												<label
													className="custom-control-label"
													htmlFor="doNotRequireArcon"
												>
													My jingle does not require
													arcon
												</label>
											</div>
										</div>
										<div className="mg-t-40">
											{hasArcon ? (
												<>
													<div className="form-group">
														<label className="mb-2">
															Attachment
														</label>
														<div className="custom-file">
															<input
																type="file"
																name="arcon"
																accept="application/pdf, image/png, image/*"
																className="custom-file-input"
																id="customFile"
																onChange={
																	handleFIleUpload
																}
																multiple
															/>
															<label
																className="custom-file-label"
																htmlFor="customFile"
															>
																{
																	selectedArconFileName
																}
															</label>
															{uploadArconPercentage >
																0 && (
																<span className="mt-2">
																	<ProgressBar
																		now={
																			uploadArconPercentage
																		}
																		label={`${uploadArconPercentage}%`}
																	/>
																</span>
															)}
															<p className="mt-2 tx-danger tx-italic">
																Asset must not
																be more than
																20mb in size.
															</p>
														</div>
													</div>
												</>
											) : (
												!doNotRequireArcon && (
													// <div className="custom-control custom-checkbox">
													// 	<input
													// 		type="checkbox"
													// 		className="custom-control-input"
													// 		value={arconTerms}
													// 		id={"arconTerms"}
													// 		onChange={handleChange(
													// 			"arconTerms"
													// 		)}
													// 		checked={arconTerms}
													// 	/>
													// 	<label
													// 		className="custom-control-label"
													// 		htmlFor={
													// 			"arconTerms"
													// 		}
													// 	>
													// 		I agree for MYSOGI
													// 		to process ARCON
													// 		license for me{" "}
													// 		<br />
													// 		(cost:{" "}
													// 		<NumberFormat
													// 			className="tx-danger"
													// 			value={arconFee}
													// 			displayType={
													// 				"text"
													// 			}
													// 			thousandSeparator={
													// 				true
													// 			}
													// 			prefix={"₦"}
													// 		/>
													// 		)
													// 	</label>
													// </div>
													<div className="form-group">
														<label className="mb-2">
															ARCON Terms
														</label>
														<div className="custom-control custom-radio">
															<input
																type="radio"
																id="arconType-2days"
																name="arconType"
																className="custom-control-input"
																checked={
																	arconType ===
																	"2days"
																}
																value={"2days"}
																onChange={handleChange(
																	"2days"
																)}
															/>
															<label
																className="custom-control-label"
																htmlFor="arconType-2days"
															>
																I agree for
																MYSOGI to
																process 2 days
																ARCON license
																for me (cost:{" "}
																<NumberFormat
																	className="tx-danger"
																	value={
																		arconFee?.arcon_2days ??
																		0
																	}
																	displayType={
																		"text"
																	}
																	thousandSeparator={
																		true
																	}
																	prefix={"₦"}
																/>
																)
															</label>
														</div>
														<div className="mt-2 custom-control custom-radio">
															<input
																type="radio"
																id="arconType-2weeks"
																name="arconType"
																className="custom-control-input"
																checked={
																	arconType ===
																	"2weeks"
																}
																value={"2weeks"}
																onChange={handleChange(
																	"2weeks"
																)}
															/>
															<label
																className="custom-control-label"
																htmlFor="arconType-2weeks"
															>
																I agree for
																MYSOGI to
																process 2 weeks
																ARCON license
																for me (cost:{" "}
																<NumberFormat
																	className="tx-danger"
																	value={
																		arconFee?.arcon_2weeks ??
																		0
																	}
																	displayType={
																		"text"
																	}
																	thousandSeparator={
																		true
																	}
																	prefix={"₦"}
																/>
																)
															</label>
														</div>
													</div>
												)
											)}
										</div>
									</div>
								)}
							</form>
						</div>
						<div className="position-sticky t-0 col-md-5 col-12 mg-t-20 radio-preview-container">
							<div className="radio-preview">
								{hasJingle && adType === "Jingle" ? (
									<>
										<h2 className="radio-preview-header">
											Here’s a preview of your audio
										</h2>
										<AudioPlayer
											src={attachment}
											onDelete={handleDeleteAttachment}
										/>
									</>
								) : (
									<>
										<h2 className="radio-preview-header">
											Here’s a preview of your Campaign
											Message
										</h2>
										{parse(campaignMessage)}
									</>
								)}
							</div>
						</div>
					</div>
					<div className="col-md-4 pd-x-0 mg-y-30">
						<div className="d-flex">
							<button
								className="btn btn-primary w-100 mg-b-15"
								onClick={Continue}
								type="submit"
								variant="contained"
							>
								Proceed
							</button>
							<button
								onClick={prevStep}
								className="btn btn-outline-primary w-100 mg-b-15 mg-l-20"
							>
								Go Back
							</button>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default RadioMedia;
