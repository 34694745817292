import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";

import MetaData from "../../../layout/MetaData";
import { getWallet } from "../../../../actions/billingActions";
import {
	createBillBoardCampaignAction,
	clearErrors,
} from "../../../../actions/campaignActions";
import { BILLBOARD_CAMPAIGN_RESET } from "../../../../constants/campaignConstants";
import Loader from "../../../loader";
import MediaPlayer from "../../../../_helpers/reactPlayer/ReactPlayer";
import useAnalyticsEventTracker from "../../../../_helpers/GoogleAnalytics/GoogleAnalytics";

const PreviewBillBoardCampaign = ({
	nextStep,
	prevStep,
	values,
	attachment,
	checkedInfluencers,
	selectedBillboards,
	handlePrice,
}) => {
	const { error, createBillBoardCampaign, loading } = useSelector(
		(state) => state.billBoardCampaign || []
	);
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { wallet } = useSelector((state) => state.wallet);
	const [walletTotal, setTotal] = useState(0);
	const [campaignDays, setCampaignDays] = useState(1);
	const [payload, setPayload] = useState({});
	const gaEventTracker = useAnalyticsEventTracker("Billboard Campaign");

	const Continue = (e) => {
		e.preventDefault();
		nextStep();
	};

	const Previous = (e) => {
		e.preventDefault();
		prevStep();
	};

	const setScheduleDate = (initialDate, endDate) => {
		let day1 = new Date(initialDate);
		let day2 = new Date(endDate);

		const difference = Math.abs(day2 - day1);
		const days = () => {
			if (values.campaignDuration === "Daily") {
				return values.publishDates.length;
			} else {
				return difference / (1000 * 3600 * 24);
			}
		};
		setCampaignDays(days());

		if (values.campaignDuration !== "Daily" || days < 1 || !days) {
			return 1;
		} else if (values.campaignDuration === "Daily") {
			return days();
		}
	};

	const setEndDate = () => {
		if (values.campaignDuration === "Monthly") {
			let monthAway = new Date(values.startDate);
			monthAway.setMonth(monthAway.getMonth() + values.duration);

			let day = monthAway.getDate();
			let month = monthAway.getMonth() + 1;
			let year = monthAway.getFullYear();

			return year + "-" + month + "-" + day;
		} else {
			let weekAway = new Date(values.startDate);
			weekAway.setDate(weekAway.getDate() + values.duration * 7);

			let day = weekAway.getDate();
			let month = weekAway.getMonth() + 1;
			let year = weekAway.getFullYear();

			return year + "-" + month + "-" + day;
		}
	};

	useEffect(() => {
		const handleCampaignCreation = () => {
			if (
				createBillBoardCampaign &&
				createBillBoardCampaign.success === true
			) {
				gaEventTracker(
					"Billboard Campaign",
					"User created billboard campaign"
				);
				toast.success(createBillBoardCampaign.message);
				dispatch(getWallet());
				navigate("/app/campaigns");
				dispatch({ type: BILLBOARD_CAMPAIGN_RESET });
			} else if (error) {
				toast.error(error);
				dispatch(clearErrors());
				dispatch(getWallet());
			}
		};

		handleCampaignCreation();
	}, [dispatch, error, createBillBoardCampaign, navigate, gaEventTracker]);

	const filteredValue = selectedBillboards;

	useEffect(() => {
		let allTotals = filteredValue.map((el) => {
			return getTotal(el);
		});
		const total = allTotals.reduce((acc, curr) => acc + curr, 0);
		setTotal(
			values.campaignDuration === "Daily"
				? total * setScheduleDate(values.startDate, values.endDate)
				: total *
						setScheduleDate(values.startDate, values.endDate) *
						(values.campaignDuration === "Weekly" ||
						values.campaignDuration === "Monthly"
							? values.duration
							: 1)
		);
		handlePrice(walletTotal);

		const payload = {
			user_id: user.user.id,
			startDate: values.startDate,
			endDate: values.endDate,
			attachment: values.attachment,
			assetType: values.assetType,
			rateType: values.campaignDuration,
			billboards: filteredValue[0],
			duration: values.duration,
			publishDates: values.publishDates,
		};
		setPayload(payload);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredValue, walletTotal]);

	const submitInfluencerCampaignHandler = (e) => {
		e.preventDefault();

		dispatch(createBillBoardCampaignAction(payload));
	};

	const getTotal = (p) => {
		let total;
		total = p.reduce(
			(accumulator, current) => accumulator + parseInt(current.cost),
			0
		);

		return total;
	};

	return (
		<Fragment>
			{loading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Preview Billboard Campaign"} />
					<div className="content-body">
						<div className="container pd-x-0">
							<p className="tx-24 tx-bold tx-com">
								Selection Preview
							</p>
							<div className="card bd-0 rounded shadow-sm">
								<div className="card-body pd-md-x-30">
									<p className="tx-18 mb-2 tx-bold tx-com">
										Preview: (
										{checkedInfluencers[0].orientation})
									</p>
									<div className=" mg-b-20">
										{checkedInfluencers[0].orientation ===
											"Portrait" ||
										checkedInfluencers[0].orientation ===
											"portrait" ? (
											<div
												className="w-100"
												style={{
													position: "relative",
													// backgroundColor: "red",
													paddingTop: "45%",
												}}
											>
												<img
													src="https://res.cloudinary.com/mysogi/image/upload/v1687131778/website/bgPortrait_1_jgkaao.png"
													alt="billboard-img"
													style={{
														position: "absolute",
														left: "33%",
														top: "0",
														width: "35%",
														height: "100%",
													}}
												/>
												<div
													className="justify-content-between"
													style={{
														position: "absolute",
														/* padding-top: 50%; */
														top: "5.5%",
														left: "34%",
														height: "85%",
														// backgroundColor: "green",
														right: "33%",
													}}
												>
													<div className="col-md-12 pd-0 mg-0 h-100">
														{values.assetType ===
														"image" ? (
															<div className="h-100 w-100 justify-content-center align-items-center">
																<img
																	src={
																		values.attachment
																	}
																	className="img-fluid img-fit-cover"
																	alt="logo"
																/>
															</div>
														) : (
															<div className="h-100">
																<MediaPlayer
																	url={
																		attachment
																	}
																	height={
																		"100%"
																	}
																/>
															</div>
														)}
													</div>
												</div>
											</div>
										) : (
											<div
												className="w-100"
												style={{
													position: "relative",
													// backgroundColor: "red",
													paddingTop: "45%",
												}}
											>
												<img
													src="https://res.cloudinary.com/mysogi/image/upload/v1687131661/website/bgBillboard_1_ds2vep.png"
													alt="billboard-img"
													style={{
														position: "absolute",
														left: "0",
														top: "0",
														width: "100%",
													}}
												/>
												<div
													className="justify-content-between"
													style={{
														position: "absolute",
														top: "5%",
														left: "2%",
														height: "89.3%",
														right: "2%",
													}}
												>
													<div className="col-md-12 pd-0 mg-0 h-100">
														{values.assetType ===
														"image" ? (
															<div className="h-100 w-100 justify-content-center align-items-center">
																<img
																	src={
																		values.attachment
																	}
																	className="img-fluid img-fit-cover"
																	alt="logo"
																/>
															</div>
														) : (
															<div className="h-100">
																<MediaPlayer
																	url={
																		attachment
																	}
																	height={
																		"100%"
																	}
																/>
															</div>
														)}
													</div>
												</div>
											</div>
										)}
									</div>
									<div className="table-responsive mg-t-40">
										<table
											className="table inf-table"
											id="campaig"
										>
											<thead className="tx-uppercase tx-medium">
												<tr>
													<th scope="col">Name</th>
													<th scope="col">
														Location
													</th>
													<th scope="col">Size</th>
													<th scope="col">
														Duration
													</th>
													<th scope="col">
														Start Date
													</th>
													<th scope="col">
														End Date
													</th>
													<th scope="col">Cost</th>
												</tr>
											</thead>
											<tbody>
												{filteredValue[0]?.map(
													(platform, id) => (
														<tr key={id}>
															<td>
																<div className="d-flex">
																	<div className="div">
																		<div className="avatar avatar-sm">
																			<img
																				src={
																					platform.imageUrl
																				}
																				className="rounded-circle"
																				alt=""
																			/>
																		</div>
																	</div>
																	<div className="mg-l-10">
																		<p className="mb-0 pd-t-5">
																			{
																				platform.name
																			}
																		</p>
																	</div>
																</div>
															</td>
															<td>
																{
																	platform.location
																}
															</td>
															<td>
																{platform.size}
															</td>
															<td>
																{(values.campaignDuration ===
																"Daily"
																	? `${campaignDays} day(s)`
																	: "") ||
																	(values.campaignDuration ===
																	"Weekly"
																		? `${values.duration} week(s)`
																		: "") ||
																	(values.campaignDuration ===
																	"Monthly"
																		? `${values.duration} month(s)`
																		: "")}
															</td>
															<td>
																{values.campaignDuration ===
																"Daily"
																	? values
																			.publishDates
																			.length >=
																	  1
																		? values
																				.publishDates[0]
																		: values.startDate
																	: values.startDate}
															</td>
															<td>
																{values.campaignDuration ===
																	"Daily" &&
																	(values
																		.publishDates
																		.length >=
																	1
																		? values.publishDates.slice(
																				-1
																		  )
																		: " - ")}
																{values.campaignDuration ===
																	"Weekly" &&
																	(values.startDate !==
																	""
																		? setEndDate()
																		: " - ")}
																{values.campaignDuration ===
																	"Monthly" &&
																	(values.startDate !==
																	""
																		? setEndDate()
																		: " - ")}
															</td>
															<td>
																{
																	<NumberFormat
																		value={
																			platform.cost
																		}
																		displayType={
																			"text"
																		}
																		thousandSeparator={
																			true
																		}
																		prefix={
																			"₦"
																		}
																	/>
																}
															</td>
														</tr>
													)
												)}
											</tbody>
										</table>
										<div className="col-md-5 col-12 offset-md-7 pd-md-x-0">
											<div className="card card-body bg-pink bd-0 rounded">
												<div className="row">
													<div className="col-6">
														<p className="mb-0 tx-right">
															Total Amount
														</p>
													</div>
													<div className="col-6">
														<p className="mb-0 tx-right tx-medium">
															{
																<NumberFormat
																	value={parseInt(
																		walletTotal
																	)}
																	displayType={
																		"text"
																	}
																	thousandSeparator={
																		true
																	}
																	prefix={"₦"}
																/>
															}
														</p>
														{values.campaignDuration ===
															"Daily" && (
															<p className="tx-bold mb-0 tx-right">
																cost x{" "}
																{campaignDays}{" "}
																day(s)
															</p>
														)}
														{values.campaignDuration ===
															"Weekly" && (
															<p className="tx-bold mb-0 tx-right">
																cost x{" "}
																{
																	values.duration
																}{" "}
																week(s)
															</p>
														)}
														{values.campaignDuration ===
															"Monthly" && (
															<p className="tx-bold mb-0 tx-right">
																cost x{" "}
																{
																	values.duration
																}{" "}
																month(s)
															</p>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-5 pd-x-0 mg-y-40">
										<div className="d-flex">
											<button
												className="btn btn-outline-primary pd-x-30 mg-r-15"
												onClick={Previous}
												disabled={
													loading ? true : false
												}
												type="submit"
												variant="contained"
											>
												Go Back
											</button>
											{parseInt(
												wallet && wallet.balance
											) < walletTotal ? (
												<button
													className="btn btn-primary pd-x-40 tx-com"
													onClick={Continue}
													disabled={
														loading ? true : false
													}
													type="submit"
													variant="contained"
												>
													Fund Wallet
												</button>
											) : (
												<button
													className="btn btn-primary pd-x-40 tx-com"
													type="button"
													data-toggle="modal"
													data-target="#successModal"
													data-dismiss="modal"
												>
													Proceed
												</button>
											)}
										</div>
									</div>
									{/*Success  Modal */}
									<div
										className="modal fade"
										id="successModal"
										tabIndex={-1}
										aria-labelledby="assignModalLabel"
										aria-hidden="true"
									>
										<div className="modal-dialog modal-dialog-centered modal-smm">
											<div className="modal-content pd-md-x-30 pd-x-20 pd-y-20">
												<div className="modal-header bd-b-0"></div>
												<div className="modal-body tx-center pd-x-10">
													<div className="form-group">
														<img
															src="https://res.cloudinary.com/mysogi/image/upload/v1687131430/website/my4_orpcuf.svg"
															className="img-fluid wd-100 ht-100"
															alt=""
															// srcSet
														/>
														<p className="tx-26 tx-com tx-bold">
															Thanks For Your
															Order
														</p>
														<p className="tx-16 mb-0">
															Your campaign will
															be vetted by ARCON
															and would be
															published within 48
															hours if successful
														</p>
													</div>
												</div>
												<div className="tx-center bd-t-0 pd-b-30">
													<button
														type="button"
														value="submit"
														form="senderIdForm"
														className="btn btn-primary w-50"
														data-dismiss="modal"
														onClick={
															submitInfluencerCampaignHandler
														}
														disabled={
															loading
																? true
																: false
														}
														variant="contained"
													>
														Publish
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default PreviewBillBoardCampaign;
