export const PROPELLER_CAMPAIGN_REQUEST = "PROPELLER_CAMPAIGN_REQUEST";
export const PROPELLER_CAMPAIGN_SUCCESS = "PROPELLER_CAMPAIGN_SUCCESS";
export const PROPELLER_CAMPAIGN_FAIL = "PROPELLER_CAMPAIGN_FAIL";
export const PROPELLER_OS_CAMPAIGN_REQUEST = "PROPELLER_OS_CAMPAIGN_REQUEST";
export const PROPELLER_OS_CAMPAIGN_SUCCESS = " PROPELLER_OS_CAMPAIGN_SUCCESS";
export const PROPELLER_OS_CAMPAIGN_FAIL = "PROPELLER_OS_CAMPAIGN_FAIL";
export const PROPELLER_MOBILE_REQUEST = "PROPELLER_MOBILE_REQUEST";
export const PROPELLER_MOBILE_SUCCESS = "PROPELLER_MOBILE_SUCCESS";
export const PROPELLER_MOBILE_FAIL = "PROPELLER_MOBILE_FAIL";
export const CAMPAIGN_DATE_REQUEST = "CAMPAIGN_DATE_REQUEST";
export const CAMPAIGN_DATE_SUCCESS = "CAMPAIGN_DATE_SUCCESS";
export const CAMPAIGN_DATE_FAIL = "CAMPAIGN_DATE_FAIL";
export const ADS_CLICK_REQUEST = "ADS_CLICK_REQUEST";
export const ADS_CLICK_SUCCESS = "ADS_CLICK_SUCCESS";
export const ADS_CLICK_FAIL = "ADS_CLICK_FAIL";
export const BITLY_CLICK_REQUEST = "BITLY_CLICK_REQUEST";
export const BITLY_CLICK_SUCCESS = "BITLY_CLICK_SUCCESS";
export const BITLY_CLICK_FAIL = "BITLY_CLICK_FAIL";
export const GET_APP_DOWNLOAD_ANALYTICS_REQUEST =
  "GET_APP_DOWNLOAD_ANALYTICS_REQUEST";
export const GET_APP_DOWNLOAD_ANALYTICS_SUCCESS =
  "GET_APP_DOWNLOAD_ANALYTICS_SUCCESS";
export const GET_APP_DOWNLOAD_ANALYTICS_FAIL =
  "GET_APP_DOWNLOAD_ANALYTICS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
