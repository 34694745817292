import React, { Fragment, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import MetaData from "../../../layout/MetaData";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import {
	getSingleAppDownloadCampaigns,
	clearErrors,
} from "../../../../actions/campaignActions";
import Loader from "../../../loader";

const AppDownloadDetails = () => {
	const { loading, error, singleAppCampaign } = useSelector(
		(state) => state.singleAppCampaign || {}
	);

	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch(clearErrors());
		}
	}, [dispatch, error]);

	useEffect(() => {
		dispatch(getSingleAppDownloadCampaigns(id));
	}, [dispatch, id]);

	return (
		<Fragment>
			{loading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Campaign Details"} />
					<div className="content-body">
						<div className="container pd-x-0">
							<div className="row justify-content-between">
								<div className="col-md-6 mg-b-20 mg-md-b-0">
									<Link
										to="../campaigns"
										className="tx-black"
									>
										<div>
											<i className="fa fa-angle-left mg-r-10 pd-t-15 tx-18"></i>
											<span className="tx-28 tx-bold mb-0">
												Campaigns
											</span>
										</div>
									</Link>
								</div>
							</div>
							<div className="card rounded bd-0 shadow-sm">
								<div className="card-body">
									<div className="d-flex">
										<div className="mg-r-20">
											<img
												src="https://res.cloudinary.com/mysogi/image/upload/v1687129551/website/Brand_Awareness_ktjz6d.svg"
												className="tx-primary"
												alt=""
												srcset=""
											/>
										</div>
										<div>
											<p className="tx-20 tx-bold pd-t-20">
												App download Details
											</p>
										</div>
									</div>
									<hr />

									<div className="row justify-content-between">
										<div className="col-md-6 col-lg-6 mg-b-20 mg-md-b-10">
											<p className="tx-18 tx-semibold mb-0">
												Basic Information
											</p>
											<div className="row mg-t-15">
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Campaign Name
													</label>
													<p className="tx-16 mb-0">
														{singleAppCampaign &&
															singleAppCampaign.campaignType}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Sender-ID
													</label>
													<p className="tx-16 mb-0">
														{singleAppCampaign &&
															singleAppCampaign.senderId}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Campaign-ID
													</label>
													<p className="tx-16 mb-0">
														{singleAppCampaign &&
															singleAppCampaign.id}
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-lg-5 mg-b-20 mg-md-b-10">
											<p className="tx-18 tx-semibold mb-0">
												Campaign
											</p>
											<div className="row mg-t-15">
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Campaign message
													</label>
													<p className="tx-16 mb-0">
														{singleAppCampaign &&
															singleAppCampaign.campaignMessage}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Channel
													</label>
													<p className="tx-16 mb-0">
														{singleAppCampaign &&
															singleAppCampaign.channel}
													</p>
												</div>
											</div>
										</div>
									</div>
									<hr />
									<div className="row justify-content-between">
										<div className="col-md-6 col-lg-5 mg-b-20 mg-md-b-10">
											<p className="tx-18 tx-semibold mb-0">
												Target Audience
											</p>
											<div className="row mg-t-15">
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Gender
													</label>
													<br />
													<p className="tx-16 mb-0 badge badge-pink">
														{singleAppCampaign &&
															singleAppCampaign.gender}
													</p>
												</div>

												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Interest
													</label>
													<br />
													<p className="tx-16 mb-0 badge badge-pink">
														{singleAppCampaign &&
															singleAppCampaign.Interest}
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-lg-5 mg-b-20 mg-md-b-10">
											<p className="tx-18 tx-semibold mb-0">
												Budget and Timing
											</p>
											<div className="row mg-t-15">
												<div className="form-group col-md-12">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Price
													</label>
													<p className="tx-16 mb-0">
														{singleAppCampaign &&
															singleAppCampaign.cost}
													</p>
												</div>

												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														From
													</label>
													<p className="tx-16 mb-0">
														{DateTime.fromJSDate(
															new Date(
																singleAppCampaign &&
																	singleAppCampaign.createdAt
															)
														).toFormat(
															"dd MMM, yyyy"
														)}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														To
													</label>
													<p className="tx-16 mb-0">
														{DateTime.fromJSDate(
															new Date(
																singleAppCampaign &&
																	singleAppCampaign.updatedAt
															)
														).toFormat(
															"dd MMM, yyyy"
														)}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default AppDownloadDetails;
