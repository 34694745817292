export const GET_WALLET_REQUEST = "GET_WALLET_REQUEST";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";
export const GET_WALLET_FAIL = "GET_WALLET_FAIL";
export const FUND_WALLET_REQUEST = "FUND_WALLET_REQUEST";
export const FUND_WALLET_SUCCESS = "FUND_WALLET_SUCCESS";
export const FUND_WALLET_RESET = "FUND_WALLET_RESET";
export const FUND_WALLET_FAIL = "FUND_WALLET_FAIL";

export const CONFIRM_FUNDING_REQUEST = "CONFIRM_FUNDING_REQUEST";
export const CONFIRM_FUNDING_SUCCESS = "CONFIRM_FUNDING_SUCCESS";
export const CONFIRM_FUNDING_FAIL = "CONFIRM_FUNDING_FAIL";
export const CONFIRM_FUNDING_RESET = "CONFIRM_FUNDING_RESET";

export const GET_TRANSACTION_HISTORY_REQUEST =
  "GET_TRANSACTION_HISTORY_REQUEST";
export const GET_TRANSACTION_HISTORY_SUCCESS =
  "GET_TRANSACTION_HISTORY_SUCCESS";
export const GET_TRANSACTION_HISTORY_FAIL = "GET_TRANSACTION_HISTORY_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
