import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import "../../dashforge.css";
import "../../main.css";

const Footer = () => {
	return (
		<Fragment>
			<section className="footer">
				<div className="footer">
					<div className="icons-div">
						<img
							src="https://res.cloudinary.com/mysogi/image/upload/v1687130798/website/logo_pwxnvp.svg"
							alt=""
						/>
						<div className="icons">
							<a href="https://twitter.com/MysogiAds">
								<img
									src="https://res.cloudinary.com/mysogi/image/upload/v1687131193/website/twitter_x71ybs.png"
									alt="twitter-icon"
								/>
							</a>
							<a href="https://www.instagram.com/mysogiads/">
								<img
									src="https://res.cloudinary.com/mysogi/image/upload/v1687131193/website/instagram_mw7pdz.png"
									alt="instagram-icon"
								/>
							</a>
							<a href="https://www.linkedin.com/company/mysogicompanylimited/">
								<img
									src="https://res.cloudinary.com/mysogi/image/upload/v1687131193/website/linkin_tqatvm.png"
									alt="linkdin-icon"
								/>
							</a>
							<a href="https://www.facebook.com/MysogiAdsCompany">
								<img
									src="https://res.cloudinary.com/mysogi/image/upload/v1687131277/website/facebook_ytbrba.png"
									alt="facebook-icon"
								/>
							</a>
						</div>
					</div>
					<div className="inner-footer">
						<div className="links">
							<h2>Quick Links</h2>
							<a href="#about-us">About Us</a>
							<a href="#about-us">Careers</a>
							<a href="#about-us">FAQs</a>
							<a href="#mysogi-contact">Help Center</a>
							<Link to="/terms-of-use">Terms of Use</Link>
							<Link to="/privacy-policy">Privacy Policy</Link>
						</div>
						<div className="links">
							<h2>OUR SERVICES</h2>
							<a href="#section-two">Billboard Marketing</a>
							<a href="#mini-website">Ecommerce Mini Website</a>
							<a href="#section-three">Smart Ads SMS</a>
							<a href="#section-six">Influencer Marketing</a>
							<a href="#section-four">Digital Ads</a>
							<a href="#section-five">Voice Ads</a>
						</div>
						<div className="links">
							<h2>Contact Info</h2>
							<p>
								9, Adedoyin Ogungbe Crescent,Lekki Phase 1,
								Lagos, Nigeria.
							</p>
							<p>+234 812 088 9773</p>
							<p>info@mysogi.com.ng</p>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
};

export default Footer;
