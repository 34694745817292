import React, { Fragment, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import MetaData from "../../layout/MetaData";
import {
	getSingleFlierVideosCampaigns,
	clearErrors,
} from "../../../actions/campaignActions";
import { getBitlyCount } from "../../../actions/analyticsActions";
import Loader from "../../loader";
import ActionsChart from "./SmartSms Chart/ActionsChart";

const SmartSmsAnalytics = () => {
	const { id } = useParams();
	const dispatch = useDispatch();

	const { loading, error, singleFlierCampaign } = useSelector(
		(state) => state.singleFlierCampaign || {}
	);

	const { bitlyCount } = useSelector((state) => state || {});

	useEffect(() => {
		dispatch(getSingleFlierVideosCampaigns(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (error || bitlyCount.error) {
			toast.error(error || bitlyCount.error);
			dispatch(clearErrors());
		}
	}, [dispatch, error, bitlyCount.error]);

	useMemo(() => {
		if (singleFlierCampaign?.bitlink) {
			const link = singleFlierCampaign?.bitlink.split("//").pop();
			dispatch(getBitlyCount(link));
		}
	}, [dispatch, singleFlierCampaign?.bitlink]);

	return (
		<Fragment>
			{loading || bitlyCount.blLoading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Campaign Details"} />
					<div className="content-body">
						<div className="container pd-x-0">
							<div className="row justify-content-between">
								<div className="col-md-6 mg-b-20 mg-md-b-0">
									<Link
										to="../analytics"
										className="tx-black"
									>
										<div>
											<i className="fa fa-angle-left mg-r-10 pd-t-15 tx-18"></i>
											<span className="tx-28 tx-bold mb-0">
												Analytics
											</span>
										</div>
									</Link>
								</div>
							</div>
							<div className="row justify-content-around">
								<div className="col-md-3 col-12 mg-t-20 mg-md-t-0">
									<div className="card rounded bd-0 shadow-sm row">
										<div className="card-body vh-36">
											<div className="d-flex">
												<div className="mg-r-20">
													{" "}
													<img
														src="https://res.cloudinary.com/mysogi/image/upload/v1687129551/website/Brand_Awareness_ktjz6d.svg"
														className="tx-primary"
														alt=""
													/>
												</div>
												<div>
													<p className="tx-24 tx-bold">
														{singleFlierCampaign?.count ===
														0
															? singleFlierCampaign?.smsSent
															: singleFlierCampaign?.count}
													</p>
													<p className="tx-15 tx-blac">
														Total number of
														impressions
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-12 mg-t-20 mg-md-t-0">
									<div className="card rounded bd-0 shadow-sm row">
										<div className="card-body vh-36">
											<div className="d-flex">
												<div className="mg-r-20">
													{" "}
													<img
														src="https://res.cloudinary.com/mysogi/image/upload/v1687140664/website/Reach_Conversion_Goals_kzlivd.svg"
														className="tx-primary"
														alt=""
														srcSet=""
													/>
												</div>
												<div>
													<p className="tx-24 tx-bold">
														{singleFlierCampaign?.bitlink ===
														null
															? "0"
															: bitlyCount
																	?.bitlyCounts
																	?.total_clicks >=
															  0
															? bitlyCount
																	?.bitlyCounts
																	?.total_clicks
															: "Please reload page!"}
													</p>
													<p className="tx-15 tx-blac">
														Total number of Clicks
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-3 col-12 mg-t-20 mg-md-t-0">
									<div className="card rounded bd-0 shadow-sm row">
										<div className="card-body vh-36">
											<div className="d-flex">
												<div className="mg-r-20">
													{" "}
													<img
														src="https://res.cloudinary.com/mysogi/image/upload/v1687140737/website/Revenue_Generated_nkfm9q.svg"
														className="tx-primary"
														alt=""
														srcSet=""
													/>
												</div>
												<div>
													<p className="tx-24 tx-bold">
														{singleFlierCampaign?.whatsAppNumberClickCount +
															singleFlierCampaign?.urlClickCount +
															singleFlierCampaign?.ussdClickCount +
															singleFlierCampaign?.phoneNumberClickCount +
															singleFlierCampaign?.smsNumberClickCount}
													</p>
													<p className="tx-15 tx-blac">
														Total number of Actions
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<ActionsChart />
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default SmartSmsAnalytics;
