export const SMS_CAMPAIGN_REQUEST = "SMS_CAMPAIGN_REQUEST";
export const SMS_CAMPAIGN_SUCCESS = "SMS_CAMPAIGN_SUCCESS";
export const SMS_CAMPAIGN_RESET = "SMS_CAMPAIGN_RESET";
export const SMS_CAMPAIGN_FAIL = "SMS_CAMPAIGN_FAIL";

export const VIDEO_FLIER_CAMPAIGN_REQUEST = "VIDEO_FLIER_CAMPAIGN_REQUEST";
export const VIDEO_FLIER_CAMPAIGN_SUCCESS = "VIDEO_FLIER_CAMPAIGN_SUCCESS";
export const VIDEO_FLIER_CAMPAIGN_FAIL = "VIDEO_FLIER_CAMPAIGN_FAIL";
export const VIDEO_FLIER_CAMPAIGN_RESET = "VIDEO_FLIER_CAMPAIGN_RESET";

export const GET_SMS_CAMPAIGN_REQUEST = "GET_SMS_CAMPAIGN_REQUEST";
export const GET_SMS_CAMPAIGN_SUCCESS = "GET_SMS_CAMPAIGN_SUCCESS";
export const GET_SMS_CAMPAIGN_FAIL = "GET_SMS_CAMPAIGN_FAIL";

export const GET_SMS_SINGLE_CAMPAIGN_REQUEST =
	"GET_SMS_SINGLE_CAMPAIGN_REQUEST";
export const GET_SMS_SINGLE_CAMPAIGN_SUCCESS =
	"GET_SMS_SINGLE_CAMPAIGN_SUCCESS";
export const GET_SMS_SINGLE_CAMPAIGN_FAIL = "GET_SMS_SINGLE_CAMPAIGN_FAIL";

export const VIEW_FLIER_VIDEO_CAMPAIGN_REQUEST =
	"VIEW_FLIER_VIDEO_CAMPAIGN_REQUEST";
export const VIEW_FLIER_VIDEO_CAMPAIGN_SUCCESS =
	"VIEW_FLIER_VIDEO_CAMPAIGN_SUCCESS";
export const VIEW_FLIER_VIDEO_CAMPAIGN_FAIL = "VIEW_FLIER_VIDEO_CAMPAIGN_FAIL";

export const VIEW_APP_DOWNLOAD_CAMPAIGN_REQUEST =
	"VIEW_APP_DOWNLOAD_CAMPAIGN_REQUEST";
export const VIEW_APP_DOWNLOAD_CAMPAIGN_SUCCESS =
	"VIEW_APP_DOWNLOAD_CAMPAIGN_SUCCESS";
export const VIEW_APP_DOWNLOAD_CAMPAIGN_FAIL =
	"VIEW_APP_DOWNLOAD_CAMPAIGN_FAIL";

export const VIEW_SINGLE_APP_CAMPAIGN_REQUEST =
	"VIEW_SINGLE_APP_CAMPAIGN_REQUEST";
export const VIEW_SINGLE_APP_CAMPAIGN_SUCCESS =
	"VIEW_SINGLE_APP_CAMPAIGN_SUCCESS";
export const VIEW_SINGLE_APP_CAMPAIGN_FAIL = "VIEW_SINGLE_APP_CAMPAIGN_FAIL";

export const VIEW_SINGLE_FLIER_CAMPAIGN_REQUEST =
	"VIEW_SINGLE_FLIER_CAMPAIGN_REQUEST";
export const VIEW_SINGLE_FLIER_CAMPAIGN_SUCCESS =
	"VIEW_SINGLE_FLIER_CAMPAIGN_SUCCESS";
export const VIEW_SINGLE_FLIER_CAMPAIGN_FAIL =
	"VIEW_SINGLE_FLIER_CAMPAIGN_FAIL";

export const APP_DOWNLOAD_CAMPAIGN_REQUEST = "APP_DOWNLOAD_CAMPAIGN_REQUEST";
export const APP_DOWNLOAD_CAMPAIGN_SUCCESS = "APP_DOWNLOAD_CAMPAIGN_SUCCESS";
export const APP_DOWNLOAD_CAMPAIGN_RESET = "APP_DOWNLOAD_CAMPAIGN_RESET";
export const APP_DOWNLOAD_CAMPAIGN_FAIL = "APP_DOWNLOAD_CAMPAIGN_FAIL";

export const GET_SINGLE_CAMPAIGN_REQUEST = "GET_SINGLE_CAMPAIGN_REQUEST";
export const GET_SINGLE_CAMPAIGN_SUCCESS = "GET_SINGLE_CAMPAIGN_SUCCESS";
export const GET_SINGLE_CAMPAIGN_FAIL = "GET_SINGLE_CAMPAIGN_FAIL";
export const INFLUENCER_CAMPAIGN_REQUEST = "INFLUENCER_CAMPAIGN_REQUEST";
export const INFLUENCER_CAMPAIGN_SUCCESS = "INFLUENCER_CAMPAIGN_SUCCESS";
export const INFLUENCER_CAMPAIGN_RESET = "INFLUENCER_CAMPAIGN_RESET";
export const INFLUENCER_CAMPAIGN_FAIL = "INFLUENCER_CAMPAIGN_FAIL";

export const BILLBOARD_CAMPAIGN_REQUEST = "BILLBOARD_CAMPAIGN_REQUEST";
export const BILLBOARD_CAMPAIGN_SUCCESS = "BILLBOARD_CAMPAIGN_SUCCESS";
export const BILLBOARD_CAMPAIGN_RESET = "BILLBOARD_CAMPAIGN_RESET";
export const BILLBOARD_CAMPAIGN_FAIL = "BILLBOARD_CAMPAIGN_FAIL";

export const GET_FILTERED_CONTACT_LIST_REQUEST =
	"GET_FILTERED_CONTACT_LIST_REQUEST";
export const GET_FILTERED_CONTACT_LIST_SUCCESS =
	"GET_FILTERED_CONTACT_LIST_SUCCESS";
export const GET_FILTERED_CONTACT_LIST_FAIL = "GET_FILTERED_CONTACT_LIST_FAIL";

export const GET_ALL_CAMPAIGN_REQUEST = "GET_ALL_CAMPAIGN_REQUEST";
export const GET_ALL_CAMPAIGN_SUCCESS = "GET_ALL_CAMPAIGN_SUCCESS";
export const GET_ALL_CAMPAIGN_FAIL = "GET_ALL_CAMPAIGN_FAIL";

export const SHOW_ADS_REQUEST = "SHOW_ADS_REQUEST";
export const SHOW_ADS_SUCCESS = "SHOW_ADS_SUCCESS";
export const SHOW_ADS_FAIL = "SHOW_ADS_FAIL";
export const SHOW_ADS_RESET = "SHOW_ADS_RESET";

export const GET_ALL_INFLUENCERS_REQUEST = "GET_ALL_INFLUENCERS_REQUEST";
export const GET_ALL_INFLUENCERS_SUCCESS = "GET_ALL_INFLUENCERS_SUCCESS";
export const GET_ALL_INFLUENCERS_FAIL = "GET_ALL_INFLUENCERS_FAIL";

export const GET_DIGITAL_CAMPAIGNS_REQUEST = "GET_DIGITAL_CAMPAIGNS_REQUEST";
export const GET_DIGITAL_CAMPAIGNS_SUCCESS = "GET_DIGITAL_CAMPAIGNS_SUCCESS";
export const GET_DIGITAL_CAMPAIGNS_FAIL = "GET_DIGITAL_CAMPAIGNS_FAIL";

export const GET_ALL_INFLUENCER_CAMPAIGN_REQUEST =
	"GET_ALL_INFLUENCER_CAMPAIGN_REQUEST";
export const GET_ALL_INFLUENCER_CAMPAIGN_SUCCESS =
	"GET_ALL_INFLUENCER_CAMPAIGN_SUCCESS";
export const GET_ALL_INFLUENCER_CAMPAIGN_FAIL =
	"GET_ALL_INFLUENCER_CAMPAIGN_FAIL";

export const GET_ALL_BILBOARD_PROVIDER_CAMPAIGN_REQUEST =
	"GET_ALL_BILBOARD_PROVIDER_CAMPAIGN_REQUEST";
export const GET_ALL_BILBOARD_PROVIDER_CAMPAIGN_SUCCESS =
	"GET_ALL_BILBOARD_PROVIDER_CAMPAIGN_SUCCESS";
export const GET_ALL_BILBOARD_PROVIDER_CAMPAIGN_FAIL =
	"GET_ALL_BILBOARD_PROVIDER_CAMPAIGN_FAIL";

export const GET_ALL_BILLBOARD_CAMPAIGN_REQUEST =
	"GET_ALL_BILLBOARD_CAMPAIGN_REQUEST";
export const GET_ALL_BILLBOARD_CAMPAIGN_SUCCESS =
	"GET_ALL_BILLBOARD_CAMPAIGN_SUCCESS";
export const GET_ALL_BILLBOARD_CAMPAIGN_FAIL =
	"GET_ALL_BILLBOARD_CAMPAIGN_FAIL";

export const GET_ALL_BILLBOARD_REQUEST = "GET_ALL_BILLBOARD_REQUEST";
export const GET_ALL_BILLBOARD_SUCCESS = "GET_ALL_BILLBOARD_SUCCESS";
export const GET_ALL_BILLBOARD_FAIL = "GET_ALL_BILLBOARD_FAIL";

export const GET_SINGLE_BILLBOARD_CAMPAIGN_REQUEST =
	"GET_SINGLE_BILLBOARD_CAMPAIGN_REQUEST";
export const GET_SINGLE_BILLBOARD_CAMPAIGN_SUCCESS =
	"GET_SINGLE_BILLBOARD_CAMPAIGN_SUCCESS";
export const GET_SINGLE_BILLBOARD_CAMPAIGN_FAIL =
	"GET_SINGLE_BILLBOARD_CAMPAIGN_FAIL";

export const GET_BILLBOARD_STATS_REQUEST = "GET_BILLBOARD_STATS_REQUEST";
export const GET_BILLBOARD_STATS_SUCCESS = "GET_BILLBOARD_STATS_SUCCESS";
export const GET_BILLBOARD_STATS_FAIL = "GET_BILLBOARD_STATS_FAIL";

export const UPDATE_INFLUENCER_CAMPAIGN_STATUS_REQUEST =
	"UPDATE_INFLUENCER_CAMPAIGN_STATUS_REQUEST";
export const UPDATE_INFLUENCER_CAMPAIGN_STATUS_SUCCESS =
	"UPDATE_INFLUENCER_CAMPAIGN_STATUS_SUCCESS";
export const UPDATE_INFLUENCER_CAMPAIGN_STATUS_FAIL =
	"UPDATE_INFLUENCER_CAMPAIGN_STATUS_FAIL";
export const UPDATE_INFLUENCER_CAMPAIGN_STATUS_RESET =
	"UPDATE_INFLUENCER_CAMPAIGN_STATUS_RESET";

export const UPDATE_BILLBOARD_CAMPAIGN_STATUS_REQUEST =
	"UPDATE_BILLBOARD_CAMPAIGN_STATUS_REQUEST";
export const UPDATE_BILLBOARD_CAMPAIGN_STATUS_SUCCESS =
	"UPDATE_BILLBOARD_CAMPAIGN_STATUS_SUCCESS";
export const UPDATE_BILLBOARD_CAMPAIGN_STATUS_FAIL =
	"UPDATE_BILLBOARD_CAMPAIGN_STATUS_FAIL";
export const UPDATE_BILLBOARD_CAMPAIGN_STATUS_RESET =
	"UPDATE_BILLBOARD_CAMPAIGN_STATUS_RESET";

export const UPDATE_BILLBOARD_PUBLISHED_STATUS_REQUEST =
	"UPDATE_BILLBOARD_PUBLISHED_STATUS_REQUEST";
export const UPDATE_BILLBOARD_PUBLISHED_STATUS_SUCCESS =
	"UPDATE_BILLBOARD_PUBLISHED_STATUS_SUCCESS";
export const UPDATE_BILLBOARD_PUBLISHED_STATUS_FAIL =
	"UPDATE_BILLBOARD_PUBLISHED_STATUS_FAIL";
export const UPDATE_BILLBOARD_PUBLISHED_STATUS_RESET =
	"UPDATE_BILLBOARD_PUBLISHED_STATUS_RESET";

export const UPDATE_INFLUENCER_PUBLISHED_STATUS_REQUEST =
	"UPDATE_INFLUENCER_PUBLISHED_STATUS_REQUEST";
export const UPDATE_INFLUENCER_PUBLISHED_STATUS_SUCCESS =
	"UPDATE_INFLUENCER_PUBLISHED_STATUS_SUCCESS";
export const UPDATE_INFLUENCER_PUBLISHED_STATUS_FAIL =
	"UPDATE_INFLUENCER_PUBLISHED_STATUS_FAIL";
export const UPDATE_INFLUENCER_PUBLISHED_STATUS_RESET =
	"UPDATE_INFLUENCER_PUBLISHED_STATUS_RESET";

export const GET_INFLUENCER_DETAILS_REQUEST = "GET_INFLUENCER_DETAILS_REQUEST";
export const GET_INFLUENCER_DETAILS_SUCCESS = "GET_INFLUENCER_DETAILS_SUCCESS";
export const GET_INFLUENCER_DETAILS_FAIL = "GET_INFLUENCER_DETAILS_FAIL";

export const ALL_USER_INFLUENCER_REQUEST = "ALL_USER_INFLUENCER_REQUEST";
export const ALL_USER_INFLUENCER_SUCCESS = "ALL_USER_INFLUENCER_SUCCESS";
export const ALL_USER_INFLUENCER_FAIL = "ALL_USER_INFLUENCER_FAIL";

export const SINGLE_USER_INFLUENCER_REQUEST = "SINGLE_USER_INFLUENCER_REQUEST";
export const SINGLE_USER_INFLUENCER_SUCCESS = "SINGLE_USER_INFLUENCER_SUCCESS";
export const SINGLE_USER_INFLUENCER_FAIL = "SINGLE_USER_INFLUENCER_FAIL";

export const GET_REVENUE_BAND_REQUEST = "GET_REVENUE_BAND_REQUEST";
export const GET_REVENUE_BAND_SUCCESS = "GET_REVENUE_BAND_SUCCESS";
export const GET_REVENUE_BAND_FAIL = "GET_REVENUE_BAND_FAIL";

export const GET_ALL_RADIO_PROVIDER_CAMPAIGN_REQUEST =
	"GET_ALL_RADIO_PROVIDER_CAMPAIGN_REQUEST";
export const GET_ALL_RADIO_PROVIDER_CAMPAIGN_SUCCESS =
	"GET_ALL_RADIO_PROVIDER_CAMPAIGN_SUCCESS";
export const GET_ALL_RADIO_PROVIDER_CAMPAIGN_FAIL =
	"GET_ALL_RADIO_PROVIDER_CAMPAIGN_FAIL";
export const UPDATE_RADIO_CAMPAIGN_STATUS_REQUEST =
	"UPDATE_RADIO_CAMPAIGN_STATUS_REQUEST";
export const UPDATE_RADIO_CAMPAIGN_STATUS_SUCCESS =
	"UPDATE_RADIO_CAMPAIGN_STATUS_SUCCESS";
export const UPDATE_RADIO_CAMPAIGN_STATUS_FAIL =
	"UPDATE_RADIO_CAMPAIGN_STATUS_FAIL";
export const UPDATE_RADIO_PUBLISHED_STATUS_REQUEST =
	"UPDATE_RADIO_PUBLISHED_STATUS_REQUEST";
export const UPDATE_RADIO_PUBLISHED_STATUS_SUCCESS =
	"UPDATE_RADIO_PUBLISHED_STATUS_SUCCESS";
export const UPDATE_RADIO_PUBLISHED_STATUS_FAIL =
	"UPDATE_RADIO_PUBLISHED_STATUS_FAIL";
export const UPDATE_RADIO_CAMPAIGN_STATUS_RESET =
	"UPDATE_RADIO_CAMPAIGN_STATUS_RESET";
export const UPDATE_RADIO_PUBLISHED_STATUS_RESET =
	"UPDATE_RADIO_PUBLISHED_STATUS_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
