import React, { Fragment, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

import MetaData from "../components/layout/MetaData";
import Loader from "../components/loader";
import { DateTime } from "luxon";
import { toast } from "react-toastify";

import { MDBDataTable } from "mdbreact";
import {
	getFilteredRadioPoviderCampaign,
	clearErrors,
} from "../actions/campaignActions";

import { getUser } from "../actions/authActions";

const FilteredRadioCampaign = () => {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const query = searchParams.get("q");

	const { radioLoading, radioProviderCampaignList, error } = useSelector(
		(state) => state.radioProviderCampaign
	);

	const { user } = useSelector((state) => state.auth);

	const setEndDate = (startDate, numOfDays) => {
		const date = new Date(startDate);
		date.setDate(date.getDate() + parseInt(numOfDays));

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");

		return `${year}/${month}/${day}`;
	};

	const setRadioCampaigns = () => {
		const data = {
			columns: [
				{
					label: "CAMPAIGN ID",
					field: "campaignId",
					sort: "asc",
				},
				{
					label: "ADS TYPE",
					field: "adType",
					sort: "asc",
				},
				{
					label: "DATE CREATED",
					field: "dateCreated",
					sort: "asc",
				},
				{
					label: "START DATE",
					field: "startDate",
					sort: "asc",
				},
				{
					label: "END DATE",
					field: "endDate",
					sort: "asc",
				},
				{
					label: "PRICE",
					field: "price",
					sort: "asc",
				},
				{
					label: "STATUS",
					field: "status",
					sort: "asc",
				},
				{
					label: "ACTIONS",
					field: "actions",
					sort: "asc",
				},
			],
			rows: [],
		};

		let reverseRadioProviderCampaignList =
			radioProviderCampaignList?.campaign
				? radioProviderCampaignList?.campaign.sort(
						(a, b) => b.radioCampaignId - a.radioCampaignId
				  )
				: [];

		reverseRadioProviderCampaignList.forEach((campaign) => {
			data.rows.push({
				checkBoxes: (
					<Fragment>
						<div className="custom-control custom-checkbox">
							<input
								type="checkbox"
								className="custom-control-input"
								id="customCheck1"
							/>
							<label
								className="custom-control-label"
								htmlFor="customCheck1"
							></label>
						</div>
					</Fragment>
				),
				campaignId: campaign.id,
				adType: campaign?.campaign_info?.adType,
				price: (
					<NumberFormat
						value={parseInt(campaign.totalCost)}
						displayType={"text"}
						thousandSeparator={true}
						prefix={"₦"}
					/>
				),
				dateCreated: DateTime.fromJSDate(
					new Date(campaign.createdAt)
				).toFormat("dd MMM, yyyy"),
				startDate: DateTime.fromJSDate(
					new Date(campaign?.campaign_info?.startDate)
				).toFormat("dd MMM, yyyy"),
				endDate: DateTime.fromJSDate(
					new Date(
						setEndDate(
							campaign?.campaign_info?.startDate,
							campaign?.campaign_info?.numOfDays
						)
					)
				).toFormat("dd MMM, yyyy"),
				status: (
					<span
						className={`badge d-flex-center ${
							(!campaign?.isAdminApproved ||
								campaign?.isAdminApproved) &&
							!campaign?.isPublished &&
							(!campaign?.isRejected || campaign?.isRejected) &&
							!campaign?.isApproved &&
							!campaign?.isAdminRejected
								? "badge-pink"
								: ""
						} 
              ${
					campaign?.isAdminApproved &&
					campaign?.isPublished &&
					!campaign?.isRejected &&
					campaign?.isApproved
						? "badge-active"
						: ""
				}
              ${
					!campaign?.isAdminApproved &&
					!campaign?.isPublished &&
					campaign?.isAdminRejected &&
					!campaign?.isApproved
						? "badge-danger"
						: ""
				}
              ${
					campaign?.isAdminApproved &&
					!campaign?.isPublished &&
					!campaign?.isRejected &&
					campaign?.isApproved
						? "badge-pending"
						: ""
				}
              `}
					>
						{campaign?.isAdminApproved &&
						!campaign?.isPublished &&
						!campaign?.isRejected &&
						campaign?.isApproved
							? "Approved"
							: null ||
							  ((!campaign?.isAdminApproved ||
									campaign?.isAdminApproved) &&
									!campaign?.isPublished &&
									(!campaign?.isRejected ||
										campaign?.isRejected) &&
									!campaign?.isApproved &&
									!campaign?.isAdminRejected)
							? "Pending"
							: null ||
							  (campaign.isAdminApproved &&
									campaign.isPublished &&
									!campaign.isRejected &&
									campaign.isApproved)
							? "Published"
							: null ||
							  (!campaign.isAdminApproved &&
									!campaign.isPublished &&
									!campaign.isApproved &&
									campaign.isAdminRejected)
							? "Rejected"
							: null}
					</span>
				),
				actions: (
					<Fragment>
						<div className="tx-black tx-14">
							<div className="d-flex">
								<Link
									to={`/radio/view-campaign/${campaign?.radioCampaignId}`}
								>
									<i className="fa fa-eye tx-orange pd-t-4 mg-r-5" />{" "}
									View{" "}
								</Link>
							</div>
						</div>
					</Fragment>
				),
			});
		});
		return data;
	};

	useEffect(() => {
		if (user && user.user.role === "radio_provider") {
			dispatch(
				getFilteredRadioPoviderCampaign(
					user.user.radio_provider_id,
					query
				)
			);
			dispatch(getUser());
		}
	}, [dispatch, user, query]);

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch(clearErrors());
		}
	}, [dispatch, error]);

	return (
		<Fragment>
			{radioLoading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Radio Campaign"} />
					<div className="content-body">
						<div className="container-fluid">
							<div className="row justify-content-between align-items-center">
								<div className="col-md-6 mg-b-20 mg-md-b-0">
									<Link to="/radio" className="tx-black">
										<div className="d-flex align-items-center">
											<i className="fa fa-angle-left mg-r-10 tx-18" />
											<div>
												<p className="tx-28 tx-bold mb-0">
													<span className="text-capitalize">
														{query}
													</span>{" "}
													Campaign
												</p>
											</div>
										</div>
									</Link>
								</div>
								<div className="col-md-5 col-xl-5 pd-xl-l-0 flex-basis-max">
									<div className="d-flex">
										<Link
											to="/radio"
											className="btn btn-outline-primary w-100"
										>
											Go Back
										</Link>
									</div>
								</div>
							</div>
							{user && user.user.role === "radio_provider" && (
								<div className="card rounded bd-0 shadow-sm mg-t-30">
									<div className="card-body pd-md-x-30 pd-t- mg-t-20 mg-md-t-0">
										<MDBDataTable
											responsive
											data={setRadioCampaigns()}
											className="px-3 scroll"
											bordered
											striped
											hover
											barReverse
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default FilteredRadioCampaign;
