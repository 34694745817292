import React, { useState, useRef } from "react";

const AudioPlayer = ({ src, mainPreview, onDelete }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const audioRef = useRef(null);

	const handlePlayPause = () => {
		const audio = audioRef.current;
		if (isPlaying) {
			audio.pause();
		} else {
			audio.play();
		}
		setIsPlaying(!isPlaying);
	};

	const handleAudioEnded = () => {
		setIsPlaying(false);
		setCurrentTime(0);
	};

	const handleTimeUpdate = () => {
		const audio = audioRef.current;
		setCurrentTime(audio.currentTime);
	};

	const handleSeek = (e) => {
		const audio = audioRef.current;
		const seekTime = e.target.value;
		audio.currentTime = seekTime;
		setCurrentTime(seekTime);
	};

	const handleDelete = () => {
		onDelete();
		setIsPlaying(false);
		setCurrentTime(0);
	};

	return (
		<div className="audio-player">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				height="100%"
				viewBox="0 0 704 230"
				fill="none"
			>
				<g>
					<path
						d="M9 79.667v70.666m35.333-106v159m35.334-106v35.334M115 62v120.381M150.333 9v212"
						stroke="red"
						strokeWidth="17.667"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="spike-path"
					>
						{isPlaying && (
							<animateTransform
								attributeName="transform"
								attributeType="XML"
								type="scale"
								from="0 0"
								to="0 10"
								dur="1s"
								repeatCount="indefinite"
								values="1,1; 1,0.3; 1,1"
								keyTimes="0; 0.5; 1"
								begin="0s"
							/>
						)}
					</path>
				</g>
				<g>
					<path
						d="M190.333 79.667v70.666m35.333-106v159m35.334-106v35.334M296.333 62v120.381M331.666 9v212"
						stroke="red"
						strokeWidth="17.667"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="spike-path"
					>
						{isPlaying && (
							<animateTransform
								attributeName="transform"
								type="scale"
								values="1,1; 1,0.3; 1,1"
								keyTimes="0; 0.5; 1"
								dur="1s"
								repeatCount="indefinite"
								begin="0.2s"
							/>
						)}
					</path>
				</g>
				<g>
					<path
						d="M371.667 79.6667V150.333M407 44.3333V203.333M442.334 97.3333V132.667M477.667 62V182.381M513 9V221"
						stroke="red"
						strokeWidth="17.667"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="spike-path"
					>
						{isPlaying && (
							<animateTransform
								attributeName="transform"
								attributeType="XML"
								type="scale"
								from="0 0"
								to="0 10"
								dur="1s"
								repeatCount="indefinite"
								values="1,1; 1,0.3; 1,1"
								keyTimes="0; 0.5; 1"
								begin="0s"
							/>
						)}
					</path>
				</g>
				<g>
					<path
						d="M553 79.6667V150.333M588.333 44.3333V203.333M623.667 97.3333V132.667M659 62V182.381M694.333 9V221"
						stroke="red"
						strokeWidth="17.667"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="spike-path"
					>
						{isPlaying && (
							<animateTransform
								attributeName="transform"
								attributeType="XML"
								type="scale"
								from="0 0"
								to="0 10"
								dur="1s"
								repeatCount="indefinite"
								values="1,1; 1,0.3; 1,1"
								keyTimes="0; 0.5; 1"
								begin="0.2s"
							/>
						)}
					</path>
				</g>
			</svg>
			{mainPreview && (
				<div className="d-flex-center w-100 mg-t-90">
					<button
						className="main-preview-button"
						onClick={handlePlayPause}
					>
						<i
							className={`fa ${
								isPlaying ? "fa-pause" : "fa-play"
							}`}
						></i>
					</button>
					<input
						type="range"
						className="seek-bar"
						value={currentTime}
						min={0}
						max={parseInt(
							audioRef.current && audioRef.current.duration
						)}
						step={0.001}
						onChange={handleSeek}
					/>
				</div>
			)}
			{!mainPreview && (
				<div className="audio-control">
					<button
						className={`play-pause-button audio-control-button ${
							isPlaying ? "playing" : ""
						}`}
						onClick={handlePlayPause}
						disabled={src === "" || src === null}
					>
						{isPlaying ? "Pause" : "Play"}
						<i className="fa fa-play"></i>
					</button>
					<button
						className="audio-control-button"
						onClick={handleDelete}
					>
						delete
						<i className="fa fa-trash"></i>
					</button>
				</div>
			)}

			<audio
				ref={audioRef}
				// controls
				src={src}
				onTimeUpdate={handleTimeUpdate}
				onEnded={handleAudioEnded}
			/>
		</div>
	);
};

export default AudioPlayer;
