// /* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect } from "react";
import "rc-slider/assets/index.css";
import Header from "./layout/Header";

import MetaData from "./layout/MetaData";
import Loader from "../components/loader";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {
	const { loading } = useSelector((state) => state.auth);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<Fragment>
			{loading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Terms and Conditions"} />
					<div className="w-100 overflow-x-hidden">
						<Header />
						<section className="header-part">
							<div className="container pd-lg-b-0">
								<div className="row">
									<div className="col-md-12 col-12 pd-md-r-0 order-2 order-md-1">
										<p className="tx-30 tx-center tx-bold mg-xl-50 mg-t-10">
											Privacy Policy
										</p>
									</div>
								</div>
							</div>
						</section>
						<section className="content-body">
							<div className="container pd-y-30 pd-md-b-0">
								<div className="row mg-t-10">
									<div className="col-md-12 col-12 mg-t-20">
										<div className="bd-0  card-height">
											<div className="tx-left pd-md-y-40 pd-md-x-30">
												<ol className="terms-list">
													<li>
														<p className="tx-20 tx-com tx-left tx-bold mb-0">
															Introduction
														</p>
														<p className="tx-16 tx-com tx-left mb-y-10">
															Mysogi respects your
															privacy and is
															committed to
															protecting it
															through our
															compliance with this
															policy. This policy
															describes:
														</p>
														<ol className="terms-list">
															<li>
																The types of
																information we
																may collect or
																that you may
																provide when you
																visit our
																website (the
																"Website"), use
																our services, or
																interact with
																us, and our
																practices for
																collecting,
																using,
																maintaining,
																protecting, and
																disclosing that
																information.
															</li>
															<li>
																This policy
																applies to
																information we
																collect:
																<ol className="terms-inner-list">
																	<li>
																		On this
																		Website.
																	</li>
																	<li>
																		In
																		email,
																		text,
																		and
																		other
																		electronic
																		messages
																		between
																		you and
																		this
																		Website
																		or us.
																	</li>
																	<li>
																		Through
																		mobile
																		and
																		desktop
																		applications
																		you
																		download
																		from
																		this
																		Website
																		or us,
																		which
																		provide
																		dedicated
																		non-browser-based
																		interaction
																		between
																		you and
																		this
																		Website
																		or us.
																	</li>
																	<li>
																		When you
																		interact
																		with our
																		advertising
																		and
																		applications
																		on
																		third-party
																		websites
																		and
																		services,
																		if those
																		applications
																		or
																		advertising
																		include
																		links to
																		this
																		policy.
																	</li>
																</ol>
															</li>
														</ol>
													</li>
													<li className="mg-t-20">
														<p className="tx-20 tx-com tx-left tx-bold mb-0">
															Information We
															Collect
														</p>
														<p className="tx-16 tx-com tx-left mb-y-10">
															We may collect
															several types of
															information from and
															about users of our
															Website and
															services, including:
														</p>
														<ol className="terms-list">
															<li>
																To present our
																Website and its
																contents to you.
																us.
															</li>
															<li>
																Automatically as
																you navigate
																through the site
																or use our
																services.
																Information
																collected
																automatically
																may include
																usage details,
																IP addresses,
																and information
																collected
																through cookies,
																web beacons, and
																other tracking
																technologies.
															</li>
														</ol>
													</li>
													<li className="mg-t-20">
														<p className="tx-20 tx-com tx-left tx-bold mb-0">
															How We Use Your
															Information
														</p>
														<p className="tx-16 tx-com tx-left mb-y-10">
															We may use
															information that we
															collect about you or
															that you provide to
															us, including any
															personal
															information:
														</p>
														<ol className="terms-list">
															<li>
																To present our
																Website and its
																contents to you.
															</li>
															<li>
																To provide you
																with
																information,
																products, or
																services that
																you request from
																us.
															</li>
															<li>
																To fulfill any
																other purpose
																for which you
																provide it.
															</li>
															<li>
																To carry out our
																obligations and
																enforce our
																rights arising
																from any
																contracts
																entered into
																between you and
																us.
															</li>
															<li>
																To notify you
																about changes to
																our Website or
																any products or
																services we
																offer or provide
																through it.
															</li>
														</ol>
													</li>
													<li className="mg-t-20">
														<p className="tx-20 tx-com tx-left tx-bold mb-0">
															Disclosure of Your
															Information
														</p>
														<p className="tx-16 tx-com tx-left mb-y-10">
															Mysogi respects your
															privacy and is
															committed to
															protecting it
															through our
															compliance with this
															policy. This policy
															describes:
														</p>
													</li>
													<li className="mg-t-20">
														<p className="tx-20 tx-com tx-left tx-bold mb-0">
															Choices About Your
															Information
														</p>
														<ol className="terms-list">
															<li>
																You can set your
																browser to
																refuse all or
																some browser
																cookies, or to
																alert you when
																cookies are
																being sent.
															</li>
															<li>
																If you do not
																wish to have
																your e-mail
																address/contact
																information used
																by us to promote
																our own or third
																parties'
																products or
																services, you
																can opt-out by
																sending us an
																email stating
																your request to
																[**Your Contact
																Email
																Address**].
															</li>
														</ol>
													</li>
													<li className="mg-t-20">
														<p className="tx-20 tx-com tx-left tx-bold mb-0">
															Data Security
														</p>
														<p className="tx-16 tx-com tx-left mb-y-10">
															We have implemented
															measures designed to
															secure your personal
															information from
															accidental loss and
															from unauthorized
															access, use,
															alteration, and
															disclosure.
														</p>
													</li>
													<li className="mg-t-20">
														<p className="tx-20 tx-com tx-left tx-bold mb-0">
															Changes to Our
															Privacy Policy
														</p>
														<p className="tx-16 tx-com tx-left mb-y-10">
															It is our policy to
															post any changes we
															make to our privacy
															policy on this page.
														</p>
													</li>
													<li className="mg-t-20">
														<p className="tx-20 tx-com tx-left tx-bold mb-0">
															Contact Information
														</p>
														<p className="tx-16 tx-com tx-left mb-y-10">
															To ask questions or
															comment about this
															privacy policy and
															our privacy
															practices, contact
															us at: [Your Contact
															Information].
														</p>
													</li>
												</ol>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default PrivacyPolicy;
