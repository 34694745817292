import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";

import MetaData from "../../../layout/MetaData";
import { getWallet } from "../../../../actions/billingActions";
import {
	createInfluencerCampaignAction,
	clearErrors,
} from "../../../../actions/campaignActions";
import { INFLUENCER_CAMPAIGN_RESET } from "../../../../constants/campaignConstants";
import Loader from "../../../loader";
import MediaPlayer from "../../../../_helpers/reactPlayer/ReactPlayer";
import useAnalyticsEventTracker from "../../../../_helpers/GoogleAnalytics/GoogleAnalytics";

const PreviewInfluencerCampaign = ({
	nextStep,
	prevStep,
	values,
	attachment,
	checkedInfluencers,
	handlePrice,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { error, createInfluencerCampaign, loading } = useSelector(
		(state) => state.influencerCampaign || []
	);
	const { wallet } = useSelector((state) => state.wallet);
	const [walletTotal, setTotal] = useState(0);
	const [payload, setPayload] = useState({});
	const gaEventTracker = useAnalyticsEventTracker("Influencer Campaign");

	const Continue = (e) => {
		e.preventDefault();
		nextStep();
	};

	const Previous = (e) => {
		e.preventDefault();
		prevStep();
	};

	useEffect(() => {
		if (
			createInfluencerCampaign &&
			createInfluencerCampaign.status === "success"
		) {
			gaEventTracker(
				"Influencer Campaign",
				"User created Influencer campaign"
			);
			toast.success(createInfluencerCampaign.message);
			dispatch(getWallet());
			navigate("/app/campaigns");
			dispatch({ type: INFLUENCER_CAMPAIGN_RESET });
		} else if (error) {
			toast.error(error);
			dispatch(clearErrors());
			dispatch(getWallet());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, error, createInfluencerCampaign, navigate]);

	const filteredValue = checkedInfluencers;

	const handlePreviewPrice = useCallback(
		(amount) => {
			handlePrice(amount);
		},
		[handlePrice]
	);

	useEffect(() => {
		// Effect for calculating total and updating price
		let allTotals = filteredValue.map((el) => {
			return getTotal(el);
		});
		const total = allTotals.reduce((acc, curr) => acc + curr, 0);
		setTotal(total);
		handlePreviewPrice(total);
	}, [filteredValue, handlePreviewPrice]);

	useEffect(() => {
		// Effect for updating payload
		let platforms = filteredValue.map((el) => {
			return {
				influencer_id: el.id,
				cost: getTotal(el),
				platform: el.platforms.map((ele) => ele.id).join(", "),
				allPlatform:
					el.platforms.findIndex((el) => el.id === "all") !== -1
						? true
						: false,
			};
		});

		const payload = {
			campaignMessage: values.campaignMessage,
			campaignType: values.campaignType,
			attachment: values.attachment,
			platform: platforms,
		};
		setPayload(payload);
	}, [
		filteredValue,
		values.attachment,
		values.campaignMessage,
		values.campaignType,
	]);

	const submitInfluencerCampaignHandler = (e) => {
		e.preventDefault();

		dispatch(createInfluencerCampaignAction(payload));
	};

	const getTotal = (p) => {
		let allIndex = p.platforms.findIndex((el) => el.id === "all");
		let total;
		if (allIndex !== -1) {
			total = parseInt(p.platforms[allIndex].cost);
		} else {
			total = p.platforms.reduce(
				(accumulator, current) => accumulator + parseInt(current.cost),
				0
			);
		}

		return total;
	};

	return (
		<Fragment>
			{loading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Preview Campaign"} />
					<div className="content-body">
						<div className="container pd-x-0">
							<p className="tx-24 tx-bold tx-com">
								Selection Preview
							</p>
							<div className="card bd-0 rounded shadow-sm">
								<div className="card-body pd-md-x-30">
									<div className="col-xl-11 mx-auto">
										<div className="row justify-content-between">
											<div className="col-md-7">
												<div className="d-flex justify-content-between">
													<div>
														<p className="tx-18 mb-0 tx-bold tx-com">
															Campaign Preview
														</p>
													</div>
												</div>
												<div className="row mg-t-15">
													<div className="form-group col-md-6">
														<label className="tx-14 tx-gray mb-0 tx-medium">
															Campaign Message
														</label>
														<p className="tx-14 mb-0">
															{
																values.campaignMessage
															}
														</p>
													</div>
												</div>
											</div>
											<div className="col-md-5">
												<p className="tx-18 mb-0 tx-bold tx-com">
													Preview
												</p>
												{values.assetType ===
												"image" ? (
													<div>
														<img
															src={
																values.attachment
															}
															className="img-fluid"
															alt="logo"
														/>
													</div>
												) : (
													<>
														<div>
															<MediaPlayer
																url={attachment}
																height={"400px"}
															/>
														</div>
													</>
												)}
											</div>
										</div>
									</div>
									<div className="table-responsive">
										<table
											className="table inf-table"
											id="campaig"
										>
											<thead className="tx-uppercase tx-medium">
												<tr>
													<th scope="col">
														Influencer
													</th>
													<th scope="col">
														Instagram
													</th>
													<th scope="col">
														Snapchat
													</th>
													<th scope="col">Twitter</th>
													<th scope="col">
														Facebook
													</th>
													<th scope="col">All</th>
													<th
														scope="col"
														className="tx-right"
													>
														Total Amount
													</th>
													{/* <th /> */}
												</tr>
											</thead>
											<tbody>
												{filteredValue?.map(
													(platform, i) => (
														<tr key={i}>
															<td>
																<div className="d-flex">
																	<div className="div">
																		<div className="avatar avatar-sm">
																			<img
																				src={
																					platform.imagePath
																				}
																				className="rounded-circle"
																				alt=""
																			/>
																		</div>
																	</div>
																	<div className="mg-l-10">
																		<p className="mb-0 pd-t-5">
																			{
																				platform.name
																			}
																		</p>
																	</div>
																</div>
															</td>
															<td>
																{platform.platforms.find(
																	(p) =>
																		p.id ===
																		"instagram"
																)?.cost || "-"}
															</td>
															<td>
																{platform.platforms.find(
																	(p) =>
																		p.id ===
																		"snapchat"
																)?.cost || "-"}
															</td>
															<td>
																{platform.platforms.find(
																	(p) =>
																		p.id ===
																		"twitter"
																)?.cost || "-"}
															</td>
															<td>
																{platform.platforms.find(
																	(p) =>
																		p.id ===
																		"facebook"
																)?.cost || "-"}
															</td>
															<td>
																{platform.platforms.find(
																	(p) =>
																		p.id ===
																		"all"
																)?.cost || "-"}
															</td>
															<td className="tx-right">
																{
																	<NumberFormat
																		value={parseInt(
																			getTotal(
																				platform
																			)
																		)}
																		displayType={
																			"text"
																		}
																		thousandSeparator={
																			true
																		}
																		prefix={
																			"₦"
																		}
																	/>
																}
															</td>
															{/* < */}
															{/* <td>
                              <div className="d-flex pd-t-3">
                                <div>
                                  <i className="fa fa-edit tx-primary mg-r-5 tx-semibold" />
                                </div>
                                <p className="mb-0">Edit</p>
                              </div>
                            </td> */}
														</tr>
													)
												)}
											</tbody>
										</table>
										<div className="col-md-5 col-12 offset-md-7 pd-md-x-0">
											<div className="card card-body bg-pink bd-0 rounded">
												<div className="row">
													<div className="col-6">
														<p className="mb-0 tx-right">
															Total Amount
														</p>
													</div>
													<div className="col-6">
														<p className="mb-0 tx-right tx-medium">
															{
																<NumberFormat
																	value={parseInt(
																		walletTotal
																	)}
																	displayType={
																		"text"
																	}
																	thousandSeparator={
																		true
																	}
																	prefix={"₦"}
																/>
															}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-5 pd-x-0 mg-y-40">
										<div className="d-flex justify-content-between">
											{parseInt(
												wallet && wallet.balance
											) < walletTotal ? (
												<button
													className="btn btn-primary pd-x-40 tx-com mg-r-15"
													onClick={Continue}
													disabled={
														loading ? true : false
													}
													type="submit"
													variant="contained"
												>
													Fund Wallet
												</button>
											) : (
												<button
													className="w-100 btn btn-primary pd-x-40 tx-com mg-r-15"
													onClick={
														submitInfluencerCampaignHandler
													}
													disabled={
														loading ? true : false
													}
													type="submit"
													variant="contained"
												>
													Publish
												</button>
											)}
											<button
												className="w-100 btn btn-outline-primary pd-x-30"
												onClick={Previous}
												disabled={
													loading ? true : false
												}
												type="submit"
												variant="contained"
											>
												Go Back
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default PreviewInfluencerCampaign;
