import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MetaData from "../layout/MetaData";
import { clearErrors, sendNewPassword } from "../../actions/authActions";
import { NEW_PASSWORD_RESET } from "../../constants/authConstants";
import Loader from "../loader";

const UserPasswordUpdate = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const {
    sendNewPassword: { message, loading, error },
  } = useSelector((state) => state);

  useEffect(() => {
    if (message && message.statusCode === 100) {
      toast.success(message.message);
      dispatch({ type: NEW_PASSWORD_RESET });
      navigate("/login");
    } else if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, navigate, message, error]);

  const [password, setPassword] = useState({
    resetLink: uuid,
    password: "",
    confirmPassword: "",
  });

  ///////////////// HANDLERS////////////////////////
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setPassword({ ...password, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(sendNewPassword(password));
  };

  return (
    <Fragment>
      {loading ? <Loader /> : null}
      <MetaData title={"Create New Password"} />
      <section className="ht-100v container-fluid">
        <div className="col-md-12 login-side">
          <div className="container pd-lg-30 pd-10">
            <div>
              <div className="col-lg-10 col-xl-8 mx-auto pd-t-30 tx-center welcome-div">
                <p className="tx-36 tx-bold mb-2 tx-com">Create New Password</p>
                <p className="tx-16 tx-gray">
                  Please, input your preferred password.
                </p>
                <form className="mg-y-50">
                  <div className="form-group">
                    <input
                      className="form-control new"
                      placeholder="New password"
                      name="password"
                      type="password"
                      id="password"
                      value={password.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control new"
                      placeholder="Confirm New password"
                      name="confirmPassword"
                      type="password"
                      id="confirmPassword"
                      value={password.confirmPassword}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mg-y-30">
                    <div className="form-group col-md-5 mx-auto">
                      <button
                        className="btn btn-primary btn-block btn-lg py-15"
                        type="submit"
                        onClick={submitHandler}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </form>
                <p className="tx-blac tx-12 pd-t-50 mb-0">mysogi ads &copy;</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default UserPasswordUpdate;
