import React, { Fragment, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import MetaData from "../../../layout/MetaData";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import {
	getSingleFlierVideosCampaigns,
	clearErrors,
} from "../../../../actions/campaignActions";
import Loader from "../../../loader";

const FlierVideosDetails = () => {
	const { loading, error, singleFlierCampaign } = useSelector(
		(state) => state.singleFlierCampaign || {}
	);

	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch(clearErrors());
		}
	}, [dispatch, error]);

	useEffect(() => {
		dispatch(getSingleFlierVideosCampaigns(id));
	}, [dispatch, id]);

	const [startDate, endDate] = (singleFlierCampaign?.timeRange ?? "").split(
		" - "
	);

	const googleInterest = [
		{ value: 3, label: "Arts & Entertainment" },
		{ value: 47, label: "Autos & Vehicles" },
		{ value: 44, label: "Beauty & Fitness" },
		{ value: 22, label: "Books & Literature" },
		{ value: 12, label: "Business & Industrial" },
		{ value: 5, label: "Computers & Electronics" },
		{ value: 7, label: "Finance" },
		{ value: 71, label: "Food & Drink" },
		{ value: 8, label: "Games" },
		{ value: 45, label: "Health" },
		{ value: 65, label: "Hobbies & Leisure" },
		{ value: 11, label: "Home & Garden" },
		{ value: 13, label: "Internet & Telecom" },
		{ value: 958, label: "Jobs & Education" },
		{ value: 19, label: "Law & Government" },
		{ value: 16, label: "News" },
		{ value: 299, label: "Online Communities" },
		{ value: 14, label: "People & Society" },
		{ value: 66, label: "Pets & Animals" },
		{ value: 29, label: "Real Estate" },
		{ value: 533, label: "Reference" },
		{ value: 174, label: "Science" },
		{ value: 18, label: "Shopping" },
		{ value: 20, label: "Sports" },
		{ value: 67, label: "Travel & Transportation" },
		{ value: 5000, label: "World Localities" },
	];

	const getCommaSeparatedLabels = (interest) => {
		const selectedLabels = interest
			?.split(",")
			?.map((value) => {
				const interestValue = Number(value.trim());
				const labelObject = googleInterest.find(
					(item) => item.value === interestValue
				);
				return labelObject ? labelObject.label : "";
			})
			.filter(Boolean);

		return selectedLabels ? selectedLabels.join(", ") : "N/A";
	};

	return (
		<Fragment>
			{loading ? (
				<Loader />
			) : (
				<Fragment>
					<MetaData title={"Campaign Details"} />
					<div className="content-body">
						<div className="container pd-x-0">
							<div className="row justify-content-between">
								<div className="col-md-6 mg-b-20 mg-md-b-0">
									<Link
										to="../campaigns"
										className="tx-black"
									>
										<div>
											<i className="fa fa-angle-left mg-r-10 pd-t-15 tx-18"></i>
											<span className="tx-28 tx-bold mb-0">
												Campaigns
											</span>
										</div>
									</Link>
								</div>
							</div>
							<div className="card rounded bd-0 shadow-sm">
								<div className="card-body">
									<div className="d-flex">
										<div className="mg-r-20">
											<img
												src="https://res.cloudinary.com/mysogi/image/upload/v1687129551/website/Brand_Awareness_ktjz6d.svg"
												className="tx-primary"
												alt=""
											/>
										</div>
										<div>
											<p className="tx-20 tx-bold pd-t-20">
												Digital/Display Ads Details
											</p>
										</div>
									</div>
									<hr />

									<div className="row justify-content-between">
										<div className="col-md-6 col-lg-6 mg-b-20 mg-md-b-10">
											<p className="tx-18 tx-semibold mb-0">
												Basic Information
											</p>
											<div className="row mg-t-15">
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Campaign Type
													</label>
													<p className="tx-16 mb-0">
														{singleFlierCampaign?.channel ===
														"smart_sms"
															? "Smart SMS"
															: "Display Ads"}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Sender-ID
													</label>
													<p className="tx-16 mb-0">
														{singleFlierCampaign?.senderId ??
															"N/A"}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Campaign-ID
													</label>
													<p className="tx-16 mb-0">
														{
															singleFlierCampaign?.id
														}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Date Created
													</label>
													<p className="tx-16 mb-0">
														{DateTime.fromJSDate(
															new Date(
																singleFlierCampaign?.createdAt
															)
														).toFormat(
															"dd MMM, yyyy hh:mm a"
														)}
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-lg-5 mg-b-20 mg-md-b-10">
											<p className="tx-18 tx-semibold mb-0">
												Campaign
											</p>
											<div className="row mg-t-15">
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Campaign message
													</label>
													<p className="tx-16 mb-0">
														{
															singleFlierCampaign?.campaignMessage
														}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Call To Action
													</label>
													<p className="tx-16 mb-0">
														{
															singleFlierCampaign?.callToAction
														}
													</p>
												</div>
											</div>
										</div>
									</div>
									<hr />
									<div className="row justify-content-between">
										<div className="col-md-6 col-lg-5 mg-b-20 mg-md-b-10">
											<p className="tx-18 tx-semibold mb-0">
												Target Audience
											</p>
											<div className="row mg-t-15">
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Gender
													</label>
													<br />
													<p className="tx-16 mb-0">
														{singleFlierCampaign?.gender ===
														null
															? "N/A"
															: singleFlierCampaign?.gender ===
															  "M"
															? "Male"
															: singleFlierCampaign?.gender ===
															  "F"
															? "Female"
															: singleFlierCampaign?.gender ===
																	"B" &&
															  "Both"}
													</p>
												</div>

												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Age
													</label>
													<br />
													<p className="tx-16 mb-0">
														{!singleFlierCampaign
															?.filterOptions?.[0]
															?.ageRange &&
														!singleFlierCampaign
															?.filterOptions
															?.ageRange
															? "N/A"
															: singleFlierCampaign
																	?.filterOptions?.[0]
																	?.ageRange ||
															  singleFlierCampaign
																	?.filterOptions
																	?.ageRange}
													</p>
												</div>

												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														State
													</label>
													<br />
													<p className="tx-16 mb-0">
														{!singleFlierCampaign
															?.filterOptions?.[0]
															?.state &&
														!singleFlierCampaign
															?.filterOptions
															?.state
															? "N/A"
															: singleFlierCampaign
																	?.filterOptions?.[0]
																	?.state ||
															  singleFlierCampaign
																	?.filterOptions
																	?.state}
													</p>
												</div>

												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														LGA
													</label>
													<br />
													<p className="tx-16 mb-0">
														{!singleFlierCampaign
															?.filterOptions?.[0]
															?.lga &&
														!singleFlierCampaign
															?.filterOptions?.lga
															? "N/A"
															: singleFlierCampaign
																	?.filterOptions?.[0]
																	?.lga ||
															  singleFlierCampaign
																	?.filterOptions
																	?.lga}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Area
													</label>
													<br />
													<p className="tx-16 mb-0">
														{!singleFlierCampaign
															?.filterOptions?.[0]
															?.area &&
														!singleFlierCampaign
															?.filterOptions
															?.area
															? "N/A"
															: singleFlierCampaign
																	?.filterOptions?.[0]
																	?.area ||
															  singleFlierCampaign
																	?.filterOptions
																	?.area}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Revenue Band
													</label>
													<br />
													<p className="tx-16 mb-0">
														{!singleFlierCampaign
															?.filterOptions?.[0]
															?.revenueBand &&
														!singleFlierCampaign
															?.filterOptions
															?.revenueBand
															? "N/A"
															: singleFlierCampaign
																	?.filterOptions?.[0]
																	?.revenueBand ||
															  singleFlierCampaign
																	?.filterOptions
																	?.revenueBand}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Interest
													</label>
													<br />
													<p className="tx-16 mb-0">
														{getCommaSeparatedLabels(
															singleFlierCampaign?.interest
														)}
													</p>
												</div>
											</div>
										</div>
										<div className="col-md-6 col-lg-5 mg-b-20 mg-md-b-10">
											<p className="tx-18 tx-semibold mb-0">
												Budget and Timing
											</p>
											<div className="row mg-t-15">
												<div className="form-group col-md-12">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														Price
													</label>
													<p className="tx-16 mb-0">
														<NumberFormat
															className="tx-red tx-bold"
															value={parseInt(
																singleFlierCampaign?.cost
															)}
															displayType={"text"}
															thousandSeparator={
																true
															}
															prefix={"₦"}
														/>
													</p>
												</div>

												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														From
													</label>

													<p className="tx-16 mb-0">
														{singleFlierCampaign?.scheduleFrom ||
														startDate
															? DateTime.fromJSDate(
																	new Date(
																		singleFlierCampaign?.scheduleFrom ||
																			startDate
																	)
															  ).toFormat(
																	"dd MMM, yyyy"
															  )
															: "N/A"}
													</p>
												</div>
												<div className="form-group col-md-6">
													<label className="tx-14 tx-gray mb-0 tx-medium">
														To
													</label>
													<p className="tx-16 mb-0">
														{singleFlierCampaign?.scheduleFrom ||
														endDate
															? DateTime.fromJSDate(
																	new Date(
																		singleFlierCampaign?.scheduleTo ||
																			endDate
																	)
															  ).toFormat(
																	"dd MMM, yyyy"
															  )
															: "N/A"}
														{}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default FlierVideosDetails;
