export const GET_SENDERID_REQUEST = "GET_SENDERID_REQUEST";
export const GET_SENDERID_SUCCESS = "GET_SENDERID_SUCCESS";
export const GET_SENDERID_FAIL = "GET_SENDERID_FAIL";

export const GET_DEFAULT_SENDERID_REQUEST = "GET_DEFAULT_SENDERID_REQUEST";
export const GET_DEFAULT_SENDERID_SUCCESS = "GET_DEFAULT_SENDERID_SUCCESS";
export const GET_DEFAULT_SENDERID_FAIL = "GET_DEFAULT_SENDERID_FAIL";


export const GET_GENERAL_REQUEST = "GET_GENERAL_REQUEST";
export const GET_GENERAL_SUCCESS = "GET_GENERAL_SUCCESS";
export const GET_GENERAL_FAIL = "GET_GENERAL_FAIL";

export const GET_ADMIN_SENDERID_REQUEST = "GET_ADMIN_SENDERID_REQUEST";
export const GET_ADMIN_SENDERID_SUCCESS = "GET_ADMIN_SENDERID_SUCCESS";
export const GET_ADMIN_SENDERID_FAIL = "GET_ADMIN_SENDERID_FAIL";

export const UPDATE_ADMIN_SENDERID_REQUEST = "UPDATE_ADMIN_SENDERID_REQUEST";
export const UPDATE_ADMIN_SENDERID_SUCCESS = "UPDATE_ADMIN_SENDERID_SUCCESS";
export const UPDATE_ADMIN_SENDERID_FAIL = "UPDATE_ADMIN_SENDERID_FAIL";
export const UPDATE_ADMIN_SENDERID_RESET = "UPDATE_ADMIN_SENDERID_RESET";

export const CREATE_SENDERID_REQUEST = "CREATE_SENDERID_REQUEST";
export const CREATE_SENDERID_SUCCESS = "CREATE_SENDERID_SUCCESS";
export const CREATE_SENDERID_RESET = "CREATE_SENDERID_RESET";
export const CREATE_SENDERID_FAIL = "CREATE_SENDERID_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
