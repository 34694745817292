import { Fragment } from "react";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";

import MetaData from "../../../layout/MetaData";

const RadioCampaign = ({
	nextStep,
	prevStep,
	selectedSpot,
	spot,
	session,
	handleChange,
	price,
	selectedStation,
	constructSpot,
	adType,
	numOfDays,
	selectedSession,
	handleKeyDown,
}) => {
	const Continue = (e) => {
		e.preventDefault();
		if (session === "") {
			toast.error("Select campaign session");
		} else if (spot < 1) {
			toast.error("Select campaign spot");
		} else if (numOfDays < 1) {
			toast.error("Enter number of days to run campaign");
		} else {
			nextStep();
		}
	};
	// const maxValue = 365;

	const selectAdsType = [
		{
			label: "Select Ads Type",
			value: "",
		},
		{
			label: "Jingle",
			value: "Jingle",
		},
		{
			label: "Announcement",
			value: "Announcement",
		},
	];

	const setSessionOffer = (filter) => {
		const lowerCaseFilter = filter?.toLowerCase();
		return (
			selectedStation?.offer.filter(
				(item) => item?.adType?.toLowerCase() === lowerCaseFilter
			) || []
		);
	};

	return (
		<Fragment>
			<MetaData title={"Radio Ads Campaign"} />
			<div className="card bd-0 content-body">
				<div className="card-body bg-white pd-30-f container pd-x-0">
					<div className="mg-b-20 mg-md-b-50">
						<div className="d-flex justify-content-between">
							<p className="tx-18 mb-0 tx-com tx-bold">75%</p>
							<p className="tx-18 mb-0 tx-com tx-bold">
								3 out of 4
							</p>
						</div>
						<div className="progress">
							<div
								className="progress-bar bg-primary progress-bar-striped progress-bar-animated wd-75p"
								role="progressbar"
								aria-valuenow={60}
								aria-valuemin={0}
								aria-valuemax={100}
							/>
						</div>
					</div>
					<div className="row justify-content-between align-items-center">
						<div className="col-md-6 col-12 mg-b-20">
							<p className="tx-22 tx-bold mb-2">
								Select your radio ads preference
							</p>
							<p className="tx-blac tx-14">
								Select the goal that would make the campaign
								successful for you
							</p>
						</div>
						<div className="col-md-6 col-12 mg-b-20">
							<div className="d-flex align-items-center justify-content-end">
								<h3 className="tx-primary tx-semi-bold tx-16 mg-0 mg-r-10">
									Campaign Cost:
								</h3>
								<NumberFormat
									className="tx-bold tx-22"
									value={price}
									displayType={"text"}
									thousandSeparator={true}
									prefix={"₦"}
								/>
							</div>
						</div>
					</div>
					<div className="align-items-start row justify-content-between">
						<div className="col-md-6 col-6 mg-t-20">
							<form className="d-flex row">
								<div className="col-md-12 form-group">
									<label className="mb-2">
										Campaign Type
									</label>
									<select
										className="custom-select"
										defaultValue={adType}
										onChange={handleChange("adType")}
									>
										{selectAdsType.map((selectType, i) => (
											<option
												value={selectType.value}
												key={i}
											>
												{selectType.label}
											</option>
										))}
									</select>
								</div>
								<div className="col-md-12 form-group">
									<label className="mb-2">
										Campaign Session (Time when Ad would be
										aired)
									</label>
									<select
										className="custom-select"
										defaultValue={session}
										onChange={handleChange(
											"selectedSession"
										)}
									>
										<option value={""}>
											Select Session
										</option>
										{setSessionOffer(adType).map(
											(session) => (
												<option
													value={JSON.stringify(
														session
													)}
													key={session.id}
												>
													{session.session}
												</option>
											)
										)}
									</select>
								</div>
								<div className="col-md-12 form-group">
									<label className="mb-2">
										No of Spots daily (No. of time ads will
										be aired)
									</label>
									<select
										className="custom-select"
										value={spot}
										onChange={handleChange("spot")}
									>
										{constructSpot(selectedSpot).map(
											(selectSpot, i) => (
												<option
													value={selectSpot.value}
													key={i}
												>
													{selectSpot.label}
												</option>
											)
										)}
									</select>
								</div>
								<div className="col-md-12 form-group">
									<label className="mb-2">
										Enter No of Days for the Campaign
									</label>
									<input
										type="number"
										className="form-control"
										defaultValue={numOfDays}
										placeholder="Enter the total number of days the  ads should run for"
										onChange={handleChange("numOfDays")}
										max={365}
										onKeyDown={handleKeyDown}
									/>
								</div>
							</form>
						</div>
					</div>
					<div className="col-md-4 pd-x-0 mg-y-30">
						<div className="d-flex">
							<button
								className="btn btn-primary w-100 mg-b-15"
								onClick={Continue}
								type="submit"
								variant="contained"
							>
								Proceed
							</button>
							<button
								onClick={prevStep}
								className="btn btn-outline-primary w-100 mg-b-15 mg-l-20"
							>
								Go Back
							</button>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default RadioCampaign;
