import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import MainRoutes from "./routes/Routes";
// import "mdbreact/dist/css/mdb.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "./main.css";
import "./style.css";
import "./dashforge.css";

import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

function App() {
	const TRACKING_ID = "G-537E2KEWXL"; // MYSOGI TRACKING_ID
	ReactGA.initialize(TRACKING_ID);

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: window.location.pathname + window.location.search,
		});
	}, []);

	return <MainRoutes />;
}

export default App;
